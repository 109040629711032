import * as sup from 'superstruct';

import { notEmpty } from '@/modules/form/utils/structs';
import { supplierLanguageStruct } from '@/modules/language/supplierLanguage/supplierLanguageConsts';
import { recompose } from '@/utils/structs/recompose';

import { contactPersonStruct } from '../ContactPersonFormDialog/contactPersonFormStruct';

export const supplierFormStruct = sup.object({
  name: recompose(sup.string(), notEmpty),
  language: sup.optional(supplierLanguageStruct),
  categoryId: sup.nullable(sup.string()),
  organizationNumber: sup.nullable(sup.string()),
  defaultContactId: sup.nullable(sup.string()),
  contactPersons: sup.array(contactPersonStruct),
  businessUnitId: sup.nullable(sup.string()),
});

export type SupplierFormType = sup.Infer<typeof supplierFormStruct>;
