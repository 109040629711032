import { NegotiationTermRole } from '../types/negotiationTypes';

import { NegotiationMetrics } from './types';

export const negotiationMetrics: NegotiationMetrics = {
  acceptedTermCounts: {
    acceptContractPeriod: 'acceptContractPeriod',
    answerSustainabilityQuestion: 'answerSustainabilityQuestion',
    extendContractPeriod: 'extendContractPeriod',
    extendInvoiceDueDate: 'extendInvoiceDueDate',
    lockPrice: 'lockPrice',
    signCodeOfConduct: 'signCodeOfConduct',
  },

  answeredCount: 'answeredCount',
  averageDiscount: 'averageDiscount',
  contactsCount: 'contactsCount',
  currentSpend: 'currentSpend',
  discountCount: 'discountCount',
  negotiatedCostAvoidance: 'negotiatedCostAvoidance',
  negotiatedSavings: 'negotiatedSavings',
  negotiatedSpend: 'negotiatedSpend',
  potentialCostAvoidance: 'potentialCostAvoidance',
  potentialSavings: 'potentialSavings',
  registeredCount: 'registeredCount',
  suppliersCount: 'suppliersCount',
  visitedCount: 'visitedCount',
  excludedCount: 'excludedCount',
  openedEmailSpend: 'openedEmailSpend',
  openedEmailCount: 'openedEmailCount',
  visitedSpend: 'visitedSpend',
  registeredSpend: 'registeredSpend',
  answeredSpend: 'answeredSpend',
  estimatedDiscount: 'estimatedDiscount',
  estimatedInflation: 'estimatedInflation',
} as const;

export const negotiationTypeRoles = [
  NegotiationTermRole.ANSWER_SUSTAINABILITY_QUESTION,
  NegotiationTermRole.EXTEND_CONTRACT_PERIOD,
  NegotiationTermRole.EXTEND_INVOICE_DUE_DATE,
  NegotiationTermRole.LOCK_PRICE,
  NegotiationTermRole.OFFER_DISCOUNT,
  NegotiationTermRole.SIGN_CODE_OF_CONDUCT,
];
