import {
  negotiationMetrics,
  negotiationTypeRoles,
} from '@/modules/negotiation/metrics/consts';
import {
  NegotiationTermRole,
  NegotiationState,
  ParsedNegotiationItem,
} from '@/modules/negotiation/types/negotiationTypes';

import {
  applyVisitedAndRegisteredMetrics,
  mapTermRolesToMetrics,
  negotiationCardTermMatrix,
} from './helpers';

// docs: https://docs.google.com/spreadsheets/d/1J5C81mDVt4Q5OHAckXWWO4y0DRIX_RVaHMr1J_X4zmA/edit#gid=730626659

export const getNegotiationCardMetrics = (
  negotiation: ParsedNegotiationItem
): Set<string> => {
  const { termRoles, state } = negotiation;

  const hasNegotiationStarted =
    state === NegotiationState.COMPLETED || state === NegotiationState.ACTIVE;

  // we only care about roles that are related to negotiation types
  const isStandAlone =
    termRoles.filter((role) => negotiationTypeRoles.includes(role)).length <= 1;

  const hasLockPrice = termRoles.includes(NegotiationTermRole.LOCK_PRICE);
  const hasOfferDiscount = termRoles.includes(
    NegotiationTermRole.OFFER_DISCOUNT
  );

  if (isStandAlone) {
    return mapTermRolesToMetrics({ termRoles, hasNegotiationStarted });
  }

  // is savings AND anti-inflation
  if (hasLockPrice && hasOfferDiscount) {
    const metrics = mapTermRolesToMetrics({
      termRoles,
      hasNegotiationStarted,
    });
    // edge-case: we don't want to show `Discounts` due to business needs
    metrics.delete(negotiationMetrics.discountCount);
    return metrics;
  }

  if (hasLockPrice) {
    return mapTermRolesToMetrics({ termRoles, hasNegotiationStarted });
  }

  if (hasOfferDiscount) {
    const metrics = mapTermRolesToMetrics({
      termRoles,
      hasNegotiationStarted,
    });
    // edge-case: we don't want to show `Discounts` due to business needs
    metrics.delete(negotiationMetrics.discountCount);
    return metrics;
  }

  // multiple terms without savings or anti-inflation
  const termCounterMetrics = termRoles.flatMap(
    (role) => negotiationCardTermMatrix[role].termCounters
  );
  return hasNegotiationStarted
    ? new Set(applyVisitedAndRegisteredMetrics(termCounterMetrics))
    : new Set([negotiationMetrics.contactsCount]);
};
