import { useCallback } from 'react';

import {
  Order,
  SuppliersQueryParams,
  SupplierTableColumn,
} from '@/modules/suppliers/types/supplierTypes';

enum SearchParams {
  pageNumber = 'page',
  pageSize = 'size',
  column = 'sortBy',
  order = 'order',
}

export const defaultPageNumber = 1;
export const defaultPageSize = 20;

export type SetSortRule = ({
  column,
  order,
}: {
  column: SupplierTableColumn | null;
  order: Order | null;
}) => void;

type UseHandleSuppliersRoutingPaginationParams = {
  queryParams: SuppliersQueryParams;
  setQueryParams: (params: Partial<SuppliersQueryParams>) => void;
};

export const useHandleSuppliersRoutingPagination = ({
  queryParams,
  setQueryParams,
}: UseHandleSuppliersRoutingPaginationParams) => {
  const pageNumber = queryParams?.page || defaultPageNumber;
  const pageSize = queryParams?.size || defaultPageSize;
  const order = queryParams?.order;
  const column = queryParams?.sortBy;

  // useCallbacks protect us from infinite loops in external useEffects
  const setPageNumber = useCallback(
    (pageNumber: number) =>
      setQueryParams({
        [SearchParams.pageNumber]: pageNumber,
      }),
    [setQueryParams]
  );

  const handlePageSizeChange = useCallback(
    (selectedPageSize: number) => {
      setQueryParams({
        [SearchParams.pageSize]: selectedPageSize,
        [SearchParams.pageNumber]: defaultPageNumber,
      });
    },
    [setQueryParams]
  );

  const setSortRule: SetSortRule = useCallback(
    ({ column, order }) => {
      if (!column || !order) {
        setQueryParams({
          [SearchParams.column]: undefined,
          [SearchParams.order]: undefined,
        });
        return;
      }
      setQueryParams({
        [SearchParams.column]: column,
        [SearchParams.order]: order,
      });
    },
    [setQueryParams]
  );

  return {
    pageNumber,
    pageSize,
    order,
    column,
    setPageNumber,
    setSortRule,
    handlePageSizeChange,
  };
};
