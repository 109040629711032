import * as sup from 'superstruct';

import { createResourceStruct } from '@/modules/api/utils/jsonApiStructs';
import { downloadResourceIdentifierStruct } from '@/modules/download/api/downloadResourceIdentifierStruct';

import { spendFileResourceIdentifierStruct } from './spendFileResourceIdentifierStruct';

export enum SpendFileStatus {
  UPLOADING = 'uploading',
  READY_FOR_PROCESSING = 'readyForProcessing',
  PROCESSING = 'processing',
  PROCESSED = 'processed',
  CANCELED = 'canceled',
}

const spendFileResourceAttributes = sup.object({
  state: sup.enums(Object.values(SpendFileStatus)),
  period: sup.nullable(
    sup.object({
      startDate: sup.string(),
      endDate: sup.string(),
    })
  ),
  file: sup.object({
    name: sup.string(),
    contentType: sup.string(),
    sizeBytes: sup.integer(),
  }),
});

export const spendFileResourceStruct = createResourceStruct({
  identifier: spendFileResourceIdentifierStruct,
  attributes: spendFileResourceAttributes,
});

export const uploadSpendFileResourceStruct = createResourceStruct({
  identifier: spendFileResourceIdentifierStruct,
  attributes: spendFileResourceAttributes,
  meta: sup.object({
    url: sup.string(),
    headers: sup.record(sup.string(), sup.string()),
  }),
});

export const spendFileDownloadResourceStruct = createResourceStruct({
  identifier: downloadResourceIdentifierStruct,
  attributes: sup.object({
    url: sup.string(),
  }),
});
