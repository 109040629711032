import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { queryKeys } from '@/modules/api/queryKeys';
import { notify } from '@/modules/notify/notify';
import { constructMeta } from '@/modules/reactQuery/constructMeta';
import { onboardedRoutes } from '@/modules/router/onboardedRoutes';
import { editSupplierMutation } from '@/modules/suppliers/api/mutations/editSupplierMutation';

export const useEditSupplierMutation = ({
  supplierId,
}: {
  supplierId: string | undefined;
}) => {
  const jsonApi = useJsonApi();
  const { t } = useTranslation('apiMessages/useEditSupplierMutation');
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation({
    mutationKey: [editSupplierMutation.name],
    mutationFn: editSupplierMutation(jsonApi),
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: [queryKeys.suppliers.getSuppliers],
      });
      queryClient.removeQueries({
        queryKey: [queryKeys.suppliers.getSupplier],
      });
      if (supplierId) {
        navigate(
          onboardedRoutes.buildUrl('supplier', { params: { supplierId } })
        );
        notify.alert.success(t('Supplier edited successfully!'));
      }
    },
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHORIZED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t("You don't have access edit suppliers"),
        },
        404: {
          SUPPLIER_NOT_FOUND: t(
            'Supplier not found. Please refresh the page or contact the administrator.'
          ),
        },
      },
    }),
  });
};
