import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  FileUpload,
  FILE_MAX_SIZE_MB,
} from '@/modules/form/components/FileUpload/FileUpload';
import { FileFormats } from '@/modules/form/utils/fileFormats';
import { MaxWidth } from '@/modules/theme/components/MaxWidth';
import { Paragraph } from '@/modules/theme/components/Typography';
import { UploadedFile } from '@/modules/theme/components/UploadedFile';

import { QuestionnaireForm } from '../helpers/questionnaireFormStruct';

import { QuestionnaireFormSection } from './QuestionnaireFormSection';

export const UploadSpendFileSection = () => {
  const { t } = useTranslation('pages/UploadSpendAndQuestionnairePage');

  const errorMessages = {
    fileUpload: {
      instance: t('Spend file is required'),
      'spendFile.maxSize': t('Maximum size is {{maxFileSize}}', {
        maxFileSize: `${FILE_MAX_SIZE_MB} MB`,
      }),
      'spendFile.unsupportedFileType': t(
        "Unsupported file type. Allowed file types are '.xls, .xlsx and .csv'"
      ),
    },
  };

  const { resetField, register, control } = useFormContext<QuestionnaireForm>();

  const spendFile = useWatch({
    control,
    name: 'spendFile',
  });

  return (
    <QuestionnaireFormSection
      number={1}
      title={t('Please upload your spend file')}
    >
      <Paragraph variant="contentBold" mb={4}>
        {t('You can only add one Excel file.')}
      </Paragraph>
      <MaxWidth maxWidth={305}>
        {spendFile ? (
          <UploadedFile
            name={spendFile.name}
            onDelete={() =>
              resetField('spendFile', { defaultValue: undefined })
            }
          />
        ) : (
          <FileUpload
            name={register('spendFile').name}
            text={t('Drag and drop file here')}
            supportedFilesTypes={[
              FileFormats.xls,
              FileFormats.xlsx,
              FileFormats.csv,
            ]}
            errorMessages={errorMessages.fileUpload}
          />
        )}
      </MaxWidth>
    </QuestionnaireFormSection>
  );
};
