import * as sup from 'superstruct';

import { createResourceStruct } from '@/modules/api/utils/jsonApiStructs';

import { supplierCategoryIdentifierStruct } from '../supplierCategory/supplierCategoryIdentifierStruct';

export const supplierCategoryResourceStruct = createResourceStruct({
  identifier: supplierCategoryIdentifierStruct,
  attributes: sup.object({
    name: sup.string(),
  }),
  relationships: sup.object({
    parent: sup.object({
      data: sup.nullable(supplierCategoryIdentifierStruct),
    }),
  }),
});

export type SupplierCategory = sup.Infer<typeof supplierCategoryResourceStruct>;
