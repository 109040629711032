import { JsonApiClient } from '@/modules/api/utils/createJsonApiClient';

import {
  getEditSupplierContactResourcePath,
  supplierContactResourceType,
} from '../resources/supplierContact/supplierContactResourceConsts';

type EditSupplierContactMutationPayload = {
  id: string;
  attributes: {
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    phoneNumber: string | null;
  };
};

export function editSupplierContactMutation(jsonApi: JsonApiClient) {
  return (payload: EditSupplierContactMutationPayload) => {
    const { id, attributes } = payload;
    return jsonApi.update({
      type: supplierContactResourceType,
      path: getEditSupplierContactResourcePath({ id }),
      id,
      attributes,
    });
  };
}
