import { useTranslation } from 'react-i18next';

import { NegotiationState } from '@/modules/negotiation/types/negotiationTypes';

import { useNegotiationStatusLabels } from '../../utils/useNegotiationStatusLabels';

import {
  ContainerStyled,
  FilterNegotiationsInfoStyled,
  FiltersContainerStyled,
} from './NegotiationStateFilter.styled';
import { NegotiationStateFilterItem } from './NegotiationStateFilterItem/NegotiationStateFilterItem';

type NegotiationStateFilterProps = {
  negotiationStateCounts: Record<NegotiationState, number> | undefined;
  isLoading: boolean;
  chosenStates: NegotiationState[];
  onStateToggle: (filter: NegotiationState) => void;
};

export const NegotiationStateFilter = ({
  negotiationStateCounts,
  isLoading,
  chosenStates,
  onStateToggle,
}: NegotiationStateFilterProps) => {
  const { t } = useTranslation('pages/DashboardPage');

  const getNegotiationLabel = useNegotiationStatusLabels();

  const states = [
    {
      count: negotiationStateCounts?.suggested,
      variant: NegotiationState.SUGGESTED,
    },
    {
      count: negotiationStateCounts?.setup,
      variant: NegotiationState.SETUP,
    },
    {
      count: negotiationStateCounts?.planned,
      variant: NegotiationState.PLANNED,
    },
    {
      count: negotiationStateCounts?.active,
      variant: NegotiationState.ACTIVE,
    },
    {
      count: negotiationStateCounts?.completed,
      variant: NegotiationState.COMPLETED,
    },
  ];

  return (
    <ContainerStyled>
      <FilterNegotiationsInfoStyled>
        {t('Filter negotiations')}
      </FilterNegotiationsInfoStyled>
      <FiltersContainerStyled>
        {states.map(({ count, variant }) => (
          <NegotiationStateFilterItem
            id={`filter-${variant}`}
            name="negotiationFilter"
            key={variant}
            count={count || 0}
            variant={variant}
            label={getNegotiationLabel(variant)}
            defaultChecked={chosenStates.includes(variant)}
            onChange={onStateToggle}
            isLoading={isLoading}
          />
        ))}
      </FiltersContainerStyled>
    </ContainerStyled>
  );
};
