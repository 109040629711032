export type I18nNamespace = Record<string, string>;
export type I18nNamespaceMap = Record<string, I18nNamespace>;

export function requireNamespaces(context: __WebpackModuleApi.RequireContext) {
  return context.keys().reduce<I18nNamespaceMap>((resources, namespacePath) => {
    const namespace = namespacePath.replace(/^\.\//, '').replace(/\.json$/, '');
    resources[namespace] = context(namespacePath) as I18nNamespace;
    return resources;
  }, {});
}
