import { ContactPersonFormType } from '../components/ContactPersonFormDialog/contactPersonFormStruct';

export const serializeContactData = ({
  firstName,
  lastName,
  email,
  phoneNumber,
}: Nullable<ContactPersonFormType>) => ({
  firstName: firstName || null,
  lastName: lastName || null,
  email: email || null,
  phoneNumber: phoneNumber || null,
});
