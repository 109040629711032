import * as sup from 'superstruct';

import { createResourceStruct } from '@/modules/api/utils/jsonApiStructs';
import {
  AcceptableTermAnswer,
  Implementation,
} from '@/modules/negotiation/types/negotiationTypes';
import { supplierContactIdentifierStruct } from '@/modules/suppliers/api/resources/supplierContact/supplierContactIdentifierStruct';

import { SupplierNegotiationState } from '../negotiationSelectedSuppliers/negotiationSelectedSuppliersResourceConsts';
import { termResourceStruct } from '../negotiationTerm/negotiationTermResourceStruct';

import { negotiationSupplierProgressIdentifierStruct } from './negotiationSupplierProgressIdentifierStruct';

export enum SupplierCompletedNegotiationSteps {
  OPEN_EMAIL = 'openEmail',
  VISIT = 'visit',
  REGISTER = 'register',
  ANSWER = 'answer',
}

export const negotiationSupplierProgressResourceStruct = createResourceStruct({
  identifier: negotiationSupplierProgressIdentifierStruct,
  attributes: sup.object({
    state: sup.enums(Object.values(SupplierNegotiationState)),
    completedSteps: sup.array(
      sup.enums(Object.values(SupplierCompletedNegotiationSteps))
    ),
    answers: sup.nullable(
      sup.array(
        sup.union([
          sup.enums(Object.values(AcceptableTermAnswer)),
          sup.enums(Object.values(Implementation)),
          sup.number(),
        ])
      )
    ),
    spend: sup.string(),
    contactInfo: sup.object({
      firstName: sup.nullable(sup.string()),
      lastName: sup.nullable(sup.string()),
      email: sup.nullable(sup.string()),
      phoneNumber: sup.nullable(sup.string()),
    }),
  }),
  relationships: sup.object({
    answeredTerms: sup.object({
      data: sup.nullable(sup.array(termResourceStruct)),
    }),
    contact: sup.object({
      data: sup.nullable(supplierContactIdentifierStruct),
    }),
  }),
});

export type NegotiationSupplierProgressResourceType = sup.Infer<
  typeof negotiationSupplierProgressResourceStruct
>;
