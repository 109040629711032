import styled from 'styled-components';

import { ReactComponent as Chevron } from '@/assets/icons/chevronRight.svg';
import { blue } from '@/modules/theme/utils/colors';

interface ArrowProps {
  onPrevious?: () => void;
  onNext?: () => void;
  direction?: 'left' | 'right';
}
export const Arrow = ({ direction, onPrevious, onNext }: ArrowProps) => {
  const handleClick = () => {
    if (direction === 'left' && onPrevious) {
      onPrevious();
    } else if (direction === 'right' && onNext) {
      onNext();
    }
  };
  return (
    <PaginateArrowLinkStyled direction={direction} onClick={handleClick}>
      <Chevron width="7" height="12" />
      <Chevron width="7" height="12" />
    </PaginateArrowLinkStyled>
  );
};

interface PaginateArrowLinkStyledProps {
  direction?: 'left' | 'right';
}

const PaginateArrowLinkStyled = styled('div')<PaginateArrowLinkStyledProps>(
  ({ direction = 'right', theme: { spacing } }) => ({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    paddingLeft: spacing(2),
    ...(direction === 'left' && { transform: 'rotate(180deg)' }),
    color: blue[800],
  })
);
