import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import * as sup from 'superstruct';

import { currencies } from '@/modules/customer/types';
import { Checkbox } from '@/modules/form/components/Checkbox/Checkbox';
import { useForm } from '@/modules/form/components/Form/hooks/useForm/useForm';
import { notify } from '@/modules/notify/notify';
import { anonymousRoutes } from '@/modules/router/anonymousRoutes';
import { Button } from '@/modules/theme/components/Button/Button';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { ExternalLink } from '@/modules/theme/components/Link/ExternalLink';
import { Heading, Text } from '@/modules/theme/components/Typography';
import { blue } from '@/modules/theme/utils/colors';

import { CategoriesSection } from './components/CategoriesSection/CategoriesSection';
import { CompanyDetailSection } from './components/CompanyDetailSection';
import { NotesSection } from './components/NotesSection';
import { TermImportanceSection } from './components/TermImportanceSection/TermImportanceSection';
import { serializeSpendAndSurveyValues } from './helpers/serializeSurveyValues';
import { surveyFormStruct } from './helpers/surveyFormStruct';
import { useUploadSpendAndSurvey } from './hooks/usePostSurvey';

export const Form = () => {
  const { t } = useTranslation('pages/LeadCustomerSurveyPage');
  const navigate = useNavigate();

  const { handleUploadSpendAndSurvey, isPending } = useUploadSpendAndSurvey();

  const formProps = useForm({
    defaultValues: {
      company: {
        name: undefined,
        email: undefined,
        currency: currencies.SEK,
      },
      privacyPolicy: undefined,
      offerDiscount: 'notImportant',
      lockPrice: 'notImportant',
      signCodeOfConduct: 'notImportant',
      extendInvoiceDueDate: 'notImportant',
      answerSustainabilityQuestion: 'notImportant',
      isoApproval: 'notImportant',
      signStandardAgreement: 'notImportant',
      importantCategories: [],
      categoryInfo: [],
      notes: undefined,
    },
    struct: surveyFormStruct,
  });

  const { handleSubmit, formState, register } = formProps;

  const submitSurvey = async (values: sup.Infer<typeof surveyFormStruct>) => {
    const serializedValues = serializeSpendAndSurveyValues(values);

    await handleUploadSpendAndSurvey({
      values: serializedValues,
      onSuccess: () => {
        notify.alert.success(
          t('Spend questionnaire was successfully uploaded'),
          {
            duration: 10000,
          }
        );
        navigate(anonymousRoutes.buildUrl('signIn'));
      },
    });
  };

  return (
    <div>
      <HeadingStyled variant="h2">{t('DigiProc questionnaire')}</HeadingStyled>
      <FormProvider {...formProps}>
        <form onSubmit={handleSubmit(submitSurvey)}>
          <CompanyDetailSection />
          <TermImportanceSection />
          <CategoriesSection />
          <NotesSection />
          <FlexContainer direction="column" align="center" gap={2}>
            <Checkbox
              id="privacyPolicy"
              {...register('privacyPolicy')}
              hideError
            >
              <Text>
                {t('I have read and accept the')}{' '}
                <ExternalLink to={'https://www2.digiproc.com/privacy-policy'}>
                  {t('Privacy policy')}
                </ExternalLink>
              </Text>
            </Checkbox>
            <Button
              type="submit"
              disabled={!formState.isValid}
              loading={isPending}
            >
              {t('Submit')}
            </Button>
          </FlexContainer>
        </form>
      </FormProvider>
    </div>
  );
};

const HeadingStyled = styled(Heading)(({ theme: { spacing } }) => ({
  position: 'relative',

  ':before': {
    content: "''",
    position: 'absolute',
    bottom: spacing(-1),
    left: 0,
    right: 0,
    height: 1,
    backgroundColor: blue[800],
  },
}));
