import { createGlobalStyle } from 'styled-components';

import { Theme } from '../utils/createTheme';

export const GlobalStyles = createGlobalStyle(
  ({ theme: { typography } }: { theme: Theme }) => ({
    'html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed,  figure, figcaption, footer, header, hgroup,  menu, nav, output, ruby, section, summary, time, mark, audio, video':
      {
        margin: 0,
        padding: 0,
        border: 0,
        fontSize: '100%',
        font: 'inherit',
        verticalAlign: 'baseline',
      },
    'article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section':
      {
        display: 'block',
      },
    body: {
      lineHeight: 1,
      fontFamily: typography.fonts.body,
      fontWeight: typography.fontWeight.normal,
      fontSize: typography.fontSize.default,
    },
    'ol, ul': { listStyle: 'none' },
    'blockquote, q': { quotes: 'none' },
    'blockquote:before, blockquote:after, q:before, q:after': {
      content: "''",
    },
    table: { borderCollapse: 'collapse', borderSpacing: '0' },

    '*': {
      boxSizing: 'border-box',
    },
    'html, body, #root': {
      height: '100%',
    },
  })
);
