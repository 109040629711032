import styled from 'styled-components';

import tickIconUrl from '@/assets/icons/tickIcon.svg';
import { dark } from '@/modules/theme/utils/colors';

type CheckboxWrapperStyledProps = {
  errorHidden?: boolean;
};

export const CheckboxWrapperStyled = styled('div')<CheckboxWrapperStyledProps>(
  ({ theme: { spacing }, errorHidden = false }) => ({
    position: 'relative',
    marginBottom: errorHidden ? 0 : spacing(2),
  })
);

export const InputStyled = styled('input')(({ theme: { palette } }) => ({
  opacity: 0,
  position: 'absolute',
  left: 0,
  margin: 0,

  '&[disabled] + label': {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
  '&[disabled] + label::before': {
    backgroundColor: palette.disabled.light,
    borderColor: palette.disabled.main,
  },
  '&:checked + label::before': {
    background: `url(${tickIconUrl}) no-repeat 3px 6px/12px 8px`,
    backgroundColor: dark[50],
  },
  '&:focus + label::before': {
    borderColor: palette.primary.lighter,
  },
}));
