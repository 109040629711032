import sanitize from 'sanitize-html';

export function sanitizeHtml(
  html: string,
  options: Omit<sanitize.IOptions, 'allowedTags'> & {
    allowedTags?: string[];
  } = {}
) {
  return sanitize(html, {
    ...sanitize.defaults,
    ...options,
    allowedTags: [
      ...sanitize.defaults.allowedTags,
      ...(options.allowedTags !== undefined ? options.allowedTags : []),
      'img',
      'a',
    ],
    allowedAttributes: {
      ...sanitize.defaults.allowedAttributes,
      ...options.allowedAttributes,
      '*': ['style', 'class'],
    },
    allowedSchemesByTag: {
      img: ['data'],
    },

    // If you're running this in a browser, we recommend to disable style parsing: options.parseStyleAttributes: false,
    // since this only works in a node environment due to a postcss dependency,
    // More info: https://github.com/apostrophecms/sanitize-html/issues/547
    parseStyleAttributes: false,
  });
}
