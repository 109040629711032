import styled from 'styled-components';

import { Editor } from '@/modules/theme/components/Editor/Editor';
import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';

export const FormWrapperStyled = styled('main')({
  maxWidth: '100%',
});

export const EditorWrapperStyled = styled('div')(
  ({ theme: { boxShadows, borderRadius, spacing } }) => ({
    marginTop: spacing(1),
    boxShadow: boxShadows.default,
    borderRadius: borderRadius.default,
  })
);

export const EditorStyled = styled(Editor)({
  background: 'transparent',
  boxShadow: 'none',
  maxWidth: '100%',
});

export const EditorSkeletonStyled = styled(SkeletonLoader)({
  marginBottom: 0,
  height: 670,
});
