import styled, { css } from 'styled-components';

import { ReactComponent as ChevronRight } from '@/assets/icons/chevronRight.svg';
import { blue, dark, white } from '@/modules/theme/utils/colors';

const commonContainerStyles = css(({ theme: { breakpoints } }) => ({
  height: 800,
  maxWidth: 1574,

  [breakpoints.up.xl]: {
    height: 900,
    maxWidth: 1778,
  },

  [breakpoints.down.lg]: {
    height: 650,
  },
}));

export const PresentationSliderWrapperStyled = styled('div')(
  () => commonContainerStyles,
  ({ theme: { spacing } }) => ({
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
    paddingBottom: spacing(5),
  })
);

export const PDFContainerStyled = styled('div')(
  () => commonContainerStyles,
  ({ theme: { spacing } }) => ({
    marginLeft: spacing(10),
    marginRight: spacing(10),
  })
);

export const PresentationLoaderContainerStyled = styled('div')(
  () => commonContainerStyles,
  {
    display: 'grid',
    placeItems: 'center',
  }
);

type DotButtonStyledProps = {
  isActive: boolean;
};

export const DotButtonStyled = styled('button')<DotButtonStyledProps>(
  ({ theme: { borderRadius }, isActive }) => ({
    appearance: 'none',
    cursor: 'pointer',
    border: 'none',
    width: 8,
    height: 8,
    padding: 0,
    borderRadius: borderRadius.round,
    backgroundColor: isActive ? blue[800] : dark[100],
  })
);

export const PresentationNavigationWrapperStyled = styled('div')(
  ({ theme: { spacing } }) => ({
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    gap: spacing(2),
    zIndex: 100,
  })
);

type CommonPageButtonStylesProps = {
  disabled?: boolean;
};

export const commonPageButtonStyles = css<CommonPageButtonStylesProps>(
  ({ theme: { borderRadius }, disabled }) => ({
    position: 'absolute',
    borderRadius: borderRadius.round,
    width: 54,
    height: 54,
    backgroundColor: white,
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.17)',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 100,
    border: 'none',
    appearance: 'none',
    cursor: disabled ? 'not-allowed' : 'pointer',
    transition: '0.1s',

    '&:hover': {
      opacity: 0.9,
    },
    ...(disabled && {
      opacity: 0.7,
      filter: 'grayscale(1)',
      '&:hover': {
        opacity: 0.7,
      },
    }),
  })
);

export const PreviousPageButtonStyled = styled('button')(
  () => commonPageButtonStyles,
  ({ theme: { spacing } }) => ({
    left: spacing(2),
  })
);

export const NextPageButtonStyled = styled('button')(
  () => commonPageButtonStyles,
  ({ theme: { spacing } }) => ({
    right: spacing(2),
  })
);

export const ChevronRightStyled = styled(ChevronRight)(
  ({ theme: { palette, spacing } }) => ({
    color: palette.default.text,
    width: 10,
    marginTop: spacing(0.5),
  })
);

export const ChevronLeftStyled = styled(ChevronRightStyled)(
  ({ theme: { spacing } }) => ({
    transform: 'rotate(-180deg)',
    marginRight: spacing(0.5),
  })
);
