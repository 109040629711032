import { useTranslation } from 'react-i18next';

import { anonymousRoutes } from '@/modules/router/anonymousRoutes';
import { Dialog } from '@/modules/theme/components/Dialog/Dialog';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { FlexLayout } from '@/modules/theme/components/FlexLayout';
import { Text } from '@/modules/theme/components/Typography/Text';

import { ButtonLinkStyled } from './ConfirmResetPasswordDialog.styled';

export function ConfirmResetPasswordDialog({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation('pages/ResetPasswordPage');

  return (
    <Dialog
      isOpen={isOpen}
      hasCloseIcon
      onClose={onClose}
      title={t('Success')}
      description={t('Your password has been reset.')}
    >
      <FlexLayout direction="column">
        <FlexContainer justify="center">
          <ButtonLinkStyled
            variant="outline"
            to={anonymousRoutes.buildUrl('signIn')}
          >
            <Text variant="normal">{t('Log in')}</Text>
          </ButtonLinkStyled>
        </FlexContainer>
      </FlexLayout>
    </Dialog>
  );
}
