import * as sup from 'superstruct';

import { JsonApiClient } from '@/modules/api/utils/createJsonApiClient';
import { createResponseStruct } from '@/modules/api/utils/jsonApiStructs';
import { includedStruct } from '@/utils/structs/includedStruct';

import { parseGetSuppliersResponse } from '../../helpers/parseGetSuppliersResponse';
import {
  GetSuppliersQueryParams,
  suppliersListResourcePath,
} from '../resources/supplier/supplierResourceConsts';
import { supplierResourceStruct } from '../resources/supplier/supplierResourceStruct';
import { supplierCategoryResourceStruct } from '../resources/supplierCategory/supplierCategoryResourceStruct';
import { supplierContactResourceStruct } from '../resources/supplierContact/supplierContactResourceStruct';

export const getSuppliersQuery = (
  jsonApi: JsonApiClient,
  params: GetSuppliersQueryParams
) => {
  return async () => {
    const queryParams = {
      'page[number]': params.pageNumber,
      'page[size]': params.pageSize,
      sort: params.sort,
      'filter[category]': params.categories?.join(','),
      'filter[exclusionReason]': params.exclusionReasons?.join(','),
      include: params.include?.join(','),
    };

    const response = await jsonApi.getMany({
      path: suppliersListResourcePath,
      queryParams,
      responseStruct: getSuppliersResponseStruct,
    });

    return parseGetSuppliersResponse(response);
  };
};

const getSuppliersResponseStruct = createResponseStruct({
  data: sup.array(supplierResourceStruct),
  included: includedStruct([
    supplierCategoryResourceStruct,
    supplierContactResourceStruct,
  ]),
  meta: sup.object({
    page: sup.object({
      total: sup.integer(),
    }),
  }),
});

export type GetSuppliersResponseType = sup.Infer<
  typeof getSuppliersResponseStruct
>;
