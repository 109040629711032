import axios, { AxiosInstance } from 'axios';
import { useEffect } from 'react';

import { useAuthContext } from '../hooks/useAuthContext';

export function useUnauthorizedResponseInterceptor({
  httpClient,
}: {
  httpClient: AxiosInstance;
}) {
  const { user, signOut } = useAuthContext();

  useEffect(() => {
    if (user?.userId) {
      return interceptUnauthorizedResponse({ httpClient, signOut });
    }
  }, [user?.userId, httpClient, signOut]);
}

function interceptUnauthorizedResponse({
  httpClient,
  signOut,
}: {
  httpClient: AxiosInstance;
  signOut: () => void;
}) {
  const interceptorId = httpClient.interceptors.response.use(
    undefined,
    (err) => {
      if (axios.isAxiosError(err) && err.response?.status === 401) {
        signOut();

        // skip other error handling
        return new Promise(() => undefined);
      }

      return Promise.reject(err);
    }
  );

  return () => httpClient.interceptors.response.eject(interceptorId);
}
