import * as sup from 'superstruct';

import { JsonApiClient } from '@/modules/api/utils/createJsonApiClient';
import { createResponseStruct } from '@/modules/api/utils/jsonApiStructs';

import { getSetNegotiationStateToSetupPath } from '../resources/negotiation/negotiationResourceConsts';
import { negotiationResourceStruct } from '../resources/negotiation/negotiationResourceStruct';

type SetNegotiationStateToSetupMutationPayload = {
  id: string;
};

export function setNegotiationStateToSetupMutation(jsonApi: JsonApiClient) {
  return async ({ id }: SetNegotiationStateToSetupMutationPayload) => {
    const response = await jsonApi.patch({
      path: getSetNegotiationStateToSetupPath(id),
      responseStruct: negotiationResponseStruct,
    });

    if (!response)
      throw new Error(
        'missing response from `setNegotiationStateToSetupMutation`'
      );

    return parseSetNegotiationStateToSetupResponse(response);
  };
}

const negotiationResponseStruct = createResponseStruct({
  data: negotiationResourceStruct,
});

type NegotiationResponseStruct = sup.Infer<typeof negotiationResponseStruct>;

const parseSetNegotiationStateToSetupResponse = (
  response: NegotiationResponseStruct
) => {
  const {
    data: {
      id,
      attributes: { title, state, termRoles, period, introEmail },
      links,
    },
  } = response;

  return {
    id,
    title,
    state,
    termRoles,
    period,
    introEmail,
    supplierPreviewUrl: links.portal,
  };
};
