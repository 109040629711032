import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { queryKeys } from '@/modules/api/queryKeys';
import { ParsedNegotiationSuppliersResponse } from '@/modules/negotiation/helpers/parseGetNegotiationSuppliersResponse';

type UseUpdateNegotiationQueryCachePayload = {
  negotiationId: string;
};

export type SelectedSuppliersQueryCache =
  InfiniteData<ParsedNegotiationSuppliersResponse>;

export const useUpdateSelectedSuppliersQueryCache = ({
  negotiationId,
}: UseUpdateNegotiationQueryCachePayload) => {
  const queryClient = useQueryClient();

  const updateSelectedSuppliersQueryCache = useCallback(
    async (
      updateNegotiation: (
        oldSelectedSuppliersData: SelectedSuppliersQueryCache
      ) => SelectedSuppliersQueryCache
    ) => {
      await queryClient.cancelQueries({
        queryKey: [
          queryKeys.negotiations.getNegotiationSuppliersInfinite,
          negotiationId,
        ],
      });
      queryClient.setQueryData<SelectedSuppliersQueryCache>(
        [queryKeys.negotiations.getNegotiationSuppliersInfinite, negotiationId],
        (oldNegotiationData) => {
          if (oldNegotiationData) {
            return updateNegotiation(oldNegotiationData);
          }
        }
      );
    },
    [negotiationId, queryClient]
  );

  const getSelectedSuppliersCacheSnapshot = useCallback(async () => {
    await queryClient.cancelQueries({
      queryKey: [
        queryKeys.negotiations.getNegotiationSuppliersInfinite,
        negotiationId,
      ],
    });
    return queryClient.getQueryData<SelectedSuppliersQueryCache>([
      queryKeys.negotiations.getNegotiationSuppliersInfinite,
      negotiationId,
    ]);
  }, [negotiationId, queryClient]);

  return {
    updateSelectedSuppliersQueryCache,
    getSelectedSuppliersCacheSnapshot,
  };
};
