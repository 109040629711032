import { PropsWithChildren } from 'react';
import { ClearIndicatorProps, components, GroupBase } from 'react-select';

import { ReactComponent as MinusIcon } from '@/assets/icons/minusIcon.svg';

export const ClearIndicator = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: PropsWithChildren<ClearIndicatorProps<Option, IsMulti, Group>>
) => {
  return (
    <components.ClearIndicator {...props}>
      <MinusIcon width="24" height="24" />
    </components.ClearIndicator>
  );
};
