import styled from 'styled-components';

import { ReactComponent as Chevron } from '../../assets/chevronDown.svg';

type ChevronStyledProps = {
  direction?: 'down' | 'up';
  arrowVisible: boolean;
};

export const ChevronStyled = styled(Chevron).withConfig({
  shouldForwardProp: () => false,
})<ChevronStyledProps>(
  ({ direction = 'up', arrowVisible, theme: { spacing } }) => ({
    gap: spacing(1),
    alignItems: 'center',
    ...(direction === 'up' && { transform: 'rotate(180deg)' }),
    ...(arrowVisible
      ? {
          display: 'flex',
          opacity: 1,
        }
      : {
          display: 'none',
          opacity: 0.5,
        }),
  })
);

export const TableHeaderButton = styled('button')(
  ({ theme: { spacing, typography } }) => ({
    minHeight: 24,
    color: 'inherit',
    appearance: 'none',
    border: 'none',
    display: 'inline-flex',
    outline: 'none',
    backgroundColor: 'transparent',
    gap: spacing(1),
    alignItems: 'center',
    cursor: 'pointer',
    fontFamily: typography.fonts.body,
    fontSize: typography.pxToRem(16),
    padding: 0,
    letterSpacing: typography.letterSpacing.default,
    ':hover': {
      [String(ChevronStyled)]: {
        display: 'flex',
      },
    },
  })
);
