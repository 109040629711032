import { useTranslation } from 'react-i18next';

import { useGetSupplierLanguageLabels } from '@/modules/language/supplierLanguage/useGetSupplierLanguageLabels';
import { Button } from '@/modules/theme/components/Button/Button';
import { Dialog } from '@/modules/theme/components/Dialog/Dialog';

import { ParseNegotiationValidationResponse } from '../../helpers/parseNegotiationValidationResponse';

import {
  LanguageListItemStyled,
  LanguagesListStyled,
  ListStyled,
  ParagraphStyled,
} from './NegotiationValidationDialog.styled';
import { ValidationItem } from './NegotiationValidationItem';

type NegotiationValidationDialogProps = {
  isOpen: boolean;
  validationState: ParseNegotiationValidationResponse;
  onClose: () => void;
};

export const NegotiationValidationDialog = ({
  isOpen,
  validationState: { startDate, invitationLetter, terms, keywords, suppliers },
  onClose,
}: NegotiationValidationDialogProps) => {
  const { t } = useTranslation('pages/NegotiationSetupPage');

  const languageLabels = useGetSupplierLanguageLabels();

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title={t('Complete setup')}
      hasCloseIcon
      variant="large"
    >
      <ParagraphStyled>
        {t(
          'You need to complete one or more of the following steps before your negotiation is ready to start.'
        )}
      </ParagraphStyled>
      <ListStyled>
        <ValidationItem isValid={startDate.isValid}>
          {t('Start date')}
        </ValidationItem>
        <ValidationItem isValid={invitationLetter.isValid}>
          {t('Invitation letter')}
        </ValidationItem>
        <ValidationItem isValid={terms.isValid}>{t('Terms')}</ValidationItem>
        <ValidationItem isValid={keywords.isValid}>
          <div>
            {t('Keywords')}
            {keywords.missingLanguages.length > 0 && (
              <LanguagesListStyled>
                {keywords.missingLanguages.map((language, index) => (
                  <LanguageListItemStyled key={index}>
                    {languageLabels[language]}
                  </LanguageListItemStyled>
                ))}
              </LanguagesListStyled>
            )}
          </div>
        </ValidationItem>
        <ValidationItem isValid={suppliers.isValid}>
          {t('Suppliers')}
        </ValidationItem>
      </ListStyled>
      <Button onClick={onClose} variant="outline">
        {t('Close')}
      </Button>
    </Dialog>
  );
};
