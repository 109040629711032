import { JsonApiClient } from '@/modules/api/utils/createJsonApiClient';

import {
  resetPasswordResourcePath,
  resetPasswordResourceType,
} from '../resources/resetPassword/resetPasswordConsts';
import { userResourceType } from '../resources/user/userResourceConsts';

type ResetPasswordMutationPayload = {
  newPassword: string;
  token: string;
  id: string;
};

export function resetPasswordMutation(jsonApi: JsonApiClient) {
  return ({ newPassword, token, id }: ResetPasswordMutationPayload) =>
    jsonApi.put({
      type: resetPasswordResourceType,
      path: resetPasswordResourcePath,
      attributes: {
        newPassword,
        token,
      },
      relationships: {
        user: {
          data: {
            type: userResourceType,
            id,
          },
        },
      },
    });
}
