import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as sup from 'superstruct';

import { ReactComponent as DownloadIcon } from '@/assets/icons/downloadIcon.svg';
import { AuthenticatedLayout } from '@/layouts/AuthenticatedLayout/AuthenticatedLayout';
import { appConfig } from '@/modules/app/appConfig';
import { useForm } from '@/modules/form/components/Form/hooks/useForm/useForm';
import { useGetNegotiationQuery } from '@/modules/negotiation/hooks/useGetNegotiationQuery';
import { useUpdateNegotiationMutation } from '@/modules/negotiation/hooks/useUpdateNegotiationMutation';
import { notify } from '@/modules/notify/notify';
import { onboardedRoutes } from '@/modules/router/onboardedRoutes';
import { Button } from '@/modules/theme/components/Button/Button';
import { ButtonSkeletonLoader } from '@/modules/theme/components/Button/ButtonSkeletonLoader';
import { LinkType } from '@/modules/theme/components/ButtonLink';
import { Editor } from '@/modules/theme/components/Editor/Editor';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { MaxWidth } from '@/modules/theme/components/MaxWidth';
import { PageHeader } from '@/modules/theme/components/PageHeader/PageHeader';
import { PageMeta } from '@/modules/theme/components/PageMeta';
import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';
import { Paragraph } from '@/modules/theme/components/Typography';
import { Text } from '@/modules/theme/components/Typography/Text';
import { dark } from '@/modules/theme/utils/colors';

import {
  ButtonsSectionStyled,
  ContainerStyled,
  DownloadButtonStyled,
  DownloadEmailLinksSectionStyled,
  DownloadEmailSectionStyled,
  EditorWrapperStyled,
  InfoWrapperContainer,
  TitleStyled,
} from './CPOLetterPage.styled';

const cpoLetterStruct = sup.object({
  cpoLetter: sup.string(),
});

type CpoLetterStructType = sup.Infer<typeof cpoLetterStruct>;

export const CPOLetterPage = () => {
  const { t } = useTranslation('pages/CPOLetterPage');
  const navigate = useNavigate();

  const { negotiationId } = onboardedRoutes.useGetRouteParams('cpoLetter');
  const { data, isPending } = useGetNegotiationQuery({
    negotiationId,
  });

  const updateNegotiationMutation = useUpdateNegotiationMutation();

  const formMethods = useForm({
    struct: cpoLetterStruct,
    defaultValues: {
      cpoLetter: data?.introEmail.bodyTemplate || '',
    },
  });
  const {
    handleSubmit,
    register,
    reset,
    formState: { isDirty },
  } = formMethods;

  useEffect(() => {
    if (data) {
      reset({ cpoLetter: data.introEmail.bodyTemplate });
    }
  }, [reset, data]);

  const cpoLetterSubmit = ({ cpoLetter }: CpoLetterStructType) => {
    updateNegotiationMutation.mutate(
      { negotiationId, introEmailBodyTemplate: cpoLetter },
      {
        onSuccess: () => {
          notify.alert.success(t('CPO letter updated successfully!'));
          // https://stackoverflow.com/questions/63953501/react-hook-form-resetting-isdirty-without-clearing-form
          reset({ cpoLetter });
        },
      }
    );
  };

  return (
    <AuthenticatedLayout>
      <PageMeta title={t('CPO-letter')} description={t('CPO-letter')} />
      <PageHeader text={t('CPO-letter')} />
      <MaxWidth>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(cpoLetterSubmit)}>
            <ContainerStyled>
              <EditorWrapperStyled>
                <TitleStyled variant={'h3'}>{t('Edit CPO-letter')}</TitleStyled>
                {isPending ? (
                  <SkeletonLoader height={448} />
                ) : (
                  <Editor name={register('cpoLetter').name} />
                )}
              </EditorWrapperStyled>
              <DownloadEmailSectionStyled>
                <TitleStyled variant={'h3'}>
                  {t('Download as email')}
                </TitleStyled>
                {isPending ? (
                  <span>
                    <SkeletonLoader height={120} />
                  </span>
                ) : (
                  <Text color={dark[400]}>
                    {t(
                      'These email templates of your CPO-letter is generated in multiple files to make sure they are not detected as spam. This means that each mail has a set list of suppliers it will be sent to.'
                    )}
                  </Text>
                )}
                <DownloadEmailLinksSectionStyled>
                  {isPending ? (
                    <span>
                      <SkeletonLoader height={26} />
                    </span>
                  ) : (
                    <FlexContainer justify="center" direction="column" gap={2}>
                      <DownloadButtonStyled
                        linkType={LinkType.External}
                        to={getIntroEmailsResourcePath(negotiationId)}
                        targetBlank={false}
                        variant="text"
                        disabled={isDirty || !data?.metrics.suppliersCount}
                      >
                        <DownloadIcon />
                        {t('Download EML files')}
                      </DownloadButtonStyled>
                      <InfoWrapperContainer>
                        {!data?.metrics.suppliersCount && (
                          <Paragraph>
                            {t(
                              'Note that there are no suppliers in this negotiation yet which means that there are no recipients to send an email to.'
                            )}
                          </Paragraph>
                        )}
                        {isDirty && (
                          <Paragraph>
                            {t(
                              'To download the EML files save the CPO letter first.'
                            )}
                          </Paragraph>
                        )}
                      </InfoWrapperContainer>
                    </FlexContainer>
                  )}
                </DownloadEmailLinksSectionStyled>
                <ButtonsSectionStyled>
                  {isPending ? (
                    <ButtonSkeletonLoader />
                  ) : (
                    <Button
                      type="submit"
                      loading={updateNegotiationMutation.isPending}
                    >
                      {t('Save changes')}
                    </Button>
                  )}
                  {isPending ? (
                    <ButtonSkeletonLoader />
                  ) : (
                    <Button variant={'outline'} onClick={() => navigate(-1)}>
                      {t('Back')}
                    </Button>
                  )}
                </ButtonsSectionStyled>
              </DownloadEmailSectionStyled>
            </ContainerStyled>
          </form>
        </FormProvider>
      </MaxWidth>
    </AuthenticatedLayout>
  );
};

const getIntroEmailsResourcePath = (negotiationId: string) => {
  const {
    api: { baseUrl },
  } = appConfig;

  return `${baseUrl}/me/customer/negotiations/${negotiationId}/intro_emails.zip`;
};
