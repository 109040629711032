import { Trans, useTranslation } from 'react-i18next';
import * as sup from 'superstruct';

import { Checkbox } from '@/modules/form/components/Checkbox/Checkbox';
import { DisplayErrorMessage } from '@/modules/form/components/ErrorMessage/DisplayErrorMessage';
import { Form } from '@/modules/form/components/Form/Form';
import { InputError } from '@/modules/form/components/InputError';
import { Implementation } from '@/modules/negotiation/types/negotiationTypes';
import { Button } from '@/modules/theme/components/Button/Button';
import { Paragraph } from '@/modules/theme/components/Typography';
import { recompose } from '@/utils/structs/recompose';
import { size } from '@/utils/structs/structs';

import {
  BoldTextStyled,
  ContainerStyled,
  DialogStyled,
} from './ChooseImplementationTypesDialog.styled';
import { useSetImplementationTypesMutation } from './useSetImplementationTypesMutation';

type ChooseImplementationTypesDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  implementations: Implementation[];
  termId: string;
  negotiationId: string;
};

const chooseImplementationDialogStruct = sup.object({
  implementationTypes: recompose(
    sup.array(sup.enums(Object.values(Implementation))),
    size(1, Infinity)
  ),
});

type ChooseImplementationTypesFormValues = sup.Infer<
  typeof chooseImplementationDialogStruct
>;

const translationNamespace = 'pages/Terms';

export const ChooseImplementationTypesDialog = ({
  isOpen,
  onClose,
  implementations,
  termId,
  negotiationId,
}: ChooseImplementationTypesDialogProps) => {
  const { t } = useTranslation(translationNamespace);
  const setImplementationTypesMutation = useSetImplementationTypesMutation();

  const errorMessages = {
    implementationTypes: {
      'array.size': t('You should pick at least one option'),
    },
  };

  const availableImplementationTypes = [
    { value: 'invoiceDiscount', label: t('Invoice discount') },
    { value: 'creditInvoice', label: t('Credit invoice') },
    { value: 'reducedPrices', label: t('Reduced prices') },
  ];

  const setImplementationTypes = ({
    implementationTypes,
  }: ChooseImplementationTypesFormValues) => {
    setImplementationTypesMutation.mutate(
      {
        termId,
        negotiationId,
        implementations: implementationTypes,
      },
      {
        onSuccess: onClose,
      }
    );
  };

  return (
    <DialogStyled
      isOpen={isOpen}
      onClose={onClose}
      hasCloseIcon
      title={t('Choose implementation')}
    >
      <div>
        <Paragraph alignment="center">
          {t('Choose how you want your suppliers to implement their discount.')}
        </Paragraph>
        <Paragraph alignment="center">
          <Trans
            ns={translationNamespace}
            i18nKey={
              '<bold>Tip:</bold> More options for suppliers will ensure a better result.'
            }
            components={{
              bold: <BoldTextStyled />,
            }}
          />
        </Paragraph>
      </div>
      <Form
        mode="onChange"
        onValidSubmit={setImplementationTypes}
        struct={chooseImplementationDialogStruct}
        defaultValues={{
          implementationTypes: implementations,
        }}
      >
        {({ register, formState: { errors } }) => {
          return (
            <>
              <ContainerStyled>
                <div>
                  {availableImplementationTypes.map((implementation) => (
                    <Checkbox
                      labelVariant="bold"
                      key={implementation.value}
                      value={implementation.value}
                      id={implementation.value}
                      {...register('implementationTypes')}
                      hideError
                    >
                      {implementation.label}
                    </Checkbox>
                  ))}
                </div>
                <InputError displayAlignment="center">
                  <DisplayErrorMessage
                    error={errors.implementationTypes?.type}
                    errorMessages={errorMessages.implementationTypes}
                  />
                </InputError>
              </ContainerStyled>
              <Button
                type="submit"
                loading={setImplementationTypesMutation.isPending}
              >
                {t('Save')}
              </Button>
            </>
          );
        }}
      </Form>
    </DialogStyled>
  );
};
