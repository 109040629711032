import { GetNegotiationResponseType } from '@/modules/negotiation/api/queries/getNegotiationsQuery';
import { NegotiationMetricsType } from '@/modules/negotiation/api/resources/negotiationMetrics/negotiationMetricsResourceStruct';
import { NegotiationState } from '@/modules/negotiation/types/negotiationTypes';

const negotiationStateOrder: Record<NegotiationState, number> = {
  [NegotiationState.SUGGESTED]: 1,
  [NegotiationState.ACTIVE]: 2,
  [NegotiationState.SETUP]: 3,
  [NegotiationState.PLANNED]: 4,
  [NegotiationState.COMPLETED]: 5,
  [NegotiationState.ESTIMATED]: 6,
  [NegotiationState.DRAFT]: 7,
};

export const parseGetNegotiationsResponse = (
  response: GetNegotiationResponseType
) => {
  const { data, included, meta } = response;
  const negotiationsList = data
    .map((negotiation) => {
      const { id, attributes, relationships } = negotiation;

      const metricsData = included.find(
        (item): item is NegotiationMetricsType =>
          item.id === relationships?.metrics?.data.id
      );

      const metrics = metricsData
        ? {
            id: metricsData.id,
            ...metricsData.attributes,
          }
        : null;

      return {
        id,
        ...attributes,
        metrics,
      };
    })
    .sort(
      (negotiation1, negotiation2) =>
        negotiationStateOrder[negotiation1.state] -
        negotiationStateOrder[negotiation2.state]
    );
  return {
    negotiationsList,
    negotiationStateCounts: meta.stateCounts,
  };
};

export type NegotiationsRes = ReturnType<typeof parseGetNegotiationsResponse>;
