import styled from 'styled-components';

export const DataWrapperStyled = styled('div')(({ theme: { spacing } }) => ({
  margin: spacing([3, 0]),
}));

type NumberStyledProps = {
  aligned?: boolean;
  color?: string;
};

export const NumberStyled = styled('p')<NumberStyledProps>(
  ({
    theme: { palette, typography, breakpoints },
    aligned = false,
    color,
  }) => ({
    fontSize: typography.pxToRem(48),
    fontWeight: typography.fontWeight.bold,
    color: color ?? palette.primary.main,
    lineHeight: 1.5,
    fontFamily: typography.fonts.heading,
    textAlign: aligned ? 'center' : 'start',

    [breakpoints.down.lg]: {
      fontSize: typography.pxToRem(30),
    },

    [breakpoints.down.md]: {
      fontSize: typography.pxToRem(24),
    },
  })
);
