import { useTranslation } from 'react-i18next';

import { anonymousRoutes } from '@/modules/router/anonymousRoutes';
import { Heading } from '@/modules/theme/components/Typography';
import { white } from '@/modules/theme/utils/colors';

import { ContentWrapperStyled, ButtonLinkStyled } from './SignedOutPage.styled';

export const SignedOutPage = () => {
  const { t } = useTranslation('pages/SignedOutPage');
  return (
    <ContentWrapperStyled>
      <Heading variant="h2" as="h1" color={white}>
        {t('You have been signed out.')}
      </Heading>
      <Heading variant="h3" as="p" color={white}>
        {t('Please sign in again.')}
      </Heading>
      <ButtonLinkStyled variant="light" to={anonymousRoutes.buildUrl('signIn')}>
        {t('Back to sign in page')}
      </ButtonLinkStyled>
    </ContentWrapperStyled>
  );
};
