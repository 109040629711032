import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { findSelectValue } from '@/modules/form/components/Select/findSelectValue';
import { SupplierNegotiationState } from '@/modules/negotiation/api/resources/negotiationSelectedSuppliers/negotiationSelectedSuppliersResourceConsts';
import { setupNegotiationRoutes } from '@/modules/router/onboardedRoutes';
import { SuppliersFilters } from '@/modules/suppliers/components/SuppliersFilters';
import { defaultSuppliersQueryParams } from '@/modules/suppliers/helpers/defaultSuppliersQueryParams';
import { SuppliersOrder } from '@/modules/suppliers/utils/suppliersOrders';
import { Box } from '@/modules/theme/components/Box';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { Pagination } from '@/modules/theme/components/Pagination/Pagination';
import { ShowPerPageDropdown } from '@/modules/theme/components/ShowPerPageDropdown/ShowPerPageDropdown';
import { Heading } from '@/modules/theme/components/Typography';
import { useDebouncedState } from '@/utils/useDebounced';
import { useDialogState } from '@/utils/useDialogState';

import { AddSupplierDialog } from './components/AddSupplierDialog/AddSupplierDialog';
import { SuppliersTable } from './components/SuppliersTable';
import { useGetNegotiationSelectedSuppliersQuery } from './hooks/useGetNegotiationSelectedSuppliersQuery';
import {
  AddSupplierButtonStyled,
  SkeletonLoaderStyled,
} from './Suppliers.styled';

type SuppliersQueryParams = {
  page?: number;
  size?: number;
  sort?: SuppliersOrder;
  searchQuery?: string;
  businessUnit?: string[];
  category?: string[];
};

type SuppliersProps = {
  queryParams: SuppliersQueryParams;
  negotiationId: string;
};

export const Suppliers = ({ queryParams, negotiationId }: SuppliersProps) => {
  const { t } = useTranslation('pages/SuppliersTab');

  const addSupplierDialog = useDialogState();

  const setQueryParams = setupNegotiationRoutes.useSetQueryParams('suppliers');

  const searchQuery = queryParams.searchQuery || '';
  const businessUnitFilter = queryParams.businessUnit || [];
  const order = queryParams.sort || defaultSuppliersQueryParams.sort;
  const categoriesFilter = queryParams.category || [];
  const pageNumber = queryParams.page || 1;
  const pageSize = queryParams.size || defaultSuppliersQueryParams.size;

  const [debouncedSearchQuery, setDebouncedSearchQuery] = useDebouncedState(
    searchQuery,
    { wait: 1000 }
  );

  useEffect(() => {
    if (!queryParams.page || !queryParams.size || !queryParams.sort) {
      setQueryParams({
        page: pageNumber,
        size: pageSize,
        sort: order,
      });
    }
  });

  const { data, isPending, isSuccess, isPlaceholderData } =
    useGetNegotiationSelectedSuppliersQuery({
      negotiationId,
      params: {
        negotiationStates: [
          SupplierNegotiationState.ANSWERED,
          SupplierNegotiationState.INVITED,
          SupplierNegotiationState.OPENEDEMAIL,
          SupplierNegotiationState.REGISTERED,
          SupplierNegotiationState.VISITED,
        ],
        pageNumber,
        pageSize,
        sort: order,
        searchQuery: debouncedSearchQuery || undefined,
        businessUnit: businessUnitFilter.length
          ? businessUnitFilter
          : undefined,
        category: categoriesFilter.length ? categoriesFilter : undefined,
      },
    });

  const paginationProps = {
    perPage: pageSize,
    totalCount: data ? data.total : 0,
    onPageChange: (selectedPage: number) => setPageNumber(selectedPage + 1),
    forcePage: pageNumber - 1,
  };

  const setPageNumber = useCallback(
    (pageNumber: number) =>
      setQueryParams({
        page: pageNumber,
      }),
    [setQueryParams]
  );

  const setPageSizeChange = useCallback(
    (pageSize: number) => {
      setQueryParams({
        size: pageSize,
        page: 1,
      });
    },
    [setQueryParams]
  );

  const setSearchQuery = useCallback(
    (query: string) => {
      setQueryParams({
        searchQuery: query || undefined,
        page: 1,
      });
      setDebouncedSearchQuery(query);
    },
    [setQueryParams, setDebouncedSearchQuery]
  );

  const setBusinessUnits = useCallback(
    (businessUnits: string[]) => {
      setQueryParams({
        businessUnit: businessUnits?.length ? businessUnits : undefined,
        page: 1,
      });
    },
    [setQueryParams]
  );

  const setCategories = useCallback(
    (categories: string[]) => {
      setQueryParams({
        category: categories?.length ? categories : undefined,
        page: 1,
      });
    },
    [setQueryParams]
  );

  const setOrder = useCallback(
    (sortRule: SuppliersOrder | undefined) => {
      setQueryParams({ sort: sortRule });
    },
    [setQueryParams]
  );

  return (
    <>
      <Heading variant="h3" as="h2" mb={3}>
        {t('Choose which suppliers you would like to negotiate with')}
      </Heading>
      <FlexContainer justify="space-between">
        <SuppliersFilters
          isLoading={isPending}
          searchQuery={searchQuery}
          businessUnitFilter={businessUnitFilter}
          categoriesFilter={categoriesFilter}
          order={order}
          onSearchQueryChange={setSearchQuery}
          onBusinessUnitChange={setBusinessUnits}
          onOrderChange={setOrder}
          onCategoryChange={setCategories}
        />
        <FlexContainer direction="column" justify="space-between">
          <Box mb={2}>
            <ShowPerPageDropdown
              options={pageSizeOptions}
              selectedPageSize={pageSize}
              onPageSizeChange={setPageSizeChange}
              defaultValue={findSelectValue({
                currentValue: pageSize,
                options: pageSizeOptions,
              })}
            />
          </Box>
          <AddSupplierButtonStyled
            variant="outline"
            size="small"
            onClick={addSupplierDialog.open}
          >
            {t('Add supplier +')}
          </AddSupplierButtonStyled>
        </FlexContainer>
      </FlexContainer>

      <FlexContainer justify="center" margins={[4, 0, 3]}>
        {isPending ? (
          <SkeletonLoaderStyled />
        ) : paginationProps.totalCount ? (
          <Pagination {...paginationProps} />
        ) : null}
      </FlexContainer>

      <SuppliersTable
        suppliers={data?.suppliers || []}
        isSuccess={isSuccess}
        pageSize={pageSize}
        isLoading={isPending || isPlaceholderData}
        negotiationId={negotiationId}
      />

      <FlexContainer justify="center" margins={[5, 0]}>
        {isPending ? (
          <SkeletonLoaderStyled />
        ) : paginationProps.totalCount ? (
          <Pagination {...paginationProps} />
        ) : null}
      </FlexContainer>
      <AddSupplierDialog
        negotiationId={negotiationId}
        isOpen={addSupplierDialog.isOpen}
        onClose={addSupplierDialog.close}
      />
    </>
  );
};

const pageSizeOptions = [20, 50, 100].map((value) => ({
  value,
  label: value.toString(),
}));
