import { useMutation } from '@tanstack/react-query';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { JsonApiClient } from '@/modules/api/utils/createJsonApiClient';

import { SerializedSpendAndSurveyValues } from '../helpers/serializeSurveyValues';

export const useSubmitSurveyMutation = () => {
  const jsonApi = useJsonApi();

  return useMutation({
    mutationFn: submitSurvey(jsonApi),
  });
};

type SubmitSurveyPayload = {
  surveyValues: SerializedSpendAndSurveyValues;
};

const submitSurvey = (jsonApi: JsonApiClient) => {
  return ({ surveyValues }: SubmitSurveyPayload) => {
    return jsonApi.post({
      path: `/lead_survey_response`,
      payload: {
        data: {
          attributes: surveyValues,
        },
      },
    });
  };
};
