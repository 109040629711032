export const suppliersOrders = [
  'name',
  '-name',
  'currentSpend',
  '-currentSpend',
  'category.name',
  '-category.name',
  'defaultContact.email',
  '-defaultContact.email',
  'businessUnit.name',
  '-businessUnit.name',
] as const;

export type SuppliersOrder = (typeof suppliersOrders)[number];
