import { PropsWithChildren } from 'react';
import { OptionProps } from 'react-select';
import { components, GroupBase } from 'react-select';

import { OptionContentWrapperStyled } from './common.styled';

export const Option = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: PropsWithChildren<OptionProps<Option, IsMulti, Group>>
) => {
  return (
    <components.Option {...props}>
      <OptionContentWrapperStyled isOptionSelected={props.isSelected}>
        {props.children}
      </OptionContentWrapperStyled>
    </components.Option>
  );
};
