import { useTranslation } from 'react-i18next';

import { Heading } from '@/modules/theme/components/Typography';
import { mint } from '@/modules/theme/utils/colors';

import womanImgSrc from './images/introPageWomanImg.png';
import {
  ImgStyled,
  LogoStyled,
  SectionStyled,
  TextWrapperStyled,
} from './IntroductionSection.styled';

export const IntroductionSection = () => {
  const { t } = useTranslation('pages/IntroPage');

  return (
    <SectionStyled>
      <TextWrapperStyled>
        <LogoStyled />
        <Heading as="p" variant="h3" color={mint[800]} mt={5} mb={3}>
          {t(
            'This is DigiProc - We enable you to run digital negotiations with all your existing suppliers to secure that no supplier is left un-negotiated.'
          )}
        </Heading>
      </TextWrapperStyled>
      <ImgStyled
        src={womanImgSrc}
        alt="Smiling woman wearing eyeglasses and holding a tablet."
      />
    </SectionStyled>
  );
};
