import { ReactComponent as PlusIcon } from '@/assets/icons/plusIcon.svg';
import { ParsedNegotiationItem } from '@/modules/negotiation/types/negotiationTypes';
import { Box } from '@/modules/theme/components/Box';
import { BulletList } from '@/modules/theme/components/BulletList';
import { Tooltip } from '@/modules/theme/components/Tooltip/Tooltip';

import { useParseTermRoles } from './hooks/useParseTermRoles';
import {
  IconWrapperStyled,
  ListItemStyled,
  TermIconsWrapperStyled,
} from './TermIcons.styled';

type TermIconsProps = {
  termRoles: ParsedNegotiationItem['termRoles'];
};

export const TermIcons = ({ termRoles }: TermIconsProps) => {
  const parsedTermRoles = useParseTermRoles(termRoles);

  if (parsedTermRoles.length > 3) {
    const visibleIconTermRoles = parsedTermRoles.slice(0, 2);
    const textTermRoles = parsedTermRoles.slice(2);
    return (
      <TermIconsWrapperStyled>
        {visibleIconTermRoles.map(({ Icon, termRoleText }) => (
          <IconWrapperStyled title={termRoleText} key={termRoleText}>
            <Icon />
          </IconWrapperStyled>
        ))}
        <Tooltip
          placement="top"
          tooltipContent={
            <Box pl={2}>
              <BulletList>
                {textTermRoles.map(({ termRoleText }) => (
                  <ListItemStyled key={termRoleText}>
                    {termRoleText}
                  </ListItemStyled>
                ))}
              </BulletList>
            </Box>
          }
        >
          <IconWrapperStyled>
            <PlusIcon />
          </IconWrapperStyled>
        </Tooltip>
      </TermIconsWrapperStyled>
    );
  }

  return (
    <TermIconsWrapperStyled>
      {parsedTermRoles.map(({ Icon, termRoleText }) => (
        <IconWrapperStyled title={termRoleText} key={termRoleText}>
          <Icon />
        </IconWrapperStyled>
      ))}
    </TermIconsWrapperStyled>
  );
};
