import styled from 'styled-components';

import { Card } from '@/modules/theme/components/Card';

import { ReactComponent as CheckRoundIcon } from './checkRoundIcon.svg';

export const NegotiationTermCardWrapperStyled = styled(Card)(
  ({ theme: { spacing, typography, palette } }) => ({
    padding: spacing([2, 4]),
    display: 'grid',
    gridTemplateColumns: `${spacing(5)} 1fr ${spacing(10)}`,
    fontWeight: typography.fontWeight.bold,
    color: palette.default.text,
    alignItems: 'center',
    minHeight: 88,
  })
);

export const CheckRoundIconStyled = styled(CheckRoundIcon)(
  ({ theme: { spacing } }) => ({
    marginRight: spacing(3),
  })
);
