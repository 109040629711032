import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DisplayErrorMessage } from '@/modules/form/components/ErrorMessage/DisplayErrorMessage';
import { useForm } from '@/modules/form/components/Form/hooks/useForm/useForm';
import { TextInput } from '@/modules/form/components/TextInput/TextInput';
import { Box } from '@/modules/theme/components/Box';
import { Button } from '@/modules/theme/components/Button/Button';

import {
  FormContentStyled,
  FormErrorStyled,
} from './ContactPersonFormDialog.styled';
import {
  contactPersonFormStruct,
  ContactPersonFormType,
} from './contactPersonFormStruct';

type ContactPersonFormProps = {
  onSubmit: (formValues: ContactPersonFormType) => void;
  defaultPersonFormValues: ContactPersonFormType;
  isLoading?: boolean;
  buttonText: string;
};

export const ContactPersonForm = ({
  defaultPersonFormValues,
  isLoading,
  buttonText,
  onSubmit,
}: ContactPersonFormProps) => {
  const { t } = useTranslation('modules/suppliers/SupplierForm');

  const formMethods = useForm({
    defaultValues: defaultPersonFormValues,
    struct: contactPersonFormStruct,
  });

  const { handleSubmit, register, formState, trigger } = formMethods;

  const formError = Object.values(formState.errors).find(
    (fieldError) =>
      fieldError.type === 'string.notEmpty' || fieldError.type === 'string'
  )?.type;

  const errorMessages = {
    string: {
      'string.notEmpty': t('You must fill in at least one field'),
      string: t('You must fill in at least one field'),
    },
    email: {
      'string.emailPattern': t('Enter a valid email address'),
      'string.notEmpty': '',
    },
  };

  // User has to provide at least 1 filled field.
  // If the form gets saved before this condition is met,
  // we want to set each input to red.
  // If the user fills in any field, only validation error
  // in this field should be visible

  const handleFieldRevalidations = {
    onChange: () => trigger(),
    onBlur: () => trigger(),
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormContentStyled>
          <TextInput
            label={t('Contact (first name)')}
            placeholder={t('First name')}
            errorHidden
            {...register('firstName', handleFieldRevalidations)}
          />
          <TextInput
            label={t('Contact (last name)')}
            placeholder={t('Last name')}
            errorHidden
            {...register('lastName', handleFieldRevalidations)}
          />
          <TextInput
            label={t('Email')}
            placeholder={t('address@example.com')}
            errorMessages={errorMessages.email}
            {...register('email', handleFieldRevalidations)}
          />
          <TextInput
            label={t('Phone')}
            placeholder={t('+(46)')}
            errorHidden
            {...register('phoneNumber', handleFieldRevalidations)}
          />
          <FormErrorStyled>
            <DisplayErrorMessage
              error={formError?.toString()}
              errorMessages={errorMessages.string}
            />
          </FormErrorStyled>
          <Box mt={2}>
            <Button type="submit" loading={isLoading}>
              {buttonText}
            </Button>
          </Box>
        </FormContentStyled>
      </form>
    </FormProvider>
  );
};
