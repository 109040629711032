import { Loader } from '@/modules/theme/components/Loader';

import { PresentationLoaderContainerStyled } from '../PresentiationSlider.styled';

export const PresentationLoader = () => {
  return (
    <PresentationLoaderContainerStyled>
      <Loader size={40} />
    </PresentationLoaderContainerStyled>
  );
};
