import styled from 'styled-components';

import { Button } from '@/modules/theme/components/Button/Button';
import { Link } from '@/modules/theme/components/Link/Link';
import { black } from '@/modules/theme/utils/colors';

export const ContentWrapperStyled = styled('div')(
  ({ theme: { spacing, breakpoints } }) => ({
    margin: `${spacing(7)} auto`,
    maxWidth: 800,
    [breakpoints.down.md]: {
      marginLeft: spacing(2),
      marginRight: spacing(2),
    },
  })
);

export const ButtonsWrapperStyled = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  gap: spacing(3),
  flex: 1,
  justifyContent: 'flex-end',
}));

export const ButtonHeaderStyled = styled(Button)(({ theme: { spacing } }) => ({
  minHeight: spacing(5.5),
  minWidth: spacing(21.6),
  padding: 'unset',
}));

export const UserInfoStyled = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const AgreementLinkStyled = styled(Link.External)(
  ({ theme: { spacing } }) => ({
    marginTop: spacing(4.5),
    color: black,
    display: 'flex',
    alignItems: 'center',
    gap: spacing(1.5),
  })
);
