export const negotiationKeywordResourceType = 'negotiation_keyword';

export const getNegotiationKeywordsResourcePath = (id: string) =>
  `/me/customer/negotiations/${id}/keywords`;

export const getUpdateNegotiationKeywordResourcePath = ({
  negotiationId,
  keywordId,
}: {
  negotiationId: string;
  keywordId: string;
}) => `/me/customer/negotiations/${negotiationId}/keywords/${keywordId}`;
