import styled from 'styled-components';

import { ReactComponent as SavedTick } from '@/assets/icons/savedTickIcon.svg';
import { Loader } from '@/modules/theme/components/Loader';
import { mint } from '@/modules/theme/utils/colors';

type SaveStateIndicatorProps = {
  isUpdating: boolean;
  isSaved: boolean;
};

export const SaveStateIndicator = ({
  isUpdating,
  isSaved,
}: SaveStateIndicatorProps) => {
  if (isUpdating) return <Loader size={20} />;

  if (isSaved) return <SavedTickStyled />;

  return null;
};

const SavedTickStyled = styled(SavedTick)({
  color: mint[800],
});
