import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { queryKeys } from '@/modules/api/queryKeys';
import { getCustomerMetricsQuery } from '@/modules/customer/api/queries/getCustomerMetricsQuery';
import { NegotiationState } from '@/modules/negotiation/types/negotiationTypes';
import { constructMeta } from '@/modules/reactQuery/constructMeta';

type UseGetAnalysisMetricsQueryPayload = {
  negotiationStates: NegotiationState[];
  recommended?: boolean;
};

export const useGetAnalysisMetricsQuery = (
  payload: UseGetAnalysisMetricsQueryPayload
) => {
  const jsonApi = useJsonApi();
  const { t } = useTranslation('apiMessages/useGetAnalysisMetricsQuery');

  return useQuery({
    queryKey: [
      queryKeys.negotiations.getSuggestedNegotiationMetrics,
      payload.negotiationStates,
      payload.recommended,
    ],
    staleTime: 5000,
    queryFn: getCustomerMetricsQuery(jsonApi, payload),
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t("You don't have access to view metrics"),
        },
      },
    }),
  });
};
