import { JsonApiClient } from '@/modules/api/utils/createJsonApiClient';

import {
  forgotPasswordResourcePath,
  forgotPasswordResourceType,
} from '../resources/forgotPassword/forgotPasswordConsts';

type ForgotPasswordMutationPayload = {
  email: string;
};

export function forgotPasswordMutation(jsonApi: JsonApiClient) {
  return (attributes: ForgotPasswordMutationPayload) =>
    jsonApi.createOne({
      type: forgotPasswordResourceType,
      path: forgotPasswordResourcePath,
      attributes,
    });
}
