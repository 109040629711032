import styled from 'styled-components';

import { Paragraph, Text } from '@/modules/theme/components/Typography';

export const ContentWrapperStyled = styled('div')({
  width: 300,
});

export const ParagraphStyled = styled(Paragraph)(({ theme: { spacing } }) => ({
  maxWidth: 375,
  marginBottom: spacing(1),
  textAlign: 'center',
  margin: spacing([2, 0, 3]),
}));

export const TextStyled = styled(Text)(({ theme: { typography } }) => ({
  fontWeight: typography.fontWeight.bold,
}));
