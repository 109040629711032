import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { queryKeys } from '@/modules/api/queryKeys';
import { JsonApiClient } from '@/modules/api/utils/createJsonApiClient';
import { constructMeta } from '@/modules/reactQuery/constructMeta';

export const useRemoveCodeOfConductMutation = () => {
  const jsonApi = useJsonApi();
  const { t } = useTranslation('apiMessages/useRemoveCodeOfConductMutation');
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: removeCodeOfConduct(jsonApi),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.negotiations.getNegotiation],
      });
    },
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t("You don't have access to remove this file"),
        },
        404: {
          NEGOTIATION_NOT_FOUND: t('Negotiation not found'),
          TERM_NOT_FOUND: t('Term not found'),
        },
        422: {
          NEGOTIATION_PLANNED: t(
            'Terms cannot be changed once a negotiation is planned'
          ),
        },
      },
    }),
  });
};

type RemoveCodeOfConductPayload = {
  negotiationId: string;
  termId: string;
};

const removeCodeOfConduct = (jsonApi: JsonApiClient) => {
  return async ({ negotiationId, termId }: RemoveCodeOfConductPayload) => {
    await jsonApi.delete({
      path: `/me/customer/negotiations/${negotiationId}/terms/${termId}/code_of_conduct_document`,
    });
  };
};
