import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as sup from 'superstruct';

import { AuthContentLayout } from '@/layouts/AuthContentLayout/AuthContentLayout';
import { useAuthContext } from '@/modules/auth/hooks/useAuthContext';
import { useGetCustomerActiveSubscriptionPlanQuery } from '@/modules/customer/api/subscriptionPlan/useGetCustomerActiveSubscriptionPlanQuery';
import { Form } from '@/modules/form/components/Form/Form';
import { PasswordInput } from '@/modules/form/components/PasswordInput/PasswordInput';
import { TextInput } from '@/modules/form/components/TextInput/TextInput';
import { emailPattern, notEmpty } from '@/modules/form/utils/structs';
import { anonymousRoutes } from '@/modules/router/anonymousRoutes';
import { Button } from '@/modules/theme/components/Button/Button';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { PageMeta } from '@/modules/theme/components/PageMeta';
import { Text } from '@/modules/theme/components/Typography';
import { noop } from '@/utils/noop';
import { recompose } from '@/utils/structs/recompose';

import { ForgotPasswordConfirmationDialog } from './components/ForgotPasswordConfirmationDialog';
import {
  ForgotPasswordDialog,
  ForgotPasswordValues,
} from './components/ForgotPasswordDialog';
import { useForgotPasswordMutation } from './hooks/useForgotPasswordMutation';
import { useGetUserDetailsQuery } from './hooks/useGetUserDetailsQuery';
import { useSignInMutation } from './hooks/useSignInMutation';
import { CheckboxStyled } from './SignIn.styled';

const signInStruct = sup.object({
  email: recompose(sup.string(), notEmpty, emailPattern),
  password: recompose(sup.string(), notEmpty),
  remember: recompose(sup.boolean()),
});

export type SignInStructType = sup.Infer<typeof signInStruct>;

export function SignInPage() {
  const [isForgotPasswordDialogOpen, setIsForgotPasswordDialogOpen] =
    useState(false);
  const [
    isForgotPasswordDialogConfirmationOpen,
    setIsForgotPasswordConfirmationDialogOpen,
  ] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation('pages/SignInPage');
  const { signIn } = useAuthContext();
  const signInMutation = useSignInMutation();
  const {
    isFetching: isGetUserDetailsFetching,
    refetch: refetchGetUserDetails,
  } = useGetUserDetailsQuery();
  const { refetch: refetchSubscriptionPlan } =
    useGetCustomerActiveSubscriptionPlanQuery({ enabled: false });

  const errorMessages = {
    email: {
      'string.notEmpty': t('This field is required'),
      'string.emailPattern': t('Invalid email address'),
    },
    password: {
      'string.notEmpty': t('This field is required'),
    },
  };

  const handleForgotPasswordFormSuccess = () => {
    setIsForgotPasswordDialogOpen(false);
    setIsForgotPasswordConfirmationDialogOpen(true);
  };

  const forgotPasswordMutation = useForgotPasswordMutation({
    onForgotPasswordSuccess: handleForgotPasswordFormSuccess,
  });

  const handleSubmit = async (payload: SignInStructType) => {
    try {
      await signInMutation.mutateAsync(payload);

      const [userDetailsResponse, activeSubscriptionPlanResponse] =
        await Promise.all([refetchGetUserDetails(), refetchSubscriptionPlan()]);

      const { data: userDetails } = userDetailsResponse;
      const { data: activeSubscriptionPlan } = activeSubscriptionPlanResponse;

      if (!userDetails) {
        throw new Error("Couldn't load user details");
      }

      if (!userDetails.customer) {
        navigate(anonymousRoutes.buildUrl('customerIdNotFound'));
      } else {
        signIn({
          user: userDetails.user,
          customer: userDetails.customer,
          hasSubscription: Boolean(activeSubscriptionPlan),
        });
      }
    } catch (err) {
      noop();
    }
  };

  const handleValidForgotPasswordSubmit = ({ email }: ForgotPasswordValues) => {
    forgotPasswordMutation.mutate({
      email,
    });
  };

  const isSigningIn = isGetUserDetailsFetching || signInMutation.isPending;

  return (
    <AuthContentLayout title={t('Welcome!')}>
      <PageMeta title={t('Sign in')} description={t('Sign in page')} />
      <Form
        defaultValues={{
          email: '',
          password: '',
          remember: false,
        }}
        struct={signInStruct}
        onValidSubmit={handleSubmit}
      >
        {({ register }) => {
          return (
            <>
              <TextInput
                label={t('Email')}
                type="email"
                placeholder={t('email@example.com')}
                autoComplete="username"
                errorMessages={errorMessages.email}
                {...register('email')}
              />
              <PasswordInput
                label={t('Password')}
                placeholder="password"
                autoComplete="current-password"
                errorMessages={errorMessages.password}
                {...register('password')}
              />
              <FlexContainer pb={3} justify="space-between">
                <CheckboxStyled {...register('remember')} id="remember">
                  {t('Remember me')}
                </CheckboxStyled>
                <Button
                  variant="text"
                  onClick={() => setIsForgotPasswordDialogOpen(true)}
                >
                  <Text variant="small">{t('Forgot password?')}</Text>
                </Button>
              </FlexContainer>
              <Button type="submit" size="fullWidth" loading={isSigningIn}>
                {t('Sign in')}
              </Button>
            </>
          );
        }}
      </Form>

      <ForgotPasswordDialog
        isOpen={isForgotPasswordDialogOpen}
        onClose={() => setIsForgotPasswordDialogOpen(false)}
        onValidSubmit={handleValidForgotPasswordSubmit}
        isLoading={forgotPasswordMutation.isPending}
      />
      <ForgotPasswordConfirmationDialog
        isOpen={isForgotPasswordDialogConfirmationOpen}
        onClose={() => setIsForgotPasswordConfirmationDialogOpen(false)}
      />
    </AuthContentLayout>
  );
}
