import styled from 'styled-components';

export const ContactPersonInputWrapper = styled('div')(
  ({ theme: { spacing, boxShadows, borderRadius, palette } }) => ({
    marginBottom: spacing(2),
    padding: spacing([1, 2]),
    boxShadow: boxShadows.radio,
    borderRadius: borderRadius.default,
    backgroundColor: palette.default.background,
    minHeight: spacing(5),
    display: 'flex',
    alignItems: 'center',
  })
);

export const RadioWrapperStyled = styled('div')({
  flex: 1,
});
