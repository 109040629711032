import { useCallback } from 'react';

import { plannedNegotiationRoutes } from '@/modules/router/onboardedRoutes';
import { SuppliersOrder } from '@/modules/suppliers/utils/suppliersOrders';
import { useDebouncedState } from '@/utils/useDebounced';

type UseSetSuppliersQueryParamsPayload = {
  searchQuery: string;
};

export const useSetSuppliersQueryParams = ({
  searchQuery,
}: UseSetSuppliersQueryParamsPayload) => {
  const setQueryParams =
    plannedNegotiationRoutes.useSetQueryParams('suppliers');

  const [debouncedSearchQuery, setDebouncedSearchQuery] = useDebouncedState(
    searchQuery,
    { wait: 1000 }
  );

  const setPageNumber = useCallback(
    (pageNumber: number) =>
      setQueryParams({
        page: pageNumber,
      }),
    [setQueryParams]
  );

  const setPageSize = useCallback(
    (pageSize: number) => {
      setQueryParams({
        size: pageSize,
        page: 1,
      });
    },
    [setQueryParams]
  );

  const setSearchQuery = useCallback(
    (query: string) => {
      setQueryParams({
        searchQuery: query || undefined,
        page: 1,
      });
      setDebouncedSearchQuery(query);
    },
    [setQueryParams, setDebouncedSearchQuery]
  );

  const setCategories = useCallback(
    (categories: string[]) => {
      setQueryParams({
        category: categories?.length ? categories : undefined,
        page: 1,
      });
    },
    [setQueryParams]
  );

  const setBusinessUnits = useCallback(
    (businessUnits: string[]) => {
      setQueryParams({
        businessUnit: businessUnits?.length ? businessUnits : undefined,
      });
    },
    [setQueryParams]
  );

  const setOrder = useCallback(
    (sortRule: SuppliersOrder) => {
      setQueryParams({ sort: sortRule });
    },
    [setQueryParams]
  );

  return {
    debouncedSearchQuery,
    setSearchQuery,
    setOrder,
    setCategories,
    setPageSize,
    setPageNumber,
    setBusinessUnits,
  };
};
