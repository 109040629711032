import {
  NegotiationTermRole,
  ParsedNegotiationItem,
} from '@/modules/negotiation/types/negotiationTypes';

import { negotiationMetrics } from './consts';
import { NegotiationTermMatrix } from './types';

const emptyMetrics = {
  financialMetricsBeforeStart: [],
  financialMetricsAfterStart: [],
  termCounters: [],
};

const negotiationDetailsTermMatrix: NegotiationTermMatrix = {
  [NegotiationTermRole.OFFER_DISCOUNT]: {
    financialMetricsBeforeStart: [negotiationMetrics.potentialSavings],
    financialMetricsAfterStart: [
      negotiationMetrics.negotiatedSavings,
      negotiationMetrics.averageDiscount,
      negotiationMetrics.discountCount,
    ],
    termCounters: [],
  },
  [NegotiationTermRole.SIGN_CODE_OF_CONDUCT]: {
    financialMetricsBeforeStart: [],
    financialMetricsAfterStart: [],
    termCounters: [negotiationMetrics.acceptedTermCounts.signCodeOfConduct],
  },
  [NegotiationTermRole.ANSWER_SUSTAINABILITY_QUESTION]: {
    financialMetricsBeforeStart: [],
    financialMetricsAfterStart: [],
    termCounters: [
      negotiationMetrics.acceptedTermCounts.answerSustainabilityQuestion,
    ],
  },
  [NegotiationTermRole.LOCK_PRICE]: {
    financialMetricsBeforeStart: [negotiationMetrics.potentialCostAvoidance],
    financialMetricsAfterStart: [negotiationMetrics.negotiatedCostAvoidance],
    termCounters: [negotiationMetrics.acceptedTermCounts.lockPrice],
  },
  [NegotiationTermRole.EXTEND_INVOICE_DUE_DATE]: {
    financialMetricsBeforeStart: [],
    financialMetricsAfterStart: [negotiationMetrics.negotiatedSpend],
    termCounters: [negotiationMetrics.acceptedTermCounts.extendInvoiceDueDate],
  },
  [NegotiationTermRole.EXTEND_CONTRACT_PERIOD]: {
    financialMetricsBeforeStart: [negotiationMetrics.potentialSavings],
    financialMetricsAfterStart: [negotiationMetrics.negotiatedSpend],
    termCounters: [negotiationMetrics.acceptedTermCounts.extendContractPeriod],
  },

  [NegotiationTermRole.ACCEPT_CONTRACT_PERIOD]: emptyMetrics,
  [NegotiationTermRole.SELECT_DISCOUNT_IMPLEMENTATION]: emptyMetrics,
  [NegotiationTermRole.ANSWER_QUESTION]: emptyMetrics,
  [NegotiationTermRole.TEXT]: emptyMetrics,
  [NegotiationTermRole.SIGN_STANDARD_AGREEMENT]: emptyMetrics,
  [NegotiationTermRole.BE_ISO_CERTIFIED]: emptyMetrics,
} as const;

export function mapTermRolesToMetrics({
  termRoles,
  hasNegotiationStarted,
}: {
  termRoles: ParsedNegotiationItem['termRoles'];
  hasNegotiationStarted: boolean;
}) {
  if (hasNegotiationStarted) {
    const calculationMetrics = termRoles.flatMap(
      (role) => negotiationDetailsTermMatrix[role].financialMetricsAfterStart
    );
    const termCounterMetrics = termRoles.flatMap(
      (role) => negotiationDetailsTermMatrix[role].termCounters
    );

    return new Set([...calculationMetrics, ...termCounterMetrics]);
  }

  return new Set([
    ...termRoles.flatMap(
      (role) => negotiationDetailsTermMatrix[role].financialMetricsBeforeStart
    ),
  ]);
}
