import { useTranslation } from 'react-i18next';

import { useSessionContext } from '@/modules/auth/hooks/useSessionContext';
import { NegotiationSupplier } from '@/modules/negotiation/helpers/parseGetNegotiationSuppliersResponse';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { Table } from '@/modules/theme/components/Table/Table';
import { TableCell } from '@/modules/theme/components/Table/TableCell';
import { TableRow } from '@/modules/theme/components/Table/TableRow';
import { TableSkeletonLoader } from '@/modules/theme/components/TableSkeletonLoader/TableSkeletonLoader';
import { Text } from '@/modules/theme/components/Typography';
import { formatAmount } from '@/utils/stringUtils';

import { SuppliersActionTableCell } from './SuppliersActionTableCell';
import { NoSuppliersInfoStyled } from './SuppliersTable.styled';

type SuppliersTableProps = {
  suppliers: NegotiationSupplier[];
  isLoading: boolean;
  isSuccess: boolean;
  pageSize: number;
  negotiationId: string;
};

export const SuppliersTable = ({
  suppliers,
  pageSize,
  isLoading,
  isSuccess,
  negotiationId,
}: SuppliersTableProps) => {
  const { t } = useTranslation('pages/SuppliersTab');
  const { customer } = useSessionContext();

  const headers = {
    company: t('Company & spend'),
    businessUnit: t('Business unit'),
    email: t('Email'),
    category: t('Category'),
    emptyColumn: '',
  } as const;

  if (isLoading) {
    return (
      <Table headers={Object.values(headers)}>
        <TableSkeletonLoader rows={pageSize} height={84} />
      </Table>
    );
  }

  if (suppliers.length === 0 && isSuccess) {
    return (
      <NoSuppliersInfoStyled as="p">{t('No results')}</NoSuppliersInfoStyled>
    );
  }

  return (
    <Table headers={Object.values(headers)}>
      <>
        {suppliers.map(
          ({
            conflictNegotiations,
            id,
            name,
            currentSpend,
            businessUnit,
            defaultContact,
            category,
          }) => {
            const displayWarning = conflictNegotiations.length > 0;

            return (
              <TableRow key={id}>
                <TableCell
                  mobileHeader={headers.company}
                  warning={displayWarning}
                >
                  <FlexContainer direction="column" gap={0.5}>
                    <Text bold>{name}</Text>
                    <Text>
                      {formatAmount(currentSpend)} {customer.currency}
                    </Text>
                  </FlexContainer>
                </TableCell>
                <TableCell
                  mobileHeader={headers.businessUnit}
                  warning={displayWarning}
                >
                  <Text>{businessUnit?.name}</Text>
                </TableCell>
                <TableCell
                  mobileHeader={headers.email}
                  warning={displayWarning}
                >
                  <Text>{defaultContact?.email}</Text>
                </TableCell>
                <TableCell
                  mobileHeader={headers.category}
                  warning={displayWarning}
                >
                  <Text>{category?.name}</Text>
                </TableCell>
                <SuppliersActionTableCell
                  negotiationId={negotiationId}
                  displayWarning={displayWarning}
                  supplierId={id}
                />
              </TableRow>
            );
          }
        )}
      </>
    </Table>
  );
};
