import { useMutation } from '@tanstack/react-query';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { forgotPasswordMutation } from '@/modules/auth/api/mutations/forgotPasswordMutation';

type UseForgotPasswordMutationPayload = {
  onForgotPasswordSuccess: () => void;
};

export const useForgotPasswordMutation = ({
  onForgotPasswordSuccess,
}: UseForgotPasswordMutationPayload) => {
  const jsonApi = useJsonApi();

  return useMutation({
    mutationKey: [forgotPasswordMutation.name],
    mutationFn: forgotPasswordMutation(jsonApi),
    onSuccess: () => onForgotPasswordSuccess(),
  });
};
