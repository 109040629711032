import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { SurveyFormSection } from '../SurveyFormSection';

import { TermImportanceLabels } from './components/TermImportanceLabels';
import { TermImportanceQuestion } from './components/TermImportanceQuestion';

export const TermImportanceSection = () => {
  const { t } = useTranslation('pages/LeadCustomerSurveyPage');

  const { register } = useFormContext();

  return (
    <SurveyFormSection number={2} title={t('What is most important for you?')}>
      <TermImportanceSectionStyled>
        <TermImportanceLabels />

        <TermImportanceQuestion {...register('offerDiscount')}>
          {t('Savings – lower prices')}
        </TermImportanceQuestion>
        <TermImportanceQuestion {...register('lockPrice')}>
          {t('Cost avoidance – locked prices')}
        </TermImportanceQuestion>
        <TermImportanceQuestion {...register('signCodeOfConduct')}>
          {t('Approval of a Supplier Code of Conduct')}
        </TermImportanceQuestion>
        <TermImportanceQuestion {...register('extendInvoiceDueDate')}>
          {t('Better payment days – improved cash flow')}
        </TermImportanceQuestion>
        <TermImportanceQuestion {...register('isoApproval')}>
          {t('Approval of certain ISO standards')}
        </TermImportanceQuestion>
        <TermImportanceQuestion {...register('signStandardAgreement')}>
          {t('Approval of a light Standard agreement')}
        </TermImportanceQuestion>
        <TermImportanceQuestion {...register('answerSustainabilityQuestion')}>
          {t(
            'Submission of a sustainability survey - more information about your suppliers’ sustainability work'
          )}
        </TermImportanceQuestion>
      </TermImportanceSectionStyled>
    </SurveyFormSection>
  );
};

const TermImportanceSectionStyled = styled('div')(({ theme: { spacing } }) => ({
  display: 'grid',
  gridTemplateColumns: '4fr repeat(3, 1fr)',
  columnGap: spacing(4),
  rowGap: spacing(2),
  justifyItems: 'center',
}));
