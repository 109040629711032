import { useTranslation } from 'react-i18next';

import { findSelectValue } from '@/modules/form/components/Select/findSelectValue';
import { SelectInput } from '@/modules/form/components/Select/SelectInput/SelectInput';
import { SupplierNegotiationState } from '@/modules/negotiation/api/resources/negotiationSelectedSuppliers/negotiationSelectedSuppliersResourceConsts';

type SupplierProgressSteppFilterProps = {
  progressStep: string;
  onChange: (value: SupplierNegotiationState | 'null' | undefined) => void;
};

export const SupplierProgressStepFilter = ({
  progressStep,
  onChange,
}: SupplierProgressSteppFilterProps) => {
  const { t } = useTranslation('modules/suppliers/SuppliersFilters');

  const options: {
    label: string;
    value: SupplierNegotiationState | 'null';
  }[] = [
    { label: t('All'), value: 'null' },
    {
      label: t('Completed process'),
      value: SupplierNegotiationState.ANSWERED,
    },
    {
      label: t('Registered'),
      value: SupplierNegotiationState.REGISTERED,
    },
    {
      label: t('Visited'),
      value: SupplierNegotiationState.VISITED,
    },
    {
      label: t('Opened email'),
      value: SupplierNegotiationState.OPENEDEMAIL,
    },
    {
      label: t('Not opened email'),
      value: SupplierNegotiationState.INVITED,
    },
    {
      label: t('Excluded'),
      value: SupplierNegotiationState.EXCLUDED,
    },
  ];

  return (
    <SelectInput
      name="progressStep"
      label={t('Progress step')}
      options={options}
      showErrorMessage={false}
      value={findSelectValue({
        currentValue: progressStep,
        options,
      })}
      placeholder={t('Choose...')}
      onChange={(option) => onChange(option?.value)}
    />
  );
};
