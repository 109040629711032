import * as sup from 'superstruct';

import {
  createIdentifierStruct,
  createResourceStruct,
} from '@/modules/api/utils/jsonApiStructs';

export enum SubscriptionPlanTier {
  BASIC = 'basic',
  PLUS = 'plus',
  CUSTOM = 'custom',
  PILOT = 'pilot',
}

const subscriptionPlanType = 'subscription_plan';

export const subscriptionPlanResourceStruct = createResourceStruct({
  identifier: createIdentifierStruct(subscriptionPlanType),
  attributes: sup.object({
    tier: sup.enums(Object.values(SubscriptionPlanTier)),
    monthlyPrice: sup.string(),
    negotiableSuppliersCount: sup.integer(),
  }),
  meta: sup.object({
    active: sup.boolean(),
    recommended: sup.boolean(),
  }),
});

export const activeSubscriptionPlanResourceStruct = createResourceStruct({
  identifier: createIdentifierStruct(subscriptionPlanType),
  attributes: sup.object({
    tier: sup.enums(Object.values(SubscriptionPlanTier)),
    monthlyPrice: sup.string(),
    negotiableSuppliersCount: sup.integer(),
  }),
});
