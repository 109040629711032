import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { queryKeys } from '@/modules/api/queryKeys';
import { constructMeta } from '@/modules/reactQuery/constructMeta';

import { getNegotiationQuery } from '../api/queries/getNegotiationQuery';

type UseGetNegotiationPayload = {
  negotiationId: string | undefined;
};

export const useGetNegotiationQuery = ({
  negotiationId,
}: UseGetNegotiationPayload) => {
  const jsonApi = useJsonApi();
  const { t } = useTranslation('apiMessages/useGetNegotiationQuery');

  return useQuery({
    queryKey: [queryKeys.negotiations.getNegotiation, negotiationId],
    queryFn: getNegotiationQuery(jsonApi, { negotiationId }),
    refetchOnWindowFocus: true,
    staleTime: 5000,
    refetchInterval: 60_000,
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t('You do not have access to see this negotiation'),
        },
        404: {
          NEGOTIATION_NOT_FOUND: t('Negotiation has not been found'),
        },
      },
    }),
  });
};
