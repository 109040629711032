import { useTranslation } from 'react-i18next';

import { NegotiationSupplier } from '@/modules/negotiation/helpers/parseGetNegotiationSuppliersResponse';
import {
  AcceptableTermAnswer,
  AnsweredTerm,
  Implementation,
} from '@/modules/negotiation/types/negotiationTypes';
import { Scrollbar } from '@/modules/theme/components/Scrollbar';
import { TableRow } from '@/modules/theme/components/Table/TableRow';
import { sanitizeHtml } from '@/utils/sanitizer';

import {
  ButtonStyled,
  ContentWrapperStyled,
  DialogStyled,
  HeadingStyled,
  LastTableCellStyled,
  TableCellStyled,
  TableStyled,
  TextStyled,
} from './SuppliersActionsDialog.styled';

type SuppliersActionsDialogProps = {
  isOpen: boolean;
  isNegotiationActive: boolean;
  supplierName: string;
  onClose: () => void;
  answeredTerms: NegotiationSupplier['answeredTerms'] | undefined;
};

export const SuppliersActionsDialog = ({
  isOpen,
  onClose,
  isNegotiationActive,
  supplierName,
  answeredTerms,
}: SuppliersActionsDialogProps) => {
  const { t } = useTranslation('modules/negotiations/NegotiationSuppliersList');

  const getTermLabel = (term: AnsweredTerm) => {
    if (term.type === 'term/offer_discount') return t('Discount');

    return term.text || '';
  };

  const getTermAnswer = (term: AnsweredTerm) => {
    if (term.type === 'term/offer_discount') return `${term.answer || ''}%`;

    switch (term.answer) {
      case AcceptableTermAnswer.YES:
        return t('Yes');
      case AcceptableTermAnswer.NO:
        return t('No');
      case AcceptableTermAnswer.DONT_KNOW:
        return t("Don't know");
      case Implementation.CREDIT_INVOICE:
        return t('Credit invoice');
      case Implementation.INVOICE_DISCOUNT:
        return t('Invoice discount');
      case Implementation.REDUCED_PRICES:
        return t('Reduced prices');
    }
  };

  return (
    <DialogStyled isOpen={isOpen} hasCloseIcon onClose={onClose}>
      <HeadingStyled variant="h2" as="h3" mb={6}>
        {supplierName}
      </HeadingStyled>
      <HeadingStyled variant="h3" as="p" mb={2}>
        {isNegotiationActive ? t('Actions so far') : t('Actions')}
      </HeadingStyled>
      <ContentWrapperStyled>
        <Scrollbar>
          <TableStyled columnsCount={2}>
            {answeredTerms?.map((term, index) => (
              <TableRow key={`${term.type}-${index}`} variant="striped">
                <TableCellStyled variant="striped">
                  <TextStyled
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHtml(getTermLabel(term)),
                    }}
                  />
                </TableCellStyled>
                <LastTableCellStyled variant="striped">
                  {getTermAnswer(term)}
                </LastTableCellStyled>
              </TableRow>
            ))}
          </TableStyled>
        </Scrollbar>
      </ContentWrapperStyled>
      <ButtonStyled variant="outline" onClick={onClose}>
        {t('Back')}
      </ButtonStyled>
    </DialogStyled>
  );
};
