import { useInfiniteQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { queryKeys, sortArrayQueryKey } from '@/modules/api/queryKeys';
import { constructMeta } from '@/modules/reactQuery/constructMeta';

import { getNegotiationSuppliersQuery } from '../api/queries/getNegotiationSuppliersQuery';
import {
  NegotiationSuppliersIncludes,
  SupplierNegotiationState,
} from '../api/resources/negotiationSelectedSuppliers/negotiationSelectedSuppliersResourceConsts';

// `pageSize` doesn't affect current middle-layer performance
// as it downloads whole list of suppliers on proxy side
const pageSize = 100;

type UseGetNegotiationSelectedSuppliersInfiniteQueryPayload = {
  negotiationId: string;
  negotiationStates: SupplierNegotiationState[];
  enabled: boolean;
};

export const useGetNegotiationSelectedSuppliersInfiniteQuery = ({
  negotiationId,
  negotiationStates,
  enabled,
}: UseGetNegotiationSelectedSuppliersInfiniteQueryPayload) => {
  const jsonApi = useJsonApi();
  const { t } = useTranslation(
    'apiMessages/useGetNegotiationSelectedSuppliersQuery'
  );

  return useInfiniteQuery({
    initialPageParam: 1,
    queryKey: [
      negotiationId,
      queryKeys.negotiations.getNegotiationSelectedSuppliersInfinite,
      sortArrayQueryKey(negotiationStates),
    ],
    queryFn: ({ pageParam = 1 }) =>
      getNegotiationSuppliersQuery(jsonApi, {
        negotiationId,
        page: pageParam,
        pageSize,
        negotiationStates,
        includes: [NegotiationSuppliersIncludes.DEFAULT_CONTACT],
      })(),
    enabled,
    refetchOnWindowFocus: true,
    staleTime: 5000,
    refetchInterval: 60_000,
    getNextPageParam: (lastPage, allPages) => {
      const currentMaxItemCount = allPages.length * pageSize;
      // https://tanstack.com/query/v4/docs/react/reference/useInfiniteQuery
      if (currentMaxItemCount >= lastPage.total) return undefined;
      return allPages.length + 1;
    },
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t('You do not have access to see selected suppliers'),
        },
        404: {
          NEGOTIATION_NOT_FOUND: t('Negotiation has not been found'),
        },
      },
    }),
  });
};
