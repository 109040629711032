import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectInput } from '@/modules/form/components/Select/SelectInput/SelectInput';
import {
  SupplierLanguageType,
  SUPPLIER_LANGUAGES,
} from '@/modules/language/supplierLanguage/supplierLanguageConsts';
import { useGetSupplierLanguageLabels } from '@/modules/language/supplierLanguage/useGetSupplierLanguageLabels';
import { setupNegotiationRoutes } from '@/modules/router/onboardedRoutes';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { Heading, Paragraph } from '@/modules/theme/components/Typography';
import { dark } from '@/modules/theme/utils/colors';

import { KeywordsForm } from './components/KeywordsForm';
import { useGetNegotiationKeywordsQuery } from './hooks/useGetNegotiationKeywordsQuery';
import { GridWrapperStyled } from './Keywords.styled';

export const Keywords = () => {
  const { t } = useTranslation('pages/Keywords');
  const { negotiationId } =
    setupNegotiationRoutes.useGetRouteParams('keywords');

  const languageLabels = useGetSupplierLanguageLabels();

  const languages = Object.fromEntries(
    SUPPLIER_LANGUAGES.map((language) => [
      language,
      { value: language, label: languageLabels[language] },
    ])
  );

  const [currentLanguageOption, setCurrentLanguageOption] = useState<{
    label: string;
    value: SupplierLanguageType;
  }>(languages.sv);

  // `sv` is a default for language
  const { data, isFetching, isPending } = useGetNegotiationKeywordsQuery({
    negotiationId,
    language: currentLanguageOption.value,
  });

  const languageOptions = data
    ? data.languages.map((language) => languages[language])
    : [];

  return (
    <>
      <FlexContainer mb={2} direction="column">
        <Heading variant="h3" as="h2" mb={2}>
          {t(
            'Fill out the keywords that will be used throughout the communication'
          )}
        </Heading>
        <Paragraph color={dark[400]}>
          {t(
            'The keywords will be shown in various contexts for your convenience. For example the automated messages being sent out to your suppliers.'
          )}
        </Paragraph>
      </FlexContainer>
      <GridWrapperStyled>
        <SelectInput
          loading={isPending}
          name="language-select"
          onChange={(language) =>
            language && setCurrentLanguageOption(language)
          }
          options={languageOptions}
          value={currentLanguageOption}
        />
      </GridWrapperStyled>
      <KeywordsForm
        negotiationId={negotiationId}
        isFetching={isFetching}
        keywords={data?.keywords}
        currentLanguage={currentLanguageOption.value}
      />
    </>
  );
};
