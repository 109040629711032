import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { requireNamespaces } from './helpers/requireNamespaces';
import { defaultLocale, LanguageCode } from './locales/locales';

const resources: Record<LanguageCode, I18nNamespaceMap> = {
  en: requireNamespaces(require.context(`./locales/en`, true, /\.json$/)),
};

void i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    returnEmptyString: false,
    lng: defaultLocale,
    fallbackLng: defaultLocale,
    nsSeparator: false,
    keySeparator: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    // eslint-disable-next-line no-restricted-globals
    debug: process.env.NODE_ENV === 'development',
  });

type I18nNamespace = Record<string, string>;
type I18nNamespaceMap = Record<string, I18nNamespace>;
