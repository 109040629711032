import { useTranslation } from 'react-i18next';

import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';
import { Heading } from '@/modules/theme/components/Typography';

import { useGetNegotiationQuery } from '../../hooks/useGetNegotiationQuery';

import { NegotiationTerm } from './components/NegotiationTerm';
import {
  BulletListStyled,
  CheckRoundIconStyled,
  NoTermsInfoHeadingStyled,
  SectionStyled,
  SkeletonLoaderStyled,
} from './NegotiationTerms.styled';

type NegotiationTermsProps = {
  negotiationId: string;
};

export const NegotiationTerms = ({ negotiationId }: NegotiationTermsProps) => {
  const { t } = useTranslation('modules/negotiations/NegotiationTerms');
  const { data, isPending } = useGetNegotiationQuery({ negotiationId });

  const negotiationTerms = data?.negotiationTerms;

  if (isPending) {
    return (
      <>
        <SkeletonLoaderStyled />
        <SkeletonLoader lines={3} />
      </>
    );
  }

  if (!negotiationTerms?.length) {
    return (
      <NoTermsInfoHeadingStyled variant="h3" as="h2">
        {t('There are no terms for this negotiation')}
      </NoTermsInfoHeadingStyled>
    );
  }

  return (
    <SectionStyled>
      <CheckRoundIconStyled />
      <div>
        <Heading variant="h3" as="h2">
          {t('Terms')}
        </Heading>
        <BulletListStyled>
          {negotiationTerms.map((term) => (
            <NegotiationTerm term={term} key={term.id} />
          ))}
        </BulletListStyled>
      </div>
    </SectionStyled>
  );
};
