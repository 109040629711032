import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { constructMeta } from '@/modules/reactQuery/constructMeta';

import { signOutMutation } from '../api/mutations/signOutMutation';

import { useSessionContext } from './useSessionContext';

export const useSignOutMutation = () => {
  const jsonApi = useJsonApi();
  const { signOut } = useSessionContext();
  const { t } = useTranslation('apiMessages/useSignOutMutation');

  return useMutation({
    mutationKey: [signOutMutation.name],
    mutationFn: signOutMutation(jsonApi),
    onSuccess: () => signOut(),
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t(
            'The token expired. Please refresh the page and try again'
          ),
        },
      },
    }),
  });
};
