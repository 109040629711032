import { noop } from '@/utils/noop';

import { useAddSupplierContactToForwardMutation } from './useAddSupplierContactToForwardMutation';
import { useForwardToNewContactMutation } from './useForwardToNewContactMutation';

type UseForwardToNewContactPayload = {
  negotiationId: string;
};

type ForwardToNewContactPayload = {
  supplierId: string | undefined;
  email: string;
  progressId: string | undefined;
};

export const useForwardToNewContact = ({
  negotiationId,
}: UseForwardToNewContactPayload) => {
  const addSupplierContactToForwardMutation =
    useAddSupplierContactToForwardMutation();
  const forwardToNewContactMutation = useForwardToNewContactMutation({
    negotiationId,
  });

  const forwardToNewContact = async (
    { email, supplierId, progressId }: ForwardToNewContactPayload,
    onSuccess: () => void
  ) => {
    try {
      const addSupplierContactResponse =
        await addSupplierContactToForwardMutation.mutateAsync({
          supplierId,
          attributes: {
            email,
            firstName: null,
            lastName: null,
            phoneNumber: null,
          },
        });

      if (!addSupplierContactResponse) {
        throw new Error('Something went wrong');
      }

      await forwardToNewContactMutation.mutateAsync({
        negotiationId,
        progressId,
        contactId: addSupplierContactResponse.data.id,
      });

      onSuccess();
    } catch (err) {
      noop();
    }
  };

  const isLoading =
    addSupplierContactToForwardMutation.isPending ||
    forwardToNewContactMutation.isPending;

  return {
    forwardToNewContact,
    isLoading,
  };
};
