import { memo, ReactNode } from 'react';
import styled from 'styled-components';

import { Footer } from '@/layouts/common/footer/Footer';

import {
  AuthenticatedLayoutWrapperStyled,
  FlexContainerStyled,
  MainContentWrapperStyled,
} from './AuthenticatedLayout.styled';
import { Sidebar } from './components/Sidebar/Sidebar';

type AuthenticatedLayoutProps = {
  children: ReactNode;
};

export const AuthenticatedNotFoundLayout = memo(
  ({ children }: AuthenticatedLayoutProps) => {
    return (
      <AuthenticatedLayoutWrapperStyled>
        <FlexContainerStyled>
          <Sidebar />
          <MainContentWrapperStyled>
            <MainStyled>{children}</MainStyled>
          </MainContentWrapperStyled>
        </FlexContainerStyled>
        <Footer />
      </AuthenticatedLayoutWrapperStyled>
    );
  }
);

const MainStyled = styled('main')({
  height: '100%',
});
