import { PropsWithChildren } from 'react';
import { components, GroupBase, OptionProps } from 'react-select';
import styled from 'styled-components';

import { dark } from '@/modules/theme/utils/colors';

import { BasicOption } from '../types';

import { OptionContentWrapperStyled } from './common.styled';

type NestOptions = {
  nestLevel?: number;
};

type NestedDropdownOption = BasicOption & NestOptions;

export const HighlightOption = <
  Option extends NestedDropdownOption = NestedDropdownOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: PropsWithChildren<OptionProps<Option, IsMulti, Group>>
) => {
  const { nestLevel } = props.data;
  return (
    <CustomOptionStyled nestLevel={nestLevel}>
      <components.Option {...props} className="option">
        <OptionContentWrapperStyled isOptionSelected={props.isSelected}>
          {nestLevel === 0 && '- '}
          {props.children}
        </OptionContentWrapperStyled>
      </components.Option>
    </CustomOptionStyled>
  );
};

const CustomOptionStyled = styled('div')<NestOptions>(
  ({ theme: { spacing }, nestLevel }) => ({
    // multiple properties are coliding with styled components props.
    // eslint-disable-next-line no-warning-comments
    // TODO: find a way to forward them without using `className`
    '.option': {
      paddingLeft: spacing(nestLevel ? nestLevel * 3 : 1),
      ...(nestLevel === 0 && { backgroundColor: dark[100] }),
    },
  })
);
