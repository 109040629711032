import styled from 'styled-components';

import { ReactComponent as ErrorIcon } from '@/assets/icons/errorIcon.svg';

import { ReactComponent as TickIcon } from './tickIcon.svg';

type ValidationItemProps = {
  isValid: boolean;
  children: string | React.ReactNode;
};

export const ValidationItem = ({ isValid, children }: ValidationItemProps) => {
  return (
    <ValidationItemStyled isValid={isValid}>
      {isValid ? <TickIcon /> : <ErrorIcon />}
      {children}
    </ValidationItemStyled>
  );
};

type ValidationItemStyledProps = {
  isValid: boolean;
};

const ValidationItemStyled = styled('li')<ValidationItemStyledProps>(
  ({ theme: { spacing, typography, palette }, isValid }) => ({
    display: 'flex',
    gap: spacing(2),
    marginBottom: spacing(2),
    alignItems: 'center',
    fontWeight: typography.fontWeight.bold,
    color: isValid ? palette.default.text : palette.error.main,
  })
);
