import { useState } from 'react';
import { GroupBase, Props as ReactSelectProps } from 'react-select';
import styled from 'styled-components';

import { Highlighter } from './components/Highlighter';
import { IndicatorsContainer } from './components/IndicatorsContainer';
import { MenuList } from './components/MenuList';
import { MultiOption } from './components/MultiOption';
import { MultiValueContainer } from './components/MultiValueContainer';
import { SelectInput } from './SelectInput/SelectInput';
import type { SelectInputProps } from './SelectInput/SelectInput';
import { BasicOption } from './types';

export function MultiSelect<
  Option extends BasicOption = BasicOption,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  name,
  label,
  error,
  isLoading,
  components,
  options,
  classNames,
  ...props
}: ReactSelectProps<Option, true, Group> & SelectInputProps<Option, true>) {
  const [searchValue, setSearchValue] = useState('');
  const [shouldControlRenderValue, setShouldControlRenderValue] =
    useState(true);

  return (
    <SelectWrapperStyled>
      <SelectInput
        isMulti
        name={name}
        options={options}
        label={label}
        isClearable={!searchValue}
        inputValue={searchValue}
        loading={isLoading}
        onInputChange={(inputValue) => {
          setSearchValue(inputValue);
        }}
        isSearchable
        formatOptionLabel={({ label }, { inputValue }) => {
          return <Highlighter sentence={label} keyword={inputValue} />;
        }}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        onMenuOpen={() => setShouldControlRenderValue(false)}
        onMenuClose={() => setShouldControlRenderValue(true)}
        controlShouldRenderValue={shouldControlRenderValue}
        components={{
          MultiValue: () => null,
          ClearIndicator: () => null,
          ValueContainer: MultiValueContainer,
          Option: MultiOption,
          MenuList,
          IndicatorsContainer: (props) => (
            <IndicatorsContainer
              {...props}
              searchValue={searchValue}
              clearSearchValue={() => setSearchValue('')}
            />
          ),
          ...components,
        }}
        showErrorMessage={false}
        {...props}
      />
    </SelectWrapperStyled>
  );
}

export const SelectWrapperStyled = styled('div')({
  position: 'relative',
});
