import * as sup from 'superstruct';

import { createResponseStruct } from '@/modules/api/utils/jsonApiStructs';
import { businessUnitResourceStruct } from '@/modules/customer/api/businessUnits/businessUnitsHelpers';
import { supplierResourceStruct } from '@/modules/suppliers/api/resources/supplier/supplierResourceStruct';
import { supplierCategoryResourceStruct } from '@/modules/suppliers/api/resources/supplierCategory/supplierCategoryResourceStruct';
import { supplierContactResourceStruct } from '@/modules/suppliers/api/resources/supplierContact/supplierContactResourceStruct';
import { includedStruct } from '@/utils/structs/includedStruct';

import { negotiationResourceStruct } from '../api/resources/negotiation/negotiationResourceStruct';
import { negotiationSupplierProgressResourceStruct } from '../api/resources/negotiationSupplierProgress/negotiationSupplierProgressResourceStruct';
import {
  acceptableTermResourceStruct,
  acceptContractPeriodResourceStruct,
  discountImplementationTermResourceStruct,
  offerDiscountTermResourceStruct,
  signCodeOfConductResourceStruct,
  textTermResourceStruct,
} from '../api/resources/negotiationTerm/negotiationTermResourceStruct';

export const getNegotiationSuppliersResponseStruct = createResponseStruct({
  data: sup.array(supplierResourceStruct),
  included: includedStruct([
    supplierContactResourceStruct,
    supplierCategoryResourceStruct,
    businessUnitResourceStruct,
    negotiationSupplierProgressResourceStruct,
    negotiationResourceStruct,

    // negotiatedTerm
    acceptableTermResourceStruct,
    offerDiscountTermResourceStruct,
    signCodeOfConductResourceStruct,
    acceptContractPeriodResourceStruct,
    discountImplementationTermResourceStruct,
    textTermResourceStruct,
  ]),
  meta: sup.object({
    page: sup.object({
      total: sup.integer(),
    }),
    totalSpend: sup.string(),
  }),
});

export type GetNegotiationSuppliersResponse = sup.Infer<
  typeof getNegotiationSuppliersResponseStruct
>;
