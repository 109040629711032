import styled from 'styled-components';

import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';

export const MetricSkeletonLoader = () => {
  return (
    <>
      <TopSkeletonLoaderStyled />
      <MiddleSkeletonLoaderStyled />
      <BottomSkeletonLoaderStyled />
    </>
  );
};

const TopSkeletonLoaderStyled = styled(SkeletonLoader)({
  height: 20,
  width: 100,
});

const MiddleSkeletonLoaderStyled = styled(SkeletonLoader)({
  height: 55,
  width: 200,
});

const BottomSkeletonLoaderStyled = styled(SkeletonLoader)({
  height: 26,
  width: 100,
});
