import styled from 'styled-components';

import { Heading } from '@/modules/theme/components/Typography';

export const NoSuppliersInfoStyled = styled(Heading)(
  ({ theme: { palette, typography, spacing } }) => ({
    margin: `${spacing(3)} auto`,
    textAlign: 'center',
    color: palette.disabled.main,
    fontWeight: typography.fontWeight.bold,
    fontSize: typography.fontSize.large,
  })
);
