import styled from 'styled-components';

import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';
import { Tabs } from '@/modules/theme/components/Tabs/Tabs';
import { Paragraph } from '@/modules/theme/components/Typography';
import { dark } from '@/modules/theme/utils/colors';

export const TabsStyled = styled(Tabs)(({ theme: { spacing } }) => ({
  marginTop: spacing(6),
}));

export const ParagraphStyled = styled(Paragraph)(
  ({ theme: { spacing, typography } }) => ({
    color: dark[400],
    fontSize: typography.fontSize.large,
    textAlign: 'center',
    fontWeight: typography.fontWeight.bold,
    margin: spacing([3, 0]),
  })
);

export const ButtonLinksWrapperStyled = styled('div')(
  ({ theme: { spacing, breakpoints } }) => ({
    display: 'flex',
    gap: spacing(2),
    marginLeft: 'auto',

    [breakpoints.down.md]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      marginLeft: 0,
    },
  })
);

export const ParagraphSkeletonLoaderStyled = styled(SkeletonLoader)(
  ({ theme: { spacing } }) => ({
    height: 32,
    margin: spacing([2, 0, 0]),
  })
);

export const ParagraphSkeletonWrapperStyled = styled('div')({
  maxWidth: 400,
  margin: '0 auto',
});
