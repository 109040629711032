import { createContext, Dispatch, SetStateAction } from 'react';

import { ParsedSupplier } from '../helpers/supplierHelpers';

export type ParsedSupplierContacts = ParsedSupplier['contacts'];

type SupplierFormContext = {
  contacts: ParsedSupplierContacts | null;
  setContacts: Dispatch<SetStateAction<ParsedSupplierContacts | null>>;
};

export const supplierFormContext = createContext<SupplierFormContext>({
  contacts: null,
  setContacts: notImplemented,
});

function notImplemented(): never {
  throw new Error('Missing SupplierFormContext');
}
