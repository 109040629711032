import styled from 'styled-components';

import { Button } from '@/modules/theme/components/Button/Button';

export const FormFieldsWrapperStyled = styled('div')(
  ({ theme: { spacing } }) => ({
    marginTop: spacing(3),
    maxWidth: 303,
  })
);

export const TextInputWrapperStyled = styled('div')(
  ({ theme: { spacing } }) => ({
    marginBottom: spacing(2.5),
    width: '100%',
  })
);

export const ButtonStyled = styled(Button)(({ theme: { spacing } }) => ({
  marginTop: spacing(4),
}));
