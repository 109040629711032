import styled from 'styled-components';

import { Text } from '@/modules/theme/components/Typography';

export const FooterContentWrapperStyled = styled('footer')(
  ({ theme: { spacing, palette, zIndexes } }) => ({
    zIndex: zIndexes.footer,
    backgroundColor: palette.secondary.background,
    padding: spacing([4, 14]),
    height: spacing(18.5),
    display: 'grid',
    gridTemplateColumns: `1fr`,
    gridTemplateRows: 'auto',
    columnGap: spacing(3),
  })
);

export const IconsContentStyled = styled('div')(({ theme: { spacing } }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  columnGap: spacing(2),
  gridColumn: '1/-1',
  maxWidth: spacing(18),
  justifySelf: 'center',
  paddingBottom: spacing(2),
}));

export const CopyrightContentStyled = styled('div')(
  ({ theme: { spacing } }) => ({
    paddingTop: spacing(2),
    gridColumn: '1/-1',
    maxWidth: spacing(23),
    justifySelf: 'center',
  })
);

export const CopyrightTextStyled = styled(Text)({
  fontSize: '10px',
});
