import { useTranslation } from 'react-i18next';

import { Paragraph } from '@/modules/theme/components/Typography';
import { mint } from '@/modules/theme/utils/colors';

import { ParsedNegotiation } from '../../helpers/parseGetNegotiationResponse';
import { NegotiationTermRole } from '../../types/negotiationTypes';

import {
  AddOnsListStyled,
  NegotiationTermsPanelWrapperStyled,
  AddOnStyled,
} from './NegotiationTermsPanel.styled';

type NegotiationTermsPanelProps = {
  termRoles: ParsedNegotiation['termRoles'];
};

export const NegotiationTermsPanel = ({
  termRoles,
}: NegotiationTermsPanelProps) => {
  const { t } = useTranslation('modules/negotiations/NegotiationDataPanel');

  const isCodeOfConductType = termRoles?.includes(
    NegotiationTermRole.SIGN_CODE_OF_CONDUCT
  );

  const isSustainabilityType = termRoles?.includes(
    NegotiationTermRole.ANSWER_SUSTAINABILITY_QUESTION
  );

  const isPaymentDaysType = termRoles?.includes(
    NegotiationTermRole.EXTEND_INVOICE_DUE_DATE
  );

  if (!isCodeOfConductType && !isSustainabilityType && !isPaymentDaysType) {
    return <></>;
  }

  return (
    <NegotiationTermsPanelWrapperStyled>
      <Paragraph variant="contentBold" as="h4">
        {t('Add ons +')}
      </Paragraph>
      <AddOnsListStyled>
        {isCodeOfConductType && (
          <AddOnStyled>{t('Code of Conduct')}</AddOnStyled>
        )}
        {isPaymentDaysType && <AddOnStyled>{t('Payment days')}</AddOnStyled>}
        {isSustainabilityType && (
          <AddOnStyled color={mint[800]}>
            {t('Supplier questionnaire')}
          </AddOnStyled>
        )}
      </AddOnsListStyled>
    </NegotiationTermsPanelWrapperStyled>
  );
};
