import { Box } from '@/modules/theme/components/Box';

import { Form } from './components/Form/Form';

export const LeadCustomerSurveyPage = () => (
  <main>
    <Box margins={[0, 7, 8]}>
      <Form />
    </Box>
  </main>
);
