import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { white } from '@/modules/theme/utils/colors';
import { fade } from '@/modules/theme/utils/fade';

import { TabVariant } from './types';

export const TabsListStyled = styled('ul')(
  ({ theme: { spacing, borderRadius, boxShadows, zIndexes } }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    marginBottom: spacing(4),
    padding: spacing([0, 4, 0, 8]),

    '&::before, &::after': {
      content: "''",
      position: 'absolute',
      width: '100%',
      left: 0,
      backgroundColor: white,
    },
    '&::before': {
      bottom: spacing(-5),
      height: spacing(3),
      zIndex: zIndexes.tabs + 1,
    },
    '&::after': {
      bottom: spacing(-3.75),
      height: spacing(4),
      borderTopRightRadius: borderRadius.large,
      boxShadow: boxShadows.tab,
      zIndex: zIndexes.tabs,
    },
  })
);

type TabLinkStyledProps = {
  variant: TabVariant;
  $layer: number;
};

export const TabLinkStyled = styled(NavLink)<TabLinkStyledProps>(
  ({
    theme: {
      spacing,
      typography,
      borderRadius,
      palette,
      breakpoints,
      boxShadows,
      zIndexes,
    },
    variant,
    $layer,
  }) => ({
    color: fade(palette.primary.text, 0.6),
    fontFamily: typography.fonts.heading,
    letterSpacing: typography.letterSpacing.default,
    position: 'relative',
    display: 'block',
    minWidth: 280,
    textAlign: 'center',
    textDecoration: 'none',
    padding: spacing(2.25),
    fontWeight: typography.fontWeight.bold,
    borderTopLeftRadius: borderRadius.large,
    borderTopRightRadius: borderRadius.large,
    boxShadow: boxShadows.section,
    background: white,
    zIndex: zIndexes.tabs - $layer,
    marginLeft: spacing(-1.5),

    [breakpoints.down.xl]: {
      minWidth: variant === 'xSmall' ? 210 : 280,
    },

    '&:focus': {
      color: palette.primary.text,
    },
    '& > .blind': {
      display: 'none',
      position: 'absolute',
      top: `calc(100% - ${spacing(1)})`,
      left: 0,
      width: '100%',
      height: spacing(2),
      backgroundColor: white,
    },
    '&.active': {
      color: palette.primary.text,
      zIndex: zIndexes.tabs + 1,
      boxShadow: boxShadows.tab,
      backgroundColor: white,
      ...(variant === 'small' && { padding: spacing([2.7, 2.25]) }),
      ...(variant === 'xSmall' && { padding: spacing([3, 1.5]) }),

      '& > .blind': {
        display: 'block',
      },

      [breakpoints.up.lg]: {
        transform: 'scale(1.05)',
        transformOrigin: 'bottom',
        fontSize:
          variant === 'default'
            ? typography.fontSize.large
            : typography.fontSize.default,

        // https://jsfiddle.net/nanilab/pzxk7gyr/
        '&::before, &::after': {
          content: '""',
          position: 'absolute',

          height: 12,
          width: 20,
          background: 'transparent',
          bottom: 0,
        },
        '&::after': {
          right: -20,
          borderRadius: '0 0 0 12px',
          boxShadow: `-12px 0 0 0 ${white}`,
        },
        '&::before': {
          left: -20,
          borderRadius: '0 0 12px 0',
          boxShadow: `12px 0 0 0 ${white}`,
        },
      },
    },
  })
);
