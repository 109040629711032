import * as sup from 'superstruct';

import { supplierLanguageStruct } from '@/modules/language/supplierLanguage/supplierLanguageConsts';
import { negotiationKeywordIdentifierStruct } from '@/modules/negotiation/api/resources/negotiationKeyword/negotiationKeywordIdentifierStruct';
import { termResourceIdentifierStruct } from '@/modules/negotiation/api/resources/negotiationTerm/negotiationTermIdentifierStruct';
import { validateApiError } from '@/modules/reactQuery/validateApiError';

export const parseNegotiationValidationResponse = (error: unknown) => {
  const validatedError = validateApiError(
    error,
    negotiationValidationErrorResponseValidator
  );

  const { errors } = validatedError.response.data;

  const keywordError = errors.find(
    (error): error is NegotiationKeywordValidationError =>
      error.code === 'NO_KEYWORD_TRANSLATION'
  );

  return {
    startDate: {
      isValid:
        checkIfValid(errors, 'NO_PERIOD') &&
        checkIfValid(errors, 'INVALID_PERIOD'),
    },
    invitationLetter: {
      isValid: checkIfValid(errors, 'NO_INTRO_EMAIL'),
    },
    suppliers: { isValid: checkIfValid(errors, 'NO_SUPPLIERS_SELECTED') },
    terms: { isValid: checkIfValid(errors, 'UNDEFINED_TERM') },
    keywords: {
      isValid: checkIfValid(errors, 'NO_KEYWORD_TRANSLATION'),
      missingLanguages: keywordError
        ? getNegotiationKeywordMissingLanguagesFromError(keywordError)
        : [],
    },
  };
};

export type ParseNegotiationValidationResponse = ReturnType<
  typeof parseNegotiationValidationResponse
>;

const negotiationKeywordValidationErrorStruct = sup.type({
  code: sup.literal('NO_KEYWORD_TRANSLATION'),
  meta: sup.object({
    keyword: negotiationKeywordIdentifierStruct,
    missingLanguages: sup.array(supplierLanguageStruct),
  }),
});

const negotiationTermValidationErrorStruct = sup.type({
  code: sup.literal('UNDEFINED_TERM'),
  meta: sup.object({
    term: termResourceIdentifierStruct,
  }),
});

const negotiationOtherValidationErrorStruct = sup.type({
  code: sup.union([
    sup.literal('NO_PERIOD'),
    sup.literal('INVALID_PERIOD'),
    sup.literal('NO_INTRO_EMAIL'),
    sup.literal('NO_SUPPLIERS_SELECTED'),
  ]),
});

const basicErrorStruct = sup.type({
  code: sup.string(),
});

const negotiationValidationErrorStruct = sup.union([
  negotiationKeywordValidationErrorStruct,
  negotiationTermValidationErrorStruct,
  negotiationOtherValidationErrorStruct,
  basicErrorStruct,
]);

type NegotiationKeywordValidationError = sup.Infer<
  typeof negotiationKeywordValidationErrorStruct
>;

const negotiationValidationErrorResponseValidator = sup.type({
  response: sup.type({
    data: sup.object({
      errors: sup.array(negotiationValidationErrorStruct),
    }),
    status: sup.integer(),
  }),
});

type NegotiationValidationErrorCode =
  | 'NO_PERIOD'
  | 'INVALID_PERIOD'
  | 'NO_INTRO_EMAIL'
  | 'NO_SUPPLIERS_SELECTED'
  | 'UNDEFINED_TERM'
  | 'NO_KEYWORD_TRANSLATION';

const checkIfValid = <T extends { code: string }>(
  errors: T[],
  errorCode: NegotiationValidationErrorCode
) => {
  return !errors.map(({ code }) => code).includes(errorCode);
};

const getNegotiationKeywordMissingLanguagesFromError = (
  error: NegotiationKeywordValidationError
) => {
  return error.meta.missingLanguages;
};
