import { NegotiationState } from '@/modules/negotiation/types/negotiationTypes';

import { NegotiationStatusBadge } from '../../NegotiationStatusBadge/NegotiationStatusBadge';

import {
  FilterItemStyled,
  LabelStyled,
  SkeletonLoaderStyled,
} from './NegotiationStateFilterItem.styled';

type NegotiationStateFilterItemProps = {
  variant: NegotiationState;
  label: string;
  count: number;
  name: string;
  id: string;
  onChange: (filter: NegotiationState) => void;
  defaultChecked: boolean;
  isLoading: boolean;
};

export const NegotiationStateFilterItem = ({
  count,
  variant,
  label,
  name,
  id,
  onChange,
  defaultChecked,
  isLoading,
}: NegotiationStateFilterItemProps) => {
  return (
    <div>
      <FilterItemStyled
        type="checkbox"
        onChange={() => onChange(variant)}
        id={id}
        name={name}
        defaultChecked={defaultChecked}
      />
      <LabelStyled htmlFor={id}>
        {label}
        {isLoading ? (
          <SkeletonLoaderStyled />
        ) : (
          <NegotiationStatusBadge variant={variant}>
            {count}
          </NegotiationStatusBadge>
        )}
      </LabelStyled>
    </div>
  );
};
