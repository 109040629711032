import { useQueryClient } from '@tanstack/react-query';

import { queryKeys } from '@/modules/api/queryKeys';
import { useUploadFileMutation } from '@/modules/customer/api/mutations/useUploadFileMutation';
import { noop } from '@/utils/noop';

import { useCreateUploadCodeOfConductMutation } from './useCreateUploadCodeOfConductMutation';

export { useUploadCodeOfConduct };

type HandleCodeOfConductPayload = {
  file: File;
  negotiationId: string;
  termId: string;
};

const useUploadCodeOfConduct = () => {
  const queryClient = useQueryClient();
  const createUploadMutation = useCreateUploadCodeOfConductMutation();
  const uploadFileMutation = useUploadFileMutation({
    onSuccess: async () =>
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.negotiations.getNegotiation],
      }),
  });

  const handleCodeOfConductUpload = async ({
    file,
    negotiationId,
    termId,
  }: HandleCodeOfConductPayload) => {
    try {
      const createUploadResult = await createUploadMutation.mutateAsync({
        file,
        negotiationId,
        termId,
      });

      if (createUploadResult) {
        const { url, headers } = createUploadResult.meta;

        await uploadFileMutation.mutateAsync({
          url,
          headers,
          file,
        });
      }
    } catch (err) {
      noop();
    }
  };

  const isPending =
    createUploadMutation.isPending || uploadFileMutation.isPending;

  return {
    handleCodeOfConductUpload,
    isPending,
  };
};
