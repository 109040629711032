import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { queryKeys } from '@/modules/api/queryKeys';
import { getNegotiationMetricsQuery } from '@/modules/negotiation/api/queries/getNegotiationMetricsQuery';
import { constructMeta } from '@/modules/reactQuery/constructMeta';

type UseGetNegotiationMetricsQueryPayload = {
  negotiationId: string;
};

export const useGetNegotiationMetricsQuery = ({
  negotiationId,
}: UseGetNegotiationMetricsQueryPayload) => {
  const jsonApi = useJsonApi();
  const { t } = useTranslation('apiMessages/useGetNegotiationMetricsQuery');

  return useQuery({
    queryKey: [queryKeys.negotiations.getNegotiationMetrics, negotiationId],
    queryFn: getNegotiationMetricsQuery(jsonApi, { negotiationId }),
    staleTime: 5000,
    refetchInterval: 60_000,
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t('You do not have access to see this negotiation'),
        },
        404: {
          NEGOTIATION_NOT_FOUND: t('Negotiation has not been found'),
        },
      },
    }),
  });
};
