import styled from 'styled-components';

import { Button } from '@/modules/theme/components/Button/Button';
import { Dialog } from '@/modules/theme/components/Dialog/Dialog';
import { Table } from '@/modules/theme/components/Table/Table';
import { TableCell } from '@/modules/theme/components/Table/TableCell';
import { Heading } from '@/modules/theme/components/Typography';

export const ButtonStyled = styled(Button)(({ theme: { spacing } }) => ({
  marginTop: spacing(5),
}));

export const HeadingStyled = styled(Heading)({
  alignSelf: 'flex-start',
});

export const TextStyled = styled('span')(
  ({ theme: { palette, typography, spacing } }) => ({
    '& a': {
      color: palette.secondary.main,
    },
    '& strong': {
      fontWeight: typography.fontWeight.extraBold,
    },
    '& em': {
      fontStyle: 'italic',
    },
    '& p:not(:first-child)': {
      margin: spacing([2, 0]),
    },
  })
);

export const ContentWrapperStyled = styled('div')({
  width: '100%',
  maxHeight: 408,
  height: '50%',
  flex: 1,
});

export const DialogStyled = styled(Dialog)(({ theme: { spacing } }) => ({
  width: '95%',
  maxWidth: 1000,
  paddingTop: spacing(5),
  height: '100%',
  maxHeight: 790,
}));

export const TableStyled = styled(Table)(
  ({ theme: { typography, palette } }) => ({
    gridTemplateColumns: '4fr 1fr',
    color: palette.default.text,
    fontWeight: typography.fontWeight.bold,
  })
);

export const TableCellStyled = styled(TableCell)(({ theme: { spacing } }) => ({
  minHeight: 40,
  padding: spacing([1, 0, 1, 2]),

  '&:last-child': {
    paddingRight: spacing(2),
  },
}));

export const LastTableCellStyled = styled(TableCellStyled)({
  justifyContent: 'flex-end',
});
