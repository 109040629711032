import styled from 'styled-components';

import { Heading, Paragraph } from '@/modules/theme/components/Typography';
import { orange, white } from '@/modules/theme/utils/colors';

export const PageWrapperStyled = styled('div')({
  width: '100%',
});

export const CountingStyled = styled('span')({
  color: orange[600],
});

export const HeadingStyled = styled(Heading)(
  ({ theme: { spacing, breakpoints } }) => ({
    color: white,
    padding: spacing([13, 3, 13, 16]),

    [breakpoints.down.md]: {
      padding: spacing([13, 2]),
    },
  })
);

export const ParagraphStyled = styled(Paragraph)(
  ({ theme: { typography } }) => ({
    fontSize: typography.pxToRem(24),
    lineHeight: 1.5,
    color: white,
  })
);

export const ContentWrapperStyled = styled('div')(
  ({ theme: { spacing, breakpoints } }) => ({
    position: 'absolute',
    top: '50%',
    margin: 0,
    paddingLeft: spacing(16),
    '-ms-transform': 'translateY(-50%)',
    transform: 'translateY(-50%)',

    [breakpoints.down.md]: {
      padding: spacing([5, 2]),
    },
  })
);
