import { useTranslation } from 'react-i18next';

import { useSessionContext } from '@/modules/auth/hooks/useSessionContext';
import { negotiationMetrics } from '@/modules/negotiation/metrics/consts';
import { ParsedNegotiationItem } from '@/modules/negotiation/types/negotiationTypes';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { Paragraph } from '@/modules/theme/components/Typography';
import { formatAmount } from '@/utils/stringUtils';

import { NegotiationCardLayout } from '../NegotiationCardLayout/NegotiationCardLayout';

import { getNegotiationCardMetrics } from './getNegotiationCardMetrics';
import { DataWrapperStyled, NumberStyled } from './NegotiationCard.styled';

type NegotiationBeforeStartCardProps = {
  negotiation: ParsedNegotiationItem;
  link?: string;
};

export const NegotiationBeforeStartCard = ({
  negotiation,
  link,
}: NegotiationBeforeStartCardProps) => {
  const { t } = useTranslation('pages/DashboardPage');
  const {
    customer: { currency },
  } = useSessionContext();

  const { metrics } = negotiation;
  const metricsToDisplay = getNegotiationCardMetrics(negotiation);

  return (
    <NegotiationCardLayout link={link} negotiation={negotiation}>
      <FlexContainer wrap="wrap" columnGap={15}>
        {metricsToDisplay.has(negotiationMetrics.currentSpend) && (
          <DataWrapperStyled>
            <NumberStyled>
              {formatAmount(metrics?.currentSpend || 0)}
            </NumberStyled>
            <Paragraph variant="contentBold">
              {t('Negotiable spend ({{currency}})', {
                currency,
              })}
            </Paragraph>
          </DataWrapperStyled>
        )}

        {metricsToDisplay.has(negotiationMetrics.potentialSavings) && (
          <DataWrapperStyled>
            <NumberStyled>
              {formatAmount(metrics?.potentialSavings || 0)}
            </NumberStyled>
            <Paragraph variant="contentBold">
              {t('Potential savings ({{currency}})', {
                currency,
              })}
            </Paragraph>
          </DataWrapperStyled>
        )}

        {metricsToDisplay.has(negotiationMetrics.potentialCostAvoidance) && (
          <DataWrapperStyled>
            <NumberStyled>
              {formatAmount(metrics?.potentialCostAvoidance || 0)}
            </NumberStyled>
            <Paragraph variant="contentBold">
              {t('Potential Cost Avoidance ({{currency}})', {
                currency,
              })}
            </Paragraph>
          </DataWrapperStyled>
        )}

        {metricsToDisplay.has(negotiationMetrics.contactsCount) && (
          <DataWrapperStyled>
            <NumberStyled>{metrics?.contactsCount}</NumberStyled>
            <Paragraph variant="contentBold">{t('Email contacts')}</Paragraph>
          </DataWrapperStyled>
        )}
      </FlexContainer>
    </NegotiationCardLayout>
  );
};
