import {
  businessUnitFromResource,
  BusinessUnitResource,
  businessUnitResourceType,
} from '@/modules/customer/api/businessUnits/businessUnitsHelpers';
import { supplierCategoryResourceType } from '@/modules/suppliers/api/resources/supplierCategory/supplierCategoryResourceConsts';
import { SupplierCategory } from '@/modules/suppliers/api/resources/supplierCategory/supplierCategoryResourceStruct';
import { supplierContactResourceType } from '@/modules/suppliers/api/resources/supplierContact/supplierContactResourceConsts';
import { SupplierContact } from '@/modules/suppliers/api/resources/supplierContact/supplierContactResourceStruct';
import { notEmpty } from '@/utils/utils';

import { negotiationResourceType } from '../api/resources/negotiation/negotiationResourceConsts';
import { NegotiationResourceType } from '../api/resources/negotiation/negotiationResourceStruct';
import { negotiationSupplierProgressType } from '../api/resources/negotiationSupplierProgress/negotiationSupplierProgressResourceConsts';
import { NegotiationSupplierProgressResourceType } from '../api/resources/negotiationSupplierProgress/negotiationSupplierProgressResourceStruct';
import { NegotiationTermResource } from '../api/resources/negotiationTerm/negotiationTermResourceStruct';
import { AnsweredTerm, NegotiationTermTypes } from '../types/negotiationTypes';

import { GetNegotiationSuppliersResponse } from './getNegotiationSuppliersResponseStruct';

export const parseGetNegotiationSuppliersResponse = (
  response: GetNegotiationSuppliersResponse
) => {
  const { data, included, meta } = response;
  const {
    page: { total },
  } = meta;

  const defaultContactData = included.filter(
    (item): item is SupplierContact => item.type === supplierContactResourceType
  );

  const businessUnitData = included.filter(
    (item): item is BusinessUnitResource =>
      item.type === businessUnitResourceType
  );

  const categoryData = included.filter(
    (item): item is SupplierCategory =>
      item.type === supplierCategoryResourceType
  );

  const negotiationSupplierProgressData = included.filter(
    (item): item is NegotiationSupplierProgressResourceType =>
      item.type === negotiationSupplierProgressType
  );

  const conflictNegotiationsData = included.filter(
    (item): item is NegotiationResourceType =>
      item.type === negotiationResourceType
  );

  const termsData = included.filter((item): item is NegotiationTermResource =>
    Object.values(NegotiationTermTypes).includes(item.type)
  );

  const suppliers = data.map(({ id, attributes, relationships }) => {
    const supplierDefaultContactData = defaultContactData.find(
      ({ id }) => id === relationships.defaultContact.data?.id
    );

    const supplierBusinessUnitData = businessUnitData.find(
      ({ id }) => id === relationships.businessUnit.data?.id
    );

    const supplierCategoryData = categoryData.find(
      ({ id }) => id === relationships.category.data?.id
    );

    const supplierNegotiationSupplierProgressData =
      negotiationSupplierProgressData.find(
        (item): item is NegotiationSupplierProgressResourceType =>
          item.id === relationships.negotiationProgress?.data?.id
      );

    const supplierConflictNegotiationsIdsArray =
      relationships.conflictNegotiations?.data.map(({ id }) => id);

    const conflictNegotiations = supplierConflictNegotiationsIdsArray?.length
      ? conflictNegotiationsData.filter(
          ({ id }) => supplierConflictNegotiationsIdsArray?.includes(id)
        )
      : [];

    const defaultContact = supplierDefaultContactData
      ? {
          id: supplierDefaultContactData.id,
          firstName: supplierDefaultContactData.attributes.firstName,
          lastName: supplierDefaultContactData.attributes.lastName,
          email: supplierDefaultContactData.attributes.email,
          phoneNumber: supplierDefaultContactData.attributes.phoneNumber,
        }
      : null;

    const businessUnit = supplierBusinessUnitData
      ? businessUnitFromResource(supplierBusinessUnitData)
      : null;

    const category = supplierCategoryData
      ? {
          name: supplierCategoryData.attributes.name,
          id: supplierCategoryData.id,
        }
      : null;

    const progress = supplierNegotiationSupplierProgressData
      ? {
          ...supplierNegotiationSupplierProgressData.attributes,
          id: supplierNegotiationSupplierProgressData.id,
        }
      : null;

    const answeredTermsIds =
      supplierNegotiationSupplierProgressData?.relationships.answeredTerms.data?.map(
        ({ id }) => id
      );

    const supplierAnsweredTermsData = answeredTermsIds?.map((id) =>
      termsData.find((term) => term.id === id)
    );

    const answeredTerms: AnsweredTerm[] =
      supplierAnsweredTermsData
        ?.map((term, index) => {
          if (!term) return null;
          return {
            type: term.type,
            text: term.attributes?.text,
            answer: progress?.answers?.[index],
          };
        })
        .filter(notEmpty) || [];

    return {
      id,
      defaultContact,
      businessUnit,
      category,
      conflictNegotiations,
      progress,
      answeredTerms,
      ...attributes,
    };
  });

  return {
    suppliers,
    total,
  };
};

export type ParsedNegotiationSuppliersResponse = ReturnType<
  typeof parseGetNegotiationSuppliersResponse
>;

export type NegotiationSupplier =
  ParsedNegotiationSuppliersResponse['suppliers'][number];
