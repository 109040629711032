import styled from 'styled-components';

type InputErrorProps = {
  errorHidden?: boolean;
  displayAlignment?: 'left' | 'center' | 'right';
};

export const InputError = styled('p')<InputErrorProps>(
  ({
    theme: { palette, typography },
    errorHidden,
    displayAlignment = 'right',
  }) => ({
    display: errorHidden ? 'none' : 'block',
    color: palette.error.main,
    margin: 0,
    fontSize: typography.fontSize.extraSmall,
    position: 'absolute',
    width: '100%',
    top: '100%',
    textAlign: displayAlignment,
  })
);
