import styled from 'styled-components';

import { FlexContainer } from '@/modules/theme/components/FlexContainer';

export const AuthenticatedLayoutWrapperStyled = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  position: 'relative',
});

export const FlexContainerStyled = styled(FlexContainer)({
  flex: 1,
});

export const MainContentWrapperStyled = styled('div')(
  ({ theme: { spacing } }) => ({
    flex: 1,
    paddingLeft: spacing(8.75),
    width: '100%',
  })
);
