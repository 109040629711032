import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SignOutDialog } from '@/modules/auth/components/SignOutDialog';
import { AccessibleSvg } from '@/modules/theme/components/AccessibleSvg';

import { ReactComponent as SidebarToggleIcon } from './icons/sidebarToggle.svg';
import { ReactComponent as SignOutIcon } from './icons/signout.svg';
import {
  BackdropStyled,
  NavButtonStyled,
  NavigationListItemStyled,
  NavigationTextStyled,
  NavLinkStyled,
  SidebarToggleButtonStyled,
  SidebarToggleWrapper,
  SidebarWrapperStyled,
} from './Sidebar.styled';
import { useMenuItems } from './useMenuItems';

export function Sidebar() {
  const { t } = useTranslation('layouts/AuthenticatedLayout');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSignOutDialogOpen, setIsSignOutDialogOpen] = useState(false);
  const menuItems = useMenuItems();

  return (
    <>
      <BackdropStyled
        onClick={() => setIsSidebarOpen(false)}
        isOpen={isSidebarOpen}
      />
      <SidebarWrapperStyled isOpen={isSidebarOpen}>
        <SidebarToggleWrapper>
          <SidebarToggleButtonStyled
            isOpen={isSidebarOpen}
            onClick={() => setIsSidebarOpen((prev) => !prev)}
          >
            <AccessibleSvg
              SvgComponent={SidebarToggleIcon}
              title={isSidebarOpen ? t('Close menu') : t('Open menu')}
            />
          </SidebarToggleButtonStyled>
        </SidebarToggleWrapper>
        <ul>
          {menuItems.map(({ svg, text, url }) => (
            <NavigationListItemStyled key={text}>
              <NavLinkStyled to={url} onClick={() => window.scrollTo(0, 0)}>
                <AccessibleSvg
                  SvgComponent={svg.src}
                  title={svg.title}
                  description={svg.description}
                />
                <NavigationTextStyled>{text}</NavigationTextStyled>
              </NavLinkStyled>
            </NavigationListItemStyled>
          ))}
          <NavigationListItemStyled>
            <NavButtonStyled onClick={() => setIsSignOutDialogOpen(true)}>
              <SignOutIcon />
              <NavigationTextStyled>{t('Sign out')}</NavigationTextStyled>
            </NavButtonStyled>
          </NavigationListItemStyled>
        </ul>
      </SidebarWrapperStyled>
      <SignOutDialog
        isOpen={isSignOutDialogOpen}
        onClose={() => setIsSignOutDialogOpen(false)}
      />
    </>
  );
}
