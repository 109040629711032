import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { queryKeys } from '@/modules/api/queryKeys';
import { constructMeta } from '@/modules/reactQuery/constructMeta';
import { getSupplierQuery } from '@/modules/suppliers/api/queries/getSupplierQuery';
import { parseGetSupplierResponse } from '@/modules/suppliers/helpers/supplierHelpers';

type UseGetSupplierPayload = {
  supplierId: string | undefined;
};

export const useGetSupplierQuery = ({ supplierId }: UseGetSupplierPayload) => {
  const jsonApi = useJsonApi();
  const { t } = useTranslation('apiMessages/useGetSupplierQuery');

  return useQuery({
    queryKey: [queryKeys.suppliers.getSupplier, supplierId],
    queryFn: getSupplierQuery(jsonApi, { supplierId }),
    select: (response) => parseGetSupplierResponse(response),
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t(`Your session has expired. Please sign in`),
        },
        404: {
          SUPPLIER_NOT_FOUND: t('Supplier has not been found'),
        },
      },
    }),
  });
};
