import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';

import { Scrollbar } from '@/modules/theme/components/Scrollbar';
import { Table } from '@/modules/theme/components/Table/Table';
import { TableCell } from '@/modules/theme/components/Table/TableCell';
import { TableRow } from '@/modules/theme/components/Table/TableRow';
import { TableSkeletonLoader } from '@/modules/theme/components/TableSkeletonLoader/TableSkeletonLoader';
import { Text } from '@/modules/theme/components/Typography';

import { NegotiationSupplier } from '../../helpers/parseGetNegotiationSuppliersResponse';

type SuppliersGroupTableProps = {
  suppliers: NegotiationSupplier[] | undefined;
  onFetchMore: () => void;
  isFetching: boolean;
};

export const SuppliersGroupTable = ({
  suppliers,
  isFetching,
  onFetchMore,
}: SuppliersGroupTableProps) => {
  const { t } = useTranslation('modules/negotiations/NegotiationDataPanel');
  const headers = {
    company: t('Company'),
    name: t('Name'),
    email: t('Email'),
  };

  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && !isFetching) {
      onFetchMore();
    }
  }, [onFetchMore, inView, isFetching]);

  if (!suppliers?.length && !isFetching) {
    return (
      <WrapperStyled>
        <NoSuppliersInfoStyled>
          {t('There are no suppliers to show at this moment')}
        </NoSuppliersInfoStyled>
      </WrapperStyled>
    );
  }

  return (
    <WrapperStyled>
      <Scrollbar>
        <Table headers={Object.values(headers)}>
          {suppliers?.map(({ id, name, defaultContact }) => (
            <TableRow key={id} variant="striped">
              <TableCell mobileHeader={headers.company}>
                <Text bold>{name}</Text>
              </TableCell>
              <TableCell mobileHeader={headers.name}>
                <Text>
                  {defaultContact?.firstName} {defaultContact?.lastName}
                </Text>
              </TableCell>
              <TableCell mobileHeader={headers.email}>
                <Text>{defaultContact?.email}</Text>
              </TableCell>
            </TableRow>
          ))}
        </Table>
        {isFetching && <TableSkeletonLoader rows={20} />}
        <div ref={ref} />
      </Scrollbar>
    </WrapperStyled>
  );
};

const WrapperStyled = styled('div')(({ theme: { spacing } }) => ({
  height: spacing(55),
  width: '100%',
}));

export const NoSuppliersInfoStyled = styled('div')(
  ({ theme: { palette, typography } }) => ({
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 278,
    margin: '0 auto',
    textAlign: 'center',
    color: palette.disabled.main,
    fontWeight: typography.fontWeight.bold,
    fontSize: typography.fontSize.large,
  })
);
