import * as sup from 'superstruct';

import { categories, categoriesImportance } from '../consts';

import { questionnaireFormStruct } from './questionnaireFormStruct';

export const serializeSpendAndQuestionnaireValues = (
  values: sup.Infer<typeof questionnaireFormStruct>
) => {
  const {
    spendFile,
    offerDiscount,
    lockPrice,
    signCodeOfConduct,
    extendInvoiceDueDate,
    answerSustainabilityQuestion,
    categoryImportance,
    notes,
  } = values;

  const defaultCategoriesImportanceObject: Record<string, string> = {};

  for (const key of categories) {
    defaultCategoriesImportanceObject[key] = categoriesImportance.notImportant;
  }

  const chosenCategoriesImportanceObject: Record<string, string> = {};

  for (const key of categoryImportance) {
    chosenCategoriesImportanceObject[key.label] = key.value;
  }

  return {
    spendFile,
    termImportance: {
      offerDiscount: offerDiscount ?? 'notImportant',
      lockPrice: lockPrice ?? 'notImportant',
      signCodeOfConduct: signCodeOfConduct ?? 'notImportant',
      extendInvoiceDueDate: extendInvoiceDueDate ?? 'notImportant',
      answerSustainabilityQuestion:
        answerSustainabilityQuestion ?? 'notImportant',
    },
    categoryImportance: {
      ...defaultCategoriesImportanceObject,
      ...chosenCategoriesImportanceObject,
    },
    notes,
  };
};

export type SerializedSpendAndQuestionnaireValues = ReturnType<
  typeof serializeSpendAndQuestionnaireValues
>;
