import { useTranslation } from 'react-i18next';

import { NegotiationState } from '@/modules/negotiation/types/negotiationTypes';

export const useNegotiationStatusLabels = () => {
  const { t } = useTranslation('pages/DashboardPage');

  const labels = {
    [NegotiationState.SUGGESTED]: t('Suggested'),
    [NegotiationState.SETUP]: t('In setup'),
    [NegotiationState.PLANNED]: t('Planned'),
    [NegotiationState.ACTIVE]: t('In progress'),
    [NegotiationState.COMPLETED]: t('Completed'),
  };

  return (state: NegotiationState) => {
    const notLaunched =
      state === NegotiationState.DRAFT || state === NegotiationState.ESTIMATED;
    if (notLaunched) throw new Error(`unsupported negotiation type: ${state}`);

    return labels[state];
  };
};
