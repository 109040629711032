import styled from 'styled-components';

import { white } from '@/modules/theme/utils/colors';

export const TermIconsWrapperStyled = styled('div')(
  ({ theme: { spacing } }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    gap: spacing(1),
    gridArea: '2 / 2',
  })
);

export const ListItemStyled = styled('li')({
  textAlign: 'left',
});

export const IconWrapperStyled = styled('div')(
  ({ theme: { borderRadius } }) => ({
    width: 24,
    height: 24,
    backgroundColor: white,
    borderRadius: borderRadius.round,
  })
);
