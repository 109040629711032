import styled from 'styled-components';

import { Button } from '@/modules/theme/components/Button/Button';

export const ButtonStyled = styled(Button)({
  display: 'block',
  margin: '0 auto',
});

export const FormStyled = styled('form')({
  width: '100%',
});

export const ContentWrapperStyled = styled('div')({
  maxWidth: 430,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
});

export const CheckboxWrapperStyled = styled('div')(
  ({ theme: { spacing } }) => ({
    marginLeft: '20%',
    marginBottom: spacing(8),
    marginTop: spacing(3),
  })
);

export const TextStyled = styled('span')(({ theme: { typography } }) => ({
  display: 'inline-block',
  maxWidth: 210,
  color: 'inherit',
  fontWeight: typography.fontWeight.bold,
  fontSize: typography.fontSize.default,
  lineHeight: 1.5,
}));
