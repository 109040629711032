import Bugsnag from '@bugsnag/js';
import { BugsnagErrorBoundary } from '@bugsnag/plugin-react';
import React, { Component, ComponentProps, ErrorInfo } from 'react';

type BoundaryProps = ComponentProps<BugsnagErrorBoundary>;

const BugsnagBoundary = Bugsnag.isStarted()
  ? Bugsnag.getPlugin('react')?.createErrorBoundary(React)
  : undefined;

class FallbackBoundary extends Component<
  BoundaryProps,
  { error: Error; errorInfo: ErrorInfo } | { error: null; errorInfo: null }
> {
  constructor(props: BoundaryProps) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error, errorInfo });
  }

  clearError = () => {
    this.setState({ error: null, errorInfo: null });
  };

  render() {
    const { FallbackComponent = () => null } = this.props;

    if (this.state.error) {
      return (
        <FallbackComponent
          error={this.state.error}
          info={this.state.errorInfo}
          clearError={this.clearError}
        />
      );
    }

    return this.props.children;
  }
}

export const AppErrorBoundary = BugsnagBoundary ?? FallbackBoundary;
