import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet-async';

import { ANALYTICS_COOKIE_NAME } from '@/layouts/common/CookieConsentDialog/consts';

import { appConfig } from '../appConfig';

export const AnalyticsScripts = () => {
  const [cookies] = useCookies([ANALYTICS_COOKIE_NAME]);

  const userAgreedToAnalyticsCookies = Boolean(cookies[ANALYTICS_COOKIE_NAME]);

  useEffect(() => {
    if (userAgreedToAnalyticsCookies && appConfig.scripts.googleAnalyticsId) {
      ReactGA.initialize(appConfig.scripts.googleAnalyticsId);
    }
  }, [userAgreedToAnalyticsCookies]);

  return (
    <Helmet>
      {appConfig.scripts.hubspotUrl && userAgreedToAnalyticsCookies && (
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src={appConfig.scripts.hubspotUrl}
        />
      )}
    </Helmet>
  );
};
