import { useTranslation } from 'react-i18next';

import { Dialog } from '@/modules/theme/components/Dialog/Dialog';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { FlexLayout } from '@/modules/theme/components/FlexLayout';

import { ForgotPasswordMailSentTextStyled } from './ForgotPasswordConfirmationDialog.styled';

export function ForgotPasswordConfirmationDialog({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation('pages/SignInPage');
  return (
    <Dialog
      isOpen={isOpen}
      hasCloseIcon
      onClose={onClose}
      title={t('Password reset email has been sent')}
    >
      <FlexLayout direction="column">
        <FlexContainer justify="center">
          <ForgotPasswordMailSentTextStyled variant="small">
            {t(
              'Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder.'
            )}
          </ForgotPasswordMailSentTextStyled>
        </FlexContainer>
      </FlexLayout>
    </Dialog>
  );
}
