import { Routes } from '@/modules/router/components/Routes';

import { Providers } from './Providers';

function App() {
  return (
    <Providers>
      <Routes />
    </Providers>
  );
}

export default App;
