import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useSessionContext } from '@/modules/auth/hooks/useSessionContext';
import { NegotiationMetric } from '@/modules/negotiation/components/NegotiationMetric';
import { NegotiationTermsPanel } from '@/modules/negotiation/components/NegotiationTermsPanel/NegotiationTermsPanel';
import { ParsedNegotiation } from '@/modules/negotiation/helpers/parseGetNegotiationResponse';
import { negotiationMetrics } from '@/modules/negotiation/metrics/consts';

type NegotiationSetupMetricsProps = {
  termRoles: ParsedNegotiation['termRoles'];
  metricsToDisplay: Set<string>;
  metrics: ParsedNegotiation['metrics'];
};

export const NegotiationSetupMetrics = ({
  termRoles,
  metricsToDisplay,
  metrics,
}: NegotiationSetupMetricsProps) => {
  const { t } = useTranslation('pages/NegotiationSetupPage');
  const { customer } = useSessionContext();

  return (
    <NegotiationSetupMetricsWrapperStyled>
      <FinancialMetricsWrapperStyled>
        {metricsToDisplay.has(negotiationMetrics.potentialSavings) && (
          <NegotiationMetric
            heading={t('Potential savings ({{currency}})', {
              currency: customer.currency,
            })}
            data={metrics.potentialSavings}
          />
        )}

        {metricsToDisplay.has(negotiationMetrics.potentialCostAvoidance) && (
          <NegotiationMetric
            heading={t('Potential cost avoidance ({{currency}})', {
              currency: customer.currency,
            })}
            data={metrics.potentialCostAvoidance}
          />
        )}
      </FinancialMetricsWrapperStyled>

      {metricsToDisplay.has(negotiationMetrics.suppliersCount) && (
        <NegotiationMetric
          variant="counter"
          heading={t('Nr of suppliers')}
          data={metrics.suppliersCount}
        />
      )}
      <NegotiationTermsPanel termRoles={termRoles} />
    </NegotiationSetupMetricsWrapperStyled>
  );
};

const NegotiationSetupMetricsWrapperStyled = styled('div')(
  ({ theme: { spacing } }) => ({
    display: 'flex',
    gap: spacing(6),
  })
);

const FinancialMetricsWrapperStyled = styled('div')(
  ({ theme: { spacing } }) => ({
    '&:empty': {
      marginRight: spacing(-6),
    },
  })
);
