import { Navigate } from 'react-router-dom';

import { anonymousRoutes } from '@/modules/router/anonymousRoutes';

import { ResetPasswordPage } from './ResetPasswordPage';

export const ResetPasswordScene = () => {
  const { queryParams, queryParamsError } =
    anonymousRoutes.useGetQueryParams('resetPassword');

  if (queryParamsError) {
    return <Navigate to={anonymousRoutes.buildUrl('signIn')} />;
  }
  return (
    <ResetPasswordPage
      userId={queryParams.userId}
      verificationId={queryParams.verificationId}
    />
  );
};
