import { nanoid } from 'nanoid';
import {
  ForwardRefExoticComponent,
  FunctionComponent,
  ReactElement,
  SVGAttributes,
  useState,
} from 'react';

// This component helps us providing title and description
// for the SVG graphics that we show on the website.
// More info about accessible SVG might be found below:
// https://css-tricks.com/accessible-svgs/#2-inline-svg
// It is required for interactive elements (such as links or buttons) that
// appear as an SVG image and don't have any text beside the image.
export const AccessibleSvg: FunctionComponent<{
  title: string;
  description?: string;
  SvgComponent: FunctionComponent<SVGAttributes<SVGElement>>;
  className?: string;
}> = ({ title, description, SvgComponent, className }) => {
  const [id] = useState(() => nanoid());
  const titleId = `title-${id}`;
  const descriptionId = `description-${id}`;

  // We need to add labeled title & description to the children of
  // the generated SVG component
  const {
    children,
    // `aria-labelledby` attribute, which I declare for `svg` tag, is overriden by this one from props,
    // I extract it here in order not to assign it to the `svg` tag with other props
    'aria-labelledby': ariaLabelledBy,
    ...props
  } = cloneSvgProps(SvgComponent);

  return (
    <svg
      className={className}
      role="img"
      aria-labelledby={titleId}
      aria-describedby={descriptionId}
      {...props}
    >
      <title id={titleId}>{title}</title>
      <desc id={descriptionId}>{description}</desc>
      {children}
    </svg>
  );
};

type SvgComponentType = FunctionComponent<SVGAttributes<SVGElement>>;

function cloneSvgProps(SvgComponent: SvgComponentType) {
  // Imported Svg is not a regular function component (contrary to its type).
  // Instead, it's an instance of the forward ref component, that has the
  // "render" method. The "render" is probably designed only for React
  // internals, however I was not able to find a better way to clone children
  // of the generated Svg.
  const CoercedComponent = SvgComponent as ForwardRefExoticComponent<
    SVGAttributes<SVGElement>
  > & {
    render: SvgComponentType;
  };

  const originalSvg = CoercedComponent.render({}) as ReactElement<
    SVGAttributes<SVGElement>
  >;

  return originalSvg.props;
}
