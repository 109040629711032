import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { queryKeys } from '@/modules/api/queryKeys';
import { JsonApiClient } from '@/modules/api/utils/createJsonApiClient';
import { NegotiationTermTypes } from '@/modules/negotiation/types/negotiationTypes';
import { constructMeta } from '@/modules/reactQuery/constructMeta';

export const useSetNegotiationContractPeriodMutation = () => {
  const jsonApi = useJsonApi();
  const queryClient = useQueryClient();
  const { t } = useTranslation(
    'apiMessages/useSetNegotiationContractPeriodMutation'
  );

  return useMutation({
    mutationFn: setNegotiationContractPeriod(jsonApi),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.negotiations.getNegotiation],
      });
    },
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t('You are not allowed to edit implementation types'),
        },
        404: {
          NEGOTIATION_NOT_FOUND: t('Negotiation not found'),
          TERM_NOT_FOUND: t('Term not found'),
        },
        422: {
          NEGOTIATION_PLANNED: t(
            'Terms cannot be changed once a negotiation is planned'
          ),
        },
      },
    }),
  });
};

type SetNegotiationContractPeriodPayload = {
  negotiationId: string;
  termId: string;
  startDate: string | null;
  endDate: string | null;
};

const setNegotiationContractPeriod = (jsonApi: JsonApiClient) => {
  return ({
    negotiationId,
    termId,
    startDate,
    endDate,
  }: SetNegotiationContractPeriodPayload) =>
    jsonApi.update({
      path: `/me/customer/negotiations/${negotiationId}/terms/${termId}`,
      type: NegotiationTermTypes.acceptContractPeriod,
      id: termId,
      attributes: {
        startDate,
        endDate,
      },
    });
};
