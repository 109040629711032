import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { JsonApiClient } from '@/modules/api/utils/createJsonApiClient';
import { constructMeta } from '@/modules/reactQuery/constructMeta';

import { SerializedSpendAndQuestionnaireValues } from '../helpers/serializeQuestionnaireValues';

export const useSubmitQuestionnaireMutation = () => {
  const jsonApi = useJsonApi();
  const { t } = useTranslation('apiMessages/useSubmitQuestionnaireMutation');

  return useMutation({
    mutationFn: submitQuestionnaire(jsonApi),
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t("You don't have access to submit questionnaire"),
        },
        404: {
          SPEND_FILE_NOT_FOUND: t(
            'Spend file not found. Please refresh the page or contact the administrator.'
          ),
        },
      },
    }),
  });
};

type SubmitQuestionnairePayload = {
  questionnaireValues: Omit<SerializedSpendAndQuestionnaireValues, 'spendFile'>;
  spendFileId: string;
};

const submitQuestionnaire = (jsonApi: JsonApiClient) => {
  return ({ spendFileId, questionnaireValues }: SubmitQuestionnairePayload) => {
    return jsonApi.put({
      type: 'spend_file_survey_response',
      path: `/me/customer/spend_files/${spendFileId}/survey_response`,
      attributes: questionnaireValues,
    });
  };
};
