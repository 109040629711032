import { useTranslation } from 'react-i18next';

import { ReactComponent as MagnifierIcon } from '@/assets/icons/magnifierIcon.svg';
import { TextInput } from '@/modules/form/components/TextInput/TextInput';

type SupplierSearchFilterProps = {
  searchQuery: string;
  onChange: (value: string) => void;
};

export const SupplierSearchFilter = ({
  searchQuery,
  onChange,
}: SupplierSearchFilterProps) => {
  const { t } = useTranslation('modules/suppliers/SuppliersFilters');

  return (
    <TextInput
      name="search"
      label={t('Search')}
      errorHidden
      startAdornment={<MagnifierIcon />}
      value={searchQuery}
      placeholder={t('Search supplier')}
      onChange={(e) => onChange(e.currentTarget.value)}
    />
  );
};
