import { BusinessUnit } from '@/modules/customer/api/businessUnits/businessUnitsHelpers';

export const mapCustomerBusinessUnitsToSelectOptions = (
  businessUnits: BusinessUnit[]
) => {
  return businessUnits.map(({ id, name }) => ({
    label: name,
    value: id,
  }));
};

export type MappedBusinessUnitsToSelectOptions = ReturnType<
  typeof mapCustomerBusinessUnitsToSelectOptions
>;
