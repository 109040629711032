import { ParsedNegotiation } from '@/modules/negotiation/helpers/parseGetNegotiationResponse';
import { RichContentTerm } from '@/modules/negotiation/negotiationTerms/NegotiationTerms/components/RichContentTerm';
import { NegotiationTermTypes } from '@/modules/negotiation/types/negotiationTypes';

import { CodeOfConduct } from '../CodeOfConduct/CodeOfConduct';
import { ImplementationTypes } from '../ImplementationTypes/ImplementationTypes';
import { NegotiationContractPeriod } from '../NegotiationContractPeriod/NegotiationContractPeriod';

import { NegotiationTermCard } from './NegotiationTermCard';

type NegotiationTermProps = {
  term: ParsedNegotiation['negotiationTerms'][number];
};

export const NegotiationTerm = ({ term }: NegotiationTermProps) => {
  if (!term.text) return null;

  switch (term.type) {
    case NegotiationTermTypes.signCodeOfConduct:
      return <CodeOfConduct term={term} />;

    case NegotiationTermTypes.acceptContractPeriod:
      return <NegotiationContractPeriod term={term} />;

    case NegotiationTermTypes.selectDiscountImplementation:
      return <ImplementationTypes term={term} />;

    default:
      return (
        <NegotiationTermCard>
          <RichContentTerm text={term.text} />
        </NegotiationTermCard>
      );
  }
};
