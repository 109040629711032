// initialize monitoring tools before any other code,
// so we can capture errors that occur during app initialization
import '@/modules/monitoring/bugsnag';

import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import App from '@/modules/app/components/App';
import { AppErrorBoundary } from '@/modules/monitoring/AppErrorBoundary';

import '@/modules/theme/utils/fontsource';
import '@/i18n/i18n';

const container = document.getElementById('root') as Element;
const root = createRoot(container);
root.render(
  <StrictMode>
    <AppErrorBoundary>
      <App />
    </AppErrorBoundary>
  </StrictMode>
);
