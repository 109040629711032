import { ReactComponent as CodeOfConductIcon } from '@/assets/icons/termIcons/codeOfConductIcon.svg';
import { ReactComponent as ExtraPaymentDaysIcon } from '@/assets/icons/termIcons/extraPaymentDaysIcon.svg';
import { ReactComponent as IsoIcon } from '@/assets/icons/termIcons/isoIcon.svg';
import { ReactComponent as LockedPricesIcon } from '@/assets/icons/termIcons/lockedPricesIcon.svg';
import { ReactComponent as SavingsIcon } from '@/assets/icons/termIcons/savingsIcon.svg';
import { ReactComponent as StandardAgreementIcon } from '@/assets/icons/termIcons/standardAgreementIcon.svg';
import { ReactComponent as SustainabilityQuestionnaireIcon } from '@/assets/icons/termIcons/sustainabilityQuestionnaireIcon.svg';
import { NegotiationTermRole } from '@/modules/negotiation/types/negotiationTypes';

export const termRoleToIconMapper = {
  [NegotiationTermRole.EXTEND_INVOICE_DUE_DATE]: ExtraPaymentDaysIcon,
  [NegotiationTermRole.OFFER_DISCOUNT]: SavingsIcon,
  [NegotiationTermRole.LOCK_PRICE]: LockedPricesIcon,
  [NegotiationTermRole.SIGN_CODE_OF_CONDUCT]: CodeOfConductIcon,
  [NegotiationTermRole.ANSWER_SUSTAINABILITY_QUESTION]:
    SustainabilityQuestionnaireIcon,
  [NegotiationTermRole.SIGN_STANDARD_AGREEMENT]: StandardAgreementIcon,
  [NegotiationTermRole.BE_ISO_CERTIFIED]: IsoIcon,
  [NegotiationTermRole.ACCEPT_CONTRACT_PERIOD]: null,
  [NegotiationTermRole.SELECT_DISCOUNT_IMPLEMENTATION]: null,
  [NegotiationTermRole.EXTEND_CONTRACT_PERIOD]: null,
  [NegotiationTermRole.ANSWER_QUESTION]: null,
  [NegotiationTermRole.TEXT]: null,
} as const;

export const termRoleImportanceOrder = {
  // technically we only care about OFFER_DISCOUNT & LOCK_PRICE
  // rest of them doesn't matter, but roles with 999 don't
  // have a meaning when it comes to graphical representation
  [NegotiationTermRole.OFFER_DISCOUNT]: 1,
  [NegotiationTermRole.LOCK_PRICE]: 2,
  [NegotiationTermRole.SIGN_CODE_OF_CONDUCT]: 3,
  [NegotiationTermRole.EXTEND_INVOICE_DUE_DATE]: 4,
  [NegotiationTermRole.ANSWER_SUSTAINABILITY_QUESTION]: 5,
  [NegotiationTermRole.SIGN_STANDARD_AGREEMENT]: 6,
  [NegotiationTermRole.BE_ISO_CERTIFIED]: 7,
  [NegotiationTermRole.ACCEPT_CONTRACT_PERIOD]: 999,
  [NegotiationTermRole.SELECT_DISCOUNT_IMPLEMENTATION]: 999,
  [NegotiationTermRole.EXTEND_CONTRACT_PERIOD]: 999,
  [NegotiationTermRole.ANSWER_QUESTION]: 999,
  [NegotiationTermRole.TEXT]: 999,
} as const;
