import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { constructMeta } from '@/modules/reactQuery/constructMeta';
import { addSupplierContactMutation } from '@/modules/suppliers/api/mutations/addSupplierContactMutation';

export const useAddSupplierContactToForwardMutation = () => {
  const jsonApi = useJsonApi();
  const { t } = useTranslation(
    'apiMessages/useAddSupplierContactToForwardMutation'
  );

  return useMutation({
    mutationFn: addSupplierContactMutation(jsonApi),
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t("You don't have access to forward to a new contact"),
        },
        404: {
          SUPPLIER_NOT_FOUND: t(
            'Supplier not found. Please refresh the page or contact the administrator.'
          ),
        },
      },
    }),
  });
};
