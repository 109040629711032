import styled from 'styled-components';

export const SuppliersFiltersLayout = styled('div')(
  ({ theme: { spacing } }) => ({
    display: 'grid',
    gridTemplate: 'auto auto / 1fr 1fr',
    flex: 1,
    columnGap: spacing(3),
    rowGap: spacing(1),
    maxWidth: 900,
  })
);
