import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { JsonApiClient } from '@/modules/api/utils/createJsonApiClient';
import { constructMeta } from '@/modules/reactQuery/constructMeta';

export const useValidateNegotiationMutation = () => {
  const jsonApi = useJsonApi();
  const { t } = useTranslation('apiMessages/useValidateNegotiationMutation');
  return useMutation({
    mutationFn: validateNegotiationMutation(jsonApi),
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t('You cannot run this negotiation'),
        },
        404: {
          NEGOTIATION_NOT_FOUND: t('Negotiation not found'),
        },
        422: {
          INVALID_NEGOTIATION_STATE: t('Invalid negotiation state'),
          NO_PERIOD: () => undefined,
          INVALID_PERIOD: () => undefined,
          NO_INTRO_EMAIL: () => undefined,
          NO_SUPPLIERS_SELECTED: () => undefined,
          UNDEFINED_TERM: () => undefined,
          NO_KEYWORD_TRANSLATION: () => undefined,
        },
      },
    }),
  });
};

type ValidateNegotiationMutation = {
  negotiationId: string;
};

const validateNegotiationMutation = (jsonApiClient: JsonApiClient) => {
  return ({ negotiationId }: ValidateNegotiationMutation) => {
    return jsonApiClient.post({
      path: `/me/customer/negotiations/${negotiationId}/plan`,
      queryParams: {
        dryRun: true,
      },
    });
  };
};
