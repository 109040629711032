import { detect } from 'detect-browser';
import { forwardRef, ReactNode } from 'react';
import styled from 'styled-components';

import { TextInputProps } from '../TextInput/TextInput';

import { ReactComponent as CalendarIcon } from './calendarIcon.svg';
import { InputsWrapperStyled, TextInputStyled } from './DateInput.styled';

export type DateInputProps = Omit<
  TextInputProps,
  'type' | 'startAdornment' | 'endAdornment' | 'label'
> & { label?: string | ReactNode };

export const DateInput = forwardRef<HTMLInputElement, DateInputProps>(
  ({ name, label, defaultValue, className, ...props }, ref) => {
    const browser = detect();
    const isFirefox = browser?.name === 'firefox';
    return (
      <InputsWrapperStyled className={className}>
        <TextInputStyled
          name={name}
          {...props}
          label={label}
          ref={ref}
          type="date"
          defaultValue={defaultValue}
          endAdornment={<CalendarIconStyled isFirefox={isFirefox} />}
        />
      </InputsWrapperStyled>
    );
  }
);

type CalendarIconStyledProps = {
  isFirefox: boolean;
};

const CalendarIconStyled = styled(CalendarIcon).withConfig({
  shouldForwardProp: (prop) => !['isFirefox'].includes(prop),
})<CalendarIconStyledProps>(({ isFirefox, theme: { spacing } }) => ({
  position: 'absolute',
  top: 'calc(50% - 12px)',
  background: 'inherit',
  right: spacing(1.25),
  borderRadius: spacing(0.5),
  pointerEvents: 'none',
  // HACK: ::-webkit-calendar-picker-indicator stopped working
  // in firefox >= 109 - release date - January 17, 2023
  // https://whattrainisitnow.com/release/?version=109
  // we have to adjust the size of our custom icon to native picker
  ...(isFirefox && { transform: 'scale(0.8)' }),
}));
