import styled from 'styled-components';

import { blue } from '@/modules/theme/utils/colors';

export const TextWrapperStyled = styled('div')({
  maxWidth: 400,
});

export const StaticImgStyled = styled('img')({
  width: '100%',
});

export const AbsoluteImgStyled = styled('img')(
  ({ theme: { breakpoints } }) => ({
    position: 'absolute',
    top: '37%',
    right: '-25%',
    width: '52%',

    [breakpoints.down.lg]: {
      top: '23%',
    },

    [breakpoints.down.md]: {
      top: '37%',
    },
  })
);

export const ImagesWrapperStyled = styled('div')(({ theme: { spacing } }) => ({
  position: 'relative',
  marginRight: spacing(15),
  maxWidth: 588,
  minWidth: 350,
}));

export const SectionStyled = styled('section')(
  ({ theme: { spacing, breakpoints } }) => ({
    padding: spacing([6, 14, 10]),
    display: 'flex',
    justifyContent: 'space-between',
    gap: '7%',
    backgroundColor: blue[1000],

    [breakpoints.down.md]: {
      flexDirection: 'column',
      gap: spacing(2),
      padding: spacing([10, 8, 9]),
    },
  })
);
