import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { queryKeys } from '@/modules/api/queryKeys';
import { JsonApiClient } from '@/modules/api/utils/createJsonApiClient';
import { negotiationSupplierProgressType } from '@/modules/negotiation/api/resources/negotiationSupplierProgress/negotiationSupplierProgressResourceConsts';
import { constructMeta } from '@/modules/reactQuery/constructMeta';
import { supplierContactResourceType } from '@/modules/suppliers/api/resources/supplierContact/supplierContactResourceConsts';

type UseForwardToNewContactMutationPayload = {
  negotiationId: string;
};

export const useForwardToNewContactMutation = ({
  negotiationId,
}: UseForwardToNewContactMutationPayload) => {
  const jsonApi = useJsonApi();
  const queryClient = useQueryClient();
  const { t } = useTranslation('apiMessages/useForwardToNewContactMutation');

  return useMutation({
    mutationFn: forwardToNewContact(jsonApi),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [
          queryKeys.negotiations.getStartedNegotiationSuppliers,
          negotiationId,
        ],
      });
    },
    meta: constructMeta({
      errorMessages: {
        404: {
          NEGOTIATION_NOT_FOUND: t(
            'Negotiation not found. Please refresh the page or contact the administrator'
          ),
          PROGRESS_NOT_FOUND: t(
            'Negotiation progress not found. Please refresh the page or contact the administrator'
          ),
        },
        422: {
          INVALID_NEGOTIATION_STATE: t('Negotiation is not in active state'),
        },
      },
    }),
  });
};

type ForwardToNewContactPayload = {
  negotiationId: string;
  progressId: string | undefined;
  contactId: string;
};

const forwardToNewContact = (jsonApi: JsonApiClient) => {
  return ({
    negotiationId,
    progressId,
    contactId,
  }: ForwardToNewContactPayload) => {
    if (!progressId) {
      throw new Error('Missing supplier progress id');
    }
    return jsonApi.update({
      path: `/me/customer/negotiations/${negotiationId}/supplier_progress/${progressId}`,
      type: negotiationSupplierProgressType,
      id: progressId,
      relationships: {
        contact: {
          data: {
            id: contactId,
            type: supplierContactResourceType,
          },
        },
      },
    });
  };
};
