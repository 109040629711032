import styled, { css, keyframes } from 'styled-components';

import { blue, white } from '@/modules/theme/utils/colors';

export type LoaderVariants = 'default' | 'outline' | 'light' | 'text';
export type LoaderProps = {
  size: string | number;
  color?: string;
  variant?: LoaderVariants;
};

export function Loader({ size, color, variant = 'default' }: LoaderProps) {
  return <LoaderStyled size={size} color={color} variant={variant} />;
}

type StyledLoaderProps = {
  size: string | number;
  color?: string;
  variant?: LoaderVariants;
};

const spinner = keyframes({
  from: {
    transform: 'rotate(0deg)',
  },
  to: {
    transform: 'rotate(360deg)',
  },
});

const LoaderStyled = styled('div')<StyledLoaderProps>(
  ({ size, variant, color, theme: { spacing, palette } }) => ({
    display: 'inline-block',
    width: size,
    height: size,
    border: `${spacing(0.5)} solid transparent`,
    borderColor: color ? color : blue[800],
    borderTopColor:
      variant === 'outline' || variant === 'light'
        ? white
        : palette.disabled.main,
    borderRadius: '50%',
  }),
  css`
    animation: ${spinner} 1s ease infinite;
  `
);
