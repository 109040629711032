import { AxiosInstance, AxiosRequestConfig } from 'axios';
import * as sup from 'superstruct';

import {
  JsonAPiMultiRequest,
  JsonApiMultiResponse,
  JsonApiRelationships,
  JsonApiResponse,
  ResourceIdentifier,
  ValidatedJsonApiMultiResponse,
  ValidatedJsonApiResponse,
} from '../types/jsonApiTypes';

export function createJsonApiClient(axios: AxiosInstance) {
  return {
    async createOne<
      RequestAttributes extends Record<string, unknown>,
      RequestMeta extends Record<string, unknown>,
      RequestRelationships extends JsonApiRelationships,
      Response extends JsonApiResponse,
    >({
      id,
      type,
      path,
      attributes,
      relationships,
      responseStruct,
      axiosConfig,
      meta,
      externalMeta,
    }: {
      path: string;
      type?: string;
      id?: string;
      attributes?: RequestAttributes;
      meta?: RequestMeta;
      externalMeta?: RequestMeta;
      relationships?: RequestRelationships;
      responseStruct?: sup.Struct<Response>;
      axiosConfig?: AxiosRequestConfig;
    }) {
      const { data } = await axios.post<unknown>(
        path,
        {
          data: {
            id,
            type,
            attributes,
            relationships,
            meta,
          },
          meta: externalMeta,
        },
        {
          ...axiosConfig,
        }
      );

      if (!responseStruct) return;
      return responseStruct.mask(data) as ValidatedJsonApiResponse<Response>;
    },
    async createMany<
      Request extends JsonAPiMultiRequest,
      Response extends JsonApiMultiResponse,
    >({
      path,
      requestData,
      responseStruct,
      axiosConfig,
    }: {
      path: string;
      requestData: Request;
      responseStruct?: sup.Struct<Response>;
      axiosConfig?: AxiosRequestConfig;
    }) {
      const { data } = await axios.post<unknown>(path, {
        ...requestData,
        ...axiosConfig,
      });

      if (!responseStruct) return;
      return responseStruct.mask(
        data
      ) as ValidatedJsonApiMultiResponse<Response>;
    },
    async update<
      RequestAttributes extends Record<string, unknown>,
      RequestMeta extends Record<string, unknown>,
      RequestRelationships extends JsonApiRelationships,
      Response extends JsonApiResponse,
    >({
      type,
      path,
      id = '',
      attributes,
      relationships,
      responseStruct,
      axiosConfig,
      meta,
    }: {
      type: string;
      path: string;
      id: string;
      attributes?: RequestAttributes;
      meta?: RequestMeta;
      relationships?: RequestRelationships;
      responseStruct?: sup.Struct<Response>;
      axiosConfig?: AxiosRequestConfig;
    }) {
      const { data } = await axios.patch<unknown>(
        path,
        {
          data: {
            id,
            type,
            attributes,
            relationships,
            meta,
          },
        },
        {
          ...axiosConfig,
        }
      );

      if (!responseStruct) return;
      return responseStruct.mask(data) as ValidatedJsonApiResponse<Response>;
    },
    async put<
      RequestAttributes extends Record<string, unknown>,
      RequestRelationships extends JsonApiRelationships,
      Response extends JsonApiResponse,
    >({
      type,
      path,
      attributes,
      relationships,
      responseStruct,
      axiosConfig,
    }: {
      path: string;
      type: string;
      attributes: RequestAttributes;
      relationships?: RequestRelationships;
      responseStruct?: sup.Struct<Response>;
      axiosConfig?: AxiosRequestConfig;
    }) {
      const { data } = await axios.put<unknown>(
        path,
        {
          data: {
            type,
            attributes,
            relationships,
          },
        },
        {
          ...axiosConfig,
        }
      );

      if (!responseStruct) return;
      return responseStruct.mask(data) as ValidatedJsonApiResponse<Response>;
    },
    async patch<
      RequestAttributes extends Record<string, unknown>,
      RequestMeta extends Record<string, unknown>,
      RequestRelationships extends JsonApiRelationships,
      Response extends JsonApiResponse,
    >({
      type,
      path,
      id = '',
      attributes,
      relationships,
      axiosConfig,
      responseStruct,
      meta,
    }: {
      type?: string;
      path: string;
      id?: string;
      attributes?: RequestAttributes;
      meta?: RequestMeta;
      relationships?: RequestRelationships;
      responseStruct?: sup.Struct<Response>;
      axiosConfig?: AxiosRequestConfig;
    }) {
      const { data } = await axios.patch<unknown>(
        path,
        id
          ? {
              data: {
                id,
                type,
                attributes,
                relationships,
                meta,
              },
            }
          : undefined,
        {
          ...axiosConfig,
        }
      );

      if (!responseStruct) return;
      return responseStruct.mask(data) as ValidatedJsonApiResponse<Response>;
    },
    async getOne<Response extends JsonApiResponse>({
      path,
      id = '',
      queryParams,
      responseStruct,
      axiosConfig,
    }: {
      path: string;
      id?: string;
      queryParams?: Record<string, unknown>;
      responseStruct: sup.Struct<Response>;
      axiosConfig?: AxiosRequestConfig;
    }) {
      const appliedPath = id === '' ? path : `${path}/${id}`;

      const { data } = await axios.get<unknown>(appliedPath, {
        params: queryParams,
        ...axiosConfig,
      });

      return responseStruct.mask(data) as ValidatedJsonApiResponse<Response>;
    },
    async getMany<Response extends JsonApiMultiResponse>({
      path,
      queryParams,
      responseStruct,
      axiosConfig,
    }: {
      path: string;
      queryParams?: Record<string, unknown>;
      responseStruct: sup.Struct<Response>;
      axiosConfig?: AxiosRequestConfig;
    }) {
      const { data } = await axios.get<unknown>(path, {
        params: queryParams,
        ...axiosConfig,
      });

      return responseStruct.mask(
        data
      ) as ValidatedJsonApiMultiResponse<Response>;
    },
    async delete({
      path,
      queryParams,
      axiosConfig,
    }: {
      path: string;
      queryParams?: Record<string, unknown>;
      axiosConfig?: AxiosRequestConfig;
    }) {
      await axios.delete<unknown>(path, {
        params: queryParams,
        ...axiosConfig,
      });
    },

    async post({
      path,
      payload,
      queryParams,
      axiosConfig,
    }: {
      path: string;
      payload?: unknown;
      queryParams?: Record<string, unknown>;
      axiosConfig?: AxiosRequestConfig;
    }) {
      await axios.post<unknown>(path, payload, {
        params: queryParams,
        ...axiosConfig,
      });
    },

    toMany: {
      async add({
        path,
        payload,
        queryParams,
        axiosConfig,
      }: {
        path: string;
        payload: ResourceIdentifier[];
        queryParams?: Record<string, unknown>;
        axiosConfig?: AxiosRequestConfig;
      }) {
        await axios.post<unknown>(
          path,
          { data: payload },
          {
            params: queryParams,
            ...axiosConfig,
          }
        );
      },
      async remove({
        path,
        payload,
        queryParams,
        axiosConfig,
      }: {
        path: string;
        payload: ResourceIdentifier[];
        queryParams?: Record<string, unknown>;
        axiosConfig?: AxiosRequestConfig;
      }) {
        await axios.delete<unknown>(path, {
          params: queryParams,
          data: {
            data: payload,
          },
          ...axiosConfig,
        });
      },
    },
  };
}

export type JsonApiClient = ReturnType<typeof createJsonApiClient>;
