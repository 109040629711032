import { useTranslation } from 'react-i18next';

import { NegotiationTermRole } from '@/modules/negotiation/types/negotiationTypes';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';

import { NegotiationTypeLabelStyled } from './NegotiationTypeLabels.styled';

type NegotiationTypeLabelsProps = {
  negotiationTermRoles: NegotiationTermRole[];
};

export const NegotiationTypeLabels = ({
  negotiationTermRoles,
}: NegotiationTypeLabelsProps) => {
  const { t } = useTranslation('pages/DashboardPage');

  const getLabel = (negotiationTermRole: NegotiationTermRole) => {
    switch (negotiationTermRole) {
      case NegotiationTermRole.LOCK_PRICE:
        return t('Anti-inflation');
      case NegotiationTermRole.SIGN_CODE_OF_CONDUCT:
        return t('Code of Conduct');
      case NegotiationTermRole.OFFER_DISCOUNT:
        return t('Savings');
      case NegotiationTermRole.ANSWER_SUSTAINABILITY_QUESTION:
        return t('Supplier questionnaire');
      case NegotiationTermRole.EXTEND_INVOICE_DUE_DATE:
        return t('Payment days');
      case NegotiationTermRole.EXTEND_CONTRACT_PERIOD:
        return t('Prolong');
      case NegotiationTermRole.BE_ISO_CERTIFIED:
        return t('ISO');
      case NegotiationTermRole.SIGN_STANDARD_AGREEMENT:
        return t('Standard agreement');
      default:
        return '';
    }
  };

  return (
    <FlexContainer wrap="wrap" gap={2}>
      {negotiationTermRoles.map((role) => {
        const label = getLabel(role);
        return (
          label && (
            <NegotiationTypeLabelStyled key={role}>
              {label}
            </NegotiationTypeLabelStyled>
          )
        );
      })}
    </FlexContainer>
  );
};
