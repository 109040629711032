import * as sup from 'superstruct';

import { createResourceStruct } from '@/modules/api/utils/jsonApiStructs';
import { Implementation } from '@/modules/negotiation/types/negotiationTypes';

import {
  acceptableTermTypesIdentifierStruct,
  acceptContractPeriodTermIdentifierStruct,
  discountImplementationTermIdentifierStruct,
  offerDiscountTermIdentifierStruct,
  signCodeOfConductTermIdentifierStruct,
  termResourceIdentifierStruct,
  textTermIdentifierStruct,
} from './negotiationTermIdentifierStruct';

export const termResourceStruct = createResourceStruct({
  identifier: termResourceIdentifierStruct,
});

export const acceptableTermResourceStruct = createResourceStruct({
  identifier: acceptableTermTypesIdentifierStruct,
  attributes: sup.object({
    text: sup.nullable(sup.string()),
    template: sup.nullable(sup.string()),
  }),
});

export const offerDiscountTermResourceStruct = createResourceStruct({
  identifier: offerDiscountTermIdentifierStruct,
});

export const discountImplementationTermResourceStruct = createResourceStruct({
  identifier: discountImplementationTermIdentifierStruct,
  attributes: sup.object({
    text: sup.nullable(sup.string()),
    template: sup.nullable(sup.string()),
    implementations: sup.array(sup.enums(Object.values(Implementation))),
  }),
});

export const signCodeOfConductResourceStruct = createResourceStruct({
  identifier: signCodeOfConductTermIdentifierStruct,
  attributes: sup.object({
    text: sup.nullable(sup.string()),
    template: sup.nullable(sup.string()),
    document: sup.nullable(
      sup.object({
        name: sup.string(),
        contentType: sup.string(),
        sizeBytes: sup.number(),
      })
    ),
  }),
});

export const acceptContractPeriodResourceStruct = createResourceStruct({
  identifier: acceptContractPeriodTermIdentifierStruct,
  attributes: sup.object({
    text: sup.nullable(sup.string()),
    template: sup.nullable(sup.string()),
    startDate: sup.nullable(sup.string()),
    endDate: sup.nullable(sup.string()),
  }),
});

export const textTermResourceStruct = createResourceStruct({
  identifier: textTermIdentifierStruct,
  attributes: sup.object({
    text: sup.nullable(sup.string()),
    template: sup.nullable(sup.string()),
  }),
});

type AcceptableTermResource = sup.Infer<typeof acceptableTermResourceStruct>;
type OfferTermResource = sup.Infer<typeof offerDiscountTermResourceStruct>;
type DiscountImplementationResource = sup.Infer<
  typeof discountImplementationTermResourceStruct
>;
type SignCodeOfConductResource = sup.Infer<
  typeof signCodeOfConductResourceStruct
>;
type AcceptContractPeriodResource = sup.Infer<
  typeof acceptContractPeriodResourceStruct
>;
type TextTermResource = sup.Infer<typeof textTermResourceStruct>;

export type NegotiationTermResource =
  | AcceptableTermResource
  | OfferTermResource
  | DiscountImplementationResource
  | TextTermResource
  | SignCodeOfConductResource
  | AcceptContractPeriodResource;
