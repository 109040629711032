import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const RouterLinkStyled = styled(Link)(({ theme: { palette } }) => ({
  color: palette.primary.dark,
  '&:hover, &:focus, &:active': {
    color: palette.primary.main,
  },
}));

export const DisabledLinkStyled = styled('span')(({ theme: { palette } }) => ({
  cursor: 'not-allowed',
  color: palette.disabled.main,
}));
