import { useTranslation } from 'react-i18next';

import { FlexContainer } from '@/modules/theme/components/FlexContainer';

import { TOKEN_COOKIE_NAME } from './consts';
import {
  ButtonStyled,
  DialogStyled,
  HeadingStyled,
  ParagraphStyled,
} from './CookieConsentPopup.styled';

type CookieConsentPopupProps = {
  onClickAllow: () => void;
  onClickSettings: () => void;
  cookies: { [x: string]: unknown };
};

export const CookieConsentPopup = ({
  onClickAllow,
  onClickSettings,
  cookies,
}: CookieConsentPopupProps) => {
  const { t } = useTranslation('layouts/Common/CookieConsentDialog');

  return (
    <DialogStyled isOpen={!cookies[TOKEN_COOKIE_NAME]}>
      <HeadingStyled variant="h3">{t('We value your privacy')}</HeadingStyled>
      <ParagraphStyled>
        {t('DigiProc uses cookies to enhance your personal experience!')}
      </ParagraphStyled>
      <FlexContainer gap={1} mt={2}>
        <ButtonStyled size="small" onClick={onClickAllow}>
          {t('Allow all')}
        </ButtonStyled>
        <ButtonStyled variant="outline" size="small" onClick={onClickSettings}>
          {t('Settings')}
        </ButtonStyled>
      </FlexContainer>
    </DialogStyled>
  );
};
