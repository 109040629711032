import styled from 'styled-components';

import { Paragraph } from '@/modules/theme/components/Typography';
import { blue, dark, graphite, white } from '@/modules/theme/utils/colors';

type TermDescriptionProps = {
  highlight?: boolean;
};

export const IconWrapperStyled = styled('div')(
  ({ theme: { borderRadius } }) => ({
    width: 24,
    height: 24,
    borderRadius: borderRadius.round,
    color: white,
  })
);

export const TermDescriptionStyled = styled('div')<TermDescriptionProps>(
  ({ theme: { spacing, typography }, highlight }) => ({
    display: 'flex',
    gap: spacing(1),
    alignItems: 'center',
    fontWeight: typography.fontWeight.bold,
    color: highlight ? blue[800] : graphite,
    [String(IconWrapperStyled)]: {
      backgroundColor: highlight ? blue[800] : graphite,
    },
  })
);

type MetricsCardWrapperProps = {
  highlight?: boolean;
};

export const MetricsCardWrapper = styled('div')<MetricsCardWrapperProps>(
  ({ highlight, theme: { borderRadius, spacing } }) => ({
    borderRadius: borderRadius.default,
    padding: spacing(2),
    ...(highlight && { backgroundColor: white }),
  })
);

export const MetricsContainer = styled('div')(({ theme: { spacing } }) => ({
  paddingBottom: spacing(5),
  borderBottom: `1px solid ${dark[100]}`,
}));

export const BottomMetricsText = styled(Paragraph)(
  ({ theme: { typography } }) => ({
    fontWeight: typography.fontWeight.bold,
  })
);
