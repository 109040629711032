import styled from 'styled-components';

import { dark } from '@/modules/theme/utils/colors';

type RadioInputProps = {
  appliedError?: boolean;
};

export const RadioInput = styled('input')<RadioInputProps>(
  ({ theme: { palette, borderRadius, spacing }, appliedError }) => ({
    appearance: 'none',
    backgroundColor: palette.default.background,
    margin: 0,
    position: 'relative',
    width: 20,
    height: 20,

    '&::before': {
      width: '100%',
      height: '100%',
      color: 'transparent',
      display: 'block',
      content: "''",
      border: `1px solid ${dark[100]}`,
      transition: `background-color 0.25s, border-color 0.25s`,
      background: dark[50],
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      left: 0,
      boxSizing: 'border-box',
      borderRadius: borderRadius.round,
      ...(appliedError && {
        borderColor: palette.error.main,
        backgroundColor: palette.error.light,
      }),
    },
    '&:hover::before': {
      borderColor: dark[400],
    },

    '&::after': {
      content: '""',
      position: 'absolute',
      left: spacing(0.5),
      width: spacing(1.5),
      height: spacing(1.5),
      borderRadius: borderRadius.round,
      transition: 'background-color 0.25s',
      top: '50%',
      transform: 'translateY(-50%)',
    },

    '&[disabled]::after, &[disabled]::before': {
      opacity: 0.5,
      cursor: 'not-allowed',
    },

    '&[disabled] + label': {
      cursor: 'default',
    },

    '&:checked::after': {
      backgroundColor: palette.secondary.main,
    },

    '&:focus::before': {
      borderColor: dark[400],
    },
  })
);
