import styled from 'styled-components';

import { dark, graphite, white } from '@/modules/theme/utils/colors';

import { CardVariant } from './helpers/getCardVariant';
import { NegotiationMetric } from './NegotiationMetric/NegotiationMetric';
import { TermIconsWrapperStyled } from './TermIcons/TermIcons.styled';

export const WrapperButtonStyled = styled('button')({
  width: '100%',
  background: 'transparent',
  border: 0,
  padding: 0,
  ':focus-visible': {
    outlineStyle: 'dashed',
    outlineWidth: 2,
  },
});

type CardWrapperStyledProps = {
  variant: CardVariant;
};

export const CardWrapperStyled = styled('div')<CardWrapperStyledProps>(
  ({
    variant,
    theme: {
      borderRadius,
      palette,
      spacing,
      gradients,
      typography,
      boxShadows,
    },
  }) => ({
    fontFamily: typography.fonts.body,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 186,
    height: '100%',
    width: '100%',
    borderRadius: borderRadius.default,
    padding: spacing(1),
    cursor: 'grab',
    boxShadow: boxShadows.card,
    color: graphite,
    background: white,
    [String(CardContentWrapperStyled)]: {
      background: dark[50],
    },
    [String(TermIconsWrapperStyled)]: {
      color: graphite,
    },
    ...(variant === 'financial' && {
      color: white,
      background: gradients.cards.financial,
      [String(TermIconsWrapperStyled)]: {
        color: palette.tertiary.dark,
      },
      [String(CardContentWrapperStyled)]: {
        background: 'rgba(160, 96, 7, 0.40)',
      },
    }),
    ...(variant === 'counter' && {
      color: white,
      background: gradients.cards.counter,
      [String(TermIconsWrapperStyled)]: {
        color: palette.primary.main,
      },
      [String(CardContentWrapperStyled)]: {
        background: 'rgba(27, 90, 124, 0.36)',
      },
    }),
    ...(variant === 'pending' && {
      color: white,
      background: gradients.cards.pending,
      [String(TermIconsWrapperStyled)]: {
        color: dark[600],
      },
      [String(CardContentWrapperStyled)]: {
        background: dark[600],
      },
    }),
  })
);

export const CardContentWrapperStyled = styled('div')(
  ({ theme: { borderRadius } }) => ({
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    borderRadius: borderRadius.default,
  })
);

export const CardContentStyled = styled('div')(({ theme: { spacing } }) => ({
  display: 'grid',
  justifyItems: 'baseline',
  gridTemplateColumns: '5fr 2fr',
  padding: spacing(2),
  flex: 1,
  maxWidth: 320,
}));

export const CardHeaderStyled = styled('div')(({ theme: { spacing } }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 16px',
  alignItems: 'center',
  gap: spacing(0.5),
  margin: spacing([0.25, 0, 1, 0]),
}));

export const TitleStyled = styled('p')(({ theme: { typography } }) => ({
  textAlign: 'left',
  fontSize: typography.fontSize.default,
  fontWeight: typography.fontWeight.bold,
  lineHeight: 1.5,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}));

type SuppliersMetricProps = {
  isStandaloneMetric: boolean;
};

export const SuppliersMetric = styled(NegotiationMetric)<SuppliersMetricProps>(
  ({ isStandaloneMetric }) => ({
    gridColumn: isStandaloneMetric ? 1 : 2,
    gridRow: 1,
  })
);
