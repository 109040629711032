import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { ParsedNegotiationItem } from '@/modules/negotiation/types/negotiationTypes';
import { onboardedRoutes } from '@/modules/router/onboardedRoutes';
import { Heading } from '@/modules/theme/components/Typography';

import { useNegotiationStatusLabels } from '../../../utils/useNegotiationStatusLabels';
import { NegotiationStatusBadge } from '../../NegotiationStatusBadge/NegotiationStatusBadge';
import { NegotiationTypeLabels } from '../NegotiationTypeLabels/NegotiationTypeLabels';

import {
  NegotiationCardContainerLinkStyled,
  NegotiationCardContentWrapper,
  NegotiationCardHeadingStyled,
  NegotiationDatesStyled,
  NegotiationStatusBadgeWrapperStyled,
  NumberOfSuppliersWrapperStyled,
  SuppliersLabelStyled,
} from './NegotiationCardLayout.styled';

type NegotiationCardLayoutProps = {
  negotiation: ParsedNegotiationItem;
  link?: string;
  children: ReactNode;
};

export const NegotiationCardLayout = ({
  negotiation,
  link,
  children,
}: NegotiationCardLayoutProps) => {
  const { t } = useTranslation('pages/DashboardPage');
  const getNegotiationLabel = useNegotiationStatusLabels();

  const { metrics } = negotiation;

  return (
    <NegotiationCardContainerLinkStyled
      to={link ?? onboardedRoutes.buildUrl('dashboard')}
      disabled={!link}
    >
      <NumberOfSuppliersWrapperStyled>
        <Heading variant="h3" as="p">
          {metrics?.suppliersCount}
        </Heading>
        <SuppliersLabelStyled>{t('Suppliers')}</SuppliersLabelStyled>
      </NumberOfSuppliersWrapperStyled>
      <NegotiationCardContentWrapper>
        <NegotiationCardHeadingStyled>
          <Heading variant="h3">{negotiation.title}</Heading>
          <NegotiationStatusBadgeWrapperStyled>
            <NegotiationStatusBadge variant={negotiation.state} width={116}>
              {getNegotiationLabel(negotiation.state)}
            </NegotiationStatusBadge>
            {negotiation.period && (
              <NegotiationDatesStyled variant="normal">
                {t('{{startDate}} - {{endDate}}', {
                  startDate: negotiation.period.startDate.toLocaleDateString(),
                  endDate: negotiation.period.endDate.toLocaleDateString(),
                })}
              </NegotiationDatesStyled>
            )}
          </NegotiationStatusBadgeWrapperStyled>
        </NegotiationCardHeadingStyled>
        {children}
        <NegotiationTypeLabels negotiationTermRoles={negotiation.termRoles} />
      </NegotiationCardContentWrapper>
    </NegotiationCardContainerLinkStyled>
  );
};
