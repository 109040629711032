import { JsonApiClient } from '@/modules/api/utils/createJsonApiClient';
import { createResponseStruct } from '@/modules/api/utils/jsonApiStructs';
import { businessUnitResourceType } from '@/modules/customer/api/businessUnits/businessUnitsHelpers';
import { SupplierLanguageType } from '@/modules/language/supplierLanguage/supplierLanguageConsts';

import {
  getEditSupplierResourcePath,
  supplierResourceType,
} from '../resources/supplier/supplierResourceConsts';
import { supplierResourceStruct } from '../resources/supplier/supplierResourceStruct';
import { supplierCategoryResourceType } from '../resources/supplierCategory/supplierCategoryResourceConsts';
import { supplierContactResourceType } from '../resources/supplierContact/supplierContactResourceConsts';

type EditSupplierMutationPayload = {
  id: string;
  attributes: {
    name: string;
    organizationNumber: string | null;
    language: SupplierLanguageType | undefined;
    currentSpend: string;
  };
  categoryId: string | null;
  defaultContactId: string | null;
  businessUnitId: string | null;
};

export function editSupplierMutation(jsonApi: JsonApiClient) {
  return (payload: EditSupplierMutationPayload) => {
    const { id, attributes, defaultContactId, categoryId, businessUnitId } =
      payload;
    return jsonApi.update({
      type: supplierResourceType,
      path: getEditSupplierResourcePath({ id }),
      responseStruct: editSupplierMutationResponseStruct,
      id,
      attributes,
      relationships: {
        category: {
          data: categoryId
            ? {
                id: categoryId,
                type: supplierCategoryResourceType,
              }
            : null,
        },
        defaultContact: {
          data: defaultContactId
            ? {
                id: defaultContactId,
                type: supplierContactResourceType,
              }
            : null,
        },
        businessUnit: {
          data: businessUnitId
            ? {
                id: businessUnitId,
                type: businessUnitResourceType,
              }
            : null,
        },
      },
    });
  };
}

const editSupplierMutationResponseStruct = createResponseStruct({
  data: supplierResourceStruct,
});
