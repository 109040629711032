import * as sup from 'superstruct';

import { JsonApiClient } from '@/modules/api/utils/createJsonApiClient';
import { createResponseStruct } from '@/modules/api/utils/jsonApiStructs';
import {
  supplierLanguageStruct,
  SupplierLanguageType,
} from '@/modules/language/supplierLanguage/supplierLanguageConsts';

import { parseNegotiationKeywordsQuery } from '../../helpers/parseGetNegotiationKeywordsQuery';
import { getNegotiationKeywordsResourcePath } from '../resources/negotiationKeyword/negotiationKeywordResourceConsts';
import { negotiationKeywordResourceStruct } from '../resources/negotiationKeyword/negotiationKeywordResourceStruct';

type GetNegotiationKeywordsQueryPayload = {
  negotiationId: string;
  language: SupplierLanguageType;
};

export const getNegotiationKeywordsQuery = (
  jsonApi: JsonApiClient,
  { negotiationId, language }: GetNegotiationKeywordsQueryPayload
) => {
  return async () => {
    const response = await jsonApi.getMany({
      path: getNegotiationKeywordsResourcePath(negotiationId),
      responseStruct: getNegotiationKeywordsResponseStruct,
      axiosConfig: {
        headers: {
          'accept-language': language,
        },
      },
    });
    return parseNegotiationKeywordsQuery(response);
  };
};

export const getNegotiationKeywordsResponseStruct = createResponseStruct({
  data: sup.array(negotiationKeywordResourceStruct),
  meta: sup.object({
    languages: sup.array(supplierLanguageStruct),
  }),
});

export type GetNegotiationKeywordsResponseType = sup.Infer<
  typeof getNegotiationKeywordsResponseStruct
>;
