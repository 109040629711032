import * as sup from 'superstruct';

import {
  categories,
  selectCategoriesImportance,
  termImportance,
} from '../consts';

export const questionnaireFormStruct = sup.object({
  spendFile: sup.instance(File),
  offerDiscount: sup.nullable(sup.enums(Object.values(termImportance))),
  lockPrice: sup.nullable(sup.enums(Object.values(termImportance))),
  signCodeOfConduct: sup.nullable(sup.enums(Object.values(termImportance))),
  extendInvoiceDueDate: sup.nullable(sup.enums(Object.values(termImportance))),
  answerSustainabilityQuestion: sup.nullable(
    sup.enums(Object.values(termImportance))
  ),
  importantCategories: sup.array(sup.enums(categories)),
  categoryImportance: sup.array(
    sup.object({
      label: sup.string(),
      value: sup.enums(Object.keys(selectCategoriesImportance)),
    })
  ),
  notes: sup.string(),
});

export type QuestionnaireForm = sup.Infer<typeof questionnaireFormStruct>;
