import { JsonApiClient } from '@/modules/api/utils/createJsonApiClient';
import { SupplierLanguageType } from '@/modules/language/supplierLanguage/supplierLanguageConsts';

import {
  getUpdateNegotiationKeywordResourcePath,
  negotiationKeywordResourceType,
} from '../resources/negotiationKeyword/negotiationKeywordResourceConsts';

export type UpdateNegotiationKeywordMutationPayload = {
  negotiationId: string;
  keywordId: string;
  value: string;
  language: SupplierLanguageType;
};

export function updateNegotiationKeywordMutation(jsonApi: JsonApiClient) {
  return ({
    keywordId,
    negotiationId,
    language,
    value,
  }: UpdateNegotiationKeywordMutationPayload) =>
    jsonApi.update({
      path: getUpdateNegotiationKeywordResourcePath({
        keywordId,
        negotiationId,
      }),
      type: negotiationKeywordResourceType,
      id: keywordId,
      attributes: {
        value,
      },
      axiosConfig: {
        headers: {
          'content-language': language,
        },
      },
    });
}
