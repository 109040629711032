import * as sup from 'superstruct';

// It is a wrapper around `dynamic` utility from `superstruct` lib.
// It adds proper typing for validating structs -
// - our implementation with `react-hook-form` requires both of structs
// returned conditionally by dynamic callback have the same type.
// Here we give `useForm` exact type of returned struct.

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function dynamicStruct<T extends sup.Struct<any, any>>(
  fn: (value: unknown, ctx: sup.Context) => T
) {
  return sup.dynamic(fn) as sup.Describe<T['TYPE']>;
}
