import * as sup from 'superstruct';

import { createResourceStruct } from '@/modules/api/utils/jsonApiStructs';

import { customerMetricsIdentifierStruct } from './customerMetricsIdentifierStruct';

export const customerMetricsResourceStruct = createResourceStruct({
  identifier: customerMetricsIdentifierStruct,
  attributes: sup.object({
    suppliersCount: sup.number(),
    contactsCount: sup.number(),
    currentSpend: sup.string(),
    negotiatedSpend: sup.string(),
    potentialSavings: sup.string(),
    negotiatedSavings: sup.string(),
    potentialCostAvoidance: sup.string(),
    negotiatedCostAvoidance: sup.string(),
    terms: sup.object({
      acceptContractPeriod: sup.object({
        potentialCount: sup.integer(),
      }),
      extendContractPeriod: sup.object({
        potentialCount: sup.integer(),
      }),
      offerDiscount: sup.object({
        potentialCount: sup.integer(),
      }),
      selectDiscountImplementation: sup.object({
        potentialCount: sup.integer(),
      }),
      lockPrice: sup.object({
        potentialCount: sup.integer(),
      }),
      extendInvoiceDueDate: sup.object({
        potentialCount: sup.integer(),
      }),
      signCodeOfConduct: sup.object({
        potentialCount: sup.integer(),
      }),
      signStandardAgreement: sup.object({
        potentialCount: sup.integer(),
      }),
      answerSustainabilityQuestion: sup.object({
        potentialCount: sup.integer(),
      }),
      beIsoCertified: sup.object({
        potentialCount: sup.integer(),
      }),
      answerQuestion: sup.object({
        potentialCount: sup.integer(),
      }),
      text: sup.object({
        potentialCount: sup.integer(),
      }),
    }),
  }),
});

export type CustomerMetricsResourceType = sup.Infer<
  typeof customerMetricsResourceStruct
>;

export type CustomerMetrics = CustomerMetricsResourceType['attributes'];
