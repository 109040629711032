import styled from 'styled-components';

import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';
import { dark } from '@/modules/theme/utils/colors';

export const FilterItemStyled = styled('input')({
  display: 'none',

  '&:checked + label': {
    backgroundColor: dark[50],
  },
});

export const LabelStyled = styled('label')(
  ({ theme: { typography, borderRadius, spacing, breakpoints, palette } }) => ({
    display: 'inline-flex',
    gap: spacing(2),
    alignItems: 'center',
    cursor: 'pointer',
    color: palette.default.text,
    padding: spacing([1, 4]),
    borderRadius: borderRadius.extraLarge,
    letterSpacing: typography.letterSpacing.default,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontWeight: typography.fontWeight.bold,

    '&:hover': {
      backgroundColor: dark[50],
    },

    [breakpoints.down.md]: {
      padding: spacing([1, 2]),
    },
  })
);

export const SkeletonLoaderStyled = styled(SkeletonLoader)(
  ({ theme: { spacing } }) => ({
    margin: spacing([0, 0, 0, 2]),
    width: 28,
    height: 28,
  })
);
