import styled from 'styled-components';

import { DashedButton } from '@/modules/theme/components/DashedButton/DashedButton';
import { Heading } from '@/modules/theme/components/Typography';

export const DashedButtonStyled = styled(DashedButton)({
  height: 68,
  backgroundColor: 'transparent',
});

export const HeadingStyled = styled(Heading)(({ theme: { palette } }) => ({
  textAlign: 'center',
  color: palette.secondary.main,
}));
