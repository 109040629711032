import styled from 'styled-components';

import { Loader } from '@/modules/theme/components/Loader';

export const RefreshOverlay = () => {
  return (
    <OverlayWrapperStyled>
      <Loader size={70} />
    </OverlayWrapperStyled>
  );
};

const OverlayWrapperStyled = styled('div')(({ theme: { zIndexes } }) => ({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(255, 255, 255, 0.5)',
  zIndex: zIndexes.dialogOverlay,
}));
