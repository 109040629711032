import styled from 'styled-components';

export const SelectedSupplierMetricsContainer = styled('section')(
  ({ theme: { spacing, palette } }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    padding: spacing([2, 0]),
    borderBottom: `1px solid ${palette.primary.main}`,
    width: '100%',
  })
);

export const ContentWrapperStyled = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  maxWidth: 600,
}));
