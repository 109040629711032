import { ComponentType } from 'react';

import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { Paragraph } from '@/modules/theme/components/Typography';
import { mint } from '@/modules/theme/utils/colors';

import {
  CountStyled,
  IconWrapperStyled,
  TermCounterWrapperStyled,
} from './TermCounter.styled';

type AnalysisTermCounterProps = {
  Icon: ComponentType;
  name: string;
  count: number;
};

export const AnalysisTermCounter = ({
  Icon,
  name,
  count,
}: AnalysisTermCounterProps) => {
  return (
    <TermCounterWrapperStyled>
      <IconWrapperStyled>
        <Icon />
      </IconWrapperStyled>
      <FlexContainer justify="space-between" align="center">
        <Paragraph variant="contentBold" color={mint[800]}>
          {name}
        </Paragraph>
        <CountStyled>{count}</CountStyled>
      </FlexContainer>
    </TermCounterWrapperStyled>
  );
};
