import * as sup from 'superstruct';

import { SupplierNegotiationState } from '@/modules/negotiation/api/resources/negotiationSelectedSuppliers/negotiationSelectedSuppliersResourceConsts';
import { negotiationWithProgressSuppliersOrders } from '@/modules/negotiation/utils/consts';
import { suppliersOrders } from '@/modules/suppliers/utils/suppliersOrders';
import { intQuery } from '@/utils/structs/structs';

export const queryParamsSetStruct = <T, S>(struct: sup.Struct<T, S>) =>
  sup.coerce(sup.array(struct), sup.string(), (value) => [
    ...new Set(value.split(',')),
  ]);

export const negotiationWithProgressSuppliersQueryStruct = sup.object({
  'suppliers.page': sup.optional(sup.size(intQuery, 1, 100)),
  'suppliers.size': sup.optional(sup.size(intQuery, 1, 100)),
  'suppliers.sort': sup.optional(
    sup.nonempty(sup.enums(negotiationWithProgressSuppliersOrders))
  ),
  'suppliers.searchQuery': sup.optional(sup.nonempty(sup.string())),
  'suppliers.category': sup.optional(queryParamsSetStruct(sup.string())),
  'suppliers.progressStep': sup.optional(
    sup.nonempty(sup.enums(Object.values(SupplierNegotiationState)))
  ),
});

export const suppliersQueryParamsStruct = sup.object({
  page: sup.optional(sup.size(intQuery, 1, 100)),
  size: sup.optional(sup.size(intQuery, 1, 100)),
  sort: sup.optional(sup.nonempty(sup.enums(suppliersOrders))),
  searchQuery: sup.optional(sup.nonempty(sup.string())),
  category: sup.optional(queryParamsSetStruct(sup.string())),
  businessUnit: sup.optional(queryParamsSetStruct(sup.string())),
});
