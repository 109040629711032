import * as sup from 'superstruct';
import { ObjectSchema } from 'superstruct/dist/utils';

import {
  JsonApiRelationships,
  JsonApiResource,
  ResourceIdentifierStruct,
} from '../types/jsonApiTypes';

/**
 * It creates validation struct for json api response
 * https://jsonapi.org/format/#document-top-level
 */
export function createResponseStruct<
  PrimaryResource extends JsonApiResource | Array<JsonApiResource>,
  IncludedResource extends JsonApiResource | undefined = undefined,
  Meta extends Record<string, unknown> | undefined = undefined,
>({
  data,
  included,
  meta,
}: {
  data: sup.Struct<PrimaryResource>;
  included?: sup.Struct<IncludedResource[]>;
  meta?: sup.Describe<Meta>;
}) {
  type IncludedStruct = undefined extends IncludedResource
    ? sup.Struct<undefined>
    : sup.Struct<IncludedResource[]>;

  type MetaStruct = undefined extends Meta
    ? sup.Struct<undefined>
    : sup.Struct<Meta>;

  return sup.object({
    data,
    included: (included || sup.literal(undefined)) as IncludedStruct,
    meta: (meta || sup.literal(undefined)) as MetaStruct,
  });
}

/**
 * It creates validation struct for json api resource
 * https://jsonapi.org/format/#document-resource-objects
 */
export function createResourceStruct<
  Type extends string,
  Attributes extends Record<string, unknown> | undefined = undefined,
  Relationships extends JsonApiRelationships | undefined = undefined,
  Meta extends Record<string, unknown> | undefined = undefined,
  Links extends Record<string, unknown> | undefined = undefined,
>({
  identifier,
  attributes,
  relationships,
  meta,
  links,
}: {
  identifier: ResourceIdentifierStruct<Type>;
  attributes?: sup.Struct<Attributes, ObjectSchema>;
  relationships?: sup.Describe<Relationships>;
  meta?: sup.Struct<Meta, ObjectSchema>;
  links?: sup.Struct<Links, ObjectSchema>;
}) {
  type AttributesStruct = undefined extends Attributes
    ? sup.Struct<undefined>
    : sup.Describe<Attributes>;

  type RelationshipsStruct = sup.Describe<Relationships>;

  type MetaStruct = undefined extends Meta
    ? sup.Struct<undefined>
    : sup.Describe<Meta>;

  type LinksStruct = undefined extends Links
    ? sup.Struct<undefined>
    : sup.Describe<Links>;

  return sup.assign(
    sup.object({
      attributes: attributes || (sup.unknown() as AttributesStruct),
      relationships: (relationships || sup.unknown()) as RelationshipsStruct,
      meta: (meta || sup.unknown()) as MetaStruct,
      links: (links || sup.unknown()) as LinksStruct,
    }),
    identifier
  );
}

/**
 * It creates validation struct for json api resource identifier
 * https://jsonapi.org/format/#document-resource-identifier-objects
 */
export function createIdentifierStruct<Type extends string>(type: Type) {
  return sup.object({
    id: sup.string(),
    type: sup.literal(type),
  });
}
