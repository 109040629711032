import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import * as sup from 'superstruct';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { JsonApiClient } from '@/modules/api/utils/createJsonApiClient';
import { constructMeta } from '@/modules/reactQuery/constructMeta';

export const useCreateUploadCodeOfConductMutation = () => {
  const { t } = useTranslation('apiMessages/useUploadCodeOfConduct');
  const jsonApi = useJsonApi();

  return useMutation({
    mutationFn: createUploadCodeOfConduct(jsonApi),
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t("You don't have access to add a file"),
        },
        404: {
          NEGOTIATION_NOT_FOUND: t('Negotiation not found'),
          TERM_NOT_FOUND: t('Term not found'),
        },
        422: {
          NEGOTIATION_PLANNED: t(
            'Terms cannot be changed once a negotiation is planned'
          ),
        },
      },
    }),
  });
};

type CreateUploadCodeOfConductPayload = {
  file: File;
  negotiationId: string;
  termId: string;
};

const createUploadCodeOfConduct = (jsonApi: JsonApiClient) => {
  return ({ file, negotiationId, termId }: CreateUploadCodeOfConductPayload) =>
    jsonApi.createOne({
      path: `/me/customer/negotiations/${negotiationId}/terms/${termId}/code_of_conduct_document`,
      type: 'file',
      attributes: {
        name: file.name,
        contentType: file.type,
        sizeBytes: file.size,
      },
      responseStruct: codeOfConductUploadResponseStruct,
    });
};

const codeOfConductUploadResponseStruct = sup.object({
  meta: sup.object({
    url: sup.string(),
    headers: sup.record(sup.string(), sup.string()),
  }),
});
