import styled from 'styled-components';

import { Text } from '@/modules/theme/components/Typography';
import { black, dark } from '@/modules/theme/utils/colors';

export const NegotiationTypeLabelStyled = styled(Text)(
  ({ theme: { spacing, borderRadius, typography } }) => ({
    color: dark[600],
    padding: spacing([0.5, 1]),
    border: `1px solid ${black}`,
    borderRadius: borderRadius.default,
    fontWeight: typography.fontWeight.bold,
  })
);
