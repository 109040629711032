import { JsonApiClient } from '@/modules/api/utils/createJsonApiClient';
import { createResponseStruct } from '@/modules/api/utils/jsonApiStructs';

import {
  getSupplierContactsResourcePath,
  supplierContactResourceType,
} from '../resources/supplierContact/supplierContactResourceConsts';
import { supplierContactResourceStruct } from '../resources/supplierContact/supplierContactResourceStruct';

type AddSupplierContactMutationPayload = {
  attributes: {
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    phoneNumber: string | null;
  };
  supplierId: string | undefined;
};

export function addSupplierContactMutation(jsonApi: JsonApiClient) {
  return ({ attributes, supplierId }: AddSupplierContactMutationPayload) => {
    if (!supplierId) {
      throw new Error('Missing supplier id');
    }
    return jsonApi.createOne({
      type: supplierContactResourceType,
      path: getSupplierContactsResourcePath({ supplierId }),
      responseStruct: addSupplierContactResponseStruct,
      attributes,
    });
  };
}

const addSupplierContactResponseStruct = createResponseStruct({
  data: supplierContactResourceStruct,
});
