import styled from 'styled-components';

import { ReactComponent as SavedTick } from '@/assets/icons/savedTickIcon.svg';
import { mint } from '@/modules/theme/utils/colors';

export const GridWrapperStyled = styled('div')(
  ({ theme: { spacing, breakpoints } }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: spacing([2, 16.25]),
    [breakpoints.down.md]: {
      gridTemplateColumns: '1fr',
    },
  })
);

export const AsteriskStyled = styled('span')(
  ({ theme: { palette, spacing } }) => ({
    marginLeft: spacing(1),
    color: palette.error.main,
  })
);

export const SavedTickStyled = styled(SavedTick)({
  color: mint[800],
});
