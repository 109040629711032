import * as sup from 'superstruct';

import { JsonApiClient } from '@/modules/api/utils/createJsonApiClient';
import { createResponseStruct } from '@/modules/api/utils/jsonApiStructs';
import { includedStruct } from '@/utils/structs/includedStruct';

import { parseGetNegotiationResponse } from '../../helpers/parseGetNegotiationResponse';
import {
  getNegotiationResourcePath,
  negotiationRelationshipsKeys,
} from '../resources/negotiation/negotiationResourceConsts';
import { negotiationResourceStruct } from '../resources/negotiation/negotiationResourceStruct';
import { negotiationCommunicationResourceStruct } from '../resources/negotiationCommunication/negotiationCommunicationResourceStruct';
import { negotiationMetricsResourceStruct } from '../resources/negotiationMetrics/negotiationMetricsResourceStruct';
import {
  acceptableTermResourceStruct,
  acceptContractPeriodResourceStruct,
  discountImplementationTermResourceStruct,
  offerDiscountTermResourceStruct,
  signCodeOfConductResourceStruct,
  textTermResourceStruct,
} from '../resources/negotiationTerm/negotiationTermResourceStruct';

export const getNegotiationQuery = (
  jsonApi: JsonApiClient,
  { negotiationId }: { negotiationId: string | undefined }
) => {
  return async () => {
    if (!negotiationId) return Promise.reject(new Error('Invalid id'));

    const response = await jsonApi.getOne({
      path: getNegotiationResourcePath(negotiationId, [
        negotiationRelationshipsKeys.metrics,
        negotiationRelationshipsKeys.communication,
        negotiationRelationshipsKeys.terms,
      ]),
      responseStruct: getNegotiationResponseStruct,
      axiosConfig: {
        headers: {
          'accept-language': 'en',
        },
      },
    });
    return parseGetNegotiationResponse(response);
  };
};

export const getNegotiationResponseStruct = createResponseStruct({
  data: negotiationResourceStruct,
  included: includedStruct([
    negotiationMetricsResourceStruct,
    negotiationCommunicationResourceStruct,

    acceptableTermResourceStruct,
    offerDiscountTermResourceStruct,
    discountImplementationTermResourceStruct,
    signCodeOfConductResourceStruct,
    acceptContractPeriodResourceStruct,
    textTermResourceStruct,
  ]),
});

export type GetNegotiationResponse = sup.Infer<
  typeof getNegotiationResponseStruct
>;
