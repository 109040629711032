import debounce from 'lodash/debounce';
import { ChangeEvent, forwardRef, useMemo, useRef } from 'react';

import { TextInput, TextInputProps } from './TextInput';

export type DebouncedTextInputProps = TextInputProps & {
  onDebounce: (value: ChangeEvent<HTMLInputElement>) => void;
  waitTime: number;
};

export const DebouncedTextInput = forwardRef<
  HTMLInputElement,
  DebouncedTextInputProps
>(({ onChange, onDebounce, waitTime, ...props }, ref) => {
  // we need to store `onDebounce` in ref in order to
  // prevent memoized function from being recreated
  const onDebounceRef = useRef(onDebounce);
  onDebounceRef.current = onDebounce;

  const debounced = useMemo(
    () =>
      debounce(
        (value: ChangeEvent<HTMLInputElement>) => onDebounceRef.current(value),
        waitTime
      ),
    [waitTime]
  );

  return (
    <TextInput
      {...props}
      ref={ref}
      onChange={(e) => {
        onChange && onChange(e);
        debounced(e);
      }}
    />
  );
});
