import { useFormContext, useFieldArray, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CurrencyInput } from '@/modules/form/components/CurrencyInput/CurrencyInput';
import { TextInput } from '@/modules/form/components/TextInput/TextInput';
import { Heading } from '@/modules/theme/components/Typography';

import { categories } from '../../consts';
import { SurveyForm } from '../../helpers/surveyFormStruct';
import { SurveyFormSection } from '../SurveyFormSection';

import {
  CategoriesImportanceSectionWrapper,
  CategoriesSectionStyled,
  CheckboxStyled,
  DropdownWrapperStyled,
  LabelStyled,
  LabelTextStyled,
  TextInputContainer,
} from './CategoriesSection.styled';

export const CategoriesSection = () => {
  const { t } = useTranslation('pages/LeadCustomerSurveyPage');

  const { control, register, getValues, setValue } =
    useFormContext<SurveyForm>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'categoryInfo',
    keyName: 'categoryInfoId',
  });

  const importantCategories = useWatch({
    control,
    name: 'importantCategories',
  });

  const handleCategoryChange = (
    value: string,
    category: (typeof categories)[number]
  ) => {
    const chosenCategoryIndex = getValues('categoryInfo').findIndex(
      (element) => element.category === value
    );

    if (chosenCategoryIndex > -1) {
      remove(chosenCategoryIndex);
    } else {
      append(
        {
          category,
          value: {
            numberOfSuppliers: 0,
            totalSpend: '',
          },
        },
        {
          shouldFocus: false,
        }
      );
    }
  };

  const importantCategoriesRegisterProps = register('importantCategories');

  return (
    <SurveyFormSection
      number={3}
      title={t('Which are your most important supplier categories?')}
    >
      <CategoriesSectionStyled>
        {categories.map((category) => (
          <CheckboxStyled
            {...importantCategoriesRegisterProps}
            id={category}
            value={category}
            labelVariant="bold"
            key={category}
            hideError
            onChange={async (event) => {
              await importantCategoriesRegisterProps.onChange(event);
              handleCategoryChange(event.target.value, category);
            }}
          >
            <LabelTextStyled>{t(category)}</LabelTextStyled>
          </CheckboxStyled>
        ))}
      </CategoriesSectionStyled>

      {importantCategories.length > 0 && (
        <CategoriesImportanceSectionWrapper>
          <Heading variant="h3" mb={2}>
            {t('Add estimates for suppliers and spend per category')}
          </Heading>
          <div>
            {fields.map((field, index) => {
              const fieldId = `categoriesImportance-${index}`;
              const categoryInfoTotalSpendRegisterProps = register(
                `categoryInfo.${index}.value.totalSpend`
              );

              return (
                <DropdownWrapperStyled key={index}>
                  <LabelStyled htmlFor={fieldId}>{field.category}</LabelStyled>
                  <TextInputContainer>
                    <TextInput
                      label={index === 0 && t('Rough Nº Suppliers*')}
                      {...register(
                        `categoryInfo.${index}.value.numberOfSuppliers`,
                        { valueAsNumber: true }
                      )}
                      type="number"
                    />
                  </TextInputContainer>
                  <TextInputContainer>
                    <CurrencyInput
                      {...categoryInfoTotalSpendRegisterProps}
                      label={index === 0 && t('Rough Spend*')}
                      allowDecimals={false}
                      allowNegativeValue={false}
                      onChange={async (event) => {
                        await categoryInfoTotalSpendRegisterProps.onChange(
                          event
                        );
                        setValue(
                          `categoryInfo.${index}.value.totalSpend`,
                          event.target.value
                        );
                      }}
                    />
                  </TextInputContainer>
                </DropdownWrapperStyled>
              );
            })}
          </div>
        </CategoriesImportanceSectionWrapper>
      )}
    </SurveyFormSection>
  );
};
