import { useTranslation } from 'react-i18next';

import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { Heading, Paragraph } from '@/modules/theme/components/Typography';

import { MetricsCardWrapper } from '../common.styled';

type AnalysisNegotiationMetricErrorProps = {
  highlight?: boolean;
};

export const AnalysisNegotiationMetricErrorError = ({
  highlight,
}: AnalysisNegotiationMetricErrorProps) => {
  const { t } = useTranslation('pages/SuggestedNegotiationsPage');
  return (
    <MetricsCardWrapper highlight={highlight}>
      <FlexContainer direction="column" align="center">
        <Heading variant="h3">{t('Something went wrong')}</Heading>
        <Paragraph>
          {t('Please refresh the page or contact the administrator.')}
        </Paragraph>
      </FlexContainer>
    </MetricsCardWrapper>
  );
};
