import { useDraggable } from '@dnd-kit/core';
import { ReactNode } from 'react';
import styled from 'styled-components';

type DraggableProps = {
  id: string;
  children?: ReactNode;
};

export const Draggable = ({ id, children }: DraggableProps) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id,
  });

  return (
    <DraggableStyled
      ref={setNodeRef}
      transform={transform}
      {...listeners}
      {...attributes}
    >
      {children}
    </DraggableStyled>
  );
};

const DraggableStyled = styled('div')<{
  transform: { x: number; y: number } | null;
}>(({ transform }) => ({
  width: '100%',
  transform: transform
    ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
    : 'none',
}));
