import { MouseEventHandler, ReactNode } from 'react';
import styled from 'styled-components';

import { dark } from '@/modules/theme/utils/colors';

import { TableVariant } from './types';

type TableRowProps = {
  children: ReactNode;
  variant?: TableVariant;
  onClick?: MouseEventHandler<HTMLDivElement>;
  className?: string;
};

export const TableRow = ({
  variant = 'default',
  onClick,
  children,
  className,
}: TableRowProps) => {
  return (
    <TableRowStyled className={className} variant={variant} onClick={onClick}>
      {children}
    </TableRowStyled>
  );
};

type TableRowStyledProps = {
  variant: TableVariant;
};

const TableRowStyled = styled('div')<TableRowStyledProps>(({ variant }) => ({
  display: 'contents',
  backgroundColor: dark[50],
  ...(variant === 'transparent' && {
    backgroundColor: 'transparent',
  }),
  ...(variant === 'striped' && {
    '&:nth-of-type(even)': {
      backgroundColor: 'transparent',
    },
  }),
}));
