import styled from 'styled-components';

import { DashedButton } from '@/modules/theme/components/DashedButton/DashedButton';

export const FileUploadInputWrapperStyled = styled('div')(
  ({ theme: { spacing } }) => ({
    position: 'relative',
    height: spacing(9),
  })
);

interface FileUploadButtonStyledProps {
  appliedError?: boolean;
}

export const FileUploadButtonStyled = styled(
  DashedButton
)<FileUploadButtonStyledProps>(
  ({ appliedError, theme: { palette, typography } }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    borderColor: appliedError ? palette.error.main : palette.disabled.light,
    color: palette.disabled.light,
    fontSize: typography.pxToRem(14),
    cursor: 'pointer',

    '&:not([disabled]):hover': {
      borderColor: appliedError ? palette.error.main : palette.disabled.light,
      color: palette.disabled.light,
    },
    '&:not([disabled]):focus, &:not([disabled]):active': {
      borderColor: appliedError ? palette.error.main : palette.disabled.light,
      color: palette.disabled.light,
    },
  })
);

export const FileUploadInputStyled = styled('input')(
  ({ theme: { zIndexes } }) => ({
    zIndex: zIndexes.dialogContent,
    cursor: 'pointer',
    opacity: 0,
    width: '100%',
    height: '100%',
  })
);

export const FileUploadWrapperStyled = styled('div')(
  ({ theme: { spacing } }) => ({
    position: 'relative',
    marginBottom: spacing(2),
  })
);
