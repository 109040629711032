import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SwitchCheckbox } from '@/modules/theme/components/SwitchCheckbox/SwitchCheckbox';

import {
  ButtonStyled,
  ButtonsWrapperStyled,
  DialogStyled,
  HeadingStyled,
  ParagraphStyled,
} from './CookieConsentSettings.styled';

type CookieConsentSettingsProps = {
  isOpen: boolean;
  onClickSave: (isAnalysisCookieAccepted: boolean) => void;
  onClickCancel: () => void;
};

export const CookieConsentSettings = ({
  isOpen,
  onClickSave,
  onClickCancel,
}: CookieConsentSettingsProps) => {
  const { t } = useTranslation('layouts/Common/CookieConsentDialog');
  const [isAnalysisCookieAccepted, setIsAnalysisCookieAccepted] =
    useState(false);

  const handleOnToggleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsAnalysisCookieAccepted(event.currentTarget.checked);
  };

  return (
    <DialogStyled isOpen={isOpen} onClose={() => null}>
      <HeadingStyled variant="h3">{t('Strictly necessary')}</HeadingStyled>
      <ParagraphStyled>
        {t(
          'These cookies are essential for you to browse the platform and use its features, such as accessing secure areas.'
        )}
      </ParagraphStyled>
      <SwitchCheckbox checked disabled />
      <HeadingStyled variant="h3">{t('Statistics')}</HeadingStyled>
      <ParagraphStyled>
        {t('These cookies collect information about how you use our platform.')}
      </ParagraphStyled>
      <SwitchCheckbox onChange={handleOnToggleChange} />
      <ButtonsWrapperStyled>
        <ButtonStyled
          size="small"
          onClick={() => onClickSave(isAnalysisCookieAccepted)}
        >
          {t('Save')}
        </ButtonStyled>
        <ButtonStyled variant="outline" size="small" onClick={onClickCancel}>
          {t('Cancel')}
        </ButtonStyled>
      </ButtonsWrapperStyled>
    </DialogStyled>
  );
};
