import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { ValueContainerProps } from 'react-select';
import { components, GroupBase } from 'react-select';

export const MultiValueContainer = <
  Option,
  IsMulti extends true,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: PropsWithChildren<ValueContainerProps<Option, IsMulti, Group>>
) => {
  const { t } = useTranslation('common/MultiValueContainer');
  const selectedValues = Array.isArray(props.selectProps.value)
    ? props.selectProps.value.length
    : 0;

  const shouldShowSelectedValuesCount =
    selectedValues > 0 && !props.selectProps.menuIsOpen;

  return (
    <components.ValueContainer {...props}>
      {shouldShowSelectedValuesCount &&
        t('{{count}} selected', { count: selectedValues })}
      {props.children}
    </components.ValueContainer>
  );
};
