import { ReactNode, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  InputErrorMessage,
  InputErrorMessageProps,
} from '@/modules/form/components/ErrorMessage/InputErrorMessage';
import { InputError } from '@/modules/form/components/InputError';
import { Text } from '@/modules/theme/components/Typography/Text';
import { dark, red } from '@/modules/theme/utils/colors';

import { FileFormats } from '../../utils/fileFormats';

import {
  FileUploadButtonStyled,
  FileUploadInputStyled,
  FileUploadInputWrapperStyled,
  FileUploadWrapperStyled,
} from './FileUpload.styled';

export type ReactInputProps = Omit<React.ComponentProps<'input'>, 'ref'>;

export const FILE_MAX_SIZE_MB = 40;

export type FileUploadProps = ReactInputProps &
  InputErrorMessageProps & {
    text?: ReactNode;
    supportedFilesTypes?: string[];
    maxFileSizeInBytes?: number;
    isDisabled?: boolean;
    onUploadFile?: (file: File) => void;
  };

const defaultSupportedFilesTypes = [FileFormats.pdf];

export const FileUpload = (props: FileUploadProps) => {
  const {
    name,
    text,
    maxFileSizeInBytes = 1_000_000 * FILE_MAX_SIZE_MB,
    supportedFilesTypes = defaultSupportedFilesTypes,
    isDisabled = false,
    error,
    errorMessages,
    ErrorMessage,
    onUploadFile,
  } = props;

  const [appliedError, setAppliedError] = useState('');
  const { setError, setValue } = useFormContext();

  const handleErrorChange = (error: string) => {
    setAppliedError(error);
  };

  const validateUploadFile = (uploadFile: File) => {
    const { type } = uploadFile;
    const unsupportedFileTypeErrorName = `${name}.unsupportedFileType`;
    const maxSizeErrorName = `${name}.maxSize`;

    if (!supportedFilesTypes.includes(type)) {
      setError(name, { type: unsupportedFileTypeErrorName });
      setValue(name, undefined);
      return false;
    }

    if (uploadFile.size > maxFileSizeInBytes) {
      setError(name, { type: maxSizeErrorName });
      setValue(name, undefined);
      return false;
    }
    return true;
  };

  const handleUploadFile = (file: File) => {
    const fileIsValid = validateUploadFile(file);

    if (fileIsValid) {
      setValue(name, file, { shouldValidate: true });
      onUploadFile && onUploadFile(file);
    }
  };

  const handleOnDrop = (event: React.DragEvent) => {
    const [fileToUpload] = event.dataTransfer.files;

    handleUploadFile(fileToUpload);
  };

  const handleOnChangeUploadFile = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const fileToUpload = event.target.files?.item(0);

    if (!fileToUpload) {
      throw new Error('Your file was not added. Try again');
    }

    handleUploadFile(fileToUpload);
  };

  return (
    <FileUploadWrapperStyled>
      <FileUploadInputWrapperStyled onDrop={handleOnDrop}>
        <FileUploadButtonStyled appliedError={Boolean(appliedError)}>
          {text && (
            <Text variant="small" color={appliedError ? red[800] : dark[400]}>
              {text}
            </Text>
          )}
        </FileUploadButtonStyled>
        <FileUploadInputStyled
          type="file"
          accept={supportedFilesTypes.join(',')}
          id={name}
          name={name}
          disabled={isDisabled}
          onChange={handleOnChangeUploadFile}
        />
      </FileUploadInputWrapperStyled>
      <InputError errorHidden={!appliedError}>
        <InputErrorMessage
          name={name}
          error={error}
          errorMessages={errorMessages}
          ErrorMessage={ErrorMessage}
          onErrorChange={handleErrorChange}
        />
      </InputError>
    </FileUploadWrapperStyled>
  );
};
