import { noop } from '@/utils/noop';

import { SerializedSpendAndSurveyValues } from '../helpers/serializeSurveyValues';

import { useSubmitSurveyMutation } from './useSubmitSurveyMutation';

export const useUploadSpendAndSurvey = () => {
  const submitSurveyMutation = useSubmitSurveyMutation();

  type HandleUploadSpendAndSurveyPayload = {
    values: SerializedSpendAndSurveyValues;
    onSuccess: () => void;
  };

  const handleUploadSpendAndSurvey = async ({
    values,
    onSuccess,
  }: HandleUploadSpendAndSurveyPayload) => {
    const { ...surveyValues } = values;

    try {
      await submitSurveyMutation.mutateAsync({
        surveyValues,
      });

      onSuccess();
    } catch (err) {
      noop();
    }
  };

  const isPending = submitSurveyMutation.isPending;

  return { handleUploadSpendAndSurvey, isPending };
};
