import * as sup from 'superstruct';

import { JsonApiClient } from '@/modules/api/utils/createJsonApiClient';
import { createResponseStruct } from '@/modules/api/utils/jsonApiStructs';
import { NegotiationState } from '@/modules/negotiation/types/negotiationTypes';

import { customerMetricsResourcePath } from '../resources/metrics/customerMetricsResourceConsts';
import { customerMetricsResourceStruct } from '../resources/metrics/customerMetricsResourceStruct';

import { parseGetCustomerMetricsQuery } from './parseGetCustomerMetricsQuery';

type GetCustomerMetricsQueryPayload = {
  negotiationStates?: NegotiationState[];
  recommended?: boolean;
};

export const getCustomerMetricsQuery = (
  jsonApi: JsonApiClient,
  payload?: GetCustomerMetricsQueryPayload
) => {
  return async () => {
    const queryParams = {
      'filter[state]': payload?.negotiationStates?.join(','),
      'filter[recommended]': payload?.recommended,
    };
    const response = await jsonApi.getOne({
      path: customerMetricsResourcePath,
      queryParams,
      responseStruct: getCustomerMetricsResponseStruct,
    });
    return parseGetCustomerMetricsQuery(response);
  };
};

const getCustomerMetricsResponseStruct = createResponseStruct({
  data: customerMetricsResourceStruct,
});

export type GetCustomerMetricsResponseType = sup.Infer<
  typeof getCustomerMetricsResponseStruct
>;
