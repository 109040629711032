import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { queryKeys } from '@/modules/api/queryKeys';
import { getNegotiationSuppliersQuery } from '@/modules/negotiation/api/queries/getNegotiationSuppliersQuery';
import {
  NegotiationSuppliersIncludes,
  SupplierNegotiationState,
} from '@/modules/negotiation/api/resources/negotiationSelectedSuppliers/negotiationSelectedSuppliersResourceConsts';
import { NegotiationWithProgressSuppliersOrder } from '@/modules/negotiation/types/negotiationTypes';
import { constructMeta } from '@/modules/reactQuery/constructMeta';

type GetStartedSuppliersQueryParams = {
  pageNumber: number;
  pageSize: number;
  searchQuery?: string;
  category?: string[];
  sort: NegotiationWithProgressSuppliersOrder;
  negotiationStates: SupplierNegotiationState[];
};

type UseGetStartedNegotiationSuppliersQueryPayload = {
  negotiationId: string;
  params: GetStartedSuppliersQueryParams;
};

export const useGetStartedNegotiationSuppliersQuery = ({
  negotiationId,
  params: {
    searchQuery,
    pageNumber,
    pageSize,
    sort,
    category,
    negotiationStates,
  },
}: UseGetStartedNegotiationSuppliersQueryPayload) => {
  const jsonApi = useJsonApi();
  const { t } = useTranslation(
    'apiMessages/useGetStartedNegotiationSuppliersQuery'
  );

  return useQuery({
    queryKey: [
      queryKeys.negotiations.getStartedNegotiationSuppliers,
      negotiationId,
      searchQuery,
      pageNumber,
      pageSize,
      sort,
      category,
      negotiationStates,
    ],
    queryFn: getNegotiationSuppliersQuery(jsonApi, {
      negotiationId,
      page: pageNumber,
      pageSize: pageSize,
      negotiationStates,
      searchQuery,
      sort,
      category,
      includes: [
        NegotiationSuppliersIncludes.DEFAULT_CONTACT,
        NegotiationSuppliersIncludes.CATEGORY,
        NegotiationSuppliersIncludes.NEGOTIATION_PROGRESS,
        NegotiationSuppliersIncludes.NEGOTIATION_PROGRESS_ANSWERED_TERMS,
      ],
    }),
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: true,
    staleTime: 5000,
    refetchInterval: 60_000,
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t('You do not have access to see suppliers'),
        },
        404: {
          NEGOTIATION_NOT_FOUND: t('Negotiation has not been found'),
        },
      },
    }),
  });
};
