import Skeleton from 'react-loading-skeleton';
import ReactSelect, {
  GroupBase,
  MultiValue,
  Props as ReactSelectProps,
  SingleValue,
} from 'react-select';
import { useTheme } from 'styled-components';

import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { LabelStyled } from '@/modules/theme/components/Typography';

import { DisplayErrorMessage } from '../../ErrorMessage/DisplayErrorMessage';
import { InputErrorMessageProps } from '../../ErrorMessage/InputErrorMessage';
import { Option } from '../components/Option';

import { createSelectInputStyles } from './createSelectInputStyles';

export type SelectInputProps<Option, IsMulti extends boolean> = {
  name: string;
  label?: React.ReactNode;
  onChange: (
    value: IsMulti extends true ? MultiValue<Option> : SingleValue<Option>
  ) => void;
  isSearchable?: boolean;
  showErrorMessage?: boolean;
  loading?: boolean;
} & InputErrorMessageProps;

export function SelectInput<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  name,
  label,
  isSearchable = false,
  showErrorMessage = true,
  loading = false,
  error,
  errorMessages,
  className,
  styles,
  ErrorMessage,
  onErrorChange,
  onChange,
  components,
  isMulti,
  ...props
}: Omit<ReactSelectProps<Option, IsMulti, Group>, 'onChange'> &
  SelectInputProps<Option, IsMulti>) {
  const theme = useTheme();
  const customStyles =
    styles ??
    createSelectInputStyles<Option, IsMulti, Group>({
      theme,
      state: {
        error: Boolean(error),
      },
    });

  return (
    <FlexContainer className={className} direction="column" gap={1} pb={1}>
      {label && (
        <LabelStyled htmlFor={name} appliedError={error}>
          {label}
        </LabelStyled>
      )}
      {loading ? (
        <Skeleton height={42} />
      ) : (
        <ReactSelect
          components={{
            IndicatorSeparator: () => null,
            Option,
            ...components,
          }}
          styles={customStyles}
          isSearchable={isSearchable}
          onChange={onChange}
          isMulti={isMulti}
          {...props}
        />
      )}
      {showErrorMessage && (
        <DisplayErrorMessage
          error={error}
          errorMessages={errorMessages}
          ErrorMessage={ErrorMessage}
          onErrorChange={onErrorChange}
        />
      )}
    </FlexContainer>
  );
}
