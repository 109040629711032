import styled, { css } from 'styled-components';

import { dark } from '@/modules/theme/utils/colors';

export type LabelVariant = 'default' | 'bold';

type LabelStyledProps = {
  appliedError?: boolean;
  variant?: LabelVariant;
};

const selectionLabelStyles = css(({ theme: { spacing, typography } }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: spacing([1, 0, 1, 3.5]),
  position: 'relative',
  minHeight: spacing(2.5),
  cursor: 'pointer',
  outline: 0,
  fontSize: typography.fontSize.small,
  letterSpacing: typography.letterSpacing.default,
  fontFamily: typography.fonts.body,
}));

export const RadioLabel = styled('label')<LabelStyledProps>(
  () => selectionLabelStyles,
  ({ theme: { spacing, typography, palette }, variant, appliedError }) => ({
    paddingLeft: spacing(1.5),
    color: appliedError ? palette.error.main : palette.default.text,
    fontWeight:
      variant === 'bold'
        ? typography.fontWeight.bold
        : typography.fontWeight.normal,
  })
);

export const CheckboxLabel = styled('label')<LabelStyledProps>(
  () => selectionLabelStyles,
  ({
    theme: { borderRadius, palette, typography, spacing },
    variant,
    appliedError,
  }) => ({
    color: appliedError ? palette.error.main : palette.default.text,
    fontWeight:
      variant === 'bold'
        ? typography.fontWeight.bold
        : typography.fontWeight.normal,

    '&::before': {
      width: spacing(2.5),
      height: spacing(2.5),
      color: 'transparent',
      display: 'block',
      content: "''",
      border: `1px solid ${dark[100]}`,
      transition: `background-color 0.25s, border-color 0.25s`,
      background: dark[50],
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      left: 0,
      boxSizing: 'border-box',
      borderRadius: borderRadius.checkbox,
      ...(appliedError && {
        borderColor: palette.error.main,
        backgroundColor: palette.error.light,
      }),
    },
    '&:hover::before': {
      borderColor: palette.primary.lighter,
    },
  })
);
