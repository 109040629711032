import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { queryKeys } from '@/modules/api/queryKeys';
import { JsonApiClient } from '@/modules/api/utils/createJsonApiClient';
import { notify } from '@/modules/notify/notify';
import { constructMeta } from '@/modules/reactQuery/constructMeta';
import { onboardedRoutes } from '@/modules/router/onboardedRoutes';

export const useActivateNegotiationMutation = () => {
  const jsonApi = useJsonApi();
  const queryClient = useQueryClient();
  const { t } = useTranslation('apiMessages/useActivateNegotiationMutation');
  const navigate = useNavigate();
  return useMutation({
    mutationFn: activateNegotiationMutation(jsonApi),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [queryKeys.negotiations.getNegotiations],
        }),
        queryClient.invalidateQueries({
          queryKey: [queryKeys.customer.getCustomerMetrics],
        }),
      ]);
      notify.alert.success(t('Negotiation setup finished successfully!'));
      navigate(onboardedRoutes.buildUrl('dashboard'));
    },
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t('You cannot run this negotiation'),
        },
        404: {
          NEGOTIATION_NOT_FOUND: t('Negotiation not found'),
        },
        422: {
          INVALID_NEGOTIATION_STATE: t('Invalid negotiation state'),
          NO_PERIOD: t('Negotiation start date is required'),
          INVALID_PERIOD: t('Invalid negotiation start date'),
          NO_INTRO_EMAIL: t('Invitation letter is required'),
          NO_SUPPLIERS_SELECTED: t('You have to choose at least one supplier'),
          UNDEFINED_TERM: t('Some terms values are missing'),
          NO_KEYWORD_TRANSLATION: t('All keywords are required'),
        },
      },
    }),
  });
};

type ActivateNegotiationMutation = {
  negotiationId: string;
};

const activateNegotiationMutation = (jsonApiClient: JsonApiClient) => {
  return ({ negotiationId }: ActivateNegotiationMutation) => {
    return jsonApiClient.post({
      path: `/me/customer/negotiations/${negotiationId}/plan`,
    });
  };
};
