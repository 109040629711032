import { ComponentProps } from 'react';
import { Toaster as RHTToaster, ToastPosition } from 'react-hot-toast';

export const TOAST_POSITION: ToastPosition = 'top-center';
export const TOAST_DURATION = 5000;

export function Toaster(options?: ComponentProps<typeof RHTToaster>) {
  const appliedToasterOptions = {
    position: TOAST_POSITION,
    ...options,
    toastOptions: {
      duration: TOAST_DURATION,
      ...options?.toastOptions,
    },
  };
  return <RHTToaster {...appliedToasterOptions} />;
}
