import { useTranslation } from 'react-i18next';

import { Button } from '@/modules/theme/components/Button/Button';
import { Dialog } from '@/modules/theme/components/Dialog/Dialog';

import { RemoveSupplierDialogState } from './SuppliersTable';
import { useDeleteSupplierMutation } from './useDeleteSupplierMutation';

type DeleteSupplierDialogProps = {
  removeSupplierDialogState: RemoveSupplierDialogState;
  onClose: () => void;
};

export const DeleteSupplierDialog = ({
  removeSupplierDialogState,
  onClose,
}: DeleteSupplierDialogProps) => {
  const { t } = useTranslation('pages/SuppliersPage');
  const deleteSupplierMutation = useDeleteSupplierMutation({
    onSuccess: onClose,
  });

  const handleDeleteSupplier = () => {
    if (removeSupplierDialogState.supplierId) {
      deleteSupplierMutation.mutate({
        id: removeSupplierDialogState.supplierId,
      });
    }
  };

  return (
    <Dialog
      isOpen={removeSupplierDialogState.isOpen}
      hasCloseIcon
      onClose={onClose}
      title={t('Delete {{companyName}}?', {
        companyName: removeSupplierDialogState.companyName,
      })}
      description={t(
        'It is not possible to undo this action. Still sure you want to delete this supplier?'
      )}
    >
      <Button
        onClick={handleDeleteSupplier}
        loading={deleteSupplierMutation.isPending}
      >
        {t('Delete')}
      </Button>
    </Dialog>
  );
};
