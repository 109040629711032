import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { defaultPrefixedSuppliersQueryParams } from '@/modules/negotiation/helpers/defaultPrefixedSuppliersQueryParams';
import { notify } from '@/modules/notify/notify';
import { onboardedRoutes } from '@/modules/router/onboardedRoutes';

import { CompletedNegotiationPage } from './CompletedNegotiationPage';

export const CompletedNegotiationScene = () => {
  const { t } = useTranslation('pages/CompletedNegotiationPage');

  const { queryParams, queryParamsError } = onboardedRoutes.useGetQueryParams(
    'completedNegotiation'
  );

  const { negotiationId } = onboardedRoutes.useGetRouteParams(
    'completedNegotiation'
  );

  useEffect(() => {
    if (queryParamsError) {
      notify.alert.error(t('Invalid pagination state'));
    }
  }, [queryParamsError, t]);

  if (queryParamsError) {
    return (
      <Navigate
        to={onboardedRoutes.buildUrl('completedNegotiation', {
          params: { negotiationId },
          query: defaultPrefixedSuppliersQueryParams,
        })}
      />
    );
  }

  return (
    <CompletedNegotiationPage
      queryParams={queryParams}
      negotiationId={negotiationId}
    />
  );
};
