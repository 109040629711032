import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useSessionContext } from '@/modules/auth/hooks/useSessionContext';
import { NegotiationMetric } from '@/modules/negotiation/components/NegotiationMetric';
import { ParsedNegotiation } from '@/modules/negotiation/helpers/parseGetNegotiationResponse';
import { negotiationMetrics } from '@/modules/negotiation/metrics/consts';

type CompletedNegotiationMetricsProps = {
  metricsToDisplay: Set<string>;
  metrics: ParsedNegotiation['metrics'];
};

export const CompletedNegotiationMetrics = ({
  metrics,
  metricsToDisplay,
}: CompletedNegotiationMetricsProps) => {
  const { t } = useTranslation('pages/CompletedNegotiationPage');
  const { customer } = useSessionContext();

  return (
    <CompletedNegotiationMetricsWrapperStyled>
      <FinancialMetricsWrapperStyled>
        {metricsToDisplay.has(negotiationMetrics.negotiatedSavings) && (
          <NegotiationMetric
            heading={t('Negotiated savings ({{currency}})', {
              currency: customer.currency,
            })}
            data={metrics.negotiatedSavings}
          />
        )}

        {metricsToDisplay.has(negotiationMetrics.negotiatedCostAvoidance) && (
          <NegotiationMetric
            heading={t('Negotiated cost avoidance ({{currency}})', {
              currency: customer.currency,
            })}
            data={metrics.negotiatedCostAvoidance}
          />
        )}

        {metricsToDisplay.has(negotiationMetrics.negotiatedSpend) && (
          <NegotiationMetric
            heading={t('Negotiated spend ({{currency}})', {
              currency: customer.currency,
            })}
            data={metrics.negotiatedSpend}
          />
        )}
      </FinancialMetricsWrapperStyled>
      <CounterMetricsWrapper>
        {metricsToDisplay.has(negotiationMetrics.discountCount) && (
          <NegotiationMetric
            heading={t('Discounts')}
            data={metrics.discountCount}
            variant="counter"
          />
        )}

        {metricsToDisplay.has(
          negotiationMetrics.acceptedTermCounts.lockPrice
        ) && (
          <NegotiationMetric
            heading={t('Locked prices')}
            data={metrics.acceptedTermCounts.lockPrice}
            variant="counter"
          />
        )}

        {metricsToDisplay.has(
          negotiationMetrics.acceptedTermCounts.signCodeOfConduct
        ) && (
          <NegotiationMetric
            heading={t('Accepted Code of Conduct')}
            data={metrics.acceptedTermCounts.signCodeOfConduct}
            variant="counter"
          />
        )}

        {metricsToDisplay.has(
          negotiationMetrics.acceptedTermCounts.answerSustainabilityQuestion
        ) && (
          <NegotiationMetric
            heading={t('Answered questionnaires')}
            data={metrics.acceptedTermCounts.answerSustainabilityQuestion}
            variant="counter"
          />
        )}

        {metricsToDisplay.has(
          negotiationMetrics.acceptedTermCounts.extendInvoiceDueDate
        ) && (
          <NegotiationMetric
            heading={t('Accepted payment days')}
            data={metrics.acceptedTermCounts.extendInvoiceDueDate}
            variant="counter"
          />
        )}

        {metricsToDisplay.has(
          negotiationMetrics.acceptedTermCounts.extendContractPeriod
        ) && (
          <NegotiationMetric
            heading={t('Accepted prolongation')}
            data={metrics.acceptedTermCounts.extendContractPeriod}
            variant="counter"
          />
        )}
      </CounterMetricsWrapper>
    </CompletedNegotiationMetricsWrapperStyled>
  );
};

const CompletedNegotiationMetricsWrapperStyled = styled('div')(
  ({ theme: { spacing } }) => ({
    flex: 1,
    display: 'flex',
    gap: spacing(11),
  })
);

const CounterMetricsWrapper = styled('div')(({ theme: { spacing } }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 0.3fr))',
  columnGap: spacing(2),
  gridAutoRows: 94,
  flex: 1,
}));

const FinancialMetricsWrapperStyled = styled('div')(
  ({ theme: { spacing } }) => ({
    minWidth: 240,
    '&:empty': {
      marginRight: spacing(-11),
      minWidth: 0,
    },
  })
);
