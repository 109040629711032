import styled from 'styled-components';

import { findSelectValue } from '@/modules/form/components/Select/findSelectValue';
import { SuppliersFilters } from '@/modules/suppliers/components/SuppliersFilters';
import { defaultSuppliersQueryParams } from '@/modules/suppliers/helpers/defaultSuppliersQueryParams';
import { SuppliersOrder } from '@/modules/suppliers/utils/suppliersOrders';
import { Box } from '@/modules/theme/components/Box';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { Pagination } from '@/modules/theme/components/Pagination/Pagination';
import { ShowPerPageDropdown } from '@/modules/theme/components/ShowPerPageDropdown/ShowPerPageDropdown';
import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';

import { useGetPlannedNegotiationSuppliersQuery } from './hooks/useGetPlannedNegotiationSuppliersQuery';
import { useSetSuppliersQueryParams } from './hooks/useSetSuppliersQueryParams';
import { PlannedNegotiationSuppliersTable } from './PlannedNegotiationSuppliersTable/PlannedNegotiationSuppliersTable';

const pageSizeOptions = [20, 50, 100].map((value) => ({
  value,
  label: value.toString(),
}));

type SuppliersQueryParams = {
  page?: number;
  size?: number;
  sort?: SuppliersOrder;
  searchQuery?: string;
  businessUnit?: string[];
  category?: string[];
};

type PlannedNegotiationSuppliersProps = {
  negotiationId: string;
  queryParams: SuppliersQueryParams;
};

export const PlannedNegotiationSuppliers = ({
  negotiationId,
  queryParams,
}: PlannedNegotiationSuppliersProps) => {
  const searchQuery = queryParams.searchQuery || '';
  const order = queryParams.sort || defaultSuppliersQueryParams.sort;
  const categoriesFilter = queryParams.category || [];
  const pageNumber = queryParams.page || 1;
  const pageSize = queryParams.size || defaultSuppliersQueryParams.size;
  const businessUnitsFilter = queryParams.businessUnit || [];

  const {
    setCategories,
    setOrder,
    setPageNumber,
    setPageSize,
    setSearchQuery,
    setBusinessUnits,
    debouncedSearchQuery,
  } = useSetSuppliersQueryParams({ searchQuery });

  const { data, isPending, isSuccess, isPlaceholderData } =
    useGetPlannedNegotiationSuppliersQuery({
      negotiationId,
      params: {
        pageNumber,
        pageSize,
        sort: order,
        searchQuery: debouncedSearchQuery || undefined,
        category: categoriesFilter.length ? categoriesFilter : undefined,
        businessUnit: businessUnitsFilter.length
          ? businessUnitsFilter
          : undefined,
      },
    });

  const paginationProps = {
    perPage: pageSize,
    totalCount: data ? data.total : 0,
    onPageChange: (selectedPage: number) => setPageNumber(selectedPage + 1),
    forcePage: pageNumber - 1,
  };

  return (
    <Box margins={[5, 8, 7]}>
      <FlexContainer justify="space-between">
        <SuppliersFilters
          isLoading={isPending}
          searchQuery={searchQuery}
          categoriesFilter={categoriesFilter}
          order={order}
          businessUnitFilter={businessUnitsFilter}
          onBusinessUnitChange={setBusinessUnits}
          onSearchQueryChange={setSearchQuery}
          onOrderChange={setOrder}
          onCategoryChange={setCategories}
        />

        <FlexContainer direction="column" justify="space-between">
          <Box mb={2}>
            <ShowPerPageDropdown
              options={pageSizeOptions}
              selectedPageSize={pageSize}
              onPageSizeChange={setPageSize}
              defaultValue={findSelectValue({
                currentValue: pageSize,
                options: pageSizeOptions,
              })}
            />
          </Box>
        </FlexContainer>
      </FlexContainer>

      <FlexContainer justify="center" margins={[4, 0, 3]}>
        {isPending ? (
          <SkeletonLoaderStyled />
        ) : paginationProps.totalCount ? (
          <Pagination {...paginationProps} />
        ) : null}
      </FlexContainer>

      <PlannedNegotiationSuppliersTable
        suppliers={data?.suppliers || []}
        negotiationId={negotiationId}
        isSuccess={isSuccess}
        pageSize={pageSize}
        isLoading={isPending || isPlaceholderData}
      />

      <FlexContainer justify="center" margins={[5, 0]}>
        {isPending ? (
          <SkeletonLoaderStyled />
        ) : paginationProps.totalCount ? (
          <Pagination {...paginationProps} />
        ) : null}
      </FlexContainer>
    </Box>
  );
};

const SkeletonLoaderStyled = styled(SkeletonLoader)({
  height: 24,
  width: 100,
  marginBottom: 0,
});
