import { Trans, useTranslation } from 'react-i18next';

import { AuthenticatedLayout } from '@/layouts/AuthenticatedLayout/AuthenticatedLayout';
import { PageHeader } from '@/modules/theme/components/PageHeader/PageHeader';
import { PageMeta } from '@/modules/theme/components/PageMeta';
import { EmailLink, Heading } from '@/modules/theme/components/Typography';
import { digiProcInfoEmail } from '@/utils/consts';

import { ContainerWrapperStyled, ParagraphStyled } from './SupportPage.styled';

const translationNamespace = 'pages/SupportPage';

export function SupportPage() {
  const { t } = useTranslation(translationNamespace);

  return (
    <AuthenticatedLayout>
      <PageMeta title={t('Support')} />
      <PageHeader text={t('Support')} />
      <ContainerWrapperStyled>
        <Heading variant="h3" as="h1">
          {t('Contact us')}
        </Heading>
        <ParagraphStyled>
          <Trans
            ns={translationNamespace}
            i18nKey={
              'If you encounter any issues, please contact us at <EmailLink email="{{email}}">{{email}}</EmailLink> <br /> and we will make sure to help you out as soon as possible.'
            }
            values={{ email: digiProcInfoEmail }}
            components={{
              br: <br />,
              EmailLink: (
                <EmailLink email={digiProcInfoEmail}>
                  {digiProcInfoEmail}
                </EmailLink>
              ),
            }}
          />
        </ParagraphStyled>
      </ContainerWrapperStyled>
    </AuthenticatedLayout>
  );
}
