import type { PDFDocumentProxy } from 'pdfjs-dist';
import { useEffect, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf';

import { usePresentationContext } from '@/modules/presentation/presentationContext/usePresentationContext';

import { PresentationLoader } from './components/PresentationLoader';
import {
  ChevronLeftStyled,
  ChevronRightStyled,
  DotButtonStyled,
  NextPageButtonStyled,
  PDFContainerStyled,
  PresentationNavigationWrapperStyled,
  PresentationSliderWrapperStyled,
  PreviousPageButtonStyled,
} from './PresentiationSlider.styled';

interface PresentationSliderProps {
  presentationUrl: string;
}

export const PresentationSlider = ({
  presentationUrl,
}: PresentationSliderProps) => {
  const [pagesCount, setPagesCount] = useState<number | undefined>(undefined);
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);

  const { pageNumber, setPageNumber } = usePresentationContext();

  useEffect(() => {
    const element = containerRef?.current;

    if (!element) return;

    const observer = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      const [entry] = entries;

      if (entry) {
        setContainerWidth(entry.target.getBoundingClientRect().width);
      }
    });

    observer.observe(element);
    return () => {
      observer.disconnect();
    };
  }, []);

  const onDocumentLoadSuccess = (loadedDocument: PDFDocumentProxy) => {
    setPagesCount(loadedDocument.numPages);
    setPageNumber(pageNumber || 1);
  };

  const goToPreviousPage = () => {
    setPageNumber(pageNumber === 1 ? 1 : pageNumber - 1);
  };

  const goToNextPage = () => {
    setPageNumber(pageNumber === pagesCount ? pageNumber : pageNumber + 1);
  };

  return (
    <PresentationSliderWrapperStyled>
      <PreviousPageButtonStyled
        type="button"
        disabled={pageNumber <= 1}
        onClick={goToPreviousPage}
      >
        <ChevronLeftStyled />
      </PreviousPageButtonStyled>
      <PDFContainerStyled ref={containerRef}>
        <Document
          file={presentationUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={<PresentationLoader />}
        >
          <Page pageNumber={pageNumber} width={containerWidth} />
        </Document>
      </PDFContainerStyled>
      {pagesCount && (
        <NextPageButtonStyled
          type="button"
          disabled={pageNumber >= pagesCount}
          onClick={goToNextPage}
        >
          <ChevronRightStyled />
        </NextPageButtonStyled>
      )}
      <PresentationNavigationWrapperStyled>
        {pagesCount &&
          Array.from({ length: pagesCount }, (_, index) => (
            <DotButtonStyled
              key={index}
              isActive={pageNumber === index + 1}
              onClick={() => setPageNumber(index + 1)}
            />
          ))}
      </PresentationNavigationWrapperStyled>
    </PresentationSliderWrapperStyled>
  );
};
