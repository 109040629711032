import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { queryKeys } from '@/modules/api/queryKeys';
import { setNegotiationStateToSetupMutation } from '@/modules/negotiation/api/mutations/setNegotiationStateToSetupMutation';
import { useUpdateNegotiationQueryCache } from '@/modules/negotiation/hooks/useUpdateNegotiationQueryCache';
import { constructMeta } from '@/modules/reactQuery/constructMeta';

type UseSetNegotiationStateToSetupMutationPayload = {
  negotiationId: string;
};

export const useSetNegotiationStateToSetupMutation = ({
  negotiationId,
}: UseSetNegotiationStateToSetupMutationPayload) => {
  const jsonApi = useJsonApi();
  const { t } = useTranslation(
    'apiMessages/useSetNegotiationStateToSetupMutation'
  );
  const queryClient = useQueryClient();

  const { updateNegotiationQueryCache } = useUpdateNegotiationQueryCache({
    negotiationId,
  });

  return useMutation({
    mutationFn: setNegotiationStateToSetupMutation(jsonApi),
    onSuccess: async (response) => {
      updateNegotiationQueryCache((negotiation) => ({
        ...negotiation,
        state: response.state,
        supplierPreviewUrl: response.supplierPreviewUrl,
      }));

      await queryClient.invalidateQueries({
        queryKey: [queryKeys.negotiations.getNegotiations],
      });
    },
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t(
            "You don't have access to start a setup of this negotiation"
          ),
        },
        404: {
          NEGOTIATION_NOT_FOUND: t(
            'Negotiation not found. Please refresh the page or contact the administrator'
          ),
        },
        422: {
          INVALID_NEGOTIATION_STATE: t('Invalid negotiation state'),
        },
      },
    }),
  });
};
