import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { mutationKeys } from '@/modules/api/mutationKeys';
import { removeSupplierFromNegotiation } from '@/modules/negotiation/api/mutations/removeSupplierFromNegotiation';
import { constructMeta } from '@/modules/reactQuery/constructMeta';

import { updateSupplierQueryCacheSelectedState } from '../helpers/updateSupplierQueryCacheSelectedState';

import { useUpdateSelectedSuppliersQueryCache } from './useUpdateSelectedSuppliersQueryCache';

type RemoveSelectedSupplierFromNegotiationMutationPayload = {
  supplierId: string;
  negotiationId: string;
};

export const useRemoveSelectedSupplierFromNegotiationMutation = ({
  supplierId,
  negotiationId,
}: RemoveSelectedSupplierFromNegotiationMutationPayload) => {
  const jsonApi = useJsonApi();
  const { t } = useTranslation(
    'apiMessages/useRemoveSelectedSupplierFromNegotiationMutation'
  );
  const { updateSelectedSuppliersQueryCache } =
    useUpdateSelectedSuppliersQueryCache({ negotiationId });

  return useMutation({
    mutationKey: [
      mutationKeys.suppliers.removeSelectedSuppliersFromNegotiation,
    ],
    mutationFn: removeSupplierFromNegotiation(jsonApi),
    onSuccess: async () => {
      await updateSelectedSuppliersQueryCache((selectedSuppliersQueryCache) =>
        updateSupplierQueryCacheSelectedState({
          selectedSuppliersQueryCache,
          supplierId,
          selected: false,
        })
      );
    },
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHORIZED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t("You don't have access to remove suppliers"),
        },
        404: {
          NEGOTIATION_NOT_FOUND: t(
            'Negotiation not found. Please refresh the page or contact the administrator'
          ),
        },
        422: {
          INVALID_NEGOTIATION_STATE: t('Invalid negotiation state'),
        },
      },
    }),
  });
};
