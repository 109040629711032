import { forwardRef, ReactNode, useState } from 'react';

import {
  InputErrorMessage,
  InputErrorMessageProps,
} from '@/modules/form/components/ErrorMessage/InputErrorMessage';
import { InputError } from '@/modules/form/components/InputError';
import { LabelStyled } from '@/modules/theme/components/Typography';

import {
  AdornmentWrapperStyled,
  InputStyled,
  InputWrapperStyled,
  TextInputSkeletonLoader,
  TextInputWrapperStyled,
} from './TextInput.styled';

export type ReactInputProps = Omit<React.ComponentProps<'input'>, 'ref'>;

export type TextInputProps = ReactInputProps &
  InputErrorMessageProps & {
    label?: ReactNode;
    startAdornment?: ReactNode;
    endAdornment?: ReactNode;
    isLoading?: boolean;
    errorHidden?: boolean;
    labelHidden?: boolean;
  };

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      name,
      label,
      error,
      errorMessages,
      ErrorMessage,
      startAdornment,
      endAdornment,
      disabled,
      isLoading,
      errorHidden = false,
      labelHidden = false,
      ...props
    },
    ref
  ) => {
    const [appliedError, setAppliedError] = useState('');

    const handleErrorChange = (error: string) => {
      setAppliedError(error);
    };

    return (
      <TextInputWrapperStyled errorHidden={errorHidden}>
        {!labelHidden && (
          <LabelStyled htmlFor={name} appliedError={appliedError}>
            {label}
          </LabelStyled>
        )}
        {isLoading ? (
          <TextInputSkeletonLoader height={42} />
        ) : (
          <InputWrapperStyled
            appliedError={Boolean(appliedError)}
            disabled={disabled}
          >
            {startAdornment && (
              <AdornmentWrapperStyled variant="start">
                {startAdornment}
              </AdornmentWrapperStyled>
            )}
            <InputStyled
              ref={ref}
              id={name}
              name={name}
              disabled={disabled}
              appliedError={Boolean(appliedError)}
              {...props}
            />
            {endAdornment && (
              <AdornmentWrapperStyled variant="end">
                {endAdornment}
              </AdornmentWrapperStyled>
            )}
          </InputWrapperStyled>
        )}
        {!errorHidden && (
          <InputError errorHidden={Boolean(!appliedError)}>
            <InputErrorMessage
              name={name}
              error={error}
              errorMessages={errorMessages}
              ErrorMessage={ErrorMessage}
              onErrorChange={handleErrorChange}
            />
          </InputError>
        )}
      </TextInputWrapperStyled>
    );
  }
);
