import { createContext, Dispatch, SetStateAction } from 'react';

type PresentationContext = {
  pageNumber: number;
  setPageNumber: Dispatch<SetStateAction<number>>;
};

export const presentationContext = createContext<PresentationContext>({
  pageNumber: 1,
  setPageNumber: notImplemented,
});

function notImplemented(): never {
  throw new Error('Missing PresentationContext');
}
