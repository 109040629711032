import { RichContentTermStyled } from './RichContentTerm.styled';

interface RichContentTermProps {
  text: string;
}

export const RichContentTerm = ({ text }: RichContentTermProps) => {
  return (
    <RichContentTermStyled>
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </RichContentTermStyled>
  );
};
