import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/modules/theme/components/Button/Button';
import { Dialog } from '@/modules/theme/components/Dialog/Dialog';
import { UploadedFile } from '@/modules/theme/components/UploadedFile';

import { useRemoveCodeOfConductMutation } from '../RemoveCodeOfConductDialog/useRemoveCodeOfConductMutation';
import { UploadCodeOfConductForm } from '../UploadCodeOfConduct/UploadCodeOfConductForm';
import { useUploadCodeOfConduct } from '../UploadCodeOfConduct/useUploadCodeOfConduct';

import {
  ContainerWrapperStyled,
  UploadedContainerStyled,
} from './AddCodeOfConduct.styled';

export interface AddCodeOfConductDialogProps {
  isOpen: boolean;
  onClose: () => void;
  negotiationId: string;
  termId: string;
}

export function AddCodeOfConductDialog({
  isOpen,
  onClose,
  negotiationId,
  termId,
}: AddCodeOfConductDialogProps) {
  const { t } = useTranslation('pages/Terms');

  const [uploadedFile, setUploadedFile] = useState<File>();

  const removeCodeOfConductMutation = useRemoveCodeOfConductMutation();

  const { handleCodeOfConductUpload, isPending: isUploadCodeOfConductPending } =
    useUploadCodeOfConduct();

  const handleOnFileUpload = async (file: File) => {
    setUploadedFile(file);
    await handleCodeOfConductUpload({ file, negotiationId, termId });
  };

  const removeCodeOfConduct = () => {
    removeCodeOfConductMutation.mutate(
      { negotiationId, termId },
      { onSuccess: () => setUploadedFile(undefined) }
    );
  };

  const handleOnClose = () => {
    setUploadedFile(undefined);
    onClose();
  };

  const isLoading =
    removeCodeOfConductMutation.isPending || isUploadCodeOfConductPending;

  return (
    <Dialog
      isOpen={isOpen}
      hasCloseIcon
      onClose={handleOnClose}
      title={t('Add code of conduct')}
      description={t('You can add only one PDF file.')}
    >
      <ContainerWrapperStyled>
        {uploadedFile ? (
          <UploadedContainerStyled>
            <UploadedFile
              name={uploadedFile.name}
              isLoading={isLoading}
              onDelete={removeCodeOfConduct}
            />
            <Button
              variant="outline"
              onClick={handleOnClose}
              disabled={isLoading}
            >
              {t('Done')}
            </Button>
          </UploadedContainerStyled>
        ) : (
          <UploadCodeOfConductForm handleOnFileUpload={handleOnFileUpload} />
        )}
      </ContainerWrapperStyled>
    </Dialog>
  );
}
