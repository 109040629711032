import { START_DATE_WEEK_DAY_INDEX } from './consts';

export const getNextYearDate = (date: Date) => {
  return new Date(date.getFullYear() + 1, date.getMonth(), date.getDate());
};

export const calculateDate = (initialDate: Date, daysToAdd: number) => {
  return new Date(
    initialDate.getTime() + transformDaysToMiliseconds(daysToAdd)
  );
};

export const calculateDaysCountToGivenDate = (date: Date) => {
  const todayDate = new Date();
  const differenceInMiliseconds = date.getTime() - todayDate.getTime();
  const result = transformMilisecondsToDays(differenceInMiliseconds);
  return Math.ceil(result);
};

export const transformDaysToMiliseconds = (daysCount: number) => {
  return daysCount * 24 * 60 * 60 * 1000;
};

export const mapDateToDateInputString = (date: Date) => {
  const day = formatDateElement(date.getDate());
  const month = formatDateElement(date.getMonth() + 1);
  const year = String(date.getFullYear());

  return `${year}-${month}-${day}`;
};

export const formatDateElement = (element: number) => {
  return element >= 10 ? String(element) : `0${element}`;
};

export const countWeekDayRatio = (todayDate: Date) => {
  const todayIndex = todayDate.getDay();

  return todayIndex < START_DATE_WEEK_DAY_INDEX
    ? START_DATE_WEEK_DAY_INDEX - todayIndex
    : START_DATE_WEEK_DAY_INDEX - todayIndex + 7;
};

function transformMilisecondsToDays(daysInMiliseconds: number) {
  return daysInMiliseconds / 24 / 60 / 60 / 1000;
}
