import styled from 'styled-components';

import { Button } from '@/modules/theme/components/Button/Button';
import { Dialog } from '@/modules/theme/components/Dialog/Dialog';
import { Heading, Paragraph } from '@/modules/theme/components/Typography';

export const DialogStyled = styled(Dialog)(({ theme: { spacing } }) => ({
  padding: spacing(3),
  maxWidth: 503,
  alignItems: 'start',
}));

export const HeadingStyled = styled(Heading)(({ theme: { typography } }) => ({
  fontFamily: typography.fonts.body,
}));

export const ParagraphStyled = styled(Paragraph)(
  ({ theme: { typography } }) => ({
    fontWeight: typography.fontWeight.semiBold,
    maxWidth: 333,
  })
);

export const ButtonStyled = styled(Button)({
  minHeight: 'auto',
  height: 44,
  padding: 0,
});
