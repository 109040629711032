// Import the functions you need from the SDKs you need
import { NotifiableError } from '@bugsnag/js';
import { initializeApp } from 'firebase/app';
import { fetchAndActivate, getRemoteConfig } from 'firebase/remote-config';

import { appConfig } from '@/modules/app/appConfig';

import { notifyBugsnag } from '../monitoring/bugsnag';

// Your web app's Firebase configuration
const firebaseConfig = {
  ...appConfig.firebase,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Export the initialized app and remote config
export const remoteConfig = getRemoteConfig(app);

remoteConfig.settings.minimumFetchIntervalMillis = 60000; // 1 minute

// Function to fetch and activate Remote Config
export const firebaseFetchRemoteConfig = async () => {
  try {
    await fetchAndActivate(remoteConfig);
  } catch (error) {
    notifyBugsnag(error as NotifiableError);
  }
};

export default app;
