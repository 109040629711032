import { ReactNode, useState } from 'react';

import {
  ParsedSupplierContacts,
  supplierFormContext,
} from '../contexts/SupplierFormContext';

type SupplierFormContentProviderProps = {
  children: ReactNode;
};

export const SupplierFormPageProvider = ({
  children,
}: SupplierFormContentProviderProps) => {
  const [contacts, setContacts] = useState<ParsedSupplierContacts | null>(null);

  return (
    <supplierFormContext.Provider value={{ contacts, setContacts }}>
      {children}
    </supplierFormContext.Provider>
  );
};
