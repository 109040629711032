import { ReactNode } from 'react';
import styled from 'styled-components';

import { white } from '@/modules/theme/utils/colors';

interface CardProps {
  onClick?: () => void;
  className?: string;
  children?: ReactNode;
}

export const Card = ({ onClick, className, children }: CardProps) => {
  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <CardContainerStyled className={className} onClick={handleOnClick}>
      {children}
    </CardContainerStyled>
  );
};

const CardContainerStyled = styled('div')(
  ({ theme: { spacing, boxShadows, borderRadius } }) => ({
    backgroundColor: white,
    padding: spacing([3, 2]),
    boxShadow: boxShadows.default,
    borderRadius: borderRadius.default,
  })
);
