import { useTranslation } from 'react-i18next';

import {
  LabelsStyled,
  LabelStyled,
  SliderStyled,
  StyledInput,
} from './SwitchCheckbox.styled';

export const SwitchCheckbox = ({
  ref,
  ...props
}: React.ComponentProps<'input'>) => {
  const { t } = useTranslation('common/SwitchCheckbox');
  return (
    <LabelStyled>
      <StyledInput type="checkbox" {...props} />
      <SliderStyled />
      <LabelsStyled data-on={t('on')} data-off={t('off')} />
    </LabelStyled>
  );
};
