import styled from 'styled-components';

import { white } from '@/modules/theme/utils/colors';

export const TermCounterWrapperStyled = styled('div')(
  ({ theme: { spacing } }) => ({
    display: 'grid',
    gridTemplateColumns: '24px 1fr',
    alignItems: 'center',
    gap: spacing(1),
  })
);

export const IconWrapperStyled = styled('div')(
  ({ theme: { borderRadius, palette } }) => ({
    width: 24,
    height: 24,
    borderRadius: borderRadius.round,
    backgroundColor: palette.secondary.main,
    color: white,
  })
);

export const CountStyled = styled('span')(
  ({ theme: { typography, palette } }) => ({
    fontSize: typography.fontSize.large,
    fontWeight: typography.fontWeight.bold,
    color: palette.secondary.main,
  })
);
