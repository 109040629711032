import styled from 'styled-components';

import { ReactComponent as Logo } from '@/assets/logoHorizontal.svg';
import { blue } from '@/modules/theme/utils/colors';

export const ImgStyled = styled('img')(({ theme: { breakpoints } }) => ({
  maxWidth: '50%',

  [breakpoints.down.md]: {
    maxWidth: '100%',
  },
}));

export const TextWrapperStyled = styled('div')(
  ({ theme: { breakpoints } }) => ({
    width: '40%',
    maxWidth: 520,

    [breakpoints.down.md]: {
      width: '100%',
    },
  })
);

export const LogoStyled = styled(Logo)({
  color: blue[800],
  width: '100%',
  maxWidth: 423,
});

export const SectionStyled = styled('section')(
  ({ theme: { spacing, breakpoints } }) => ({
    padding: spacing([3, 0, 0, 14]),
    display: 'flex',
    justifyContent: 'space-between',
    gap: '7%',

    [breakpoints.down.md]: {
      flexDirection: 'column',
      gap: spacing(2),
      padding: spacing([10, 8, 9]),
    },
  })
);
