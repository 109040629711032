import { useQueryClient } from '@tanstack/react-query';
import { ReactNode, useCallback, useState } from 'react';
import { useHref, useLocation, useNavigate } from 'react-router-dom';

import { anonymousRoutes } from '@/modules/router/anonymousRoutes';

import { authContext } from '../contexts/AuthContext';
import {
  Customer,
  SignInPayload,
  SignOutPayload,
  User,
} from '../contexts/types';

export const lastSignedInLocalStorageKey = 'last-signed-in';

type AuthContentProviderProps = {
  children: ReactNode;
};

type RedirectLocationState = {
  redirectTo: Location;
};

export const AuthContextProvider = ({ children }: AuthContentProviderProps) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { state: locationState } = useLocation();

  const [user, setUser] = useState<User | null>(null);
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [hasActiveSubscription, setHasActiveSubscription] =
    useState<boolean>(false);

  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const signInHref = useHref(anonymousRoutes.buildUrl('signIn'));

  const signIn = useCallback(
    ({ user, customer, hasSubscription }: SignInPayload) => {
      setHasActiveSubscription(hasSubscription);
      setCustomer(customer);
      setUser(user);
      localStorage.setItem(lastSignedInLocalStorageKey, Date.now().toString());

      if (locationState) {
        // state is any by default
        const { redirectTo } = locationState as RedirectLocationState;
        navigate(`${redirectTo.pathname}${redirectTo.search}`);
      }
    },
    [locationState]
  );

  const signOut = useCallback(
    (options: SignOutPayload = { hardRedirect: true }) => {
      localStorage.clear();
      sessionStorage.clear();
      queryClient.clear();
      if (options.hardRedirect) {
        window.location.href = signInHref;
      }
    },
    [queryClient, signInHref]
  );

  return (
    <authContext.Provider
      value={{
        user,
        customer,
        isAuthenticating,
        hasActiveSubscription,
        setIsAuthenticating,
        setHasActiveSubscription,
        signIn,
        signOut,
      }}
    >
      {children}
    </authContext.Provider>
  );
};
