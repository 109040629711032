import { getValue } from 'firebase/remote-config';
import { useState, useEffect } from 'react';

import { firebaseFetchRemoteConfig, remoteConfig } from '..';

const useFirebaseRemoteConfigValue = <T>(key: string): T | undefined => {
  const [value, setValue] = useState<T>();

  useEffect(() => {
    const fetchValue = async () => {
      await firebaseFetchRemoteConfig();
      // Get the value for the key
      const fetchedValue = getValue(remoteConfig, key).asString();
      const jsonValue = JSON.parse(fetchedValue || '{}') as T;
      setValue(jsonValue);
    };

    void fetchValue();
  }, [key]);

  return value;
};

export default useFirebaseRemoteConfigValue;
