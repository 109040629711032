import qs from 'qs';
import { useTranslation } from 'react-i18next';

import { setupNegotiationRoutes } from '@/modules/router/onboardedRoutes';
import { defaultSuppliersQueryParams } from '@/modules/suppliers/helpers/defaultSuppliersQueryParams';
import { Tab } from '@/modules/theme/components/Tabs/Tabs';

export const useTabItems = () => {
  const { t } = useTranslation('pages/NegotiationSetupPage');
  const tabItems: Tab[] = [
    {
      link: setupNegotiationRoutes.config.startDate.nestedPath,
      text: t('Start date'),
    },
    {
      link: setupNegotiationRoutes.config.suppliers.nestedPath,
      text: t('Suppliers'),
      query: qs.stringify(defaultSuppliersQueryParams),
    },
    {
      link: setupNegotiationRoutes.config.terms.nestedPath,
      text: t('Terms'),
    },
    {
      link: setupNegotiationRoutes.config.invitationLetter.nestedPath,
      text: t('Invitation letter'),
    },
    {
      link: setupNegotiationRoutes.config.keywords.nestedPath,
      text: t('Keywords'),
      end: true,
    },
  ];
  return tabItems;
};
