import styled from 'styled-components';

export type FlexLayoutDirection = 'row' | 'column';

export interface FlexLayoutProps {
  direction?: FlexLayoutDirection;
  gap?: number;
}

export const FlexLayout = styled('div')<FlexLayoutProps>(
  ({ theme: { spacing }, direction = 'row', gap = 1 }) => ({
    display: 'flex',
    flexDirection: direction === 'row' ? 'row' : 'column',
    position: 'relative',
    gap: spacing(gap),
  })
);
