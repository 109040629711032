import { useInfiniteQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import * as sup from 'superstruct';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { queryKeys } from '@/modules/api/queryKeys';
import { JsonApiClient } from '@/modules/api/utils/createJsonApiClient';
import { createResponseStruct } from '@/modules/api/utils/jsonApiStructs';
import {
  NegotiationSuppliersIncludes,
  SupplierNegotiationState,
} from '@/modules/negotiation/api/resources/negotiationSelectedSuppliers/negotiationSelectedSuppliersResourceConsts';
import { constructMeta } from '@/modules/reactQuery/constructMeta';
import { supplierResourceStruct } from '@/modules/suppliers/api/resources/supplier/supplierResourceStruct';
import { supplierCategoryResourceStruct } from '@/modules/suppliers/api/resources/supplierCategory/supplierCategoryResourceStruct';
import { parseGetSuppliersResponse } from '@/modules/suppliers/helpers/parseGetSuppliersResponse';

const pageSize = 20;

type UseGetCustomerRecommendedSuppliersInfiniteQueryPayload = {
  negotiationId: string;
  enabled: boolean;
};

export const useGetCustomerRecommendedSuppliersInfiniteQuery = ({
  negotiationId,
  enabled,
}: UseGetCustomerRecommendedSuppliersInfiniteQueryPayload) => {
  const jsonApi = useJsonApi();
  const { t } = useTranslation(
    'apiMessages/useGetCustomerRecommendedSuppliersInfiniteQuery'
  );

  return useInfiniteQuery({
    initialPageParam: 1,
    queryKey: [
      negotiationId,
      queryKeys.customer.getCustomerRecommendedSuppliers,
    ],
    queryFn: ({ pageParam = 1 }) =>
      getCustomerRecommendedSuppliers(jsonApi, {
        negotiationId,
        page: pageParam,
        pageSize,
        includes: [NegotiationSuppliersIncludes.CATEGORY],
      })(),
    enabled,
    getNextPageParam: (lastPage, allPages) => {
      const totalItemsCounted = allPages.length * pageSize;
      if (totalItemsCounted >= lastPage.totalSuppliersCount) return undefined;
      return allPages.length + 1;
    },
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t('You do not have access to see recommended suppliers'),
        },
        404: {
          NEGOTIATION_NOT_FOUND: t('Negotiation has not been found'),
        },
      },
    }),
  });
};

const getCustomerRecommendedSuppliersStruct = createResponseStruct({
  data: sup.array(supplierResourceStruct),
  included: sup.array(supplierCategoryResourceStruct),
  meta: sup.object({
    page: sup.object({
      total: sup.integer(),
    }),
  }),
});

type getCustomerRecommendedSuppliersPayload = {
  negotiationId: string;
  page: number;
  pageSize: number;
  includes: NegotiationSuppliersIncludes[];
};

function getCustomerRecommendedSuppliers(
  jsonApi: JsonApiClient,
  {
    negotiationId,
    pageSize,
    page,
    includes,
  }: getCustomerRecommendedSuppliersPayload
) {
  return async () => {
    const queryParams = {
      'page[size]': pageSize,
      'page[number]': page,
      'filter[negotiationState]': SupplierNegotiationState.RECOMMENDED,
      include: includes?.join(','),
    };
    const response = await jsonApi.getMany({
      path: `/me/customer/negotiations/${negotiationId}/suppliers`,
      queryParams,
      responseStruct: getCustomerRecommendedSuppliersStruct,
    });

    return parseGetSuppliersResponse(response);
  };
}
