import { JsonApiClient } from '@/modules/api/utils/createJsonApiClient';

import {
  customerUserResourcePath,
  customerUserResourceType,
} from '../resources/customer/customerUserResourceConsts';

type SignUpMutationPayload = {
  customerToken: string;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
};

export function signUpMutation(jsonApi: JsonApiClient) {
  return (attributes: SignUpMutationPayload) =>
    jsonApi.createOne({
      type: customerUserResourceType,
      path: customerUserResourcePath,
      attributes,
    });
}
