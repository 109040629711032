import styled, { CSSObject } from 'styled-components';

import { dark } from '@/modules/theme/utils/colors';

type TextAreaWrapperStyledProps = {
  errorHidden?: boolean;
};

export const TextAreaWrapperStyled = styled('div')<TextAreaWrapperStyledProps>(
  ({ theme: { spacing }, errorHidden }) => ({
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: errorHidden ? 0 : spacing(1),
  })
);

type TextAreaStyledProps = {
  appliedError?: boolean;
};

export const TextAreaStyled = styled('textarea')<TextAreaStyledProps>(({
  appliedError,
  theme: { spacing, palette, typography, borderRadius },
}) => {
  const regularInput = {
    '&:not([disabled])::placeholder': {
      color: palette.disabled.main,
    },

    '&[disabled]': {
      backgroundColor: palette.disabled.lighter,
    },
  };
  const errorInput: CSSObject = {
    color: palette.error.main,
    '&::placeholder': {
      color: palette.error.main,
    },
  };

  return {
    width: '100%',
    border: 0,
    borderRadius: borderRadius.default,
    padding: spacing(1),
    backgroundColor: 'transparent',
    fontSize: typography.fontSize.default,
    fontFamily: typography.fonts.body,
    lineHeight: typography.fontSize.large,
    outline: 'none',
    minHeight: 115,
    ...(appliedError ? errorInput : regularInput),
    resize: 'vertical',
  };
});

type TextAreaContainerStyledProps = {
  appliedError?: boolean;
  disabled?: boolean;
};

export const TextAreaContainerStyled = styled(
  'div'
)<TextAreaContainerStyledProps>(
  ({
    appliedError,
    disabled,
    theme: { borders, borderRadius, palette, spacing },
  }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    outline: 'none',
    border: '1px solid transparent',
    margin: spacing([1, 0]),
    borderRadius: borderRadius.default,
    backgroundColor: appliedError ? palette.error.light : dark[50],
    transition: 'border-color 0.1s',
    '&:focus-within, &:hover': {
      border: appliedError ? borders.input.error : borders.input.main,
    },
    ...(disabled && {
      backgroundColor: palette.disabled.lighter,
    }),
  })
);
