import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { queryKeys } from '@/modules/api/queryKeys';
import { JsonApiClient } from '@/modules/api/utils/createJsonApiClient';
import { negotiationSupplierProgressType } from '@/modules/negotiation/api/resources/negotiationSupplierProgress/negotiationSupplierProgressResourceConsts';
import { constructMeta } from '@/modules/reactQuery/constructMeta';

type UseRemoveSupplierMutationPayload = {
  negotiationId: string;
};

export const useRemoveSupplierMutation = ({
  negotiationId,
}: UseRemoveSupplierMutationPayload) => {
  const queryClient = useQueryClient();
  const jsonApi = useJsonApi();
  const { t } = useTranslation('apiMessages/useRemoveSupplierMutation');

  return useMutation({
    mutationFn: excludeSupplierFromNegotiation({ jsonApi, negotiationId }),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [
            queryKeys.negotiations.getStartedNegotiationSuppliers,
            negotiationId,
          ],
        }),
        queryClient.invalidateQueries({
          queryKey: [queryKeys.negotiations.getNegotiation, negotiationId],
        }),
      ]);
    },
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Your session expired. Please sign in'),
        },
        403: {
          FORDBIDDEN: t("You don't have permission to remove this supplier"),
        },
        404: {
          NEGOTIATION_NOT_FOUND: t(
            'Negotiation not found. Please refresh the page or contact the administrator'
          ),
          PROGRESS_NOT_FOUND: t(
            'Negotiation progress not found. Please refresh the page or contact the administrator'
          ),
        },
        422: {
          INVALID_NEGOTIATION_STATE: t('Negotiation is not in active state'),
        },
      },
    }),
  });
};

type ExcludeSupplierFromNegotiationPayload = {
  jsonApi: JsonApiClient;
  negotiationId: string;
};

type ExcludeSupplierFromNegotiationMutatePayload = {
  progressId: string | undefined;
};

const excludeSupplierFromNegotiation = ({
  jsonApi,
  negotiationId,
}: ExcludeSupplierFromNegotiationPayload) => {
  return ({ progressId }: ExcludeSupplierFromNegotiationMutatePayload) => {
    if (!progressId) {
      throw new Error('Missing supplier progress id');
    }

    return jsonApi.post({
      path: `/customer/negotiations/${negotiationId}/supplier_progress/${progressId}/exclude`,
      payload: {
        type: negotiationSupplierProgressType,
      },
    });
  };
};
