import { GroupBase, StylesConfig } from 'react-select';

import CheckedIconUrl from '@/assets/icons/tickIcon.svg';
import { BasicOption } from '@/modules/form/components/Select/types';
import { blue, dark } from '@/modules/theme/utils/colors';
import { Theme } from '@/modules/theme/utils/createTheme';

type ShowPerPageDropdownStylesProps = {
  theme: Theme;
};

type ReactStylesConstructor = <
  Option extends BasicOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: ShowPerPageDropdownStylesProps
) => StylesConfig<Option, IsMulti, Group>;

export const createShowPerPageDropdownStyles: ReactStylesConstructor = ({
  theme: { palette, typography, borderRadius, spacing, zIndexes, boxShadows },
}) => {
  return {
    menu: (provided) => ({
      ...provided,
      marginTop: spacing(0.5),
      zIndex: zIndexes.dropdownContent,
      boxShadow: boxShadows.dropdown,
    }),
    valueContainer: (provided) => ({
      ...provided,
      lineHeight: 'normal',
      paddingLeft: 0,
    }),
    control: (provided) => ({
      ...provided,
      minHeight: 42,
      flexWrap: 'nowrap',
      fontFamily: typography.fonts.body,
      cursor: 'pointer',
      border: 'none',
      boxShadow: 'none',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: blue[900],
      fontWeight: typography.fontWeight.bold,
      letterSpacing: typography.letterSpacing.default,
    }),
    placeholder: (provided) => ({
      ...provided,
      fontWeight: typography.fontWeight.normal,
      color: dark[400],
    }),
    option: (provided, { isSelected }) => ({
      ...provided,
      position: 'relative',
      padding: spacing([1.5, 2]),
      cursor: 'pointer',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      paddingRight: spacing(5),
      backgroundColor: 'transparent',
      color: blue[900],
      fontWeight: typography.fontWeight.bold,

      '&:focus, &:active': {
        backgroundColor: 'transparent',
      },
      '&:hover': {
        backgroundColor: dark[50],
      },
      '&:after': {
        content: `url("${CheckedIconUrl}")`,
        position: 'absolute',
        right: spacing(2),
        top: `calc(50% - ${spacing(1)})`,
        display: isSelected ? 'block' : 'none',
        height: 13,
        width: 18,
      },
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      borderRadius: borderRadius.default,
      backgroundColor: palette.default.background,
      border: 'none',
    }),
    dropdownIndicator: (provided, { selectProps: { menuIsOpen } }) => ({
      ...provided,
      padding: spacing(0.625),
      marginRight: spacing(0.625),
      color: palette.default.text,
      ...(menuIsOpen && { transform: 'rotate(180deg)' }),
      '&:hover': {
        color: 'inherit',
      },
    }),
  };
};
