import { useTranslation } from 'react-i18next';

import { useGetNegotiationQuery } from '@/modules/negotiation/hooks/useGetNegotiationQuery';
import { setupNegotiationRoutes } from '@/modules/router/onboardedRoutes';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { Heading, Paragraph } from '@/modules/theme/components/Typography';
import { dark } from '@/modules/theme/utils/colors';

import { NegotiationTerms } from './components/NegotiationTerms/NegotiationTerms';

export const Terms = () => {
  const { t } = useTranslation('pages/Terms');
  const { negotiationId } = setupNegotiationRoutes.useGetRouteParams('terms');

  const { data, isPending } = useGetNegotiationQuery({ negotiationId });

  return (
    <>
      <Heading variant="h3" as="h2" mb={2}>
        {t('Set up the terms for your upcoming negotiation')}
      </Heading>
      <Paragraph color={dark[400]}>
        {t('Select the terms you want to include in this negotiation.')}
      </Paragraph>
      <FlexContainer mt={7} gap={2} direction="column">
        <NegotiationTerms
          isLoading={isPending}
          terms={data?.negotiationTerms}
        />
      </FlexContainer>
    </>
  );
};
