import { useTranslation } from 'react-i18next';

import { ReactComponent as EditIcon } from '@/assets/icons/editIcon.svg';
import { ReactComponent as DeleteIcon } from '@/assets/icons/minusIcon.svg';
import { onboardedRoutes } from '@/modules/router/onboardedRoutes';
import { Loader } from '@/modules/theme/components/Loader';
import {
  ActionButton,
  ActionButtonLink,
} from '@/modules/theme/components/Table/ActionButton.styled';

import { useRemoveSupplierFromNegotiationMutation } from '../hooks/useRemoveSupplierFromNegotiationMutation';

import { ActionTableCellStyled } from './SuppliersActionTableCell.styled';
import { WarningTooltip } from './WarningTooltip';

type SuppliersActionTableCellProps = {
  negotiationId: string;
  displayWarning: boolean;
  supplierId: string;
};

export const SuppliersActionTableCell = ({
  negotiationId,
  displayWarning,
  supplierId,
}: SuppliersActionTableCellProps) => {
  const { t } = useTranslation('pages/SuppliersTab');

  const { mutate: removeSupplierMutate, isPending: isRemoveSupplierPending } =
    useRemoveSupplierFromNegotiationMutation();

  return (
    <ActionTableCellStyled warning={displayWarning}>
      <ActionButtonLink
        disabled={isRemoveSupplierPending}
        to={onboardedRoutes.buildUrl('editSupplier', {
          params: {
            supplierId,
          },
        })}
      >
        <EditIcon />
      </ActionButtonLink>
      <ActionButton
        disabled={isRemoveSupplierPending}
        onClick={() => {
          removeSupplierMutate({
            selectedSuppliers: [supplierId],
            negotiationId,
          });
        }}
      >
        {isRemoveSupplierPending ? <Loader size={24} /> : <DeleteIcon />}
      </ActionButton>
      {displayWarning && (
        <WarningTooltip
          content={t(
            'This supplier is included in another ongoing or planned negotiation.'
          )}
        />
      )}
    </ActionTableCellStyled>
  );
};
