import { JsonApiClient } from '@/modules/api/utils/createJsonApiClient';

import { getDeleteSupplierResourcePath } from '../resources/supplier/supplierResourceConsts';

type DeleteSupplierMutationPayload = {
  id: string;
};

export function deleteSupplierMutation(jsonApi: JsonApiClient) {
  return ({ id }: DeleteSupplierMutationPayload) => {
    return jsonApi.delete({
      path: getDeleteSupplierResourcePath({ id }),
    });
  };
}
