import { ReactNode } from 'react';

import { ApiContext } from '../contexts/ApiContext';
import { jsonApiClient } from '../httpClient';
import { createJsonApiClient } from '../utils/createJsonApiClient';
import { createJsonApiV2Client } from '../utils/createJsonApiV2Client';

export const ApiProvider = ({ children }: { children: ReactNode }) => {
  const jsonApi = createJsonApiClient(jsonApiClient);
  const jsonApiV2 = createJsonApiV2Client(jsonApiClient);
  return (
    <ApiContext.Provider
      value={{
        jsonApi,
        jsonApiV2,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};
