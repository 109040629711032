import { Helmet } from 'react-helmet-async';

import packageJson from '../../../../../package.json';

export const AppVersion = () => {
  return (
    <Helmet>
      <meta name="app-version" content={packageJson.version} />
    </Helmet>
  );
};
