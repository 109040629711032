import { appConfigKey } from './appConfigKey';
import type { AppConfig } from './createAppConfigFromEnv';

/**
 * Application configuration.
 *
 * When the app is run locally using `npm start` or when the tests are run with `npm test`, the config is read from
 * environment variables. In an actual deployment the config is read from
 * `window._config` injected by `tools/configure` into the `index.html`.
 * This allows us to use the same builds in different environments.
 *
 * To enforce using appConfig over process.env, eslint is configured
 * to mark `process` references as errors.
 */
export const appConfig = getConfig();

declare global {
  interface Window {
    /** Don't use it directly, use `appConfig` instead */
    [appConfigKey]: AppConfig;
  }
}

function getConfig(): AppConfig {
  // eslint-disable-next-line no-restricted-globals
  if (process.env.NODE_ENV === 'development') {
    const { createAppConfigFromEnv } =
      require('./createAppConfigFromEnv') as typeof import('./createAppConfigFromEnv');

    const config = createAppConfigFromEnv();

    // eslint-disable-next-line no-console
    console.debug('appConfig', config);

    return config;
  }

  // eslint-disable-next-line no-restricted-globals
  if (process.env.NODE_ENV === 'test') {
    const { createTestAppConfig } =
      require('./createTestAppConfig') as typeof import('./createTestAppConfig');

    return createTestAppConfig();
  }

  return window[appConfigKey];
}
