import * as sup from 'superstruct';

/**
 * It converts built-in superstruct refinement into composable, so that it
 * can be used with the `recompose` helper.
 */
export function makeRefinementComposable<T, S, O extends unknown[]>(
  refiner: (struct: sup.Struct<T, S>, ...options: O) => sup.Struct<T, S>
) {
  return (...options: O) =>
    (struct: sup.Struct<T, S>) =>
      refiner(struct, ...options);
}
