import styled from 'styled-components';

import { AuthBackground } from '@/layouts/common/AuthBackground';
import { ButtonLink } from '@/modules/theme/components/ButtonLink';
import { white } from '@/modules/theme/utils/colors';

export const ContentWrapperStyled = styled(AuthBackground)(
  ({ theme: { spacing } }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    gap: spacing(2),
    padding: spacing([3, 5]),
  })
);

export const ButtonLinkStyled = styled(ButtonLink)(
  ({ theme: { spacing } }) => ({
    '&:hover, &:focus': {
      color: white,
    },
    marginTop: spacing(2),
  })
);
