import styled from 'styled-components';

import { Button } from '@/modules/theme/components/Button/Button';
import { Dialog } from '@/modules/theme/components/Dialog/Dialog';

export const DialogStyled = styled(Dialog)(({ theme: { breakpoints } }) => ({
  width: 700,
  [breakpoints.down.sm]: {
    width: '90%',
  },
}));

export const SubmitButtonStyled = styled(Button)({
  width: '100%',
});
