import axios from 'axios';

import { appConfig } from '@/modules/app/appConfig';

const API_VERSION_HEADER_NAME = 'X-Api-Version';
const API_VERSION_HEADER_VALUE = 'latest';
const CSRF_PROTECTION_HEADER_NAME = 'X-CSRF-Protected';

export const jsonApiClient = axios.create({
  withCredentials: true,
  timeout: Number(appConfig.api.requestTimeout),
  baseURL: appConfig.api.baseUrl,
  headers: {
    [API_VERSION_HEADER_NAME]: [API_VERSION_HEADER_VALUE],
    [CSRF_PROTECTION_HEADER_NAME]: '',
    'Content-Type': 'application/vnd.api+json',
  },
});
