import { useTranslation } from 'react-i18next';
import * as sup from 'superstruct';

import { Form } from '@/modules/form/components/Form/Form';
import { notEmpty } from '@/modules/form/utils/structs';
import { SupplierLanguageType } from '@/modules/language/supplierLanguage/supplierLanguageConsts';
import { ParsedNegotiationKeywords } from '@/modules/negotiation/helpers/parseGetNegotiationKeywordsQuery';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { InputSkeletonLoader } from '@/modules/theme/components/InputSkeletonLoader/InputSkeletonLoader';
import { Heading } from '@/modules/theme/components/Typography';
import { dark } from '@/modules/theme/utils/colors';
import { recompose } from '@/utils/structs/recompose';

import { GridWrapperStyled } from '../Keywords.styled';

import { KeywordsFieldArray } from './KeywordsFieldArray';

const keywordsFormStruct = sup.object({
  keywords: sup.array(
    sup.object({
      id: recompose(sup.string(), notEmpty),
      name: recompose(sup.string(), notEmpty),
      value: recompose(sup.string(), notEmpty),
    })
  ),
});
export type KeywordsFormStruct = sup.Infer<typeof keywordsFormStruct>;

type KeywordsFormProps = {
  isFetching: boolean;
  negotiationId: string;
  keywords: ParsedNegotiationKeywords | undefined;
  currentLanguage: SupplierLanguageType;
};

export const KeywordsForm = ({
  isFetching,
  negotiationId,
  keywords,
  currentLanguage,
}: KeywordsFormProps) => {
  const { t } = useTranslation('pages/Keywords');

  if (isFetching) {
    return (
      <GridWrapperStyled>
        <InputSkeletonLoader lines={12} />
      </GridWrapperStyled>
    );
  }

  if (keywords?.length) {
    return (
      <Form
        mode="all"
        struct={keywordsFormStruct}
        defaultValues={{
          keywords: keywords.map(({ id, value, name }) => ({
            id,
            value,
            name,
          })),
        }}
      >
        {() => (
          <GridWrapperStyled>
            <KeywordsFieldArray
              negotiationId={negotiationId}
              language={currentLanguage}
            />
          </GridWrapperStyled>
        )}
      </Form>
    );
  }
  return (
    <FlexContainer justify="center">
      <Heading variant="h3" color={dark[400]}>
        {t('There are no keywords asigned')}
      </Heading>
    </FlexContainer>
  );
};
