import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetCustomerBusinessUnitsQuery } from '@/modules/customer/api/businessUnits/useGetCustomerBusinessUnitsQuery';
import { findSelectValue } from '@/modules/form/components/Select/findSelectValue';
import { MultiSelect } from '@/modules/form/components/Select/MultiSelect';
import { SelectInput } from '@/modules/form/components/Select/SelectInput/SelectInput';
import { SupplierCategoryFilter } from '@/modules/suppliers/components/SuppliersFilters/SupplierCategoryFilter';
import { SupplierSearchFilter } from '@/modules/suppliers/components/SuppliersFilters/SupplierSearchFilter';
import { SuppliersFiltersLayout } from '@/modules/suppliers/components/SuppliersFilters/SuppliersFiltersLayout';
import { mapCustomerBusinessUnitsToSelectOptions } from '@/modules/suppliers/helpers/mapCustomerBusinessUnitsToSelectOptions';
import { SuppliersOrder } from '@/modules/suppliers/utils/suppliersOrders';
import { notEmpty } from '@/utils/utils';

type SuppliersFiltersProps = {
  isLoading: boolean;
  searchQuery: string;
  businessUnitFilter: string[];
  order: string;
  categoriesFilter: string[];
  onSearchQueryChange: (query: string) => void;
  onBusinessUnitChange: (values: string[]) => void;
  onCategoryChange: (values: string[]) => void;
  onOrderChange: (order: SuppliersOrder) => void;
};

export const SuppliersFilters = ({
  searchQuery,
  businessUnitFilter,
  order,
  categoriesFilter,
  onSearchQueryChange,
  onBusinessUnitChange,
  onOrderChange,
  onCategoryChange,
}: SuppliersFiltersProps) => {
  const { t } = useTranslation('modules/suppliers/SuppliersFilters');

  const { data: businessUnits, isPending: isPendingBusinessUnits } =
    useGetCustomerBusinessUnitsQuery();

  const orderOptions: { label: string; value: SuppliersOrder }[] = [
    {
      label: t('Name ascending'),
      value: 'name',
    },
    {
      label: t('Name descending'),
      value: '-name',
    },
    {
      label: t('Spend ascending'),
      value: 'currentSpend',
    },
    {
      label: t('Spend descending'),
      value: '-currentSpend',
    },
    {
      label: t('Category name ascending'),
      value: 'category.name',
    },
    {
      label: t('Category name descending'),
      value: '-category.name',
    },
    {
      label: t('Contact email ascending'),
      value: 'defaultContact.email',
    },
    {
      label: t('Contact email descending'),
      value: '-defaultContact.email',
    },
    {
      label: t('Business unit name ascending'),
      value: 'businessUnit.name',
    },
    {
      label: t('Business unit name descending'),
      value: '-businessUnit.name',
    },
  ];

  // https://dev.api.digiproc.com/documentation/static/index.html#/supplier/get_me_customer_negotiations__negotiationId__selected_suppliers
  // pass "null" to get suppliers without business units
  const businessUnitsOptions = useMemo(() => {
    return (
      businessUnits && [
        { label: t('No business unit'), value: 'null' },
        ...mapCustomerBusinessUnitsToSelectOptions(businessUnits),
      ]
    );
  }, [t, businessUnits]);

  const businessUnitMultiValues = useMemo(() => {
    return businessUnitFilter
      .map((businessUnit) =>
        findSelectValue({
          currentValue: businessUnit,
          options: businessUnitsOptions,
        })
      )
      .filter(notEmpty);
  }, [businessUnitFilter, businessUnitsOptions]);

  return (
    <SuppliersFiltersLayout>
      <SupplierSearchFilter
        searchQuery={searchQuery}
        onChange={onSearchQueryChange}
      />
      <SelectInput
        name="sortBy"
        label={t('Sort by')}
        options={orderOptions}
        showErrorMessage={false}
        value={findSelectValue({
          currentValue: order,
          options: orderOptions,
        })}
        placeholder={t('Choose...')}
        onChange={(option) => onOrderChange(option?.value || 'name')}
      />
      <MultiSelect
        name="businessUnit"
        label={t('Filter by business unit')}
        options={businessUnitsOptions}
        showErrorMessage={false}
        placeholder={t('Business unit')}
        isLoading={isPendingBusinessUnits}
        onChange={(options) =>
          onBusinessUnitChange(options.map(({ value }) => value))
        }
        value={businessUnitMultiValues}
      />
      <SupplierCategoryFilter
        filterValues={categoriesFilter}
        onChange={onCategoryChange}
      />
    </SuppliersFiltersLayout>
  );
};
