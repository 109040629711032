import { useTranslation } from 'react-i18next';
import * as sup from 'superstruct';

import { AuthContentLayout } from '@/layouts/AuthContentLayout/AuthContentLayout';
import { Form } from '@/modules/form/components/Form/Form';
import { PasswordInput } from '@/modules/form/components/PasswordInput/PasswordInput';
import { TextInput } from '@/modules/form/components/TextInput/TextInput';
import {
  areEqual,
  emailPattern,
  notEmpty,
  passwordPattern,
} from '@/modules/form/utils/structs';
import { PageMeta } from '@/modules/theme/components/PageMeta';
import { recompose } from '@/utils/structs/recompose';

import { useSignUpMutation } from './hooks/useSignUpMutation';
import { ButtonStyled, TextInputWrapperStyled } from './SignUpPage.styled';

export type SignUpValues = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  repeatedPassword: string;
};
type SignUpStruct = sup.Describe<SignUpValues>;

const signUpStruct: SignUpStruct = sup.object({
  firstName: recompose(sup.string(), notEmpty),
  lastName: recompose(sup.string(), notEmpty),
  email: recompose(sup.string(), notEmpty, emailPattern),
  password: recompose(sup.string(), notEmpty, passwordPattern),
  repeatedPassword: recompose(
    sup.string(),
    notEmpty,
    areEqual<SignUpStruct, string>('password')
  ),
});

type SignUpPageProps = {
  token: string;
};

export const SignUpPage = ({ token }: SignUpPageProps) => {
  const { t } = useTranslation('pages/SignUpPage');
  const signUpMutation = useSignUpMutation();

  const errorMessages = {
    firstName: {
      'string.notEmpty': t('This field is required'),
    },
    lastName: {
      'string.notEmpty': t('This field is required'),
    },
    email: {
      'string.notEmpty': t('This field is required'),
      'string.emailPattern': t('Email format is invalid'),
    },
    password: {
      'string.notEmpty': t('This field is required'),
      'string.passwordPattern': t(
        'The password should be at least 6 characters long, have min. 1 uppercase letter and 1 digit.'
      ),
    },
    repeatedPassword: {
      'string.notEmpty': t('This field is required'),
      'string.areEqual': t('Passwords should match'),
    },
  };

  const handleValidSubmit = ({
    firstName,
    lastName,
    email,
    password,
  }: SignUpValues) => {
    signUpMutation.mutate({
      firstName,
      lastName,
      email,
      password,
      customerToken: token,
    });
  };

  return (
    <AuthContentLayout title={t('Register')} mode="signUp">
      <PageMeta title={t('Sign up page')} description={t('Sign up page')} />
      <Form
        defaultValues={{
          email: '',
          password: '',
          firstName: '',
          lastName: '',
        }}
        struct={signUpStruct}
        onValidSubmit={handleValidSubmit}
      >
        {({ register, trigger, formState }) => {
          return (
            <>
              <TextInputWrapperStyled>
                <TextInput
                  label={t('Name')}
                  {...register('firstName')}
                  errorMessages={errorMessages.firstName}
                />
              </TextInputWrapperStyled>
              <TextInputWrapperStyled>
                <TextInput
                  label={t('Last Name')}
                  {...register('lastName')}
                  errorMessages={errorMessages.lastName}
                />
              </TextInputWrapperStyled>
              <TextInputWrapperStyled>
                <TextInput
                  label={t('Email')}
                  {...register('email')}
                  errorMessages={errorMessages.email}
                />
              </TextInputWrapperStyled>
              <TextInputWrapperStyled>
                <PasswordInput
                  label={t('Password')}
                  {...register('password')}
                  onChange={() => {
                    if (!formState.touchedFields.repeatedPassword) return;
                    void trigger('repeatedPassword');
                  }}
                  errorMessages={errorMessages.password}
                />
              </TextInputWrapperStyled>
              <TextInputWrapperStyled>
                <PasswordInput
                  {...register('repeatedPassword')}
                  label={t('Confirm password')}
                  errorMessages={errorMessages.repeatedPassword}
                />
              </TextInputWrapperStyled>
              <ButtonStyled
                type="submit"
                loading={signUpMutation.isPending}
                disabled={signUpMutation.isPending}
              >
                {t('Register')}
              </ButtonStyled>
            </>
          );
        }}
      </Form>
    </AuthContentLayout>
  );
};
