import * as sup from 'superstruct';

import { BasicApiError } from './basicApiErrorValidator';

export const getApiErrorStatusCode = <T extends BasicApiError>(
  error: unknown,
  validator: sup.Struct<T>
) => {
  const [validationError, apiError] = sup.validate(error, validator);

  if (!validationError && apiError) return apiError.response.status;
};

export const getApiErrorCodes = <T extends BasicApiError>(
  error: unknown,
  validator: sup.Struct<T>
) => {
  const [validationError, apiError] = sup.validate(error, validator);

  if (!validationError && apiError)
    return apiError.response.data.errors.map((error) => error.code);
};

export const validateApiError = <T extends BasicApiError>(
  error: unknown,
  validator: sup.Struct<T>
) => {
  const [validationError, apiError] = sup.validate(error, validator);

  if (validationError) {
    throw validationError;
  }

  return apiError;
};
