import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { queryKeys } from '@/modules/api/queryKeys';

import { ParsedNegotiation } from '../helpers/parseGetNegotiationResponse';

type UseUpdateNegotiationQueryCachePayload = {
  negotiationId: string;
};

export const useUpdateNegotiationQueryCache = ({
  negotiationId,
}: UseUpdateNegotiationQueryCachePayload) => {
  const queryClient = useQueryClient();

  const updateNegotiationQueryCache = useCallback(
    (
      updateNegotiation: (
        oldNegotiationData: ParsedNegotiation
      ) => ParsedNegotiation
    ) => {
      queryClient.setQueryData<ParsedNegotiation>(
        [queryKeys.negotiations.getNegotiation, negotiationId],
        (oldNegotiationData) => {
          if (oldNegotiationData) {
            return updateNegotiation(oldNegotiationData);
          }
        }
      );
    },
    [negotiationId, queryClient]
  );

  const getNegotiationQueryCacheSnapshot = useCallback(async () => {
    await queryClient.cancelQueries({
      queryKey: [queryKeys.negotiations.getNegotiation, negotiationId],
    });
    return queryClient.getQueryData<ParsedNegotiation>([
      queryKeys.negotiations.getNegotiation,
      negotiationId,
    ]);
  }, [negotiationId, queryClient]);

  return { updateNegotiationQueryCache, getNegotiationQueryCacheSnapshot };
};
