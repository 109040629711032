import {
  focusManager,
  MutationCache,
  MutationMeta,
  QueryCache,
  QueryClient,
  QueryClientProvider,
  QueryMeta,
} from '@tanstack/react-query';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { appConfig } from '../app/appConfig';
import {
  notifyBugsnagMutationError,
  notifyBugsnagQueryError,
} from '../monitoring/bugsnag';
import { notify } from '../notify/notify';

import { basicErrorResponseValidator } from './basicApiErrorValidator';
import { validateMeta } from './constructMeta';
import { displayErrorToast } from './displayErrorToast';
import { getApiErrorStatusCode, validateApiError } from './validateApiError';

// https://github.com/TanStack/query/issues/2960
// https://github.com/TanStack/query/discussions/4797
// https://tanstack.com/query/v4/docs/react/guides/window-focus-refetching
// in v5 React Query switches to `visibilitychange` listener dropping support
// for `focus`. Remove after update.
focusManager.setEventListener((handleFocus) => {
  const setPageFocus = () => handleFocus(!document.hidden);
  window.addEventListener('visibilitychange', setPageFocus);
  return () => {
    window.removeEventListener('visibilitychange', setPageFocus);
  };
});

type ReactQueryProviderProps = {
  children: ReactNode;
};

export const ReactQueryProvider = ({ children }: ReactQueryProviderProps) => {
  const { t } = useTranslation('apiMessages/defaults');
  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: (error, query) => {
        try {
          handleAppError(error, query.meta);
        } catch (error) {
          notify.alert.error(t('Something went wrong, try again later'));
          void notifyBugsnagQueryError(error, query);
        }
      },
    }),
    mutationCache: new MutationCache({
      onError: (error, variables, context, mutation) => {
        try {
          handleAppError(error, mutation.meta);
        } catch (error) {
          notify.alert.error(t('Something went wrong, try again later'));
          void notifyBugsnagMutationError(error, variables, context, mutation);
        }
      },
    }),
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
          if (appConfig.environment === 'local') return false;

          const apiErrorCode = getApiErrorStatusCode(
            error,
            basicErrorResponseValidator
          );
          if (apiErrorCode && apiErrorCode >= 400 && apiErrorCode < 500) {
            return false;
          }

          return failureCount < 3;
        },
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

const handleAppError = (
  unknownError: unknown,
  unknownMeta: QueryMeta | MutationMeta | undefined
) => {
  const error = validateApiError(unknownError, basicErrorResponseValidator);
  const meta = validateMeta(unknownMeta);

  displayErrorToast(error, meta.errorMessages);
};
