import styled from 'styled-components';

import { ReactComponent as MinusIcon } from '@/assets/icons/minusIcon.svg';
import { ReactComponent as FileIcon } from '@/assets/icons/pdfIcon.svg';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { Loader } from '@/modules/theme/components/Loader';
import { Text } from '@/modules/theme/components/Typography';
import { blue } from '@/modules/theme/utils/colors';

type UploadedFileProps = {
  name: string;
  isLoading?: boolean;
  onDelete: () => void;
};

export const UploadedFile = ({
  name,
  isLoading = false,
  onDelete,
}: UploadedFileProps) => {
  return (
    <FlexContainer>
      <FileUploadNameWrapperStyled>
        <FileIcon width={24} />
        <TextStyled variant="normal">{name}</TextStyled>
      </FileUploadNameWrapperStyled>
      {isLoading ? (
        <div>
          <Loader size={20} variant="outline" />
        </div>
      ) : (
        <MinusIconStyled onClick={onDelete} color={blue[700]} />
      )}
    </FlexContainer>
  );
};

const FileUploadNameWrapperStyled = styled('div')(
  ({ theme: { spacing, palette } }) => ({
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    gridColumnGap: spacing(1),
    color: palette.default.text,
  })
);

const MinusIconStyled = styled(MinusIcon)({
  cursor: 'pointer',
  minWidth: 24,
});

const TextStyled = styled(Text)(({ theme: { typography } }) => ({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflowX: 'hidden',
  lineHeight: 1.5,
  fontSize: typography.pxToRem(14),
}));
