import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Dialog } from '@/modules/theme/components/Dialog/Dialog';
import { Heading } from '@/modules/theme/components/Typography';

import { SupplierNegotiationState } from '../../api/resources/negotiationSelectedSuppliers/negotiationSelectedSuppliersResourceConsts';
import { useGetNegotiationSelectedSuppliersInfiniteQuery } from '../../hooks/useGetNegotiationSelectedSuppliersInfiniteQuery';

import { SuppliersGroupTable } from './SuppliersGroupTable';

type NegotiationExcludedSuppliersDialogProps = {
  negotiationId: string;
  isOpen: boolean;
  onClose: () => void;
};

export const NegotiationExcludedSuppliersDialog = ({
  negotiationId,
  isOpen,
  onClose,
}: NegotiationExcludedSuppliersDialogProps) => {
  const { t } = useTranslation('modules/negotiations/NegotiationDataPanel');

  const { data, fetchNextPage, isFetching } =
    useGetNegotiationSelectedSuppliersInfiniteQuery({
      negotiationId,
      negotiationStates: [SupplierNegotiationState.EXCLUDED],
      enabled: isOpen,
    });

  const suppliers = useMemo(() => {
    return data?.pages.flatMap((page) => page.suppliers);
  }, [data]);

  return (
    <DialogStyled isOpen={isOpen} onClose={onClose} hasCloseIcon>
      <Heading variant="h2">{t('Excluded')}</Heading>
      <SuppliersGroupTable
        isFetching={isFetching}
        suppliers={suppliers}
        onFetchMore={fetchNextPage}
      />
    </DialogStyled>
  );
};

const DialogStyled = styled(Dialog)(({ theme: { spacing } }) => ({
  width: '95%',
  maxWidth: spacing(159),
  alignItems: 'flex-start',
  gap: spacing(2),
}));
