import * as sup from 'superstruct';

import { createResourceStruct } from '@/modules/api/utils/jsonApiStructs';

import { supplierContactIdentifierStruct } from '../supplierContact/supplierContactIdentifierStruct';

export const supplierContactResourceStruct = createResourceStruct({
  identifier: supplierContactIdentifierStruct,
  attributes: sup.object({
    firstName: sup.nullable(sup.string()),
    lastName: sup.nullable(sup.string()),
    email: sup.nullable(sup.string()),
    phoneNumber: sup.nullable(sup.string()),
  }),
});

export type SupplierContact = sup.Infer<typeof supplierContactResourceStruct>;
