import styled from 'styled-components';

import backgroundSrc from '../assets/background.svg';

export const AuthBackground = styled('div')(({ theme: { gradients } }) => ({
  position: 'relative',
  backgroundImage: `url(${backgroundSrc}), ${gradients.background}`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  width: '100%',
}));
