import { css } from 'styled-components';

export type SpacingProps = {
  margins?: number[];
  paddings?: number[];
  mt?: number;
  mb?: number;
  ml?: number;
  mr?: number;
  pt?: number;
  pb?: number;
  pl?: number;
  pr?: number;
};

export const commonSpacings = css<SpacingProps>(
  ({ theme: { spacing }, ...props }) => {
    return {
      ...(props.margins && { margin: spacing(props.margins) }),
      ...(props.mt && { marginTop: spacing(props.mt) }),
      ...(props.mb && { marginBottom: spacing(props.mb) }),
      ...(props.ml && { marginLeft: spacing(props.ml) }),
      ...(props.mr && { marginRight: spacing(props.mr) }),
      ...(props.paddings && { padding: spacing(props.paddings) }),
      ...(props.pt && { paddingTop: spacing(props.pt) }),
      ...(props.pb && { paddingBottom: spacing(props.pb) }),
      ...(props.pl && { paddingLeft: spacing(props.pl) }),
      ...(props.pr && { paddingRight: spacing(props.pr) }),
    };
  }
);
