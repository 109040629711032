import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { DefaultCheckbox } from '@/modules/form/components/Checkbox/DefaultCheckbox';
import { Button } from '@/modules/theme/components/Button/Button';
import { Dialog } from '@/modules/theme/components/Dialog/Dialog';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { Paragraph, Text } from '@/modules/theme/components/Typography';

import { useRemoveSupplierFromPlannedNegotiationMutation } from './hooks/useRemoveSupplierFromNegotiationMutation';

type RemoveSupplierDialogProps = {
  negotiationId: string;
  supplierId: string;
  onClose: () => void;
};

export const RemoveSupplierDialog = ({
  negotiationId,
  supplierId,
  onClose,
}: RemoveSupplierDialogProps) => {
  const { t } = useTranslation('pages/PlannedNegotiationPage');

  const [isRemoveConfirmed, setIsRemoveConfirmed] = useState(false);

  const removeSupplierFromPlannedNegotiationMutation =
    useRemoveSupplierFromPlannedNegotiationMutation({
      negotiationId,
    });

  const onDialogClose = () => {
    setIsRemoveConfirmed(false);
    onClose();
  };

  return (
    <Dialog
      hasCloseIcon
      onClose={onDialogClose}
      isOpen
      title={t('Remove supplier')}
      variant="large"
    >
      <ContentWrapperStyled>
        <Paragraph alignment="center" margins={[2, 0, 5]}>
          {t(
            'Are you sure you want to remove this supplier from this negotiation?'
          )}
        </Paragraph>
        <DefaultCheckbox
          id="isRemoveConfirmed"
          checked={isRemoveConfirmed}
          onChange={() => setIsRemoveConfirmed((prevState) => !prevState)}
        >
          <Text bold>{t('Yes I want to remove this supplier')}</Text>
        </DefaultCheckbox>
        <FlexContainer direction="column" align="center" mt={5} gap={2}>
          <Button variant="outline" onClick={onDialogClose}>
            {t('Cancel')}
          </Button>
          <Button
            disabled={!isRemoveConfirmed}
            loading={removeSupplierFromPlannedNegotiationMutation.isPending}
            onClick={() => {
              removeSupplierFromPlannedNegotiationMutation.mutate(
                { selectedSuppliers: [supplierId], negotiationId },
                {
                  onSuccess: onDialogClose,
                }
              );
            }}
          >
            {t('Remove supplier')}
          </Button>
        </FlexContainer>
      </ContentWrapperStyled>
    </Dialog>
  );
};

const ContentWrapperStyled = styled('div')({
  maxWidth: 370,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
});
