import styled from 'styled-components';

import { white } from '@/modules/theme/utils/colors';

type BadgeStyledProps = {
  color: string;
  width: number | 'auto';
};

export const BadgeStyled = styled('div')<BadgeStyledProps>(
  ({ theme: { borderRadius, spacing, typography }, color, width }) => ({
    backgroundColor: color,
    borderRadius: borderRadius.default,
    fontWeight: typography.fontWeight.bold,
    color: white,
    fontSize: typography.pxToRem(16),
    padding: spacing([1, 1.5]),
    lineHeight: 1,
    whiteSpace: 'nowrap',
    width: width,
    textAlign: 'center',
  })
);
