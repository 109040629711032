import styled from 'styled-components';

import { Button } from '@/modules/theme/components/Button/Button';
import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';

export const AddSupplierButtonStyled = styled(Button)(
  ({ theme: { spacing } }) => ({
    minHeight: 44,
    padding: spacing([1, 2]),
    width: 173,
  })
);

export const SkeletonLoaderStyled = styled(SkeletonLoader)({
  height: 24,
  width: 100,
  marginBottom: 0,
});
