import styled from 'styled-components';

import { Button } from '@/modules/theme/components/Button/Button';

export const FormStyled = styled('div')(({ theme: { spacing } }) => ({
  width: spacing(38),
}));

export const ButtonStyled = styled(Button)(({ theme: { spacing } }) => ({
  marginTop: spacing(2),
}));
