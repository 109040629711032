import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as sup from 'superstruct';

import { DateInput } from '@/modules/form/components/DateInput/DateInput';
import { useForm } from '@/modules/form/components/Form/hooks/useForm/useForm';
import {
  isFutureDate,
  isValidEndDate,
  notEmpty,
} from '@/modules/form/utils/structs';
import {
  getNextYearDate,
  mapDateToDateInputString,
} from '@/modules/negotiation/utils/dateUtils';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { Paragraph } from '@/modules/theme/components/Typography';
import { recompose } from '@/utils/structs/recompose';

import { useSetNegotiationContractPeriodMutation } from '../useSetNegotiationContractPeriodMutation';

import {
  DialogStyled,
  SubmitButtonStyled,
} from './SetNegotiationContractPeriodDialog.styled';

type ContractPeriod = {
  startDate: string | null;
  endDate: string | null;
};

type SetNegotiationContractPeriodDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  contractPeriod: ContractPeriod;
  negotiationId: string;
  termId: string;
};

type SetNegotiationContractPeriod = {
  startDate: string;
  endDate: string;
};

type SetNegotiationContractPeriodStruct =
  sup.Describe<SetNegotiationContractPeriod>;

const setNegotiationContractPeriodStruct = sup.object({
  startDate: recompose(sup.string(), notEmpty, isFutureDate),
  endDate: recompose(
    sup.string(),
    isValidEndDate<SetNegotiationContractPeriodStruct, string>('startDate'),
    notEmpty
  ),
});

export const SetNegotiationContractPeriodDialog = ({
  isOpen,
  onClose,
  contractPeriod,
  negotiationId,
  termId,
}: SetNegotiationContractPeriodDialogProps) => {
  const { t } = useTranslation('pages/Terms');

  const formProps = useForm({
    struct: setNegotiationContractPeriodStruct,
    defaultValues: {
      startDate: contractPeriod.startDate || '',
      endDate: contractPeriod.endDate || '',
    },
  });

  const setNegotiationContractPeriodMutation =
    useSetNegotiationContractPeriodMutation();

  const { register, watch, setValue, handleSubmit, trigger, formState } =
    formProps;

  const errorMessages = {
    startDate: {
      'string.notEmpty': t('This field is required'),
      'string.isFutureDate': t('You can pick only future date'),
    },
    endDate: {
      'string.notEmpty': t('This field is required'),
      'string.isValidEndDate': t(
        'End date cannot be set before the start date'
      ),
    },
  };

  const startDate = watch('startDate');
  const endDate = watch('endDate');

  useEffect(() => {
    if (startDate && !endDate && !formState.touchedFields.endDate) {
      const nextYearDate = getNextYearDate(new Date(startDate));
      setValue('endDate', mapDateToDateInputString(nextYearDate));
      void trigger('endDate');
    }
  }, [endDate, setValue, trigger, startDate, formState.touchedFields.endDate]);

  const setNegotiationContractPeriod = (startDate: string, endDate: string) => {
    setNegotiationContractPeriodMutation.mutate(
      {
        negotiationId,
        termId,
        startDate,
        endDate,
      },
      { onSuccess: onClose }
    );
  };

  return (
    <DialogStyled
      isOpen={isOpen}
      onClose={onClose}
      hasCloseIcon
      title={t('Set start & end date')}
    >
      <Paragraph alignment="center" margins={[3, 0, 4, 0]}>
        {t(
          'Select the period for the terms of your negotiation to apply. Based on standards, the default period is 12 months.'
        )}
      </Paragraph>
      <FormProvider {...formProps}>
        <form
          onSubmit={handleSubmit(({ startDate, endDate }) =>
            setNegotiationContractPeriod(startDate, endDate)
          )}
          noValidate
        >
          <FlexContainer direction="column" mb={7}>
            <DateInput
              {...register('startDate', {
                onChange: () => void trigger('endDate'),
              })}
              label={t('Start date')}
              errorMessages={errorMessages.startDate}
              min={mapDateToDateInputString(new Date())}
            />
            <DateInput
              {...register('endDate')}
              label={t('End date')}
              errorMessages={errorMessages.endDate}
              min={startDate && mapDateToDateInputString(new Date(startDate))}
            />
          </FlexContainer>
          <SubmitButtonStyled
            type="submit"
            loading={setNegotiationContractPeriodMutation.isPending}
          >
            {t('Save')}
          </SubmitButtonStyled>
        </form>
      </FormProvider>
    </DialogStyled>
  );
};
