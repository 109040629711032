import * as sup from 'superstruct';
import { AnyStruct, InferStructTuple } from 'superstruct/dist/utils';

// `includedStruct` allows the union of objects with unexpected properties
// to be correctly masked and validated by superstruct.

// When using built-in:
// `sup.array(sup.union([struct1, struct2])`
// when `struct1` has e.g. additional, unexpected field, validation will
// throw an error despite using `.mask`.

// We could use `sup.type`, but it does not remove redundant fields.
// When `...spread` is used somewhere, e.g. in request body,
// unexpected fields may appear in runtime causing errors.

export const includedStruct = <A extends AnyStruct, B extends AnyStruct[]>(
  resourceStructs: [A, ...B]
) => {
  const typeStructs = resourceStructs.map((struct) => {
    const resourceStruct = struct as sup.Describe<{ type: string }>;

    if (!resourceStruct.schema.type) {
      throw new Error('Invalid resource struct');
    }

    return resourceStruct.schema.type;
  });

  const baseStructs = typeStructs.map((typeStruct) =>
    sup.type({ type: typeStruct })
  );

  const baseStruct = sup.type({
    type: sup.union(
      typeStructs as [sup.Describe<string>, ...sup.Describe<string>[]]
    ),
  });

  return sup.coerce(
    sup.array(
      sup.dynamic((unknownResource) => {
        const resource = unknownResource as Record<string, unknown>;
        const resourceStructIndex = baseStructs.findIndex((baseStruct) =>
          baseStruct.is(unknownResource)
        );
        const resourceStruct = resourceStructs[resourceStructIndex];

        if (!resourceStruct) return baseStruct;

        // FIX: dynamic() doesn't respect the mask option
        for (const key in resource) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          if (resourceStruct.schema[key] === undefined) {
            delete resource[key];
          }
        }

        return resourceStruct;
      })
    ),
    sup.array(),
    (included) => included.filter((resource) => baseStruct.is(resource))
  ) as sup.Describe<(sup.Infer<A> | InferStructTuple<B>[number])[]>;
};
