import { FC } from 'react';
import styled from 'styled-components';

import { CommonTypographyProps } from './helpers/types';

export type TextProps = CommonTypographyProps & {
  variant?: 'extraSmall' | 'small' | 'normal' | 'large';
  bold?: boolean;
  className?: string;
};

export const Text: FC<TextProps> = ({ children, variant, ...props }) => {
  switch (variant) {
    case 'extraSmall':
      return <ExtraSmallTextStyled {...props}>{children}</ExtraSmallTextStyled>;
    case 'small':
      return <SmallTextStyled {...props}>{children}</SmallTextStyled>;
    case 'large':
      return <LargeTextStyled {...props}>{children}</LargeTextStyled>;
    default:
      return <StandardTextStyled {...props}>{children}</StandardTextStyled>;
  }
};

const ExtraSmallTextStyled = styled('span')<TextProps>(({
  theme: { typography, palette },
  color,
  bold,
}) => {
  return {
    color: color ? color : palette.primary.text,
    fontFamily: typography.fonts.body,
    fontSize: typography.fontSize.extraSmall,
    lineHeight: typography.fontSize.extraSmall,
    fontWeight: bold
      ? typography.fontWeight.bold
      : typography.fontWeight.normal,
    letterSpacing: typography.letterSpacing.default,
  };
});

const SmallTextStyled = styled('span')<TextProps>(({
  theme: { typography, palette },
  color,
  bold,
}) => {
  return {
    color: color ? color : palette.primary.text,
    fontFamily: typography.fonts.body,
    fontSize: typography.fontSize.small,
    lineHeight: typography.fontSize.small,
    fontWeight: bold
      ? typography.fontWeight.bold
      : typography.fontWeight.normal,
    letterSpacing: typography.letterSpacing.default,
  };
});

const StandardTextStyled = styled('span')<TextProps>(({
  theme: { typography, palette },
  color,
  bold,
}) => {
  return {
    color: color ? color : palette.primary.text,
    fontFamily: typography.fonts.body,
    fontSize: typography.fontSize.default,
    lineHeight: typography.fontSize.large,
    fontWeight: bold
      ? typography.fontWeight.bold
      : typography.fontWeight.normal,
    letterSpacing: typography.letterSpacing.default,
  };
});

const LargeTextStyled = styled('span')<TextProps>(({
  theme: { typography, palette },
  color,
  bold,
}) => {
  return {
    color: color ? color : palette.primary.text,
    fontFamily: typography.fonts.body,
    fontSize: typography.fontSize.large,
    lineHeight: typography.fontSize.extraLarge,
    letterSpacing: typography.letterSpacing.default,
    fontWeight: bold
      ? typography.fontWeight.bold
      : typography.fontWeight.normal,
  };
});
