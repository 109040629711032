import { useQueryClient } from '@tanstack/react-query';

import { queryKeys } from '@/modules/api/queryKeys';
import { useUploadFileMutation } from '@/modules/customer/api/mutations/useUploadFileMutation';
import { noop } from '@/utils/noop';

import { SerializedSpendAndQuestionnaireValues } from '../helpers/serializeQuestionnaireValues';

import { useCreateUploadSpendFileMutation } from './useCreateUploadSpendFileMutation';
import { useSubmitQuestionnaireMutation } from './useSubmitQuestionnaireMutation';

export const useUploadSpendAndQuestionnaire = () => {
  const createUploadSpendFileMutation = useCreateUploadSpendFileMutation();
  const uploadFileMutation = useUploadFileMutation();
  const submitQuestionnaireMutation = useSubmitQuestionnaireMutation();
  const queryClient = useQueryClient();

  const useGetSpendFileQueryState = queryClient.getQueryState([
    queryKeys.user.spendFile.getSpendFiles,
  ]);

  const isGetSpendFileQueryFetching =
    useGetSpendFileQueryState?.fetchStatus === 'fetching';

  type HandleUploadSpendAndQuestionnairePayload = {
    values: SerializedSpendAndQuestionnaireValues;
    onSuccess: () => void;
  };

  const handleUploadSpendAndQuestionnaire = async ({
    values,
    onSuccess,
  }: HandleUploadSpendAndQuestionnairePayload) => {
    const { spendFile, ...questionnaireValues } = values;

    try {
      const createUploadResult =
        await createUploadSpendFileMutation.mutateAsync({
          file: spendFile,
        });

      if (createUploadResult) {
        const spendFileId = createUploadResult.data.id;
        const { url, headers } = createUploadResult.data.meta;

        await uploadFileMutation.mutateAsync({
          url,
          headers,
          file: spendFile,
        });

        await submitQuestionnaireMutation.mutateAsync({
          spendFileId,
          questionnaireValues,
        });

        await queryClient.invalidateQueries({
          queryKey: [queryKeys.user.spendFile.getSpendFiles],
          refetchType: 'all',
        });

        onSuccess();
      }
    } catch (err) {
      noop();
    }
  };

  const isPending =
    createUploadSpendFileMutation.isPending ||
    uploadFileMutation.isPending ||
    submitQuestionnaireMutation.isPending ||
    isGetSpendFileQueryFetching;

  return { handleUploadSpendAndQuestionnaire, isPending };
};
