import styled from 'styled-components';

import { Button } from '@/modules/theme/components/Button/Button';

export const ButtonStyled = styled(Button)(({ theme: { spacing } }) => ({
  padding: spacing([2, 3]),
}));

export const ButtonLinksWrapperStyled = styled('div')(
  ({ theme: { spacing, breakpoints } }) => ({
    display: 'flex',
    gap: spacing(2),
    marginLeft: 'auto',

    [breakpoints.down.md]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      marginLeft: 0,
    },
  })
);
