import * as sup from 'superstruct';

export const basicErrorResponseValidator = sup.type({
  response: sup.type({
    data: sup.object({
      errors: sup.array(
        sup.type({
          code: sup.string(),
        })
      ),
    }),
    status: sup.integer(),
  }),
});

export type BasicApiError = sup.Infer<typeof basicErrorResponseValidator>;
