import styled from 'styled-components';

type NegotiationMetricProps = {
  amount: string | number;
  label: string;
  className?: string;
};

export const NegotiationMetric = ({
  amount,
  label,
  className,
}: NegotiationMetricProps) => {
  return (
    <NegotiationMetricWrapper className={className}>
      <AmountStyled>{amount}</AmountStyled>
      <AmountLabelStyled>{label}</AmountLabelStyled>
    </NegotiationMetricWrapper>
  );
};

const NegotiationMetricWrapper = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: spacing(0.5),
}));

const AmountStyled = styled('p')(({ theme: { typography } }) => ({
  fontSize: typography.fontSize.large,
  fontWeight: typography.fontWeight.bold,
  lineHeight: 1.5,
}));

const AmountLabelStyled = styled('span')(({ theme: { typography } }) => ({
  fontSize: typography.pxToRem(10),
  fontWeight: typography.fontWeight.bold,
}));
