import { memo, ReactNode } from 'react';

import { Footer } from '@/layouts/common/footer/Footer';

import {
  AuthenticatedLayoutWrapperStyled,
  FlexContainerStyled,
  MainContentWrapperStyled,
} from './AuthenticatedLayout.styled';
import { Sidebar } from './components/Sidebar/Sidebar';

type AuthenticatedLayoutProps = {
  children: ReactNode;
};

export const AuthenticatedLayout = memo(
  ({ children }: AuthenticatedLayoutProps) => {
    return (
      <AuthenticatedLayoutWrapperStyled>
        <FlexContainerStyled>
          <Sidebar />
          <MainContentWrapperStyled>{children}</MainContentWrapperStyled>
        </FlexContainerStyled>
        <Footer />
      </AuthenticatedLayoutWrapperStyled>
    );
  }
);
