import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { queryKeys, sortArrayQueryKey } from '@/modules/api/queryKeys';
import { getNegotiationsQuery } from '@/modules/negotiation/api/queries/getNegotiationsQuery';
import {
  GetNegotiationsQueryOptions,
  GetNegotiationsQueryParams,
} from '@/modules/negotiation/api/resources/negotiation/negotiationResourceConsts';
import { NegotiationsRes } from '@/modules/negotiation/utils/parseGetNegotiationsResponse';
import { constructMeta } from '@/modules/reactQuery/constructMeta';

import { DnDNegotiation } from '../components/types';

export const useGetNegotiationsQuery = (
  params: GetNegotiationsQueryParams,
  options?: GetNegotiationsQueryOptions
) => {
  const jsonApi = useJsonApi();
  const { t } = useTranslation('apiMessages/useGetNegotiationsQuery');

  return useQuery({
    queryKey: [
      queryKeys.negotiations.getNegotiations,
      params.pageNumber,
      params.pageSize,
      sortArrayQueryKey(params.states),
    ],
    queryFn: getNegotiationsQuery(jsonApi, params),
    select: (response) => parseSuggestedNegotiations(response),
    ...options,
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t("You don't have access to view negotiations"),
        },
      },
    }),
  });
};

const parseSuggestedNegotiations = (
  response: NegotiationsRes
): DnDNegotiation[] => {
  return response.negotiationsList.map(
    ({
      id,
      periodIndex,
      metrics,
      title,
      termRoles,
      createdAt,
      periodOrder,
      negotiationTypes,
    }) => ({
      id,
      periodIndex,
      periodOrder,
      createdAt,
      title,
      ...(metrics && {
        metrics: {
          suppliersCount: metrics.suppliersCount,
          potentialSavings: metrics.potentialSavings,
          potentialCostAvoidance: metrics.potentialCostAvoidance,
        },
      }),
      termRoles,
      negotiationTypes,
    })
  );
};
