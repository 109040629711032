import { useTranslation } from 'react-i18next';

import { RichContentTerm } from '@/modules/negotiation/negotiationTerms/NegotiationTerms/components/RichContentTerm';
import { NegotiationContractPeriodTerm } from '@/modules/negotiation/types/negotiationTypes';
import { setupNegotiationRoutes } from '@/modules/router/onboardedRoutes';
import { useDialogState } from '@/utils/useDialogState';

import { AddTermButton } from '../AddTermButton/AddTermButton';
import { NegotiationTermCard } from '../NegotiationTerms/NegotiationTermCard';

import { RemoveNegotiationContractPeriodDialog } from './RemoveNegotiationContractPeriodDialog';
import { SetNegotiationContractPeriodDialog } from './SetNegotiationContractPeriodDialog/SetNegotiationContractPeriodDialog';

type NegotiationContractPeriodProps = {
  term: NegotiationContractPeriodTerm;
};

export const NegotiationContractPeriod = ({
  term,
}: NegotiationContractPeriodProps) => {
  const { t } = useTranslation('pages/Terms');

  const setContractPeriodDialog = useDialogState();
  const removeContractPeriodDialog = useDialogState();

  const { negotiationId } = setupNegotiationRoutes.useGetRouteParams('terms');

  const { startDate, endDate, text } = term;

  return (
    <>
      {!startDate || !endDate ? (
        <AddTermButton onClick={setContractPeriodDialog.open}>
          {t('Add period for terms +')}
        </AddTermButton>
      ) : (
        <NegotiationTermCard
          onEdit={setContractPeriodDialog.open}
          onRemove={removeContractPeriodDialog.open}
        >
          <RichContentTerm text={text} />
        </NegotiationTermCard>
      )}
      <SetNegotiationContractPeriodDialog
        contractPeriod={{ startDate, endDate }}
        isOpen={setContractPeriodDialog.isOpen}
        onClose={setContractPeriodDialog.close}
        termId={term.id}
        negotiationId={negotiationId}
      />
      <RemoveNegotiationContractPeriodDialog
        isOpen={removeContractPeriodDialog.isOpen}
        onClose={removeContractPeriodDialog.close}
        negotiationId={negotiationId}
        termId={term.id}
      />
    </>
  );
};
