import qs from 'qs';
import { useTranslation } from 'react-i18next';

import { plannedNegotiationRoutes } from '@/modules/router/onboardedRoutes';
import { defaultSuppliersQueryParams } from '@/modules/suppliers/helpers/defaultSuppliersQueryParams';
import { Tab } from '@/modules/theme/components/Tabs/Tabs';

export const useTabItems = () => {
  const { t } = useTranslation('layouts/AuthenticatedLayout');
  const tabItems: Tab[] = [
    {
      link: plannedNegotiationRoutes.config.suppliers.nestedPath,
      text: t('Suppliers'),
      query: qs.stringify(defaultSuppliersQueryParams),
      end: true,
    },
    {
      link: plannedNegotiationRoutes.config.terms.nestedPath,
      text: t('Terms'),
    },
  ];

  return tabItems;
};
