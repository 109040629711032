import { forwardRef, ReactNode, useState } from 'react';
import { CurrencyInputProps as ReactCurrencyInputProps } from 'react-currency-input-field';

import {
  InputErrorMessage,
  InputErrorMessageProps,
} from '@/modules/form/components/ErrorMessage/InputErrorMessage';
import { InputError } from '@/modules/form/components/InputError';
import { LabelStyled } from '@/modules/theme/components/Typography';

import {
  AdornmentWrapperStyled,
  InputStyled,
  InputWrapperStyled,
  CurrencyInputSkeletonLoader,
  CurrencyInputWrapperStyled,
} from './CurrencyInput.styled';

export type ReactInputProps = Omit<ReactCurrencyInputProps, 'ref'>;

export type CurrencyInputProps = ReactInputProps &
  InputErrorMessageProps & {
    label?: ReactNode;
    startAdornment?: ReactNode;
    endAdornment?: ReactNode;
    isLoading?: boolean;
    errorHidden?: boolean;
    labelHidden?: boolean;
  };

export const CurrencyInput = forwardRef<HTMLInputElement, CurrencyInputProps>(
  (
    {
      name,
      label,
      error,
      errorMessages,
      ErrorMessage,
      startAdornment,
      endAdornment,
      disabled,
      isLoading,
      errorHidden = false,
      labelHidden = false,
      ...props
    },
    ref
  ) => {
    const [appliedError, setAppliedError] = useState('');

    const handleErrorChange = (error: string) => {
      setAppliedError(error);
    };

    return (
      <CurrencyInputWrapperStyled errorHidden={errorHidden}>
        {!labelHidden && (
          <LabelStyled htmlFor={name} appliedError={appliedError}>
            {label}
          </LabelStyled>
        )}
        {isLoading ? (
          <CurrencyInputSkeletonLoader height={42} />
        ) : (
          <InputWrapperStyled
            appliedError={Boolean(appliedError)}
            disabled={disabled}
          >
            {startAdornment && (
              <AdornmentWrapperStyled variant="start">
                {startAdornment}
              </AdornmentWrapperStyled>
            )}
            <InputStyled
              ref={ref}
              id={name}
              name={name}
              disabled={disabled}
              {...props}
            />
            {endAdornment && (
              <AdornmentWrapperStyled variant="end">
                {endAdornment}
              </AdornmentWrapperStyled>
            )}
          </InputWrapperStyled>
        )}
        {!errorHidden && (
          <InputError errorHidden={Boolean(!appliedError)}>
            <InputErrorMessage
              name={name}
              error={error}
              errorMessages={errorMessages}
              ErrorMessage={ErrorMessage}
              onErrorChange={handleErrorChange}
            />
          </InputError>
        )}
      </CurrencyInputWrapperStyled>
    );
  }
);
