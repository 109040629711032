import { useState } from 'react';
import { useCookies } from 'react-cookie';

import { ANALYTICS_COOKIE_NAME, TOKEN_COOKIE_NAME } from './consts';
import { CookieConsentPopup } from './CookieConsentPopup';
import { CookieConsentSettings } from './CookieConsentSettings';

export const CookieConsentDialog = () => {
  const [cookies, setCookie] = useCookies();
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const allowWPCookie = () => {
    setCookie(TOKEN_COOKIE_NAME, 'true', {
      path: '/',
      maxAge: 31556926,
      sameSite: 'strict',
    });
  };

  const allowAnalysisCookie = () => {
    setCookie(ANALYTICS_COOKIE_NAME, 'true', {
      path: '/',
      maxAge: 31556926,
      sameSite: 'strict',
    });
  };

  const handleOnCookiesAllow = () => {
    allowWPCookie();
    allowAnalysisCookie();
  };

  const handleSaveSettings = (isAnalysisCookieAccepted: boolean) => {
    if (isAnalysisCookieAccepted) {
      allowAnalysisCookie();
    }
    allowWPCookie();
    setIsSettingsOpen(false);
  };

  return (
    <>
      {isSettingsOpen ? (
        <CookieConsentSettings
          isOpen={isSettingsOpen}
          onClickSave={handleSaveSettings}
          onClickCancel={() => setIsSettingsOpen(false)}
        />
      ) : (
        <CookieConsentPopup
          onClickAllow={handleOnCookiesAllow}
          onClickSettings={() => setIsSettingsOpen(true)}
          cookies={cookies}
        />
      )}
    </>
  );
};
