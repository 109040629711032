import styled from 'styled-components';

import { Button } from '@/modules/theme/components/Button/Button';
import { Dialog } from '@/modules/theme/components/Dialog/Dialog';
import { FlexLayout } from '@/modules/theme/components/FlexLayout';
import { Heading } from '@/modules/theme/components/Typography';

export const ContentWrapperStyled = styled(FlexLayout)({
  width: '100%',
});

export const DialogStyled = styled(Dialog)(({ theme: { breakpoints } }) => ({
  width: 1300,
  [breakpoints.down.lg]: {
    width: '90%',
  },
}));

export const HeadingStyled = styled(Heading)(({ theme: { spacing } }) => ({
  marginBottom: spacing(4),
  textAlign: 'left',
  width: '100%',
}));

export const CloseButtonStyled = styled(Button)(({ theme: { spacing } }) => ({
  display: 'block',
  margin: `${spacing(7)} auto 0`,
}));
