import { AxiosInstance, AxiosRequestConfig } from 'axios';

export function createJsonApiV2Client(axios: AxiosInstance) {
  return {
    async get({
      path,
      queryParams,
      axiosConfig,
    }: {
      path: string;
      queryParams?: Record<string, unknown>;
      axiosConfig?: AxiosRequestConfig;
    }) {
      const { data } = await axios.get<unknown>(path, {
        params: queryParams,
        ...axiosConfig,
      });
      return data;
    },
  };
}
export type JsonApiV2Client = ReturnType<typeof createJsonApiV2Client>;
