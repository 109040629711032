import { IAllProps as TinyMceEditorProps } from '@tinymce/tinymce-react';

export const EDITOR_MIN_HEIGHT = 500;

export const editorConfig: TinyMceEditorProps['init'] = {
  resize: true,
  min_height: EDITOR_MIN_HEIGHT,
  menubar: false,
  plugins: ['anchor', 'autoresize', 'link', 'lists'],
  // https://www.tiny.cloud/legal/attribution-requirements/
  // if we need to disable branding it's technically possible,
  // but keeping the attribution is welcome.
  // branding: false,
  toolbar_mode: 'wrap',
  entity_encoding: 'raw',
  toolbar:
    'bold italic strikethrough forecolor |  fontsize blocks | ' +
    'undo redo | alignleft aligncenter ' +
    'alignright alignjustify | bullist numlist outdent indent | ' +
    'link removeformat',
  font_size_formats: '8px 10px 12px 14px 16px 18px 24px 36px',
  content_style:
    'body { font-family: Arial,Helvetica,sans-serif; font-size:16px }',
  elementpath: false,
};
