import { forwardRef, useState } from 'react';

import { TextInput, TextInputProps } from '../TextInput/TextInput';

import { ToggleVisibilityButton } from './ToggleVisibilityButton';

export const PasswordInput = forwardRef<HTMLInputElement, TextInputProps>(
  (props, ref) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    return (
      <TextInput
        {...props}
        ref={ref}
        type={isPasswordVisible ? 'text' : 'password'}
        endAdornment={
          <ToggleVisibilityButton
            isVisible={isPasswordVisible}
            togglePasswordVisibility={() => {
              setIsPasswordVisible((prev) => !prev);
            }}
          />
        }
      />
    );
  }
);
