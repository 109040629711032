import { useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { GroupBase } from 'react-select';
import styled from 'styled-components';

import { InputErrorMessage } from '../ErrorMessage/InputErrorMessage';
import { InputError } from '../InputError';

import { ClearIndicator } from './components/ClearIndicator';
import { Highlighter } from './components/Highlighter';
import { IndicatorsContainer } from './components/IndicatorsContainer';
import { MenuList } from './components/MenuList';
import { ValueContainer } from './components/ValueContainer';
import { findSelectValue } from './findSelectValue';
import { SelectInput } from './SelectInput/SelectInput';
import { BasicOption, FormSelectProps } from './types';

export function SearchSelect<
  Option extends BasicOption = BasicOption,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  name,
  label,
  error,
  errorMessages,
  isLoading,
  ErrorMessage,
  onErrorChange,
  components,
  options,
  ...props
}: FormSelectProps<Option, false, Group>) {
  const { trigger, control } = useFormContext();
  const { field } = useController({
    name,
    control,
  });

  const [selectError, setSelectError] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const onSelectErrorChange = (err: string) => {
    setSelectError(err);
    onErrorChange && onErrorChange(err);
  };

  return (
    <SelectWrapperStyled>
      <SelectInput
        {...props}
        name={name}
        options={options}
        label={label}
        isMulti={false}
        isClearable={!searchValue}
        inputValue={searchValue}
        loading={isLoading}
        onInputChange={(inputValue) => {
          setSearchValue(inputValue);
        }}
        isSearchable
        formatOptionLabel={({ label }, { inputValue }) => {
          return <Highlighter sentence={label} keyword={inputValue} />;
        }}
        components={{
          DropdownIndicator: () => null,
          ClearIndicator,
          ValueContainer,
          MenuList,
          IndicatorsContainer: (props) => (
            <IndicatorsContainer
              {...props}
              searchValue={searchValue}
              clearSearchValue={() => setSearchValue('')}
            />
          ),
          ...components,
        }}
        onBlur={async () => await trigger(name)}
        onChange={(selectedOption) =>
          field.onChange(selectedOption?.value || null)
        }
        error={selectError}
        showErrorMessage={false}
        defaultValue={findSelectValue({ currentValue: field.value, options })}
      />
      <InputError>
        <InputErrorMessage
          name={name}
          error={error}
          errorMessages={errorMessages}
          ErrorMessage={ErrorMessage}
          onErrorChange={onSelectErrorChange}
        />
      </InputError>
    </SelectWrapperStyled>
  );
}

export const SelectWrapperStyled = styled('div')({
  position: 'relative',
});
