import { useTranslation } from 'react-i18next';
import ReactSelect, {
  GroupBase,
  components,
  Props as ReactSelectProps,
} from 'react-select';
import { useTheme } from 'styled-components';

import { BasicOption } from '@/modules/form/components/Select/types';

import { createShowPerPageDropdownStyles } from './createShowPerPageDropdownStyles';

type ShowPerPageDropdownProps = {
  selectedPageSize: number;
  onPageSizeChange: (newValue: number) => void;
};

export function ShowPerPageDropdown<
  Option extends BasicOption = BasicOption,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  name,
  onChange,
  options,
  isLoading,
  selectedPageSize,
  onPageSizeChange,
  ...props
}: ReactSelectProps<Option, false, Group> & ShowPerPageDropdownProps) {
  const { t } = useTranslation('pages/SuppliersPage');
  const theme = useTheme();

  const styles = createShowPerPageDropdownStyles<Option, false, Group>({
    theme,
  });

  return (
    <ReactSelect
      {...props}
      styles={styles}
      name="showPerPage"
      isSearchable={false}
      options={options}
      formatOptionLabel={(data) =>
        t('Show {{suppliersCount}} per page', {
          suppliersCount: data && 'value' in data ? data.value : 0,
        })
      }
      components={{
        ...components,
        IndicatorSeparator: () => null,
        Option: ({ data, ...props }) => (
          <components.Option data={data} {...props}>
            {data.value}
          </components.Option>
        ),
      }}
      onChange={(option) => {
        if (option && 'value' in option) {
          onPageSizeChange(Number(option.value));
        }
      }}
    />
  );
}
