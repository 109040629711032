import { useTranslation } from 'react-i18next';

import { useSessionContext } from '@/modules/auth/hooks/useSessionContext';
import { negotiationMetrics } from '@/modules/negotiation/metrics/consts';
import { ParsedNegotiationItem } from '@/modules/negotiation/types/negotiationTypes';
import { formatPercentage } from '@/modules/negotiation/utils/formatPercentage';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { Paragraph } from '@/modules/theme/components/Typography';
import { formatAmount } from '@/utils/stringUtils';

import { NegotiationCardLayout } from '../NegotiationCardLayout/NegotiationCardLayout';

import { getNegotiationCardMetrics } from './getNegotiationCardMetrics';
import { DataWrapperStyled, NumberStyled } from './NegotiationCard.styled';

type NegotiationAfterStartCardProps = {
  negotiation: ParsedNegotiationItem;
  link?: string;
};

export const NegotiationAfterStartCard = ({
  negotiation,
  link,
}: NegotiationAfterStartCardProps) => {
  const { t } = useTranslation('pages/DashboardPage');
  const {
    customer: { currency },
  } = useSessionContext();

  const { metrics } = negotiation;
  const metricsToDisplay = getNegotiationCardMetrics(negotiation);

  const averageDiscount = (metrics?.averageDiscount || 0) * 100;

  return (
    <NegotiationCardLayout link={link} negotiation={negotiation}>
      <FlexContainer wrap="wrap" columnGap={15}>
        {metricsToDisplay.has(negotiationMetrics.negotiatedSpend) && (
          <DataWrapperStyled>
            <NumberStyled>
              {formatAmount(metrics?.negotiatedSpend || 0)}
            </NumberStyled>
            <Paragraph variant="contentBold">
              {t('Negotiated spend ({{currency}})', {
                currency,
              })}
            </Paragraph>
          </DataWrapperStyled>
        )}

        {metricsToDisplay.has(negotiationMetrics.negotiatedCostAvoidance) && (
          <DataWrapperStyled>
            <NumberStyled>
              {formatAmount(metrics?.negotiatedCostAvoidance || 0)}
            </NumberStyled>
            <Paragraph variant="contentBold">
              {t('Negotiated cost avoidance ({{currency}})', {
                currency,
              })}
            </Paragraph>
          </DataWrapperStyled>
        )}

        {metricsToDisplay.has(negotiationMetrics.discountCount) && (
          <DataWrapperStyled>
            <NumberStyled>{metrics?.discountCount}</NumberStyled>
            <Paragraph variant="contentBold">{t('Discounts')}</Paragraph>
          </DataWrapperStyled>
        )}

        {metricsToDisplay.has(negotiationMetrics.negotiatedSavings) && (
          <DataWrapperStyled>
            <NumberStyled>
              {formatAmount(metrics?.negotiatedSavings || 0)}
            </NumberStyled>
            <Paragraph variant="contentBold">
              {t('Money saved ({{currency}})', {
                currency,
              })}
            </Paragraph>
          </DataWrapperStyled>
        )}

        {metricsToDisplay.has(negotiationMetrics.averageDiscount) && (
          <DataWrapperStyled>
            <NumberStyled>
              {t('{{averageDiscount}}%', {
                averageDiscount: formatPercentage(averageDiscount),
              })}
            </NumberStyled>
            <Paragraph variant="contentBold">{t('Avg. discount')}</Paragraph>
          </DataWrapperStyled>
        )}

        {metricsToDisplay.has(
          negotiationMetrics.acceptedTermCounts.signCodeOfConduct
        ) && (
          <DataWrapperStyled>
            <NumberStyled>
              {metrics?.acceptedTermCounts.signCodeOfConduct}
            </NumberStyled>
            <Paragraph variant="contentBold">
              {t('Accepted code of conduct')}
            </Paragraph>
          </DataWrapperStyled>
        )}

        {metricsToDisplay.has(
          negotiationMetrics.acceptedTermCounts.lockPrice
        ) && (
          <DataWrapperStyled>
            <NumberStyled>{metrics?.acceptedTermCounts.lockPrice}</NumberStyled>
            <Paragraph variant="contentBold">{t('Locked prices')}</Paragraph>
          </DataWrapperStyled>
        )}

        {metricsToDisplay.has(
          negotiationMetrics.acceptedTermCounts.answerSustainabilityQuestion
        ) && (
          <DataWrapperStyled>
            <NumberStyled>
              {metrics?.acceptedTermCounts.answerSustainabilityQuestion}
            </NumberStyled>
            <Paragraph variant="contentBold">
              {t('Answered questionnaires')}
            </Paragraph>
          </DataWrapperStyled>
        )}

        {metricsToDisplay.has(
          negotiationMetrics.acceptedTermCounts.extendInvoiceDueDate
        ) && (
          <DataWrapperStyled>
            <NumberStyled>
              {metrics?.acceptedTermCounts.extendInvoiceDueDate}
            </NumberStyled>
            <Paragraph variant="contentBold">
              {t('Accepted payment days')}
            </Paragraph>
          </DataWrapperStyled>
        )}

        {metricsToDisplay.has(
          negotiationMetrics.acceptedTermCounts.extendContractPeriod
        ) && (
          <DataWrapperStyled>
            <NumberStyled>
              {metrics?.acceptedTermCounts.extendContractPeriod}
            </NumberStyled>
            <Paragraph variant="contentBold">
              {t('Accepted prolongation')}
            </Paragraph>
          </DataWrapperStyled>
        )}

        {metricsToDisplay.has(negotiationMetrics.registeredCount) && (
          <DataWrapperStyled>
            <NumberStyled>{metrics?.registeredCount}</NumberStyled>
            <Paragraph variant="contentBold">{t('Registered')}</Paragraph>
          </DataWrapperStyled>
        )}

        {metricsToDisplay.has(negotiationMetrics.visitedCount) && (
          <DataWrapperStyled>
            <NumberStyled>{metrics?.visitedCount}</NumberStyled>
            <Paragraph variant="contentBold">{t('Visited')}</Paragraph>
          </DataWrapperStyled>
        )}
      </FlexContainer>
    </NegotiationCardLayout>
  );
};
