import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as sup from 'superstruct';

import { AuthContentLayout } from '@/layouts/AuthContentLayout/AuthContentLayout';
import { Form } from '@/modules/form/components/Form/Form';
import { PasswordInput } from '@/modules/form/components/PasswordInput/PasswordInput';
import {
  areEqual,
  notEmpty,
  passwordPattern,
} from '@/modules/form/utils/structs';
import { PageMeta } from '@/modules/theme/components/PageMeta';
import { recompose } from '@/utils/structs/recompose';

import { ConfirmResetPasswordDialog } from './components/ConfirmResetPasswordDialog';
import { useResetPasswordMutation } from './hooks/useResetPasswordMutation';
import {
  ButtonStyled,
  TextInputWrapperStyled,
} from './ResetPasswordPage.styled';

export type ResetPasswordValues = {
  password: string;
  repeatedPassword: string;
};
type ResetPasswordStruct = sup.Describe<ResetPasswordValues>;

const resetPasswordStruct = sup.object({
  password: recompose(sup.string(), notEmpty, passwordPattern),
  repeatedPassword: recompose(
    sup.string(),
    notEmpty,
    areEqual<ResetPasswordStruct, string>('password')
  ),
});

type ResetPasswordPageProps = {
  verificationId: string;
  userId: string;
};

export const ResetPasswordPage = ({
  userId,
  verificationId,
}: ResetPasswordPageProps) => {
  const { t } = useTranslation('pages/ResetPasswordPage');
  const [isConfirmResetPasswordDialogOpen, setConfirmResetPasswordDialogOpen] =
    useState(false);

  const resetPasswordMutation = useResetPasswordMutation({
    onResetPasswordSuccess: () => setConfirmResetPasswordDialogOpen(true),
  });

  const errorMessages = {
    password: {
      'string.notEmpty': t('This field is required'),
      'string.passwordPattern': t(
        'The password should be at least 6 characters long, have min. 1 uppercase letter and 1 digit.'
      ),
    },
    repeatedPassword: {
      'string.notEmpty': t('This field is required'),
      'string.areEqual': t('The password must match'),
    },
  };

  const onValidSubmit = ({ password }: ResetPasswordValues) => {
    if (verificationId && userId) {
      resetPasswordMutation.mutate({
        token: verificationId,
        id: userId,
        newPassword: password,
      });
    }
  };

  return (
    <AuthContentLayout title={t('Reset password')}>
      <PageMeta title={t('Reset password')} description="Reset your password" />
      <Form
        struct={resetPasswordStruct}
        defaultValues={{
          password: '',
          repeatedPassword: '',
        }}
        onValidSubmit={onValidSubmit}
      >
        {({ register, trigger, formState }) => (
          <>
            <TextInputWrapperStyled>
              <PasswordInput
                label={t('New password')}
                {...register('password')}
                onChange={() => {
                  if (!formState.touchedFields.repeatedPassword) return;
                  void trigger('repeatedPassword');
                }}
                errorMessages={errorMessages.password}
              />
            </TextInputWrapperStyled>
            <TextInputWrapperStyled>
              <PasswordInput
                label={t('Repeat new password,')}
                {...register('repeatedPassword')}
                errorMessages={errorMessages.repeatedPassword}
              />
            </TextInputWrapperStyled>
            <ButtonStyled
              type="submit"
              loading={resetPasswordMutation.isPending}
            >
              {t('Reset')}
            </ButtonStyled>
          </>
        )}
      </Form>
      <ConfirmResetPasswordDialog
        isOpen={isConfirmResetPasswordDialogOpen}
        onClose={() => setConfirmResetPasswordDialogOpen(false)}
      />
    </AuthContentLayout>
  );
};
