import { ReactNode, useEffect } from 'react';

import { NonBreakingSpace } from '@/modules/theme/components/NonBreakingSpace';

import { DefaultErrorMessage } from './DefaultErrorMessage';
import { ErrorMessageComponent } from './ErrorMessage';

export interface DisplayErrorMessageProps {
  error?: string;
  errorMessages?: Record<string, ReactNode | undefined>;
  className?: string;
  ErrorMessage?: ErrorMessageComponent;
  onErrorChange?: (error: string) => void;
}

export function DisplayErrorMessage(props: DisplayErrorMessageProps) {
  const {
    error,
    errorMessages = {},
    className,
    ErrorMessage = DefaultErrorMessage,
    onErrorChange,
  } = props;

  useEffect(() => {
    if (onErrorChange) {
      onErrorChange(error ? error : '');
    }
  }, [error, onErrorChange]);

  return error ? (
    <ErrorMessage
      error={error}
      errorMessage={errorMessages[error]}
      className={className}
    />
  ) : (
    <NonBreakingSpace />
  );
}
