import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { queryKeys, sortArrayQueryKey } from '@/modules/api/queryKeys';
import { constructMeta } from '@/modules/reactQuery/constructMeta';
import { getSuppliersQuery } from '@/modules/suppliers/api/queries/getSuppliersQuery';
import { GetSuppliersQueryParams } from '@/modules/suppliers/api/resources/supplier/supplierResourceConsts';

export const useGetSuppliersQuery = (params: GetSuppliersQueryParams) => {
  const jsonApi = useJsonApi();
  const { t } = useTranslation('apiMessages/useGetSuppliersQuery');

  return useQuery({
    queryKey: [
      queryKeys.suppliers.getSuppliers,
      params.pageNumber,
      params.pageSize,
      params.sort,
      sortArrayQueryKey(params.include),
    ],
    placeholderData: keepPreviousData,
    staleTime: 5000,
    queryFn: getSuppliersQuery(jsonApi, params),
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t("You don't have access to view suppliers"),
        },
      },
    }),
  });
};
