import { memo } from 'react';
import { Outlet } from 'react-router-dom';

import { CookieConsentDialog } from '@/layouts/common/CookieConsentDialog/CookieConsentDialog';
import { Footer } from '@/layouts/common/footer/Footer';

import {
  AnonymousLayoutWrapperStyled,
  FlexContainerStyled,
} from './AnonymousLayout.styled';

export const AnonymousLayout = memo(() => {
  return (
    <AnonymousLayoutWrapperStyled>
      <FlexContainerStyled>
        <Outlet />
      </FlexContainerStyled>
      <Footer />
      <CookieConsentDialog />
    </AnonymousLayoutWrapperStyled>
  );
});
