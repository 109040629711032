import { useTranslation } from 'react-i18next';

import { AuthenticatedLayout } from '@/layouts/AuthenticatedLayout/AuthenticatedLayout';
import { useSessionContext } from '@/modules/auth/hooks/useSessionContext';
import { PageHeader } from '@/modules/theme/components/PageHeader/PageHeader';
import { PageMeta } from '@/modules/theme/components/PageMeta';
import { Heading, Text } from '@/modules/theme/components/Typography';
import { blue, mint } from '@/modules/theme/utils/colors';
import { useDialogState } from '@/utils/useDialogState';

import { ChangePasswordDialog } from './components/ChangePasswordDialog/ChangePasswordDialog';
import {
  ButtonHeaderStyled,
  ButtonsWrapperStyled,
  ContentWrapperStyled,
  UserInfoStyled,
} from './ProfilePage.styled';

export function ProfilePage() {
  const { user, customer } = useSessionContext();

  const { t } = useTranslation('pages/ProfilePage');

  const changePasswordDialog = useDialogState();

  return (
    <AuthenticatedLayout>
      <PageMeta title={t('Profile')} />
      <PageHeader
        text={t('Profile')}
        buttonLink={
          <ButtonsWrapperStyled>
            <ButtonHeaderStyled
              variant="light"
              size="small"
              onClick={changePasswordDialog.open}
            >
              {t('Change password')}
            </ButtonHeaderStyled>
          </ButtonsWrapperStyled>
        }
      />
      <ContentWrapperStyled>
        <section>
          <UserInfoStyled>
            <Heading variant="h2">
              {user.firstName} {user.lastName}
            </Heading>
            <Text color={blue[800]} bold>
              {user.email}
            </Text>
            <Text color={mint[800]} bold>
              {customer.companyName}
            </Text>
          </UserInfoStyled>
        </section>
      </ContentWrapperStyled>

      <ChangePasswordDialog
        isOpen={changePasswordDialog.isOpen}
        userEmail={user.email}
        onClose={changePasswordDialog.close}
      />
    </AuthenticatedLayout>
  );
}
