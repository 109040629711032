import isPropValid from '@emotion/is-prop-valid';
import { ReactNode } from 'react';
import { StyleSheetManager, WebTarget } from 'styled-components';

type StyleSheetManagerProps = {
  children: ReactNode;
};

export const StyledComponentsStyleSheetManager = ({
  children,
}: StyleSheetManagerProps) => {
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      {children}
    </StyleSheetManager>
  );
};

// This implements the default behavior from styled-components v5
function shouldForwardProp(propName: string, target: WebTarget) {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
}
