import { useTranslation } from 'react-i18next';

import { AuthenticatedLayout } from '@/layouts/AuthenticatedLayout/AuthenticatedLayout';
import { NegotiationState } from '@/modules/negotiation/types/negotiationTypes';
import { launchedNegotiationStateFilters } from '@/modules/negotiation/utils/consts';
import { Box } from '@/modules/theme/components/Box';
import { PageHeader } from '@/modules/theme/components/PageHeader/PageHeader';
import { PageMeta } from '@/modules/theme/components/PageMeta';
import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';

import { CustomerMetricsPanel } from './components/CustomerMetricsPanel/CustomerMetricsPanel';
import { NegotiationAfterStartCard } from './components/NegotiationCards/NegotiationCard/NegotiationAfterStartCard';
import { NegotiationBeforeStartCard } from './components/NegotiationCards/NegotiationCard/NegotiationBeforeStartCard';
import { NegotiationStateFilter } from './components/NegotiationStateFilter/NegotiationStateFilter';
import { ContentWrapperStyled, LogoStyled } from './DashboardPage.styled';
import { useGetNegotiationsQuery } from './useGetNegotiationsQuery';
import { getNegotiationDetailsPath } from './utils/getNegotiationDetailsPath';
import { useGetCustomerMetricsQuery } from './utils/useGetCustomerMetricsQuery';

const pageNumber = 1;
const pageSize = 100;

type DashboardPageProps = {
  chosenNegotiationStates: NegotiationState[];
  setNegotiationState: (negotiationState: NegotiationState[]) => void;
};

export const DashboardPage = ({
  chosenNegotiationStates,
  setNegotiationState,
}: DashboardPageProps) => {
  const { t } = useTranslation('pages/DashboardPage');

  const toggleState = (pickedState: NegotiationState) => {
    const wasPicked = chosenNegotiationStates.includes(pickedState);

    const updatedNegotiationState = wasPicked
      ? chosenNegotiationStates.filter((filter) => filter !== pickedState)
      : [...chosenNegotiationStates, pickedState];

    setNegotiationState(updatedNegotiationState);
  };

  const { data: metrics, isPending: isMetricsPending } =
    useGetCustomerMetricsQuery();
  const { data, isPending, isPlaceholderData } = useGetNegotiationsQuery({
    // We use hard-coded params, as we don't have a design for
    // negotiations pagination and we were assured that there
    // wouldn't be more than 100 negotiations per account
    // in the near future
    pageNumber,
    pageSize,
    states: chosenNegotiationStates.length
      ? chosenNegotiationStates
      : launchedNegotiationStateFilters,
  });
  return (
    <AuthenticatedLayout>
      <PageMeta title={t('Dashboard')} description={t('Dashboard')} />
      <PageHeader text={t('Dashboard')} />
      <ContentWrapperStyled>
        <LogoStyled />
        <CustomerMetricsPanel metrics={metrics} isLoading={isMetricsPending} />
        <NegotiationStateFilter
          negotiationStateCounts={data?.negotiationStateCounts}
          isLoading={isPending}
          onStateToggle={toggleState}
          chosenStates={chosenNegotiationStates}
        />
        {isPending || isPlaceholderData ? (
          <SkeletonLoader lines={3} height={260} />
        ) : (
          data && (
            <Box mt={6}>
              {data.negotiationsList.map((negotiation) => {
                const hasNegotiationStarted =
                  negotiation.state === NegotiationState.COMPLETED ||
                  negotiation.state === NegotiationState.ACTIVE;
                return hasNegotiationStarted ? (
                  <NegotiationAfterStartCard
                    negotiation={negotiation}
                    link={getNegotiationDetailsPath(negotiation)}
                    key={negotiation.id}
                  />
                ) : (
                  <NegotiationBeforeStartCard
                    negotiation={negotiation}
                    link={getNegotiationDetailsPath(negotiation)}
                    key={negotiation.id}
                  />
                );
              })}
            </Box>
          )
        )}
      </ContentWrapperStyled>
    </AuthenticatedLayout>
  );
};
