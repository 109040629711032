import { useState } from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useForm } from '@/modules/form/components/Form/hooks/useForm/useForm';
import { HighlightOption } from '@/modules/form/components/Select/components/HighlightOption';
import { DropdownSelect } from '@/modules/form/components/Select/DropdownSelect';
import { SearchSelect } from '@/modules/form/components/Select/SearchSelect';
import { TextInput } from '@/modules/form/components/TextInput/TextInput';
import {
  SupplierLanguageType,
  SUPPLIER_LANGUAGES,
} from '@/modules/language/supplierLanguage/supplierLanguageConsts';
import { Button } from '@/modules/theme/components/Button/Button';
import { Heading, Paragraph } from '@/modules/theme/components/Typography';
import { dark } from '@/modules/theme/utils/colors';

import { MappedBusinessUnitsToSelectOptions } from '../../helpers/mapCustomerBusinessUnitsToSelectOptions';
import { MappedCategoriesToSelectOptions } from '../../helpers/supplierCategoriesHelpers';
import { ParsedSupplier } from '../../helpers/supplierHelpers';
import { AddContactPersonFormDialog } from '../ContactPersonFormDialog/AddContactPersonFormDialog';
import { ContactPerson } from '../ContactPersonFormDialog/contactPersonFormStruct';
import {
  EditContactPersonFormDialog,
  FormMode,
} from '../ContactPersonFormDialog/EditContactPersonFormDialog';
import { ContactPersonsForm } from '../ContactPersonsForm/ContactPersonsForm';
import { RemoveContactPersonDialog } from '../RemoveContactPersonDialog/RemoveContactPersonDialog';

import {
  ButtonsWrapperStyled,
  ContactPersonLoaderStyled,
  FormContentStyled,
} from './SupplierForm.styled';
import { SupplierFormHeading } from './SupplierFormHeading';
import { supplierFormStruct, SupplierFormType } from './supplierFormStruct';

type DialogState = {
  contactPerson: ContactPerson | null;
  isOpen: boolean;
};

type SupplierFormProps = {
  mode: FormMode;
  supplierData?: ParsedSupplier;
  contactPersonsData: ContactPerson[] | null;
  categoriesData: MappedCategoriesToSelectOptions | null;
  businessUnitsData: MappedBusinessUnitsToSelectOptions | null;
  onSubmit: SubmitHandler<SupplierFormType>;
  isLoading: {
    isSupplierLoading: boolean;
    isCategoriesLoading: boolean;
    isBusinessUnitsLoading: boolean;
  };
  isSubmitting: boolean;
};

type LanguageOption = {
  value: SupplierLanguageType;
  label: string;
};

const defaultDialogState: DialogState = {
  contactPerson: null,
  isOpen: false,
};

export const SupplierForm = ({
  mode,
  supplierData,
  contactPersonsData,
  categoriesData,
  businessUnitsData,
  onSubmit,
  isLoading,
  isSubmitting,
}: SupplierFormProps) => {
  const { t } = useTranslation('modules/suppliers/SupplierForm');
  const navigate = useNavigate();

  const isEditing = mode === 'edit';

  const languageOptions: LanguageOption[] = SUPPLIER_LANGUAGES.map(
    (language) => ({
      value: language,
      label: language.toUpperCase(),
    })
  );

  const errorMessages = {
    name: {
      'string.notEmpty': t('This field is required'),
    },
    language: {
      'string.notEmpty': t('This field is required'),
    },
  };

  const [editContactPersonDialogState, setEditContactPersonDialogState] =
    useState<DialogState>(defaultDialogState);
  const [removeContactPersonDialogState, setRemoveContactPersonDialogState] =
    useState<DialogState>(defaultDialogState);
  const [isAddContactPersonDialogOpen, setIsAddContactPersonDialogOpen] =
    useState(false);

  const handleOnClickEditPerson = (contactPerson: ContactPerson) => {
    setEditContactPersonDialogState({
      contactPerson,
      isOpen: true,
    });
  };

  const handleOnClickRemovePerson = (contactPerson: ContactPerson) => {
    setRemoveContactPersonDialogState({
      contactPerson,
      isOpen: true,
    });
  };

  const formValues: SupplierFormType = {
    name: supplierData?.name || '',
    categoryId: supplierData?.categoriesHierarchy?.[0]?.id || null,
    businessUnitId: supplierData?.businessUnit?.id || null,
    defaultContactId: supplierData?.selectedContactPerson?.id || null,
    language: supplierData?.language,
    organizationNumber: supplierData?.organizationNumber || null,
    contactPersons: contactPersonsData || [],
  };

  const formMethods = useForm({
    struct: supplierFormStruct,
    defaultValues: formValues,
    values: formValues,
    resetOptions: {
      keepDirtyValues: true,
      keepErrors: true,
    },
  });

  const { handleSubmit, register, setValue, getValues } = formMethods;

  const handleRemoveContactPerson = ({ id }: { id: string }) => {
    if (id === getValues('defaultContactId')) {
      setValue('defaultContactId', null, { shouldDirty: true });
    }
  };
  const { isBusinessUnitsLoading, isCategoriesLoading, isSupplierLoading } =
    isLoading;

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormContentStyled>
            <div>
              <Heading variant="h3" as="h2" mt={2} mb={2}>
                <SupplierFormHeading
                  isEditing={isEditing}
                  isLoading={isSupplierLoading}
                  supplierName={supplierData?.name}
                />
              </Heading>
              <div>
                <TextInput
                  isLoading={isSupplierLoading}
                  label={t('Company name')}
                  placeholder={t('Name of company')}
                  errorMessages={errorMessages.name}
                  {...register('name')}
                />
                <SearchSelect
                  isLoading={isSupplierLoading || isCategoriesLoading}
                  label={t('Category')}
                  placeholder={t('Ex. IT Consultants')}
                  name={register('categoryId').name}
                  options={categoriesData || []}
                  components={{
                    Option: HighlightOption,
                  }}
                />
                <SearchSelect
                  isLoading={isSupplierLoading || isBusinessUnitsLoading}
                  label={t('Business unit')}
                  placeholder={t('Ex. Office supplies')}
                  name={register('businessUnitId').name}
                  options={businessUnitsData || []}
                />
                <TextInput
                  isLoading={isSupplierLoading}
                  label={t('Org nr.')}
                  placeholder={t('Number')}
                  {...register('organizationNumber')}
                />
                <DropdownSelect
                  isLoading={isSupplierLoading}
                  name={register('language').name}
                  label={t('Language')}
                  options={languageOptions}
                  errorMessages={errorMessages.language}
                />
              </div>
            </div>
            <div>
              <Heading variant="h3" as="h2" mt={2} mb={2}>
                {t('Contact persons')}
              </Heading>
              <Paragraph color={dark[400]}>
                {t('Select one person as your contact for this supplier.')}
              </Paragraph>
              {isSupplierLoading && isEditing ? (
                <ContactPersonLoaderStyled height={40} lines={4} />
              ) : (
                <ContactPersonsForm
                  onAddPersonClick={() => setIsAddContactPersonDialogOpen(true)}
                  fieldName={register('defaultContactId').name}
                  contactPersons={contactPersonsData}
                  onEditPerson={handleOnClickEditPerson}
                  onRemovePerson={handleOnClickRemovePerson}
                />
              )}
            </div>
            <ButtonsWrapperStyled>
              <Button type="submit" loading={isSubmitting || isSupplierLoading}>
                {isEditing ? t('Save changes') : t('Add supplier')}
              </Button>
              <Button
                variant="outline"
                disabled={isSubmitting || isSupplierLoading}
                onClick={() => navigate(-1)}
              >
                {t('Back')}
              </Button>
            </ButtonsWrapperStyled>
          </FormContentStyled>
        </form>
      </FormProvider>
      <EditContactPersonFormDialog
        isOpen={editContactPersonDialogState.isOpen}
        contactPerson={editContactPersonDialogState.contactPerson}
        supplierFormMode={mode}
        onClose={() => setEditContactPersonDialogState(defaultDialogState)}
      />
      <RemoveContactPersonDialog
        isOpen={removeContactPersonDialogState.isOpen}
        contactPerson={removeContactPersonDialogState.contactPerson}
        supplierFormMode={mode}
        onRemoveContactPerson={handleRemoveContactPerson}
        onClose={() => setRemoveContactPersonDialogState(defaultDialogState)}
      />
      <AddContactPersonFormDialog
        isOpen={isAddContactPersonDialogOpen}
        onClose={() => setIsAddContactPersonDialogOpen(false)}
        supplierId={supplierData?.id || null}
      />
    </>
  );
};
