import { GetSuppliersResponseType } from '@/modules/suppliers/api/queries/getSuppliersQuery';
import { supplierCategoryResourceType } from '@/modules/suppliers/api/resources/supplierCategory/supplierCategoryResourceConsts';
import { SupplierCategory } from '@/modules/suppliers/api/resources/supplierCategory/supplierCategoryResourceStruct';
import { supplierContactResourceType } from '@/modules/suppliers/api/resources/supplierContact/supplierContactResourceConsts';
import { SupplierContact } from '@/modules/suppliers/api/resources/supplierContact/supplierContactResourceStruct';

export const parseGetSuppliersResponse = (
  response: GetSuppliersResponseType
) => {
  const { data, included, meta } = response;
  const suppliers = data.map(
    ({
      id,
      attributes: { name, currentSpend, language, organizationNumber },
      relationships: {
        category: categoryRelationship,
        defaultContact: defaultContactRelationship,
      },
    }) => {
      const categoryData = included.find((item): item is SupplierCategory => {
        return (
          item.type === supplierCategoryResourceType &&
          item.id === categoryRelationship.data?.id
        );
      });

      const defaultContactData = included.find(
        (item): item is SupplierContact => {
          return (
            item.type === supplierContactResourceType &&
            item.id === defaultContactRelationship.data?.id
          );
        }
      );

      const category = categoryData
        ? {
            id: categoryData.id,
            name: categoryData.attributes.name,
          }
        : null;

      const defaultContact = defaultContactData
        ? {
            id: defaultContactData.id,
            firstName: defaultContactData.attributes.firstName,
            lastName: defaultContactData.attributes.lastName,
            email: defaultContactData.attributes.email,
            phoneNumber: defaultContactData.attributes.phoneNumber,
          }
        : null;

      return {
        id,
        name,
        currentSpend,
        language,
        organizationNumber,
        category,
        defaultContact,
      };
    }
  );

  return {
    suppliers,
    totalSuppliersCount: meta.page.total,
  };
};

export type ParsedSuppliers = ReturnType<typeof parseGetSuppliersResponse>;
