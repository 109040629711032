import { useTranslation } from 'react-i18next';

import { RichContentTerm } from '@/modules/negotiation/negotiationTerms/NegotiationTerms/components/RichContentTerm';
import { CodeOfConductTerm } from '@/modules/negotiation/types/negotiationTypes';
import { setupNegotiationRoutes } from '@/modules/router/onboardedRoutes';
import { useDialogState } from '@/utils/useDialogState';

import { AddTermButton } from '../AddTermButton/AddTermButton';
import { NegotiationTermCard } from '../NegotiationTerms/NegotiationTermCard';

import { AddCodeOfConductDialog } from './AddCodeOfConductDialog/AddCodeOfConductDialog';
import { RemoveCodeOfConductDialog } from './RemoveCodeOfConductDialog/RemoveCodeOfConductDialog';

type CodeOfConductProps = {
  term: CodeOfConductTerm;
};

export const CodeOfConduct = ({ term }: CodeOfConductProps) => {
  const { t } = useTranslation('pages/Terms');
  const { negotiationId } = setupNegotiationRoutes.useGetRouteParams('terms');

  const addCodeOfConductDialog = useDialogState();
  const removeCodeOfConductDialog = useDialogState();

  return (
    <>
      {term.document ? (
        <NegotiationTermCard onRemove={removeCodeOfConductDialog.open}>
          <RichContentTerm text={term.text} />
        </NegotiationTermCard>
      ) : (
        <AddTermButton onClick={addCodeOfConductDialog.open}>
          {t('Add code of conduct +')}
        </AddTermButton>
      )}

      <AddCodeOfConductDialog
        isOpen={addCodeOfConductDialog.isOpen}
        onClose={addCodeOfConductDialog.close}
        negotiationId={negotiationId}
        termId={term.id}
      />
      <RemoveCodeOfConductDialog
        isOpen={removeCodeOfConductDialog.isOpen}
        onClose={removeCodeOfConductDialog.close}
        negotiationId={negotiationId}
        termId={term.id}
      />
    </>
  );
};
