import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';

import { TableCellStyled, TableRowStyled } from './TableSkeletonLoader.styled';

type TableSkeletonLoaderProps = {
  rows: number;
  height?: number;
};

export const TableSkeletonLoader = ({
  rows,
  height = 56,
}: TableSkeletonLoaderProps) => {
  return (
    <>
      {Array.from({ length: rows }).map((_, i) => (
        <TableRowStyled key={i}>
          <TableCellStyled>
            <SkeletonLoader height={height} />
          </TableCellStyled>
        </TableRowStyled>
      ))}
    </>
  );
};
