import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { findSelectValue } from '@/modules/form/components/Select/findSelectValue';
import { MultiSelect } from '@/modules/form/components/Select/MultiSelect';
import { notEmpty } from '@/utils/utils';

import { useGetSupplierCategoriesQuery } from '../../api/hooks/useGetSupplierCategoriesQuery';
import { mapSupplierCategoriesToSelectOptions } from '../../helpers/supplierCategoriesHelpers';

type SupplierCategoryFilterProps = {
  filterValues: string[];
  onChange: (value: string[]) => void;
};

export const SupplierCategoryFilter = ({
  filterValues,
  onChange,
}: SupplierCategoryFilterProps) => {
  const { t } = useTranslation('modules/suppliers/SuppliersFilters');

  const { data, isPending } = useGetSupplierCategoriesQuery();

  // https://dev.api.digiproc.com/documentation/static/index.html#/supplier/get_me_customer_negotiations__negotiationId__selected_suppliers
  // pass "null" to get suppliers without categories
  const options = useMemo(() => {
    return (
      data && [
        { label: t('No category'), value: 'null' },
        ...mapSupplierCategoriesToSelectOptions(data),
      ]
    );
  }, [t, data]);

  const values = useMemo(() => {
    return filterValues
      .map((category) =>
        findSelectValue({
          currentValue: category,
          options,
        })
      )
      .filter(notEmpty);
  }, [filterValues, options]);

  return (
    <MultiSelect
      name="category"
      label={t('Filter by category')}
      isLoading={isPending}
      options={options}
      showErrorMessage={false}
      placeholder={t('Category')}
      onChange={(options) => onChange(options.map(({ value }) => value))}
      value={values}
    />
  );
};
