import styled from 'styled-components';

import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';
import { black, dark } from '@/modules/theme/utils/colors';

type TextInputWrapperStyledProps = {
  errorHidden?: boolean;
};

export const TextInputWrapperStyled = styled(
  'div'
)<TextInputWrapperStyledProps>(({ theme: { spacing }, errorHidden }) => ({
  position: 'relative',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  marginBottom: errorHidden ? 0 : spacing(1),
}));

type InputStyledProps = {
  appliedError?: boolean;
};

export const InputStyled = styled('input')<InputStyledProps>(({
  appliedError,
  theme: { spacing, palette, typography, borderRadius },
}) => {
  const regularInput = {
    '&:not([disabled])::placeholder': {
      color: palette.disabled.main,
    },

    '&[disabled]': {
      backgroundColor: palette.disabled.lighter,
    },
  };
  const errorInput = {
    color: palette.error.main,
    '&::placeholder': {
      color: palette.error.main,
    },
  };

  return {
    width: '100%',
    border: 0,
    borderRadius: borderRadius.default,
    padding: spacing(1),
    backgroundColor: 'transparent',
    fontSize: typography.fontSize.default,
    lineHeight: typography.fontSize.large,
    outline: 'none',
    ...(appliedError ? errorInput : regularInput),
  };
});

type InputWrapperStyledProps = {
  appliedError?: boolean;
  disabled?: boolean;
};

export const InputWrapperStyled = styled('div')<InputWrapperStyledProps>(
  ({
    appliedError,
    disabled,
    theme: { borders, borderRadius, palette, spacing },
  }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    outline: 'none',
    border: '1px solid transparent',
    margin: spacing([1, 0]),
    borderRadius: borderRadius.default,
    backgroundColor: appliedError ? palette.error.light : dark[50],
    transition: 'border-color 0.1s',
    '&:focus-within, &:hover': {
      border: appliedError ? borders.input.error : borders.input.main,
    },
    ...(disabled && {
      backgroundColor: palette.disabled.lighter,
    }),
  })
);

type AdornmentWrapperStyledProps = {
  variant: 'start' | 'end';
};

export const AdornmentWrapperStyled = styled(
  'div'
)<AdornmentWrapperStyledProps>(({ variant, theme: { spacing } }) => ({
  color: black,
  backgroundColor: 'inherit',
  ...(variant === 'start'
    ? { paddingLeft: spacing(1) }
    : { paddingRight: spacing(1) }),
}));

export const TextInputSkeletonLoader = styled(SkeletonLoader)(
  ({ theme: { spacing } }) => ({
    margin: spacing([1, 0]),
  })
);
