import { useTranslation } from 'react-i18next';

import { analysisAndOfferNestedRoutes } from '@/modules/router/analysisRoutes';
import { Tab } from '@/modules/theme/components/Tabs/Tabs';

import useAnalysisDynamicTabs from './tabs/DynamicTabs/useAnalysisDynamicTabs';

export const useAnalysisAndOfferTabItems = () => {
  const { t } = useTranslation('pages/AnalysisAndOfferPage');
  const analysisDynamicTabs = useAnalysisDynamicTabs();

  const spendAnalysisTabItems: Tab[] = [
    {
      link: analysisAndOfferNestedRoutes.config.intro.nestedPath,
      text: t('Intro'),
    },
    ...(analysisDynamicTabs && analysisDynamicTabs.tabs
      ? analysisDynamicTabs.tabs.map((tab) => ({
          link: tab.path,
          text: tab.name,
        }))
      : []),
    {
      link: analysisAndOfferNestedRoutes.config.analysis.nestedPath,
      text: t('Analysis'),
      end: true,
    },
    {
      link: analysisAndOfferNestedRoutes.config.suggestedNegotiations
        .nestedPath,
      text: t('Suggested negotiations'),
    },
  ];

  return spendAnalysisTabItems;
};
