export const queryKeys = {
  user: {
    getUserDetails: 'getUserDetails',
    reauthenticateUser: 'reauthenticateUser',
    spendFile: {
      getSpendFiles: 'getSpendFiles',
      getSpendFileDownload: 'getSpendFileDownload',
    },
    serviceAgreement: {
      getServiceAgreement: 'getServiceAgreement',
      getServiceAgreementDownload: 'getServiceAgreementDownload',
    },
  },
  suppliers: {
    getSuppliers: 'getSuppliers',
    getSupplier: 'getSupplier',
    getSupplierCategories: 'getSupplierCategories',
    getChartSuppliersInfinite: 'getChartSuppliersInfinite',
  },
  customer: {
    getCustomerMetrics: 'getCustomerMetrics',
    getCustomerBusinessUnits: 'getCustomerBusinessUnits',
    getCustomerRecommendedSuppliers: 'getCustomerRecommendedSuppliers',
    useGetRecommendedCategoriesChartQuery:
      'useGetRecommendedCategoriesChartQuery',
    useGetExclusionReasonsChartQuery: 'useGetExclusionReasonsChartQuery',
    getCustomerSubscriptionPlans: 'getCustomerSubscriptionPlans',
    getActiveSubscriptionPlan: 'getActiveSubscriptionPlan',
  },
  agreement: {
    getAgreement: 'getAgreement',
    getAgreementFile: 'getAgreementFile',
  },
  negotiations: {
    getNegotiations: 'getNegotiations',
    getNegotiation: 'getNegotiation',
    getNegotiationMetrics: 'getNegotiationMetrics',
    getIntroEmails: 'getIntroEmails',
    getNegotiationSuppliers: 'getNegotiationSuppliers',
    getNegotiationSelectedSuppliersInfinite:
      'getNegotiationSelectedSuppliersInfinite',
    getNegotiationSuppliersInfinite: 'getNegotiationSuppliersInfinite',
    getKeywords: 'getKeywords',
    getSuggestedNegotiationMetrics: 'getSuggestedNegotiationMetrics',
    getStartedNegotiationSuppliers: 'getStartedNegotiationSuppliers',
  },
} as const;

export const sortArrayQueryKey = <T extends unknown[] | undefined>(
  arrayQueryKey: T
) => {
  return [...(arrayQueryKey || [])].sort();
};
