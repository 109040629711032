import styled from 'styled-components';

import { Dialog } from '@/modules/theme/components/Dialog/Dialog';

export const ContainerStyled = styled('div')(({ theme: { spacing } }) => ({
  display: 'grid',
  placeItems: 'center',
  margin: spacing([5, 0]),
  position: 'relative',
}));

export const BoldTextStyled = styled('span')(({ theme: { typography } }) => ({
  fontWeight: typography.fontWeight.bold,
}));

export const DialogStyled = styled(Dialog)(({ theme: { breakpoints } }) => ({
  width: 700,
  [breakpoints.down.sm]: {
    width: '90%',
  },
}));
