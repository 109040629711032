import * as sup from 'superstruct';

import { JsonApiClient } from '@/modules/api/utils/createJsonApiClient';
import { createResponseStruct } from '@/modules/api/utils/jsonApiStructs';

import {
  getUpdateNegotiationResourcePath,
  negotiationResourceType,
} from '../resources/negotiation/negotiationResourceConsts';
import { negotiationResourceStruct } from '../resources/negotiation/negotiationResourceStruct';

type UpdateNegotiationMutationPayload = {
  negotiationId: string;
  introEmailBodyTemplate?: string;
  startDate?: string;
  periodIndex?: number | null;
};

export function updateNegotiationMutation(jsonApi: JsonApiClient) {
  return async ({
    negotiationId,
    introEmailBodyTemplate,
    startDate,
    periodIndex,
  }: UpdateNegotiationMutationPayload) => {
    const response = await jsonApi.update({
      path: getUpdateNegotiationResourcePath(negotiationId),
      type: negotiationResourceType,
      id: negotiationId,
      attributes: {
        periodIndex,
        ...(introEmailBodyTemplate !== undefined && {
          introEmail: {
            bodyTemplate: introEmailBodyTemplate,
          },
        }),
        ...(startDate && {
          period: {
            startDate,
          },
        }),
      },
      responseStruct: updateNegotiationResponseStruct,
    });
    if (!response)
      throw new Error('missing response from `updateNegotiationMutation`');

    return parseUpdateNegotiationResponse(response);
  };
}

export const updateNegotiationResponseStruct = createResponseStruct({
  data: negotiationResourceStruct,
});

type UpdateNegotiationResponse = sup.Infer<
  typeof updateNegotiationResponseStruct
>;

function parseUpdateNegotiationResponse(response: UpdateNegotiationResponse) {
  const {
    data: {
      id,
      attributes: { title, state, termRoles, period, introEmail },
      links,
    },
  } = response;

  return {
    id,
    title,
    state,
    termRoles,
    period,
    introEmail,
    supplierPreviewUrl: links.portal,
  };
}
