import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { QuestionnaireFormSection } from '../QuestionnaireFormSection';

import { TermImportanceLabels } from './components/TermImportanceLabels';
import { TermImportanceQuestion } from './components/TermImportanceQuestion';

export const TermImportanceSection = () => {
  const { t } = useTranslation('pages/UploadSpendAndQuestionnairePage');

  const { register } = useFormContext();

  return (
    <QuestionnaireFormSection
      number={2}
      title={t('What is most important for you?')}
    >
      <TermImportanceSectionStyled>
        <TermImportanceLabels />

        <TermImportanceQuestion {...register('offerDiscount')}>
          {t('Lower prices (savings)')}
        </TermImportanceQuestion>
        <TermImportanceQuestion {...register('lockPrice')}>
          {t('Locked prices (cost avoidance)')}
        </TermImportanceQuestion>
        <TermImportanceQuestion {...register('signCodeOfConduct')}>
          {t('Approval of our Supplier Code of Conduct')}
        </TermImportanceQuestion>
        <TermImportanceQuestion {...register('extendInvoiceDueDate')}>
          {t('Improved cash flow via extended payment days')}
        </TermImportanceQuestion>
        <TermImportanceQuestion {...register('answerSustainabilityQuestion')}>
          {t('Better understanding of suppliers’ sustainability work')}
        </TermImportanceQuestion>
      </TermImportanceSectionStyled>
    </QuestionnaireFormSection>
  );
};

const TermImportanceSectionStyled = styled('div')(({ theme: { spacing } }) => ({
  display: 'grid',
  gridTemplateColumns: '4fr repeat(3, 1fr)',
  columnGap: spacing(4),
  rowGap: spacing(2),
  justifyItems: 'center',
}));
