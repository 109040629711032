import { useTranslation } from 'react-i18next';

import { NegotiationState } from '@/modules/negotiation/types/negotiationTypes';
import { Box } from '@/modules/theme/components/Box';
import { Heading } from '@/modules/theme/components/Typography';

import { useGetAnalysisMetricsQuery } from '../../hooks/useGetAnalysisMetricsQuery';
import { MetricsWrapperStyled } from '../../SuggestedNegotiations.styled';
import { SuggestedNegotiationMetrics } from '../SuggestedNegotiationMetrics/SuggestedNegotiationMetrics';
import { TotalPotentialMetrics } from '../TotalPotentialMetrics/TotalPotentialMetrics';

import { SuggestedNegotiationsDnD } from './components/SuggestedNegotiationsDnD/SuggestedNegotiationsDnD';

const totalPotentialMetricsNegotiationStates = [
  NegotiationState.ESTIMATED,
  NegotiationState.SUGGESTED,
  NegotiationState.SETUP,
  NegotiationState.PLANNED,
  NegotiationState.ACTIVE,
];

type SuggestedNegotiationsProps = {
  isLoading: boolean;
  isError: boolean;
};

export const SuggestedNegotiations = ({
  isLoading,
  isError,
}: SuggestedNegotiationsProps) => {
  const { t } = useTranslation('pages/SuggestedNegotiationsPage');

  const {
    data: suggestedMetrics,
    isFetching: isSuggestedMetricsFetching,
    isError: isSuggestedMetricsError,
  } = useGetAnalysisMetricsQuery({
    negotiationStates: [NegotiationState.SUGGESTED],
    recommended: false,
  });

  const {
    data: totalPotentialMetrics,
    isPending: isTotalPotentialMetricsPending,
    isError: isTotalPotentialMetricsError,
  } = useGetAnalysisMetricsQuery({
    negotiationStates: totalPotentialMetricsNegotiationStates,
    recommended: true,
  });
  return (
    <Box as="main" pl={4} pr={4} mb={2}>
      <Heading variant="h2" ml={3}>
        {t('DigiProc potential')}
      </Heading>
      <MetricsWrapperStyled>
        <SuggestedNegotiationMetrics
          metrics={suggestedMetrics}
          isFetching={isSuggestedMetricsFetching || isLoading}
          isError={isSuggestedMetricsError || isError}
        />
        <TotalPotentialMetrics
          metrics={totalPotentialMetrics}
          isFetching={isTotalPotentialMetricsPending || isLoading}
          isError={isTotalPotentialMetricsError || isError}
        />
      </MetricsWrapperStyled>
      <SuggestedNegotiationsDnD />
    </Box>
  );
};
