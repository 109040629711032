import * as hookForm from 'react-hook-form';

import { superstructResolver } from './helpers/superstructResolver';
import { UseFormProps, UseFormReturn } from './helpers/types';

/**
 * It's a thin wrapper around the `useForm` hook from `react-hook-form` lib.
 * It requires validation struct built upon `superstruct` lib utilities
 *
 * The hook takes the following props
 * - `defaultValues` - default values for the form inputs
 * - `struct` - superstruct's object schema defining the form validation
 */
export function useForm<T extends Record<string, unknown>>({
  struct,
  mode = 'onBlur',
  defaultValues,
  values,
  shouldUnregister = false,
  resetOptions,
}: UseFormProps<T>): UseFormReturn<T> {
  if (struct) {
    return hookForm.useForm({
      mode,
      resolver: superstructResolver(struct),
      defaultValues,
      values,
      shouldUnregister,
      // https://react-hook-form.com/api/useform/#resetOptions
      resetOptions,
    });
  }
  return hookForm.useForm({
    mode,
    defaultValues,
    values,
    shouldUnregister,
  });
}
