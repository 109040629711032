import { nanoid } from 'nanoid';
import { ReactNode, useState } from 'react';
import { Config, usePopperTooltip } from 'react-popper-tooltip';

import { spacingUnitPxValue } from '@/modules/theme/utils/createTheme';

import { TooltipArrowStyled, TooltipStyled } from './Tooltip.styled';

interface TooltipProps {
  children: ReactNode;
  tooltipContent: ReactNode;
  className?: string;
  tooltipClassName?: string;
  arrowVisible?: boolean;
  arrowClassName?: string;
  placement?: Config['placement'];
  offsetX?: number;
  offsetY?: number;
}

export const Tooltip = ({
  children,
  tooltipContent,
  className,
  tooltipClassName,
  arrowVisible = false,
  arrowClassName,
  placement = 'auto',
  offsetX = 0,
  offsetY = spacingUnitPxValue,
}: TooltipProps) => {
  const [textContentId] = useState(() => nanoid());
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    offset: [offsetX, offsetY],
    placement,
    trigger: ['click', 'focus', 'hover'],
  });

  return (
    <div className={className}>
      <div ref={setTriggerRef}>{children}</div>
      <TooltipStyled
        visible={visible}
        role="tooltip"
        aria-labelledby={textContentId}
        ref={setTooltipRef}
        {...getTooltipProps({
          className: tooltipClassName,
        })}
      >
        {arrowVisible && (
          <TooltipArrowStyled
            {...getArrowProps({
              className: arrowClassName,
            })}
          />
        )}
        <span id={textContentId}>{tooltipContent}</span>
      </TooltipStyled>
    </div>
  );
};
