import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import {
  components,
  GroupBase,
  IndicatorsContainerProps as ReactSelectIndicatorsContainerProps,
} from 'react-select';
import styled from 'styled-components';

import { ReactComponent as CrossIcon } from '../icons/cross.svg';

type IndicatorsContainerProps<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = PropsWithChildren<
  ReactSelectIndicatorsContainerProps<Option, IsMulti, Group>
> & {
  searchValue: string;
  clearSearchValue: () => void;
};

export const IndicatorsContainer = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: IndicatorsContainerProps<Option, IsMulti, Group>
) => {
  const { t } = useTranslation('common/IndicatorsContainer');
  return (
    <components.IndicatorsContainer {...props}>
      {props.searchValue && (
        <ClearButtonStyled type="button" onClick={props.clearSearchValue}>
          {t('Clear')} <CrossIcon />
        </ClearButtonStyled>
      )}
      {props.children}
    </components.IndicatorsContainer>
  );
};

export const ClearButtonStyled = styled('button')(
  ({ theme: { spacing, palette, typography } }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: spacing(1),
    background: 'transparent',
    border: 0,
    marginRight: spacing(1),
    color: palette.primary.main,
    cursor: 'pointer',
    fontSize: typography.fontSize.html,
  })
);
