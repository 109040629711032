import { lazy, Suspense } from 'react';

import { ContentWrapper } from './AnalysisPage.styled';
import { DigiProcAreaByCategoriesSkeleton } from './components/DigiProcAreaByCategories/DigiProcAreaByCategoriesSkeleton';
import { DigiProcAreaSpreadSkeleton } from './components/DigiProcAreaSpread/DigiProcAreaSpreadSkeleton';

const DigiProcAreaSpread = lazy(
  () => import('./components/DigiProcAreaSpread/DigiProcAreaSpread')
);
const DigiProcAreaByCategories = lazy(
  () => import('./components/DigiProcAreaByCategories/DigiProcAreaByCategories')
);

export const AnalysisPage = () => {
  return (
    <ContentWrapper>
      <Suspense fallback={<DigiProcAreaSpreadSkeleton />}>
        <DigiProcAreaSpread />
      </Suspense>
      <Suspense fallback={<DigiProcAreaByCategoriesSkeleton />}>
        <DigiProcAreaByCategories />
      </Suspense>
    </ContentWrapper>
  );
};
