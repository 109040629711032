import { useTranslation } from 'react-i18next';
import * as sup from 'superstruct';

import { FileUpload } from '@/modules/form/components/FileUpload/FileUpload';
import { Form } from '@/modules/form/components/Form/Form';

type UploadCodeOfConductFormProps = {
  handleOnFileUpload: (file: File) => void;
};

const codeOfConductStruct = sup.object({
  codeOfConduct: sup.instance(File),
});

const fileMaxSizeMb = 10;

export const UploadCodeOfConductForm = ({
  handleOnFileUpload,
}: UploadCodeOfConductFormProps) => {
  const { t } = useTranslation('pages/Terms');

  const errorMessages = {
    codeOfConduct: {
      'codeOfConduct.maxSize': t('Maximum size is {{maxFileSize}}', {
        maxFileSize: `${fileMaxSizeMb} MB`,
      }),
      'codeOfConduct.unsupportedFileType': t(
        'Unsupported file type. Allowed file type is PDF'
      ),
    },
  };

  return (
    <Form
      defaultValues={{
        codeOfConduct: undefined,
      }}
      struct={codeOfConductStruct}
    >
      {({ register }) => (
        <FileUpload
          name={register('codeOfConduct').name}
          text={t('Click or drag and drop file here')}
          onUploadFile={(file) => {
            handleOnFileUpload(file);
          }}
          errorMessages={errorMessages.codeOfConduct}
          maxFileSizeInBytes={1_000_000 * fileMaxSizeMb}
        />
      )}
    </Form>
  );
};
