import { NegotiationState } from '../../../types/negotiationTypes';

export const negotiationResourceType = 'negotiation';

export const negotiationRelationshipsKeys = {
  metrics: 'metrics',
  communication: 'communication',
  terms: 'terms',
} as const;

export type GetNegotiationsQueryParams = {
  pageNumber: number;
  pageSize: number;
  states?: NegotiationState[];
};

export type GetNegotiationsQueryOptions = { enabled?: boolean };

export const getNegotiationsResourcePath = (
  params: GetNegotiationsQueryParams
) => {
  const stateFilter = params.states?.length
    ? `&filter[state]=${params.states.join(',')}`
    : '';
  return `/me/customer/negotiations?page[number]=${params.pageNumber}&page[size]=${params.pageSize}&include=metrics${stateFilter}`;
};

export const getNegotiationResourcePath = (
  id: string,
  includes: Array<keyof typeof negotiationRelationshipsKeys>
) => {
  return `me/customer/negotiations/${id}?include=${includes.join(',')}`;
};

export const getUpdateNegotiationResourcePath = (id: string) => {
  return `/me/customer/negotiations/${id}`;
};

export const getSetNegotiationStateToSetupPath = (id: string) => {
  return `/me/customer/negotiations/${id}/start_setup`;
};
