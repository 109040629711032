import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextArea } from '@/modules/form/components/TextArea/TextArea';
import { Box } from '@/modules/theme/components/Box';

import { QuestionnaireForm } from '../helpers/questionnaireFormStruct';

import { QuestionnaireFormSection } from './QuestionnaireFormSection';

export const NotesSection = () => {
  const { t } = useTranslation('pages/UploadSpendAndQuestionnairePage');
  const { register } = useFormContext<QuestionnaireForm>();
  return (
    <QuestionnaireFormSection
      number={4}
      title={t(
        'Additional information that could be valuable for the analysis'
      )}
    >
      <Box mr={6}>
        <TextArea
          {...register('notes')}
          errorHidden
          placeholder={t('Text (optional)')}
        />
      </Box>
    </QuestionnaireFormSection>
  );
};
