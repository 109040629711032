import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { resetPasswordMutation } from '@/modules/auth/api/mutations/resetPasswordMutation';
import { constructMeta } from '@/modules/reactQuery/constructMeta';

type UseResetPasswordMutationPayload = {
  onResetPasswordSuccess: () => void;
};

export const useResetPasswordMutation = ({
  onResetPasswordSuccess,
}: UseResetPasswordMutationPayload) => {
  const jsonApi = useJsonApi();
  const { t } = useTranslation('apiMessages/useResetPasswordMutation');

  return useMutation({
    mutationKey: [resetPasswordMutation.name],
    mutationFn: resetPasswordMutation(jsonApi),
    onSuccess: () => onResetPasswordSuccess(),
    meta: constructMeta({
      errorMessages: {
        404: {
          REQUEST_NOT_FOUND: t('Token is invalid or expired'),
        },
      },
    }),
  });
};
