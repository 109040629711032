import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { queryKeys } from '@/modules/api/queryKeys';
import { parseGetUserDetailsResponse } from '@/modules/auth/api/helpers/parseGetUserDetailsResponse';
import { getUserQuery } from '@/modules/auth/api/queries/getUserQuery';
import { constructMeta } from '@/modules/reactQuery/constructMeta';

export const useRefetchUserDataQuery = () => {
  const jsonApi = useJsonApi();
  const { t } = useTranslation('apiMessages/useRefetchUserDataQuery');

  return useQuery({
    queryKey: [queryKeys.user.reauthenticateUser],
    enabled: false,
    queryFn: getUserQuery(jsonApi),
    select: (response) => parseGetUserDetailsResponse(response),
    retry: false,
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Your session expired. Please sign in'),
        },
      },
    }),
  });
};
