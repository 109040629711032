import Bugsnag, {
  Event,
  Client as BugsnagClient,
  NotifiableError,
} from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { Mutation, Query, QueryKey } from '@tanstack/react-query';
import axios from 'axios';
import { StructError } from 'superstruct';

import { appConfig } from '@/modules/app/appConfig';
import { appUniqueVersion } from '@/modules/app/appVersion';

const bugsnag = appConfig.bugsnag.apiKey
  ? Bugsnag.start({
      releaseStage: appConfig.environment,
      apiKey: appConfig.bugsnag.apiKey,
      appVersion: appUniqueVersion,
      onError: [addAxiosMetadata, addStructMetadata],
      redactedKeys: [
        /password/i,
        /passphrase/i,
        /token/i,
        /email/i,
        /nonce/i,
        /apikey/i,
      ],

      plugins: [new BugsnagPluginReact()],
    })
  : undefined;

function addAxiosMetadata(event: Event) {
  if (!axios.isAxiosError(event.originalError)) return;

  const error = event.originalError;
  const responseData = error.response?.data as
    | { message?: unknown }
    | undefined;

  event.addMetadata('axios', {
    method: error.config?.method,
    url: axios.getUri(error.config),

    status: error.response?.status,
    errorMessage: responseData?.message,
  });
}

function addStructMetadata(event: Event) {
  if (!(event.originalError instanceof StructError)) return;

  const failures = event.originalError.failures();

  event.addMetadata('struct', {
    failures: failures.map(
      (failure) => `${failure.path.join('.')}: ${failure.message}`
    ),
  });
}

export const notifyBugsnag: BugsnagClient['notify'] = bugsnag
  ? bugsnag.notify.bind(bugsnag)
  : () => undefined;

export function notifyBugsnagQueryError(
  error: unknown,
  query: Query<unknown, unknown, unknown, QueryKey>
) {
  const key = query.queryKey;
  const name = key[0];

  notifyBugsnag(error as NotifiableError, (event) => {
    event.addMetadata('query', { name, key });
  });
}

export function notifyBugsnagMutationError(
  error: unknown,
  variables: unknown,
  _context: unknown,
  mutation?: Mutation<unknown, unknown, unknown, unknown>
) {
  const key = mutation?.options.mutationKey || ['unknown mutation key'];
  const name = key[0];

  notifyBugsnag(error as NotifiableError, (event) => {
    event.addMetadata('mutation', { name, key, variables });
  });
}
