import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { signInMutation } from '@/modules/auth/api/mutations/signInMutation';
import { constructMeta } from '@/modules/reactQuery/constructMeta';

export const useSignInMutation = () => {
  const jsonApi = useJsonApi();
  const { t } = useTranslation('apiMessages/useSignInMutation');

  return useMutation({
    mutationKey: [signInMutation.name],
    mutationFn: signInMutation(jsonApi),
    meta: constructMeta({
      errorMessages: {
        401: {
          INVALID_CREDENTIALS: t('You entered incorrect email and/or password'),
        },
      },
    }),
  });
};
