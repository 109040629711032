import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { findSelectValue } from '@/modules/form/components/Select/findSelectValue';
import { SupplierNegotiationState } from '@/modules/negotiation/api/resources/negotiationSelectedSuppliers/negotiationSelectedSuppliersResourceConsts';
import { NegotiationWithProgressSuppliersOrder } from '@/modules/negotiation/types/negotiationTypes';
import { Box } from '@/modules/theme/components/Box';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { Pagination } from '@/modules/theme/components/Pagination/Pagination';
import { ShowPerPageDropdown } from '@/modules/theme/components/ShowPerPageDropdown/ShowPerPageDropdown';
import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';
import { Heading } from '@/modules/theme/components/Typography';

import { defaultPrefixedSuppliersQueryParams } from '../../helpers/defaultPrefixedSuppliersQueryParams';

import { SuppliersFilters } from './components/SuppliersFilters/SuppliersFilters';
import { SuppliersTable } from './components/SuppliersTable/SuppliersTable';
import { useGetStartedNegotiationSuppliersQuery } from './hooks/useGetStartedNegotiationSuppliersQuery/useGetStartedNegotiationSuppliersQuery';
import { useSetSuppliersQueryParams } from './hooks/useSetSuppliersQueryParams';

export type NegotiationPageQueryParams = {
  'suppliers.page'?: number;
  'suppliers.size'?: number;
  'suppliers.sort'?: NegotiationWithProgressSuppliersOrder;
  'suppliers.searchQuery'?: string;
  'suppliers.category'?: string[];
  'suppliers.progressStep'?: SupplierNegotiationState;
};

type StartedNegotiationSuppliersListProps = {
  negotiationId: string;
  queryParams: NegotiationPageQueryParams;
  isNegotiationActive?: boolean;
};

const pageSizeOptions = [20, 50, 100].map((value) => ({
  value,
  label: value.toString(),
}));

const allStartedNegotiationSupplierStates = [
  SupplierNegotiationState.ANSWERED,
  SupplierNegotiationState.EXCLUDED,
  SupplierNegotiationState.INVITED,
  SupplierNegotiationState.OPENEDEMAIL,
  SupplierNegotiationState.REGISTERED,
  SupplierNegotiationState.VISITED,
];

export const StartedNegotiationSuppliersList = ({
  negotiationId,
  queryParams,
  isNegotiationActive = false,
}: StartedNegotiationSuppliersListProps) => {
  const { t } = useTranslation('modules/negotiations/NegotiationSuppliersList');

  const searchQuery = queryParams['suppliers.searchQuery'] || '';
  const order =
    queryParams['suppliers.sort'] ||
    defaultPrefixedSuppliersQueryParams['suppliers.sort'];
  const categoriesFilter = queryParams['suppliers.category'] || [];
  const pageNumber = queryParams['suppliers.page'] || 1;
  const pageSize =
    queryParams['suppliers.size'] ||
    defaultPrefixedSuppliersQueryParams['suppliers.size'];
  const progressStep = queryParams['suppliers.progressStep'] || '';

  const {
    setCategories,
    setOrder,
    setPageNumber,
    setPageSize,
    setProgressStep,
    setSearchQuery,
    debouncedSearchQuery,
  } = useSetSuppliersQueryParams({ searchQuery, isNegotiationActive });

  const { data, isLoading, isSuccess, isPlaceholderData } =
    useGetStartedNegotiationSuppliersQuery({
      negotiationId,
      params: {
        negotiationStates: progressStep
          ? [progressStep]
          : allStartedNegotiationSupplierStates,
        pageNumber,
        pageSize,
        sort: order,
        searchQuery: debouncedSearchQuery || undefined,
        category: categoriesFilter.length ? categoriesFilter : undefined,
      },
    });

  const paginationProps = {
    perPage: pageSize,
    totalCount: data ? data.total : 0,
    onPageChange: (selectedPage: number) => setPageNumber(selectedPage + 1),
    forcePage: pageNumber - 1,
  };

  return (
    <Box margins={[7, 0, 6]}>
      <Heading variant="h3" as="h2" mb={3}>
        {t('Suppliers in negotiation')}
      </Heading>

      <FlexContainer justify="space-between">
        <SuppliersFilters
          isLoading={isLoading}
          searchQuery={searchQuery}
          categoriesFilter={categoriesFilter}
          order={order}
          progressStep={progressStep}
          onProgressStepChange={setProgressStep}
          onPageSizeChange={setPageSize}
          onSearchQueryChange={setSearchQuery}
          onOrderChange={setOrder}
          onCategoryChange={setCategories}
        />

        <FlexContainer direction="column" justify="space-between">
          <Box mb={2}>
            <ShowPerPageDropdown
              options={pageSizeOptions}
              selectedPageSize={pageSize}
              onPageSizeChange={setPageSize}
              defaultValue={findSelectValue({
                currentValue: pageSize,
                options: pageSizeOptions,
              })}
            />
          </Box>
        </FlexContainer>
      </FlexContainer>

      <FlexContainer justify="center" margins={[4, 0, 3]}>
        {isLoading ? (
          <SkeletonLoaderStyled />
        ) : paginationProps.totalCount ? (
          <Pagination {...paginationProps} />
        ) : null}
      </FlexContainer>

      <SuppliersTable
        suppliers={data?.suppliers || []}
        isSuccess={isSuccess}
        pageSize={pageSize}
        isLoading={isLoading || isPlaceholderData}
        isNegotiationActive={isNegotiationActive}
        negotiationId={negotiationId}
      />

      <FlexContainer justify="center" margins={[5, 0]}>
        {isLoading ? (
          <SkeletonLoaderStyled />
        ) : paginationProps.totalCount ? (
          <Pagination {...paginationProps} />
        ) : null}
      </FlexContainer>
    </Box>
  );
};

const SkeletonLoaderStyled = styled(SkeletonLoader)({
  height: 24,
  width: 100,
  marginBottom: 0,
});
