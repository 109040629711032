import { ReactNode } from 'react';

import { Box } from '@/modules/theme/components/Box';
import { white } from '@/modules/theme/utils/colors';

import {
  HeadingStyled,
  TileHeaderStyled,
  TileWrapperStyled,
} from './CustomerMetric.styled';

type CustomerMetricProps = {
  label: string;
  className?: string;
  labelAligned?: boolean;
  children: ReactNode;
};

export const CustomerMetric = ({
  label,
  children,
  labelAligned = false,
  className,
}: CustomerMetricProps) => {
  return (
    <TileWrapperStyled className={className}>
      <TileHeaderStyled isAligned={labelAligned}>
        <HeadingStyled variant="h3" color={white}>
          {label}
        </HeadingStyled>
      </TileHeaderStyled>
      <Box paddings={[4, 7]}>{children}</Box>
    </TileWrapperStyled>
  );
};
