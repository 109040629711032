import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DebouncedTextInput } from '@/modules/form/components/TextInput/DebouncedTextInput';
import { SupplierLanguageType } from '@/modules/language/supplierLanguage/supplierLanguageConsts';

import { useUpdateNegotiationMutation } from '../hooks/useUpdateNegotiationKeywordMutation';
import { AsteriskStyled, SavedTickStyled } from '../Keywords.styled';

import { KeywordsFormStruct } from './KeywordsForm';

type KeywordFieldArrayProps = {
  negotiationId: string;
  language: SupplierLanguageType;
};

type UpdateKeywordPayload = {
  value: string;
  keywordId: string;
  fieldIndex: number;
};

export const KeywordsFieldArray = ({
  negotiationId,
  language,
}: KeywordFieldArrayProps) => {
  const {
    control,
    register,
    watch,
    formState: { errors },
    setError,
  } = useFormContext<KeywordsFormStruct>();
  const { t } = useTranslation('pages/Keywords');
  const { fields } = useFieldArray({
    control,
    name: 'keywords',
    keyName: 'hookFormId',
  });

  const { mutate: updateNegotiationKeywordMutate } =
    useUpdateNegotiationMutation();

  const updateKeyword = ({
    keywordId,
    value,
    fieldIndex,
  }: UpdateKeywordPayload) => {
    updateNegotiationKeywordMutate(
      {
        keywordId,
        value,
        language,
        negotiationId,
      },
      {
        onError: () =>
          setError(`keywords.${fieldIndex}.value`, { type: 'api.error' }),
      }
    );
  };

  return (
    <>
      {fields.map((keyword, index) => {
        const currentValue = watch(`keywords.${index}.value`);
        const fieldErrorType = errors.keywords?.[index]?.value?.type;

        const hasFieldError =
          currentValue.length <= 0 || Boolean(fieldErrorType);

        return (
          <DebouncedTextInput
            onDebounce={(e) =>
              updateKeyword({
                value: e.target.value,
                keywordId: keyword.id,
                fieldIndex: index,
              })
            }
            waitTime={1000}
            {...register(`keywords.${index}.value`)}
            error={fieldErrorType}
            endAdornment={!hasFieldError && <SavedTickStyled />}
            errorMessages={{
              'api.error': t(
                'Something went wrong with your request, try again'
              ),
              'string.notEmpty': t('Keyword is required'),
            }}
            label={
              <>
                {keyword.name}
                {hasFieldError && (
                  <AsteriskStyled title={t('required')}>*</AsteriskStyled>
                )}
              </>
            }
            key={keyword.hookFormId}
          />
        );
      })}
    </>
  );
};
