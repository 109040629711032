import styled from 'styled-components';

import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';

export const FormContentStyled = styled('div')(
  ({ theme: { spacing, breakpoints } }) => ({
    maxWidth: 1000,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: spacing(4),
    margin: `${spacing(7)} auto`,

    [breakpoints.down.md]: {
      gridTemplateColumns: '1fr',
      margin: spacing(2),
    },
  })
);

export const ButtonsWrapperStyled = styled('div')(({ theme: { spacing } }) => ({
  marginTop: spacing(8),
  justifySelf: 'center',
  gridRow: 2,
  gridColumn: '1/3',
  display: 'grid',
  gridGap: spacing(2),
}));

export const ContactPersonLoaderStyled = styled(SkeletonLoader)(
  ({ theme: { spacing } }) => ({
    margin: spacing([1, 0, 1, 0]),
  })
);
