import { ReactNode, useState } from 'react';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

import { presentationContext } from './presentationContext';

// due to problems with CRA production build (more info: https://github.com/wojtekmaj/react-pdf/issues/852)
// alternative solution is used here
// https://github.com/wojtekmaj/react-pdf/blob/main/packages/react-pdf/README.md#use-external-cdn
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

type PresentationContextProviderProps = {
  children: ReactNode;
};

export const PresentationContextProvider = ({
  children,
}: PresentationContextProviderProps) => {
  const [pageNumber, setPageNumber] = useState<number>(1);

  return (
    <presentationContext.Provider value={{ pageNumber, setPageNumber }}>
      {children}
    </presentationContext.Provider>
  );
};
