import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { dark, mint } from '@/modules/theme/utils/colors';

type Props = {
  variant: 'border' | 'none';
};

export const DragPlaceholder = ({ variant = 'border' }: Props) => {
  const { t } = useTranslation('pages/SuggestedNegotiationsPage');

  if (variant === 'border') {
    return (
      <ContainerBorderStyled>
        <h4>{t('Add negotiation')} +</h4>
        <h4>({t('drag & drop')})</h4>
      </ContainerBorderStyled>
    );
  }
  return (
    <ContainerStyled>
      <h4>{t('Add negotiation')} +</h4>
      <h4>({t('drag & drop')})</h4>
    </ContainerStyled>
  );
};

const ContainerBorderStyled = styled('div')(
  ({ theme: { spacing, borderRadius } }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: dark[200],
    border: `2px dashed ${mint[200]}`,
    height: spacing(23),
    width: '100%',
    borderRadius: borderRadius.default,
  })
);

const ContainerStyled = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  color: dark[200],
  width: '100%',
  padding: spacing(2),
}));
