import { GetUserResponse } from '../queries/getUserQuery';

export function parseGetUserDetailsResponse(response: GetUserResponse) {
  const {
    data: {
      id,
      attributes: { email, firstName, lastName, roles },
      relationships,
    },
    included,
  } = response;

  const user = { userId: id, email, firstName, lastName, roles };

  const customerId = relationships.customer.data?.id;
  const customerData = included.find(({ id }) => id === customerId);

  const customer = customerData
    ? {
        customerId: customerData.id,
        companyName: customerData.attributes.name,
        currency: customerData.attributes.currency,
        pendingOnboardingSteps: customerData.attributes.onboarding.pendingSteps,
      }
    : null;

  return { user, customer };
}
