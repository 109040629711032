import { ReactNode } from 'react';

import { DisabledLinkStyled, RouterLinkStyled } from './Link.styled';

interface InternalLinkProps {
  to: string;
  children?: ReactNode;
  disabled?: boolean;
  title?: string;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

export function InternalLink({
  to,
  children,
  disabled,
  title,
  className,
  onClick,
}: InternalLinkProps) {
  if (disabled) {
    return (
      <DisabledLinkStyled className={className}>{children}</DisabledLinkStyled>
    );
  }
  const handleOnClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (onClick) {
      onClick(e);
    }
    window.scrollTo(0, 0);
  };
  return (
    <RouterLinkStyled
      to={to}
      title={title}
      className={className}
      onClick={handleOnClick}
    >
      {children}
    </RouterLinkStyled>
  );
}
