import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { queryKeys } from '@/modules/api/queryKeys';
import { JsonApiClient } from '@/modules/api/utils/createJsonApiClient';
import { negotiationSupplierProgressType } from '@/modules/negotiation/api/resources/negotiationSupplierProgress/negotiationSupplierProgressResourceConsts';
import { constructMeta } from '@/modules/reactQuery/constructMeta';

type UseResetSupplierMutationPayload = {
  negotiationId: string;
};

export const useResetSupplierMutation = ({
  negotiationId,
}: UseResetSupplierMutationPayload) => {
  const queryClient = useQueryClient();
  const jsonApi = useJsonApi();
  const { t } = useTranslation('apiMessages/useResetSupplierMutation');

  return useMutation({
    mutationFn: resetSupplier({ jsonApi, negotiationId }),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [
            queryKeys.negotiations.getStartedNegotiationSuppliers,
            negotiationId,
          ],
        }),
        queryClient.invalidateQueries({
          queryKey: [queryKeys.negotiations.getNegotiation, negotiationId],
        }),
      ]);
    },
    meta: constructMeta({
      errorMessages: {
        404: {
          NEGOTIATION_NOT_FOUND: t(
            'Negotiation not found. Please refresh the page or contact the administrator'
          ),
          PROGRESS_NOT_FOUND: t(
            'Negotiation progress not found. Please refresh the page or contact the administrator'
          ),
        },
        422: {
          INVALID_NEGOTIATION_STATE: t('Negotiation is not in active state'),
        },
      },
    }),
  });
};
type ResetSupplierPayload = {
  jsonApi: JsonApiClient;
  negotiationId: string;
};

type ResetSupplierMutatePayload = {
  progressId: string | undefined;
};

const resetSupplier = ({ jsonApi, negotiationId }: ResetSupplierPayload) => {
  return ({ progressId }: ResetSupplierMutatePayload) => {
    if (!progressId) {
      throw new Error('Missing supplier progress id');
    }

    return jsonApi.post({
      path: `/customer/negotiations/${negotiationId}/supplier_progress/${progressId}/reset`,
      payload: {
        type: negotiationSupplierProgressType,
      },
    });
  };
};
