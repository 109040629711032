import { GroupBase, Props as ReactSelectProps } from 'react-select';

import { BasicOption } from './types';

export const findSelectValue = <
  Option extends BasicOption = BasicOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  currentValue,
  options,
}: {
  currentValue: string | number | null;
  options: ReactSelectProps<Option, IsMulti, Group>['options'];
}) => {
  return options
    ?.flatMap((optionOrGroup) => {
      if ('value' in optionOrGroup) return optionOrGroup;
      return optionOrGroup.options;
    })
    .find((option) => option.value === currentValue);
};
