import styled from 'styled-components';

import { Paragraph } from '@/modules/theme/components/Typography';
import { blue } from '@/modules/theme/utils/colors';

import { CustomerMetric } from './CustomerMetric/CustomerMetric';

export const CustomerMetricsSectionStyled = styled('div')(
  ({ theme: { spacing, breakpoints } }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: spacing(2),

    [breakpoints.down.lg]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },

    [breakpoints.down.md]: {
      gridTemplateColumns: '1fr',
    },
  })
);

export const SavingsTileStyled = styled(CustomerMetric)(
  ({ theme: { breakpoints } }) => ({
    gridColumn: '1 / 3',

    [breakpoints.down.md]: {
      gridColumn: 1,
    },
  })
);

type NumberStyledProps = {
  aligned?: boolean;
  color?: string;
};

export const NumberStyled = styled('p')<NumberStyledProps>(
  ({
    theme: { palette, typography, breakpoints },
    aligned = false,
    color,
  }) => ({
    fontSize: typography.pxToRem(48),
    fontWeight: typography.fontWeight.bold,
    color: color ?? palette.primary.main,
    lineHeight: 1.5,
    fontFamily: typography.fonts.heading,
    textAlign: aligned ? 'center' : 'start',

    [breakpoints.down.lg]: {
      fontSize: typography.pxToRem(30),
    },

    [breakpoints.down.md]: {
      fontSize: typography.pxToRem(24),
    },
  })
);

export const ParagraphWithTopBorderStyled = styled(Paragraph)(
  ({ theme: { spacing, typography } }) => ({
    paddingTop: spacing(0.5),
    borderTop: `1px solid ${blue[800]}`,
    fontWeight: typography.fontWeight.bold,
  })
);
