import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { constructMeta } from '@/modules/reactQuery/constructMeta';
import { editSupplierContactMutation } from '@/modules/suppliers/api/mutations/editSupplierContactMutation';

export const useEditSupplierContactMutation = () => {
  const jsonApi = useJsonApi();
  const { t } = useTranslation('apiMessages/useEditSupplierContactMutation');

  return useMutation({
    mutationKey: [editSupplierContactMutation.name],
    mutationFn: editSupplierContactMutation(jsonApi),
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t("You don't have access edit contacts"),
        },
        404: {
          CONTACT_NOT_FOUND: t(
            'Contact not found. Please refresh the page or contact the administrator.'
          ),
        },
      },
    }),
  });
};
