import { useInfiniteQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { queryKeys } from '@/modules/api/queryKeys';
import { getNegotiationSuppliersQuery } from '@/modules/negotiation/api/queries/getNegotiationSuppliersQuery';
import { NegotiationSuppliersIncludes } from '@/modules/negotiation/api/resources/negotiationSelectedSuppliers/negotiationSelectedSuppliersResourceConsts';
import { constructMeta } from '@/modules/reactQuery/constructMeta';

// `pageSize` doesn't affect current middle-layer performance
// as it downloads whole list of suppliers on proxy side
const pageSize = 100;

type UseGetNegotiationSuppliersInfiniteQueryPayload = {
  negotiationId: string;
  enabled: boolean;
};

const sortByCurrentSpendDescending = '-currentSpend';

export const useGetNegotiationSuppliersInfiniteQuery = ({
  negotiationId,
  enabled,
}: UseGetNegotiationSuppliersInfiniteQueryPayload) => {
  const jsonApi = useJsonApi();
  const { t } = useTranslation('apiMessages/useGetNegotiationSuppliersQuery');

  return useInfiniteQuery({
    initialPageParam: 1,
    queryKey: [
      queryKeys.negotiations.getNegotiationSuppliersInfinite,
      negotiationId,
    ],
    queryFn: ({ pageParam = 1 }) =>
      getNegotiationSuppliersQuery(jsonApi, {
        negotiationId,
        page: pageParam,
        sort: sortByCurrentSpendDescending,
        pageSize,
        includes: [
          NegotiationSuppliersIncludes.DEFAULT_CONTACT,
          NegotiationSuppliersIncludes.NEGOTIATION_PROGRESS,
          NegotiationSuppliersIncludes.CATEGORY,
        ],
      })(),
    enabled,
    staleTime: 5000,
    getNextPageParam: (lastPage, allPages) => {
      const currentMaxItemCount = allPages.length * pageSize;
      // https://tanstack.com/query/v4/docs/react/reference/useInfiniteQuery
      if (currentMaxItemCount >= lastPage.total) return undefined;
      return allPages.length + 1;
    },
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t('You do not have access to see selected suppliers'),
        },
        404: {
          NEGOTIATION_NOT_FOUND: t('Negotiation has not been found'),
        },
      },
    }),
  });
};
