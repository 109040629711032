import styled from 'styled-components';

import { Button } from '@/modules/theme/components/Button/Button';
import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';
import { Heading, Text } from '@/modules/theme/components/Typography';
import { white } from '@/modules/theme/utils/colors';

import { ReactComponent as Portfolio } from './portfolio.svg';

export const ContainerStyled = styled('div')(
  ({ theme: { spacing, breakpoints } }) => ({
    display: 'flex',
    padding: spacing([7, 7, 9, 7]),
    gap: spacing(3),
    flexWrap: 'wrap',
    [breakpoints.down.md]: {
      padding: spacing([7, 2, 9, 2]),
    },
  })
);

export const SpendAmountStyled = styled(Heading)(
  ({ theme: { spacing, palette } }) => ({
    color: palette.tertiary.main,
    paddingTop: spacing(2),
  })
);

export const PortfolioStyled = styled(Portfolio)(({ theme: { spacing } }) => ({
  marginTop: spacing(11.5),
}));

export const SupplierInfoContentStyled = styled('div')(
  ({ theme: { breakpoints, spacing } }) => ({
    display: 'grid',
    flex: 1,
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'repeat(6, min-content)',
    gridAutoFlow: 'column',
    gridColumnGap: spacing(1),
    [breakpoints.down.md]: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'none',
      gridAutoFlow: 'row',
    },
  })
);

export const PortfolioContentStyled = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  alignItems: 'center',
});

export const DataContainerStyled = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(2),
  paddingBottom: spacing(3),
}));

export const NegotiationsContainerStyled = styled('div')(
  ({ theme: { spacing } }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(0.5),
    paddingBottom: spacing(3),
  })
);

export const NegotiationsLabelStyled = styled(Text)(
  ({ theme: { spacing } }) => ({
    marginBottom: spacing(2),
  })
);

export const BackButtonStyled = styled(Button)(({ theme: { spacing } }) => ({
  display: 'block',
  margin: '0 auto',
  marginBottom: spacing(6),
}));

export const TextStyled = styled(Text)(
  ({ theme: { spacing, typography } }) => ({
    display: 'inline-block',
    color: white,
    marginRight: spacing(1),
    fontWeight: typography.fontWeight.bold,
  })
);

export const SpendSkeletonLoaderStyled = styled(SkeletonLoader)({
  height: 45,
  width: 230,
});

export const NegotiationsSkeletonLoaderStyled = styled(SkeletonLoader)({
  height: 24,
  width: 150,
});
