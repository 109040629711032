import { useTranslation } from 'react-i18next';

import { Button } from '@/modules/theme/components/Button/Button';
import { Dialog } from '@/modules/theme/components/Dialog/Dialog';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';

import { useSupplierFormContext } from '../../hooks/useSupplierFormContext';
import { getContactPersonDisplayLabel } from '../../utils/getContactPersonDisplayedLabel';
import { ContactPerson } from '../ContactPersonFormDialog/contactPersonFormStruct';
import { FormMode } from '../ContactPersonFormDialog/EditContactPersonFormDialog';

import { useDeleteSupplierContactMutation } from './useDeleteSupplierContactMutation';
type RemoveContactPersonDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onRemoveContactPerson: ({ id }: { id: string }) => void;
  contactPerson: ContactPerson | null;
  supplierFormMode?: FormMode;
};

export const RemoveContactPersonDialog = ({
  isOpen,
  onClose,
  contactPerson,
  supplierFormMode,
  onRemoveContactPerson,
}: RemoveContactPersonDialogProps) => {
  const { t } = useTranslation('modules/suppliers/SupplierForm');
  const { setContacts } = useSupplierFormContext();

  const label =
    getContactPersonDisplayLabel(contactPerson) || t('Unknown contact person');

  const removeContactFromContext = ({ id }: { id: string }) => {
    setContacts((prev) => {
      if (prev) {
        return prev.filter((contact) => contact.id !== id);
      }
      return null;
    });
  };

  const onRemoveSupplierContactSuccess = () => {
    if (contactPerson?.id) {
      onRemoveContactPerson({ id: contactPerson.id });
      removeContactFromContext({ id: contactPerson.id });
    }
    onClose();
  };

  const deleteSupplierContactMutation = useDeleteSupplierContactMutation({
    onSuccess: onRemoveSupplierContactSuccess,
  });

  const handleRemoveContactPerson = () => {
    if (contactPerson?.id) {
      if (supplierFormMode === 'add') {
        removeContactFromContext({
          id: contactPerson.id,
        });
        onClose();
        return;
      }
      deleteSupplierContactMutation.mutate({ id: contactPerson.id });
    }
  };

  return (
    <Dialog
      isOpen={isOpen}
      hasCloseIcon
      onClose={onClose}
      title={t('Remove {{personName}}', { personName: label })}
      description={t('Are you sure you want to remove this contact person?')}
    >
      <FlexContainer gap={2}>
        <Button variant="outline" onClick={onClose} size="small">
          {t('No')}
        </Button>
        <Button
          onClick={handleRemoveContactPerson}
          size="small"
          loading={deleteSupplierContactMutation.isPending}
        >
          {t('Yes')}
        </Button>
      </FlexContainer>
    </Dialog>
  );
};
