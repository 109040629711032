import toast from 'react-hot-toast';

import { AlertToast } from './AlertToast/AlertToast';
import { Notify } from './types';

export const notify: Notify = {
  alert: {
    success: (content, options) => {
      return toast.custom(
        (t) => (
          <AlertToast rhtToastProps={t} variant={'success'} content={content} />
        ),
        options
      );
    },
    error: (content, options) => {
      return toast.custom(
        (t) => (
          <AlertToast rhtToastProps={t} variant={'error'} content={content} />
        ),
        options
      );
    },
  },
  dismiss: (toastId: string) => {
    toast.dismiss(toastId);
  },
  remove: (toastId: string) => {
    toast.remove(toastId);
  },
};
