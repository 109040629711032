import { defaultPrefixedSuppliersQueryParams } from '@/modules/negotiation/helpers/defaultPrefixedSuppliersQueryParams';
import {
  NegotiationState,
  ParsedNegotiationItem,
} from '@/modules/negotiation/types/negotiationTypes';
import {
  onboardedRoutes,
  plannedNegotiationRoutes,
  setupNegotiationRoutes,
} from '@/modules/router/onboardedRoutes';
import { defaultSuppliersQueryParams } from '@/modules/suppliers/helpers/defaultSuppliersQueryParams';

export const getNegotiationDetailsPath = (
  negotiation: ParsedNegotiationItem
) => {
  const { state, id } = negotiation;
  if (
    state === NegotiationState.SUGGESTED ||
    state === NegotiationState.SETUP
  ) {
    return setupNegotiationRoutes.buildUrl('startDate', {
      params: { negotiationId: id },
    });
  }
  if (state === NegotiationState.PLANNED) {
    return plannedNegotiationRoutes.buildUrl('suppliers', {
      params: { negotiationId: id },
      query: defaultSuppliersQueryParams,
    });
  }
  if (state === NegotiationState.ACTIVE) {
    return onboardedRoutes.buildUrl('activeNegotiation', {
      params: { negotiationId: id },
      query: defaultPrefixedSuppliersQueryParams,
    });
  }
  if (state === NegotiationState.COMPLETED) {
    return onboardedRoutes.buildUrl('completedNegotiation', {
      params: { negotiationId: id },
      query: defaultPrefixedSuppliersQueryParams,
    });
  }
};
