import { Dialog } from '@headlessui/react';
import styled from 'styled-components';

import { ReactComponent as CloseIcon } from '@/assets/icons/closeIcon.svg';
import { black, blue } from '@/modules/theme/utils/colors';
import { fade } from '@/modules/theme/utils/fade';

export const OverlayStyled = styled(Dialog.Overlay)(
  ({ theme: { zIndexes } }) => ({
    position: 'fixed',
    backgroundColor: fade(black, 0.5),
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: zIndexes.dialogOverlay,
  })
);

export type DialogVariant = 'small' | 'default' | 'large';

type ContentWrapperStyledProps = {
  variant: DialogVariant;
};

export const ContentWrapperStyled = styled('div')<ContentWrapperStyledProps>(
  ({
    theme: { spacing, palette, dialog, zIndexes, borderRadius, breakpoints },
    variant,
  }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: spacing(1),
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: spacing(7),
    backgroundColor: palette.default.background,
    borderRadius: borderRadius.small,
    zIndex: zIndexes.dialogContent,
    width: dialog[variant],
    [breakpoints.down.sm]: {
      width: dialog.small,
    },
  })
);

export const TitleStyled = styled(Dialog.Title)(
  ({ theme: { typography, spacing } }) => ({
    fontSize: typography.pxToRem(22),
    marginBottom: spacing(2),
  })
);

export const DescriptionStyled = styled(Dialog.Description)(
  ({ theme: { spacing, typography } }) => ({
    color: blue[800],
    fontSize: typography.pxToRem(16),
    marginBottom: spacing(3),
    lineHeight: typography.fontSize.large,
    fontWeight: typography.fontWeight.bold,
    textAlign: 'center',
  })
);

export const CloseIconStyled = styled(CloseIcon)(({ theme: { spacing } }) => ({
  position: 'absolute',
  top: spacing(4),
  right: spacing(4),
  cursor: 'pointer',
  width: 24,
  height: 24,
}));
