import styled from 'styled-components';

import { ReactComponent as Logo } from '@/assets/signet.svg';

export const ContentWrapperStyled = styled('div')(
  ({ theme: { breakpoints, spacing } }) => ({
    padding: spacing([5, 6, 10, 5]),
    position: 'relative',

    [breakpoints.down.md]: {
      padding: spacing([2, 2, 4]),
    },
  })
);

export const LogoStyled = styled(Logo)(({ theme: { zIndexes } }) => ({
  position: 'absolute',
  right: 0,
  top: 185,
  zIndex: zIndexes.background,
}));
