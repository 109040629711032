import styled, { CSSObject } from 'styled-components';

import { blue, white } from '@/modules/theme/utils/colors';

import { ButtonSizes, ButtonVariants } from './Button';

type ButtonStyledProps = {
  size?: ButtonSizes;
  variant?: ButtonVariants;
};

export const ButtonStyled = styled('button')<ButtonStyledProps>(({
  size,
  variant,
  theme: { palette, spacing, borderRadius, typography },
}) => {
  const defaultStyles: CSSObject = {
    border: 'none',
    backgroundColor: palette.tertiary.main,
    color: palette.tertiary.text,
    '&[disabled]': {
      backgroundColor: palette.disabled.main,
      color: palette.disabled.text,
      cursor: 'not-allowed',
    },
    '&:not([disabled])': {
      cursor: 'pointer',
    },

    '&:not([disabled]):hover': {
      backgroundColor: palette.tertiary.light,
    },
    '&:not([disabled]):focus, &:not([disabled]):active': {
      backgroundColor: palette.tertiary.dark,
    },
  };

  const outlinedStyles: CSSObject = {
    border: `2px solid ${palette.primary.main}`,
    backgroundColor: 'inherit',
    color: palette.primary.text,
    '&[disabled]': {
      borderColor: palette.disabled.main,
      color: palette.disabled.main,
      cursor: 'not-allowed',
    },
    '&:not([disabled])': {
      cursor: 'pointer',
    },

    '&:not([disabled]):hover': {
      borderColor: palette.primary.lighter,
      color: palette.primary.lighter,
    },

    '&:not([disabled]):focus, &:not([disabled]):active': {
      borderColor: palette.primary.dark,
      color: palette.primary.dark,
    },
  };

  const lightStyles: CSSObject = {
    border: `2px solid ${white}`,
    backgroundColor: 'transparent',
    color: white,
    '&[disabled]': {
      borderColor: palette.disabled.main,
      color: palette.disabled.main,
      cursor: 'not-allowed',
    },
    '&:not([disabled])': {
      cursor: 'pointer',
    },
    '&:not([disabled]):hover': {
      opacity: 0.7,
    },
    '&:not([disabled]):focus, &:not([disabled]):active': {
      opacity: 0.7,
    },
  };

  const textStyles: CSSObject = {
    border: 'none',
    appearance: 'none',
    backgroundColor: 'transparent',
    padding: 0,
    textDecoration: 'underline',
    color: blue[800],
    minHeight: 'auto',
    minWidth: 'auto',
    fontWeight: typography.fontWeight.normal,
    '&[disabled]': {
      color: palette.disabled.main,
      cursor: 'not-allowed',
    },

    '&:not([disabled])': {
      cursor: 'pointer',
    },

    '&:not([disabled]):hover, &:not([disabled]):focus, &:not([disabled]):active':
      {
        opacity: 0.8,
      },
  };

  return {
    fontFamily: typography.fonts.body,
    padding: spacing(2),
    minHeight: spacing(7),
    position: 'relative',
    borderRadius: borderRadius.default,
    transition: 'background-color 0.3s, border-color 0.3s, color 0.3s',
    fontSize: typography.pxToRem(16),
    fontWeight: typography.fontWeight.bold,
    ...(variant === 'default' && defaultStyles),
    ...(variant === 'outline' && outlinedStyles),
    ...(variant === 'light' && lightStyles),
    ...(size === 'fullWidth'
      ? {
          width: '100%',
        }
      : size === 'small'
      ? {
          minWidth: 173,
        }
      : { minWidth: 304 }),
    ...(variant === 'text' && textStyles),
  };
});

type LoaderWrapperStyledProps = {
  variant: ButtonVariants;
};

export const LoaderWrapperStyled = styled('div')<LoaderWrapperStyledProps>(
  ({ variant }) => ({
    ...(variant !== 'text' && {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
    }),
  })
);
