import { useTranslation } from 'react-i18next';

import { NegotiationTermRole } from '@/modules/negotiation/types/negotiationTypes';

import {
  termRoleImportanceOrder,
  termRoleToIconMapper,
} from '../helpers/consts';

export const useParseTermRoles = (termRoles: NegotiationTermRole[]) => {
  const { t } = useTranslation('pages/SuggestedNegotiationsPage');

  const termRoleToTextMapper = {
    [NegotiationTermRole.EXTEND_INVOICE_DUE_DATE]: t('Extra payment days'),
    [NegotiationTermRole.OFFER_DISCOUNT]: t('Improved prices'),
    [NegotiationTermRole.LOCK_PRICE]: t('Locked prices'),
    [NegotiationTermRole.SIGN_CODE_OF_CONDUCT]: t('Code of Conduct'),
    [NegotiationTermRole.ANSWER_SUSTAINABILITY_QUESTION]: t(
      'Supplier questionnaire'
    ),
    [NegotiationTermRole.SIGN_STANDARD_AGREEMENT]: t('Standard agreement'),
    [NegotiationTermRole.BE_ISO_CERTIFIED]: t('ISO'),
    [NegotiationTermRole.ACCEPT_CONTRACT_PERIOD]: null,
    [NegotiationTermRole.SELECT_DISCOUNT_IMPLEMENTATION]: null,
    [NegotiationTermRole.EXTEND_CONTRACT_PERIOD]: null,
    [NegotiationTermRole.ANSWER_QUESTION]: null,
    [NegotiationTermRole.TEXT]: null,
  };

  return termRoles
    .map((termRole) => {
      const Icon = termRoleToIconMapper[termRole];
      const termRoleText = termRoleToTextMapper[termRole];
      return Icon && termRoleText ? { Icon, termRoleText, termRole } : null;
    })
    .filter(Boolean)
    .sort(
      (first, second) =>
        termRoleImportanceOrder[first.termRole] -
        termRoleImportanceOrder[second.termRole]
    );
};
