import { sanitizeHtml } from '@/utils/sanitizer';

import { GetNegotiationResponse } from '../api/queries/getNegotiationQuery';
import { NegotiationTermResource } from '../api/resources/negotiationTerm/negotiationTermResourceStruct';
import { NegotiationTermTypes } from '../types/negotiationTypes';

export const parseNegotiationTerms = (
  included: GetNegotiationResponse['included']
) => {
  const negotiationTermItems = included
    .filter(
      (item): item is NegotiationTermResource =>
        Object.values(NegotiationTermTypes).includes(
          item.type as NegotiationTermResource['type']
        )
      // https://github.com/microsoft/TypeScript/issues/31018
    )
    .map(({ id, type, attributes }) => {
      if (type === NegotiationTermTypes.offerDiscount) {
        return { id, type };
      }

      const template = sanitizeHtml(attributes.template || '');
      const text = sanitizeHtml(attributes.text || '');

      if (type === NegotiationTermTypes.selectDiscountImplementation) {
        return {
          id,
          type,
          text,
          template,
          implementations: attributes.implementations,
        };
      }

      if (type === NegotiationTermTypes.acceptContractPeriod) {
        return {
          id,
          type,
          text,
          template,
          startDate: attributes.startDate,
          endDate: attributes.endDate,
        };
      }

      if (type === NegotiationTermTypes.signCodeOfConduct) {
        return {
          id,
          type,
          text,
          template,
          document: attributes.document,
        };
      }

      return { id, type, text, template };
    });

  return negotiationTermItems;
};
