import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Text } from '@/modules/theme/components/Typography';

export const TermImportanceLabels = () => {
  const { t } = useTranslation('pages/UploadSpendAndQuestionnairePage');

  return (
    <>
      <TermImportanceLabelStyled variant="small">
        {t('Not important')}
      </TermImportanceLabelStyled>
      <Text variant="small">{t('Interesting')}</Text>
      <Text variant="small">{t('Important')}</Text>
    </>
  );
};

const TermImportanceLabelStyled = styled(Text)({
  gridColumnStart: 2,
});
