import styled from 'styled-components';

import { Text } from '@/modules/theme/components/Typography';

export const ContainerWrapperStyled = styled('div')({
  width: '100%',
});

export const UploadedContainerStyled = styled('div')(
  ({ theme: { spacing } }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 300,
    margin: '0 auto',
    gap: spacing(3.5),
  })
);

export const ErrorMessageStyled = styled(Text)(({ theme: { palette } }) => ({
  color: palette.error.main,
}));
