import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { queryKeys } from '@/modules/api/queryKeys';
import { removeSupplierFromNegotiation } from '@/modules/negotiation/api/mutations/removeSupplierFromNegotiation';
import { constructMeta } from '@/modules/reactQuery/constructMeta';

export const useRemoveSupplierFromNegotiationMutation = () => {
  const jsonApi = useJsonApi();
  const { t } = useTranslation(
    'apiMessages/useRemoveSupplierFromNegotiationMutation'
  );

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: removeSupplierFromNegotiation(jsonApi),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.negotiations.getNegotiationSuppliers],
      });
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.negotiations.getNegotiation],
      });
    },
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHORIZED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t("You don't have access to remove suppliers"),
        },
        404: {
          NEGOTIATION_NOT_FOUND: t(
            'Negotiation not found. Please refresh the page or contact the administrator'
          ),
        },
        422: {
          INVALID_NEGOTIATION_STATE: t('Invalid negotiation state'),
        },
      },
    }),
  });
};
