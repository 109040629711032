import { IAllProps as TinyMceEditorProps } from '@tinymce/tinymce-react';
import { Suspense, lazy, ComponentType, ReactNode } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import {
  EditorCardStyled,
  EditorWrapperStyled,
  SkeletonLoaderStyled,
} from './Editor.styled';
import { editorConfig } from './editorConsts';

const BundledEditor = lazy(
  () =>
    import('./BundledEditor') as Promise<{
      default: ComponentType<TinyMceEditorProps>;
    }>
);

type EditorProps = {
  name: string;
  id?: string;
  loading?: boolean;
  Skeleton?: JSX.Element;
  className?: string;
  children?: ReactNode;
};

export const Editor = ({
  name,
  id,
  loading = false,
  Skeleton = <SkeletonLoaderStyled />,
  className,
  children,
}: EditorProps) => {
  const { control } = useFormContext();
  const {
    // `ref` can't be passed to the editor because it's not a `forwardRef` component
    field: { ref, onChange: onEditorChange, ...fieldProps },
  } = useController({ control, name });

  if (loading) {
    return Skeleton;
  }

  return (
    <Suspense fallback={Skeleton}>
      <EditorCardStyled className={className}>
        <EditorWrapperStyled>
          <BundledEditor
            id={id}
            onEditorChange={onEditorChange}
            {...fieldProps}
            init={editorConfig}
          />
        </EditorWrapperStyled>
        {children}
      </EditorCardStyled>
    </Suspense>
  );
};
