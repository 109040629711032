import styled from 'styled-components';

import { TextInput } from '../TextInput/TextInput';

export const TextInputStyled = styled(TextInput)(
  ({ theme: { spacing, borderRadius, typography, palette } }) => ({
    borderRadius: borderRadius.small,
    fontFamily: typography.fonts.body,
    width: 300,
    marginRight: spacing(-1),
    // hide picker indicator on android devices
    WebkitAppearance: 'none',
    '&::-webkit-calendar-picker-indicator': {
      opacity: 0,
      transform: 'scale(1.6)',
      cursor: 'pointer',
    },
    '&:invalid::-webkit-datetime-edit-day-field, &:invalid::-webkit-datetime-edit-month-field, &:invalid::-webkit-datetime-edit-year-field':
      {
        color: palette.error.main,
      },
  })
);

export const InputsWrapperStyled = styled('div')({
  position: 'relative',
});
