import styled from 'styled-components';

export const RichContentTermStyled = styled('div')(
  ({ theme: { typography, palette } }) => ({
    color: palette.primary.text,
    fontWeight: typography.fontWeight.bold,
    lineHeight: typography.fontSize.large,
    letterSpacing: typography.letterSpacing.default,
    '& a': {
      color: palette.secondary.main,
    },
    '& strong': {
      fontWeight: typography.fontWeight.extraBold,
    },
    '& em': {
      fontStyle: 'italic',
    },
  })
);
