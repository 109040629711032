import styled from 'styled-components';

type TableStyledProps = {
  columnsCount: number;
};

export const TableStyled = styled('div')<TableStyledProps>(
  ({ theme: { breakpoints }, columnsCount }) => ({
    width: '100%',
    display: 'grid',
    position: 'relative',
    gridTemplateColumns: `repeat(${columnsCount}, minmax(min-content, 1fr))`,
    [breakpoints.down.md]: {
      display: 'block',
    },
  })
);

export const TableHeader = styled('div')({
  display: 'contents',
});

export const TableHeaderCell = styled('div')(
  ({ theme: { palette, breakpoints, spacing, typography, zIndexes } }) => ({
    backgroundColor: palette.default.background,
    color: palette.secondary.main,
    padding: spacing([2, 0, 2, 2]),
    position: 'sticky',
    top: 0,
    fontSize: typography.pxToRem(14),
    zIndex: zIndexes.tableHeader,

    [breakpoints.down.md]: {
      display: 'none',
    },
  })
);
