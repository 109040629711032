export type GetNegotiationSuppliersPayload = {
  negotiationId: string;
  page: number;
  pageSize?: number;
  negotiationStates?: SupplierNegotiationState[];
  searchQuery?: string;
  businessUnit?: string[];
  category?: string[];
  sort?: string;
  includes?: NegotiationSuppliersIncludes[];
};

export const getSelectNegotiationSuppliersPath = (id: string) => {
  return `me/customer/negotiations/${id}/relationships/selected_suppliers`;
};

export enum SupplierNegotiationState {
  ANSWERED = 'answered',
  EXCLUDED = 'excluded',
  INVITED = 'invited',
  OPENEDEMAIL = 'openedEmail',
  RECOMMENDED = 'recommended',
  REGISTERED = 'registered',
  VISITED = 'visited',
}

export enum NegotiationSuppliersIncludes {
  BUSINESS_UNIT = 'businessUnit',
  CATEGORY = 'category',
  CONFLICT_NEGOTIATIONS = 'conflictNegotiations',
  DEFAULT_CONTACT = 'defaultContact',
  NEGOTIATION_PROGRESS = 'negotiationProgress',
  NEGOTIATION_PROGRESS_ANSWERED_TERMS = 'negotiationProgress.answeredTerms',
}
