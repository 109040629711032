import { NegotiationWithProgressSuppliersOrder } from '../types/negotiationTypes';

type DefaultPrefixedSuppliersQueryParams = {
  'suppliers.page': number;
  'suppliers.size': number;
  'suppliers.sort': NegotiationWithProgressSuppliersOrder;
};

export const defaultPrefixedSuppliersQueryParams: DefaultPrefixedSuppliersQueryParams =
  {
    'suppliers.page': 1,
    'suppliers.size': 20,
    'suppliers.sort': 'name',
  };
