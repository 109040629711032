import styled from 'styled-components';

import { AuthenticatedNotFoundLayout } from '@/layouts/AuthenticatedLayout/AuthenticatedNotFoundLayout';
import { NotFoundSection } from '@/modules/theme/components/NotFoundSection/NotFoundSection';

export function AuthenticatedNotFoundPage() {
  return (
    <AuthenticatedNotFoundLayout>
      <NotFoundSection />
    </AuthenticatedNotFoundLayout>
  );
}

export const TitleStyled = styled('h2')(
  ({ theme: { typography, spacing } }) => ({
    fontSize: typography.pxToRem(20),
    margin: spacing([2, 0]),
    textAlign: 'center',
  })
);
