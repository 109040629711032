import styled, { css } from 'styled-components';

import { commonSpacings, SpacingProps } from '../../utils/commonSpacings';

import { CommonTypographyProps } from './helpers/types';

type Alignment = 'left' | 'center' | 'right' | 'justify';

export type ParagraphProps = CommonTypographyProps &
  SpacingProps & {
    variant?: 'contentBold' | 'contentNormal' | 'contentSmall';
    alignment?: Alignment;
  };

export const Paragraph: React.FC<ParagraphProps> = ({
  children,
  variant,
  ...props
}) => {
  switch (variant) {
    case 'contentBold':
      return <ContentBoldStyled {...props}>{children}</ContentBoldStyled>;
    case 'contentNormal':
      return <ContentNormalStyled {...props}>{children}</ContentNormalStyled>;
    case 'contentSmall':
      return <ContentSmallStyled {...props}>{children}</ContentSmallStyled>;
    default:
      return <ContentNormalStyled {...props}>{children}</ContentNormalStyled>;
  }
};

type BaseStylesProps = {
  color?: string;
  alignment?: Alignment;
};

const baseStyles = css<BaseStylesProps & SpacingProps>(
  () => commonSpacings,
  ({ theme: { typography, palette }, color, alignment = 'left' }) => ({
    color: color ? color : palette.primary.main,
    fontFamily: typography.fonts.body,
    letterSpacing: typography.letterSpacing.default,
    textAlign: alignment,
  })
);

const ContentBoldStyled = styled('p')<ParagraphProps>(
  () => baseStyles,
  ({ theme: { typography } }) => {
    return {
      fontSize: typography.fontSize.default,
      lineHeight: typography.fontSize.large,
      fontWeight: typography.fontWeight.bold,
    };
  }
);

const ContentNormalStyled = styled('p')<ParagraphProps>(
  () => baseStyles,
  ({ theme: { typography } }) => {
    return {
      fontSize: typography.fontSize.default,
      lineHeight: typography.fontSize.large,
      fontWeight: typography.fontWeight.normal,
    };
  }
);

const ContentSmallStyled = styled('p')<ParagraphProps>(
  () => baseStyles,
  ({ theme: { typography } }) => {
    return {
      fontSize: typography.pxToRem(14),
      lineHeight: typography.pxToRem(21),
      fontWeight: typography.fontWeight.normal,
    };
  }
);
