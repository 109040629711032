import styled from 'styled-components';

import { DebouncedDateInput } from '@/modules/form/components/DateInput/DebouncedDateInput';
import { Text } from '@/modules/theme/components/Typography';
import { dark } from '@/modules/theme/utils/colors';

export const ContentWrapperStyled = styled('div')(({ theme: { spacing } }) => ({
  paddingTop: spacing(7),
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 624,
  width: '100%',
}));

export const DebouncedDateInputStyled = styled(DebouncedDateInput)({
  maxWidth: 303,
});

export const InfoTextStyled = styled(Text)(({ theme: { spacing } }) => ({
  marginTop: spacing(2),
  color: dark[400],
}));

export const EndDateStyled = styled(Text)(({ theme: { typography } }) => ({
  fontWeight: typography.fontWeight.bold,
}));

export const TextErrorStyled = styled(Text)(
  ({ theme: { palette, spacing } }) => ({
    color: palette.error.main,
    textAlign: 'center',
    paddingTop: spacing(5),
  })
);
