import { ReactNode } from 'react';

import { DashedButtonStyled, HeadingStyled } from './AddTermButton.styled';

type AddTermButtonProps = {
  children: ReactNode;
  onClick: () => void;
};

export const AddTermButton = ({ children, onClick }: AddTermButtonProps) => {
  return (
    <DashedButtonStyled size="small" onClick={onClick}>
      <HeadingStyled variant="h3" as="p">
        {children}
      </HeadingStyled>
    </DashedButtonStyled>
  );
};
