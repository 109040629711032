import { ParsedNegotiation } from '@/modules/negotiation/helpers/parseGetNegotiationResponse';
import {
  NegotiationTermRole,
  NegotiationState,
} from '@/modules/negotiation/types/negotiationTypes';

import { negotiationMetrics, negotiationTypeRoles } from './consts';
import { mapTermRolesToMetrics } from './helpers';

export const getNegotiationDetailsMetrics = (
  negotiation: ParsedNegotiation
): Set<string> => {
  const { termRoles, state } = negotiation;

  const hasNegotiationStarted =
    state === NegotiationState.COMPLETED || state === NegotiationState.ACTIVE;

  const hasOfferDiscount = termRoles.includes(
    NegotiationTermRole.OFFER_DISCOUNT
  );
  const hasLockPrice = termRoles.includes(NegotiationTermRole.LOCK_PRICE);
  const hasExtendContractPeriod = termRoles.includes(
    NegotiationTermRole.EXTEND_CONTRACT_PERIOD
  );

  // we only care about roles that are related to negotiation types
  const isStandAlone =
    termRoles.filter((role) => negotiationTypeRoles.includes(role)).length <= 1;

  if ((hasOfferDiscount || hasExtendContractPeriod) && isStandAlone) {
    return mapTermRolesToMetrics({ termRoles, hasNegotiationStarted });
  }

  if (hasLockPrice || hasOfferDiscount) {
    const metrics = mapTermRolesToMetrics({
      termRoles,
      hasNegotiationStarted,
    });
    // edge-case: we don't want to show `Discounts` due to business needs
    metrics.delete(negotiationMetrics.discountCount);
    return metrics;
  }

  return hasNegotiationStarted
    ? new Set(mapTermRolesToMetrics({ termRoles, hasNegotiationStarted }))
    : new Set([negotiationMetrics.suppliersCount]);
};
