import { SkeletonTheme } from 'react-loading-skeleton';

import 'react-loading-skeleton/dist/skeleton.css';
import { dark } from '@/modules/theme/utils/colors';

import { SkeletonLoaderStyled } from './SkeletonLoader.styled';

interface SkeletonLoaderProps {
  lines?: number;
  height?: number;
  width?: number | string;
  className?: string;
}

export const SkeletonLoader = ({
  lines = 1,
  height,
  width,
  className,
}: SkeletonLoaderProps) => {
  return (
    <SkeletonTheme baseColor={dark[200]} highlightColor={dark[50]}>
      {Array.from(Array(lines).keys()).map((line) => (
        <SkeletonLoaderStyled
          key={line}
          height={height}
          width={width}
          className={className}
        />
      ))}
    </SkeletonTheme>
  );
};
