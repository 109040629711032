import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AccessibleSvg } from '@/modules/theme/components/AccessibleSvg';

import { ReactComponent as EyeClosed } from './icons/eyeClosed.svg';
import { ReactComponent as EyeOpen } from './icons/eyeOpen.svg';

type ToggleVisibilityButtonProps = {
  isVisible: boolean;
  togglePasswordVisibility: () => void;
};

export const ToggleVisibilityButton: React.FC<ToggleVisibilityButtonProps> = ({
  isVisible,
  togglePasswordVisibility,
}) => {
  const { t } = useTranslation('layouts/Common/ToggleVisibilityButton');
  return (
    <VisibilityButtonStyled onClick={togglePasswordVisibility} type="button">
      {isVisible ? (
        <AccessibleSvg
          title={t('Hide password icon')}
          description={t('It hides the password')}
          SvgComponent={EyeClosed}
        />
      ) : (
        <AccessibleSvg
          title={t('Show password icon')}
          description={t('It shows the password')}
          SvgComponent={EyeOpen}
        />
      )}
    </VisibilityButtonStyled>
  );
};

const VisibilityButtonStyled = styled('button')({
  display: 'flex',
  padding: 0,
  border: 0,
  background: 'transparent',
  color: 'inherit',
  cursor: 'pointer',
});
