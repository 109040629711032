import { TabLinkStyled, TabsListStyled } from './Tabs.styled';
import { TabVariant } from './types';

export type Tab = {
  link: string;
  text: string;
  end?: boolean;
  query?: string;
};

type TabsProps = {
  tabs: Tab[];
  variant?: TabVariant;
  className?: string;
  onClick?: (link: string) => void;
};

export const Tabs = ({
  tabs,
  variant = 'default',
  className,
  onClick,
}: TabsProps) => {
  return (
    <nav className={className}>
      <TabsListStyled>
        {tabs.map(({ link, text, end, query }, index) => (
          <li key={text} onClick={onClick && (() => onClick(link))}>
            <TabLinkStyled
              to={{ pathname: link, search: query }}
              end={end}
              variant={variant}
              $layer={index}
            >
              {text}
              <div className="blind" />
            </TabLinkStyled>
          </li>
        ))}
      </TabsListStyled>
    </nav>
  );
};
