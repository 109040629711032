import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { queryKeys } from '@/modules/api/queryKeys';
import { getNegotiationSuppliersQuery } from '@/modules/negotiation/api/queries/getNegotiationSuppliersQuery';
import { NegotiationSuppliersIncludes } from '@/modules/negotiation/api/resources/negotiationSelectedSuppliers/negotiationSelectedSuppliersResourceConsts';
import { SupplierNegotiationState } from '@/modules/negotiation/api/resources/negotiationSelectedSuppliers/negotiationSelectedSuppliersResourceConsts';
import { constructMeta } from '@/modules/reactQuery/constructMeta';

type GetSelectedSuppliersQueryParams = {
  pageNumber: number;
  pageSize: number;
  searchQuery?: string;
  businessUnit?: string[];
  category?: string[];
  sort?: string;
  negotiationStates: SupplierNegotiationState[];
};

export type UseGetNegotiationSelectedSuppliersQueryPayload = {
  negotiationId: string;
  params: GetSelectedSuppliersQueryParams;
};

export const useGetNegotiationSelectedSuppliersQuery = ({
  negotiationId,
  params: {
    searchQuery,
    businessUnit,
    pageNumber,
    pageSize,
    sort,
    category,
    negotiationStates,
  },
}: UseGetNegotiationSelectedSuppliersQueryPayload) => {
  const jsonApi = useJsonApi();
  const { t } = useTranslation(
    'apiMessages/useGetNegotiationSelectedSuppliersQuery'
  );

  return useQuery({
    queryKey: [
      queryKeys.negotiations.getNegotiationSuppliers,
      negotiationId,
      searchQuery,
      businessUnit,
      pageNumber,
      pageSize,
      sort,
      category,
      negotiationStates,
    ],
    queryFn: getNegotiationSuppliersQuery(jsonApi, {
      negotiationId,
      page: pageNumber,
      pageSize: pageSize,
      negotiationStates,
      includes: [
        NegotiationSuppliersIncludes.DEFAULT_CONTACT,
        NegotiationSuppliersIncludes.BUSINESS_UNIT,
        NegotiationSuppliersIncludes.CATEGORY,
        NegotiationSuppliersIncludes.CONFLICT_NEGOTIATIONS,
      ],
      searchQuery,
      businessUnit,
      sort,
      category,
    }),
    placeholderData: keepPreviousData,
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t('You do not have access to see selected suppliers'),
        },
        404: {
          NEGOTIATION_NOT_FOUND: t('Negotiation has not been found'),
        },
      },
    }),
  });
};
