import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { NegotiationLayout } from '@/layouts/NegotiationLayout/NegotiationLayout';
import { useGetNegotiationQuery } from '@/modules/negotiation/hooks/useGetNegotiationQuery';
import { calculateDaysCountToGivenDate } from '@/modules/negotiation/utils/dateUtils';
import { basicErrorResponseValidator } from '@/modules/reactQuery/basicApiErrorValidator';
import { getApiErrorStatusCode } from '@/modules/reactQuery/validateApiError';
import { onboardedRoutes } from '@/modules/router/onboardedRoutes';
import { LinkType } from '@/modules/theme/components/ButtonLink';
import { HeaderButtonLink } from '@/modules/theme/components/HeaderButton';
import { PageMeta } from '@/modules/theme/components/PageMeta';
import { useDetectEnglishBrowser } from '@/utils/useDetectEnglishBrowser';

import { AuthenticatedNotFoundPage } from '../AuthenticatedNotFoundPage';

import { PlannedNegotiationDataPanel } from './components/PlannedNegotiationDataPanel';
import { useTabItems } from './hooks/useTabItems';
import {
  ButtonLinksWrapperStyled,
  ParagraphStyled,
  ParagraphSkeletonLoaderStyled,
  TabsStyled,
  ParagraphSkeletonWrapperStyled,
} from './PlannedNegotiationPage.styled';

export const PlannedNegotiationPage = () => {
  const { t } = useTranslation('pages/PlannedNegotiationPage');
  const isEnBrowser = useDetectEnglishBrowser();

  const { negotiationId } =
    onboardedRoutes.useGetRouteParams('plannedNegotiation');

  const { data, isPending, error } = useGetNegotiationQuery({
    negotiationId,
  });

  const parsedPreviewUrl = isEnBrowser
    ? data?.supplierPreviewUrl
    : data?.supplierPreviewUrl?.replace('/en/', '/');

  const tabs = useTabItems();

  const errorCode = getApiErrorStatusCode(error, basicErrorResponseValidator);
  if (errorCode === 404) {
    return <AuthenticatedNotFoundPage />;
  }

  return (
    <NegotiationLayout
      negotiationName={data?.title}
      negotiationState={data?.state}
      isLoading={isPending}
      buttonLink={
        <ButtonLinksWrapperStyled>
          {/* Removed for now (we don't want people to download the contactinfo without being a paying customer) */}
          {/* <SupplierExportButton negotiationId={negotiationId} /> */}
          <HeaderButtonLink
            to={onboardedRoutes.buildUrl('cpoLetter', {
              params: { negotiationId },
            })}
          >
            {t('Invitation letter')}
          </HeaderButtonLink>
          {parsedPreviewUrl && (
            <HeaderButtonLink
              to={parsedPreviewUrl}
              linkType={LinkType.External}
            >
              {t('Suppliers view')}
            </HeaderButtonLink>
          )}
          <HeaderButtonLink to={onboardedRoutes.buildUrl('dashboard')}>
            {t('Back')}
          </HeaderButtonLink>
        </ButtonLinksWrapperStyled>
      }
    >
      <>
        <PageMeta
          title={t('Planned negotiation')}
          description={t('Planned negotiation details page')}
        />
        {isPending ? (
          <ParagraphSkeletonWrapperStyled>
            <ParagraphSkeletonLoaderStyled />
          </ParagraphSkeletonWrapperStyled>
        ) : (
          data?.period?.startDate && (
            <ParagraphStyled>
              {t('Starts in {{daysToStartCount}} days', {
                daysToStartCount: calculateDaysCountToGivenDate(
                  data.period.startDate
                ),
              })}
            </ParagraphStyled>
          )
        )}
        <PlannedNegotiationDataPanel
          negotiation={data}
          shouldDisplayLoader={isPending}
        />
        <TabsStyled tabs={tabs} />
        <Outlet />
      </>
    </NegotiationLayout>
  );
};
