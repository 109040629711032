import { NegotiationState } from '../types/negotiationTypes';

export const START_DATE_WEEK_DAY_INDEX = 3;
export const DAYS_TO_NEGOTIATION_END_DATE = 9;

/** There are also
        
DRAFT = 'draft'
ESTIMATED = 'estimated'

negotiation states, but we don't want to include them 
on dashboard as they're only valuable on Analysis & Offer page
*/
export const launchedNegotiationStateFilters = [
  NegotiationState.SUGGESTED,
  NegotiationState.SETUP,
  NegotiationState.PLANNED,
  NegotiationState.ACTIVE,
  NegotiationState.COMPLETED,
];

export const negotiationWithProgressSuppliersOrders = [
  'name',
  '-name',
  'currentSpend',
  '-currentSpend',
  'negotiationProgress.state',
  '-negotiationProgress.state',
  'category.name',
  '-category.name',
  'defaultContact.email',
  '-defaultContact.email',
] as const;
