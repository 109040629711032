import styled from 'styled-components';

import { Text } from '@/modules/theme/components/Typography';
import { dark } from '@/modules/theme/utils/colors';

export const ContainerStyled = styled('div')(
  ({ theme: { spacing, boxShadows, borderRadius, palette } }) => ({
    display: 'grid',
    gridTemplateColumns: '200px 1fr',
    position: 'relative',
    minHeight: 60,
    boxShadow: boxShadows.filters,
    borderRadius: borderRadius.default,
    backgroundColor: palette.default.background,

    margin: spacing([2, 0, 7, 0]),
    padding: spacing([0.25, 0]),
  })
);

export const FiltersContainerStyled = styled('div')(
  ({ theme: { spacing } }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(170px, 1fr))',
    gap: spacing([1, 2]),
    margin: spacing([0, 3]),
    alignItems: 'center',
  })
);

export const FilterNegotiationsInfoStyled = styled(Text)(
  ({ theme: { spacing, typography } }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: spacing([0.25, 0]),
    borderRight: `2px solid ${dark[100]}`,
    height: '100%',
    fontWeight: typography.fontWeight.bold,
    color: dark[600],
  })
);
