import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';

import { useSupplierFormContext } from '@/modules/suppliers/hooks/useSupplierFormContext';
import { noop } from '@/utils/noop';

import { serializeContactData } from '../../utils/serializeContactData';

import { ContactPersonForm } from './ContactPersonForm';
import { DialogStyled } from './ContactPersonFormDialog.styled';
import {
  ContactPerson,
  ContactPersonFormType,
} from './contactPersonFormStruct';
import { useAddSupplierContactMutation } from './useAddSupplierContactMutation';

type ContactPersonFormDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  supplierId: string | null;
};

const defaultPersonFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
};

export const AddContactPersonFormDialog = ({
  isOpen,
  onClose,
  supplierId,
}: ContactPersonFormDialogProps) => {
  const { t } = useTranslation('modules/suppliers/SupplierForm');
  const { setContacts } = useSupplierFormContext();
  const addSupplierContactMutation = useAddSupplierContactMutation();

  const saveNewContactPersonInContext = (newContactPerson: ContactPerson) => {
    setContacts((prev) => {
      if (prev) return [...prev, newContactPerson];
      return [newContactPerson];
    });
  };

  const handleSubmit = async (attributes: ContactPersonFormType) => {
    if (supplierId) {
      try {
        const newContactPersonResponse =
          await addSupplierContactMutation.mutateAsync({
            attributes: serializeContactData(attributes),
            supplierId,
          });

        if (newContactPersonResponse) {
          const { id, attributes } = newContactPersonResponse.data;

          saveNewContactPersonInContext({
            ...attributes,
            id,
          });
        }
      } catch (err) {
        noop();
      }
    } else {
      saveNewContactPersonInContext({
        ...attributes,
        id: nanoid(),
      });
    }

    onClose();
  };

  return (
    <DialogStyled
      isOpen={isOpen}
      hasCloseIcon
      onClose={onClose}
      title={t('New contact')}
      variant="large"
    >
      <ContactPersonForm
        onSubmit={handleSubmit}
        defaultPersonFormValues={defaultPersonFormValues}
        buttonText={t('Add contact')}
        isLoading={addSupplierContactMutation.isPending}
      />
    </DialogStyled>
  );
};
