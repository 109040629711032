import styled from 'styled-components';

import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';
import { Text } from '@/modules/theme/components/Typography';
import { white } from '@/modules/theme/utils/colors';

export const ContainerStyled = styled('div')(
  ({ theme: { spacing, breakpoints } }) => ({
    padding: spacing([5, 7]),
    [breakpoints.down.md]: {
      padding: spacing([2, 2, 4]),
    },
  })
);

export const ContentHeaderStyled = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
});

export const HeaderPaginatorContainerStyled = styled('div')({
  justifySelf: 'center',
});

export const PerPageContainerStyled = styled('div')({
  justifySelf: 'right',
});

export const TextStyled = styled(Text)(({ theme: { typography } }) => ({
  color: white,
  fontWeight: typography.fontWeight.bold,
}));

export const SkeletonLoaderStyled = styled(SkeletonLoader)({
  height: 20,
  width: 100,
});
