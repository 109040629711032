import styled from 'styled-components';

import { blue } from '@/modules/theme/utils/colors';

export const MetricsWrapperStyled = styled('section')(
  ({ theme: { borderRadius, spacing } }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(350px, 1fr))',
    gap: spacing(2),
    backgroundColor: blue[200],
    borderRadius: borderRadius.default,
    padding: spacing(2),
    marginTop: spacing(2),
  })
);
