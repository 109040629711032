import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { signUpMutation } from '@/modules/auth/api/mutations/signUpMutation';
import { constructMeta } from '@/modules/reactQuery/constructMeta';
import { anonymousRoutes } from '@/modules/router/anonymousRoutes';

export const useSignUpMutation = () => {
  const jsonApi = useJsonApi();
  const { t } = useTranslation('apiMessages/useSignUpMutation');
  const navigate = useNavigate();

  return useMutation({
    mutationKey: [signUpMutation.name],
    mutationFn: signUpMutation(jsonApi),
    onSuccess: () => {
      navigate(anonymousRoutes.buildUrl('signUpSuccess'));
    },
    meta: constructMeta({
      errorMessages: {
        409: {
          EMAIL_TAKEN: t('Email address is already registered'),
        },
        422: {
          INVALID_CUSTOMER_TOKEN: t('Invalid customer token specified'),
        },
      },
    }),
  });
};
