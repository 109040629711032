import { useContext } from 'react';

import { authContext } from '../contexts/AuthContext';

export const useSessionContext = () => {
  const {
    customer,
    user,
    hasActiveSubscription,
    setHasActiveSubscription,
    signOut,
  } = useContext(authContext);

  if (!customer || !user) {
    throw new Error('Authenticated user data not found');
  }

  return {
    customer,
    user,
    hasActiveSubscription,
    setHasActiveSubscription,
    signOut,
  };
};
