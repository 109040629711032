import * as sup from 'superstruct';

import { currencies } from '@/modules/customer/types';

import { categories, termImportance } from '../consts';

const isTrue = sup.define('isTrue', (value) => value === true);

const currenciesWithMix = {
  ...currencies,
  MIX: 'MIX',
};

export const surveyFormStruct = sup.object({
  company: sup.object({
    name: sup.nonempty(sup.string()),
    email: sup.nonempty(sup.string()),
    currency: sup.nonempty(sup.enums(Object.values(currenciesWithMix))),
  }),
  privacyPolicy: isTrue,
  offerDiscount: sup.nullable(sup.enums(Object.values(termImportance))),
  lockPrice: sup.nullable(sup.enums(Object.values(termImportance))),
  signCodeOfConduct: sup.nullable(sup.enums(Object.values(termImportance))),
  extendInvoiceDueDate: sup.nullable(sup.enums(Object.values(termImportance))),
  isoApproval: sup.nullable(sup.enums(Object.values(termImportance))),
  signStandardAgreement: sup.nullable(sup.enums(Object.values(termImportance))),
  answerSustainabilityQuestion: sup.nullable(
    sup.enums(Object.values(termImportance))
  ),
  importantCategories: sup.array(sup.enums(categories)),
  categoryInfo: sup.array(
    sup.object({
      category: sup.string(),
      value: sup.object({
        numberOfSuppliers: sup.min(sup.number(), 1),
        totalSpend: sup.nonempty(sup.string()),
      }),
    })
  ),
  notes: sup.optional(sup.size(sup.string(), 0, 2000)),
});

export type SurveyForm = sup.Infer<typeof surveyFormStruct>;
