import { GetSupplierCategoriesResponse } from '@/modules/suppliers/api/queries/getSupplierCategoriesQuery';

export const parseSupplierCategories = (
  response: GetSupplierCategoriesResponse
) => {
  const { data } = response;

  const categories = data.map(
    ({ id, attributes, relationships: { parent } }) => {
      const parentCategoryData = data.find(
        (category) => category.id === parent.data?.id
      );
      return {
        id,
        name: attributes.name,
        parent: parentCategoryData
          ? {
              id: parentCategoryData.id,
              name: parentCategoryData.attributes.name,
            }
          : null,
      };
    }
  );

  return categories;
};

type ParsedSupplierCategories = ReturnType<typeof parseSupplierCategories>;

type ParsedCategoryWithChildren = ParsedSupplierCategories[number] & {
  children: ParsedCategoryWithChildren[];
};

export const mapSupplierCategoriesToSelectOptions = (
  categories: ParsedSupplierCategories
) => {
  const flattenedCategories = flattenCategories(
    rebuildCategoryTree(categories, null)
  );
  return flattenedCategories.map(({ id, name, parent }) => {
    return {
      label: name,
      value: id,
      nestLevel: parent ? 1 : 0,
    };
  });
};

export type MappedCategoriesToSelectOptions = ReturnType<
  typeof mapSupplierCategoriesToSelectOptions
>;

function rebuildCategoryTree(
  categories: ParsedSupplierCategories,
  parentId: string | null
) {
  const nestedCategories = [];
  for (const category of categories) {
    const categoryId = category.parent?.id || null;
    if (categoryId === parentId) {
      const categoryWithChildren: ParsedCategoryWithChildren = {
        ...category,
        children: [],
      };
      const childrenCategories = rebuildCategoryTree(categories, category.id);
      categoryWithChildren.children = childrenCategories;

      nestedCategories.push(categoryWithChildren);
    }
  }
  return nestedCategories;
}

function flattenCategories(
  categoryTree: ParsedCategoryWithChildren[]
): ParsedCategoryWithChildren[] {
  return categoryTree.flatMap((category) => {
    return [category, ...flattenCategories(category.children)];
  });
}
