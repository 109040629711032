export const notEmpty = <TValue>(
  value: TValue | null | undefined
): value is TValue => {
  return value !== null && value !== undefined;
};

// a small helper for getting correct key types from `Object.keys()` function
export const getKeys = Object.keys as <T extends object>(
  obj: T
) => Array<keyof T>;
