import * as sup from 'superstruct';

import { JsonApiClient } from '@/modules/api/utils/createJsonApiClient';
import { createResponseStruct } from '@/modules/api/utils/jsonApiStructs';
import { includedStruct } from '@/utils/structs/includedStruct';

import { parseGetNegotiationResponse } from '../../helpers/parseGetNegotiationResponse';
import {
  getNegotiationResourcePath,
  negotiationRelationshipsKeys,
} from '../resources/negotiation/negotiationResourceConsts';
import { negotiationResourceStruct } from '../resources/negotiation/negotiationResourceStruct';
import {
  negotiationMetricsResourceStruct,
  NegotiationMetricsType,
} from '../resources/negotiationMetrics/negotiationMetricsResourceStruct';

export const getNegotiationMetricsQuery = (
  jsonApi: JsonApiClient,
  { negotiationId }: { negotiationId: string }
) => {
  return async () => {
    const response = await jsonApi.getOne({
      path: getNegotiationResourcePath(negotiationId, [
        negotiationRelationshipsKeys.metrics,
      ]),
      responseStruct: getNegotiationResponseStruct,
      axiosConfig: {
        headers: {
          'accept-language': 'en',
        },
      },
    });
    return parseGetNegotiationResponse(response);
  };
};

export const getNegotiationResponseStruct = createResponseStruct({
  data: negotiationResourceStruct,
  included: includedStruct([negotiationMetricsResourceStruct]),
});

type GetNegotiationResponseStruct = sup.Infer<
  typeof getNegotiationResponseStruct
>;

const parseGetNegotiationMetricsResponse = (
  response: GetNegotiationResponseStruct
) => {
  const {
    data: {
      id,
      attributes: { title, state, termRoles, period, introEmail },
      relationships,
      links,
    },
    included,
  } = response;

  const metricsItem = included.find(
    (item): item is NegotiationMetricsType =>
      item.id === relationships?.metrics?.data.id
  );

  if (!metricsItem) {
    throw new Error('missing metrics');
  }

  return {
    id,
    title,
    state,
    termRoles,
    period,
    introEmail,
    supplierPreviewUrl: links.portal,
    metrics: metricsItem.attributes,
  };
};

export type ParsedNegotiationMetrics = ReturnType<
  typeof parseGetNegotiationMetricsResponse
>;
