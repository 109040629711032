import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { currencies } from '@/modules/customer/types';
import { findSelectValue } from '@/modules/form/components/Select/findSelectValue';
import { SelectInput } from '@/modules/form/components/Select/SelectInput/SelectInput';
import { TextInput } from '@/modules/form/components/TextInput/TextInput';
import { MaxWidth } from '@/modules/theme/components/MaxWidth';

import { SurveyForm } from '../helpers/surveyFormStruct';

import { SurveyFormSection } from './SurveyFormSection';

const options = [
  {
    label: 'SEK',
    value: currencies.SEK,
  },
  {
    label: 'EUR',
    value: currencies.EUR,
  },
  {
    label: 'USD',
    value: currencies.USD,
  },
  {
    label: 'DKK',
    value: currencies.DKK,
  },
  {
    label: 'NOK',
    value: currencies.NOK,
  },
  {
    label: 'Mix currencies',
    value: 'MIX',
  },
] as const;

export const CompanyDetailSection = () => {
  const { t } = useTranslation('pages/LeadCustomerSurveyPage');
  const { register, control } = useFormContext<SurveyForm>();
  const { field } = useController({
    name: 'company.currency',
    control,
  });

  return (
    <SurveyFormSection number={1} title={t('Company information')}>
      <MaxWidth maxWidth={305}>
        <TextInput
          label={t('Company name*')}
          placeholder={t('Your company name')}
          {...register('company.name')}
          required
        />
        <TextInput
          label={t('Email address*')}
          placeholder={t('Your email address')}
          type="email"
          {...register('company.email')}
          required
        />
        <SelectInput
          label={t('Currency*')}
          name={field.name}
          id="currency"
          options={options}
          value={findSelectValue({
            currentValue: field.value,
            options,
          })}
          onChange={(selectedOption) =>
            field.onChange(selectedOption?.value || null)
          }
        />
      </MaxWidth>
    </SurveyFormSection>
  );
};
