import * as sup from 'superstruct';

import {
  createIdentifierStruct,
  createResourceStruct,
} from '@/modules/api/utils/jsonApiStructs';

export {
  businessUnitIdentifierStruct,
  businessUnitResourceStruct,
  businessUnitResourceType,
  businessUnitFromResource,
};
export type { BusinessUnitResource, BusinessUnit };

const businessUnitResourceType = 'business_unit';

const businessUnitIdentifierStruct = createIdentifierStruct(
  businessUnitResourceType
);

const businessUnitResourceStruct = createResourceStruct({
  identifier: businessUnitIdentifierStruct,
  attributes: sup.object({
    name: sup.string(),
  }),
});

type BusinessUnitResource = sup.Infer<typeof businessUnitResourceStruct>;

function businessUnitFromResource(resource: BusinessUnitResource) {
  return {
    id: resource.id,
    name: resource.attributes.name,
  };
}

type BusinessUnit = ReturnType<typeof businessUnitFromResource>;
