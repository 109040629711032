import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Text } from '@/modules/theme/components/Typography';

export const TermImportanceLabels = () => {
  const { t } = useTranslation('pages/LeadCustomerSurveyPage');

  return (
    <>
      <TermImportanceLabelStyled bold variant="small">
        {t('Not important')}
      </TermImportanceLabelStyled>
      <Text bold variant="small">
        {t('Interesting')}
      </Text>
      <Text bold variant="small">
        {t('Important')}
      </Text>
    </>
  );
};

const TermImportanceLabelStyled = styled(Text)({
  gridColumnStart: 2,
});
