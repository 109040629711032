import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as sup from 'superstruct';

import { Checkbox } from '@/modules/form/components/Checkbox/Checkbox';
import { useForm } from '@/modules/form/components/Form/hooks/useForm/useForm';
import { TextInput } from '@/modules/form/components/TextInput/TextInput';
import { emailPattern, notEmpty } from '@/modules/form/utils/structs';
import { notify } from '@/modules/notify/notify';
import { Dialog } from '@/modules/theme/components/Dialog/Dialog';
import { Paragraph } from '@/modules/theme/components/Typography';
import { recompose } from '@/utils/structs/recompose';

import {
  ButtonStyled,
  CheckboxWrapperStyled,
  ContentWrapperStyled,
  FormStyled,
  TextStyled,
} from './ForwardToNewContactDialog.styled';
import { useForwardToNewContact } from './hooks/useForwardToNewContact';

type ForwardToNewContactDialogProps = {
  isOpen: boolean;
  negotiationId: string;
  supplierId: string | undefined;
  progressId: string | undefined;
  onClose: () => void;
};

const forwardToNewContactFormStruct = sup.object({
  email: recompose(sup.string(), notEmpty, emailPattern),
  confirm: sup.literal(true),
});

export const ForwardToNewContactDialog = ({
  isOpen,
  onClose,
  supplierId,
  progressId,
  negotiationId,
}: ForwardToNewContactDialogProps) => {
  const { t } = useTranslation('modules/negotiations/NegotiationSuppliersList');

  const errorMessages = {
    email: {
      'string.notEmpty': t('This field is required'),
      'string.emailPattern': t('Invalid email address'),
    },
    confirm: {
      literal: t('Consent is required'),
    },
  };

  const formProps = useForm({
    defaultValues: { email: '', confirm: undefined },
    struct: forwardToNewContactFormStruct,
  });

  const { handleSubmit, reset, register } = formProps;

  const onDialogClose = () => {
    reset();
    onClose();
  };

  const onForwardSuccess = () => {
    onDialogClose();
    notify.alert.success(
      t('Negotiation was successfully forwarded to a new contact!')
    );
  };

  const { forwardToNewContact, isLoading } = useForwardToNewContact({
    negotiationId,
  });

  const onSubmit = async (
    values: sup.Infer<typeof forwardToNewContactFormStruct>
  ) => {
    await forwardToNewContact(
      { email: values.email, supplierId, progressId },
      onForwardSuccess
    );
  };

  return (
    <Dialog
      hasCloseIcon
      onClose={onDialogClose}
      isOpen={isOpen}
      title={t('Forward to contact')}
      variant="large"
    >
      <ContentWrapperStyled>
        <Paragraph alignment="center" margins={[2, 4, 3]}>
          {t(
            'Assign a new contact together with an email adress for this supplier.'
          )}
        </Paragraph>
        <FormProvider {...formProps}>
          <FormStyled onSubmit={handleSubmit(onSubmit)}>
            <TextInput
              {...register('email')}
              errorMessages={errorMessages.email}
              labelHidden
              placeholder={t('Email')}
            />
            <CheckboxWrapperStyled>
              <Checkbox
                {...register('confirm')}
                id="confirmForward"
                errorMessages={errorMessages.confirm}
              >
                <TextStyled>
                  {t('Yes I want to set this as new contact for this supplier')}
                </TextStyled>
              </Checkbox>
            </CheckboxWrapperStyled>
            <ButtonStyled
              type="submit"
              loading={isLoading}
              disabled={!formProps.formState.isValid}
            >
              {t('Confirm')}
            </ButtonStyled>
          </FormStyled>
        </FormProvider>
      </ContentWrapperStyled>
    </Dialog>
  );
};
