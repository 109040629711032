import { useMemo } from 'react';

import useFirebaseRemoteConfigValue from '@/modules/firebase/hooks/useFirebaseRemoteConfigValue';

import { PresentationSlide } from './PresentationSlide.interface';

const defaultPresentationUrl =
  'https://digiproc.com/wp-content/plugins/m-router-data/data/action-link/?type=dp%2Fpresentation&responseType=data';

const usePresentationSlide = (): PresentationSlide => {
  const presentationSlide = useFirebaseRemoteConfigValue<PresentationSlide>(
    'intro_page_presentation_slide'
  );

  return useMemo(
    () =>
      presentationSlide?.pdfLink
        ? presentationSlide
        : { pdfLink: defaultPresentationUrl },
    [presentationSlide]
  );
};

export default usePresentationSlide;
