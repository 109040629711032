import { forwardRef } from 'react';
import styled from 'styled-components';

import { RadioInput } from '@/modules/form/components/RadioInput';
import { Text } from '@/modules/theme/components/Typography';

import { termImportance } from '../../../consts';

type TermImportanceQuestionProps = React.ComponentProps<'input'> & {
  children: string;
};

export const TermImportanceQuestion = forwardRef<
  HTMLInputElement,
  TermImportanceQuestionProps
>(({ name, onChange, value, id, children, ...props }, ref) => {
  return (
    <>
      <TextStyled bold>{children}</TextStyled>
      <RadioInput
        {...props}
        type="radio"
        id={`${name || ''}-${termImportance.notImportant}`}
        name={name}
        ref={ref}
        value={termImportance.notImportant}
        onChange={onChange}
      />
      <RadioInput
        {...props}
        type="radio"
        id={`${name || ''}-${termImportance.interesting}`}
        name={name}
        ref={ref}
        value={termImportance.interesting}
        onChange={onChange}
      />
      <RadioInput
        {...props}
        type="radio"
        id={`${name || ''}-${termImportance.important}`}
        name={name}
        ref={ref}
        value={termImportance.important}
        onChange={onChange}
      />
    </>
  );
});

const TextStyled = styled(Text)({
  justifySelf: 'left',
});
