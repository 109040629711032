import { useTranslation } from 'react-i18next';

import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { ExternalLink } from '@/modules/theme/components/Link/ExternalLink';
import { InternalLink } from '@/modules/theme/components/Link/InternalLink';
import { Text } from '@/modules/theme/components/Typography';
import { dark } from '@/modules/theme/utils/colors';

import {
  CopyrightContentStyled,
  CopyrightTextStyled,
  FooterContentWrapperStyled,
  IconsContentStyled,
} from './Footer.styled';
import { ReactComponent as Linkedin } from './linkedin.svg';
import { ReactComponent as Sygnet } from './sygnet.svg';

export const Footer = () => {
  const { t } = useTranslation('layouts/Common/Footer');
  const textColor = dark[400];
  return (
    <FooterContentWrapperStyled>
      <IconsContentStyled>
        <InternalLink to={'/'}>
          <Sygnet />
        </InternalLink>
        <ExternalLink to={'https://www.linkedin.com/company/digiproc/'}>
          <Linkedin />
        </ExternalLink>
      </IconsContentStyled>
      <FlexContainer gap={2} justify="center">
        <ExternalLink to={'https://www2.digiproc.com/privacy-policy'}>
          <Text color={textColor} variant={'small'}>
            {t('Privacy policy')}
          </Text>
        </ExternalLink>
        <ExternalLink to={'https://www2.digiproc.com/about-us'}>
          <Text color={textColor} variant={'small'}>
            {t('About us')}
          </Text>
        </ExternalLink>
      </FlexContainer>
      <CopyrightContentStyled>
        <CopyrightTextStyled color={textColor} variant={'extraSmall'}>
          {t('© {{year}} DigiProc', { year: new Date().getFullYear() })}
        </CopyrightTextStyled>
      </CopyrightContentStyled>
    </FooterContentWrapperStyled>
  );
};
