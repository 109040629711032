import * as sup from 'superstruct';

import { createResourceStruct } from '@/modules/api/utils/jsonApiStructs';
import { currencies } from '@/modules/customer/types';

import { customerIdentifierStruct } from '../customer/customerIdentifierStruct';

export enum OnboardingStep {
  uploadSpendFile = 'uploadSpendFile',
  waitForSpendFileProcessing = 'waitForSpendFileProcessing',
  reviewAnalysis = 'reviewAnalysis',
  signServiceAgreement = 'signServiceAgreement',
  watchTutorial = 'watchTutorial',
}

export const customerResourceStruct = createResourceStruct({
  identifier: customerIdentifierStruct,
  attributes: sup.object({
    name: sup.string(),
    currency: sup.enums([...Object.values(currencies)]),
    onboarding: sup.object({
      pendingSteps: sup.array(sup.enums(Object.values(OnboardingStep))),
    }),
  }),
});
