import { forwardRef, useState } from 'react';

import {
  InputErrorMessage,
  InputErrorMessageProps,
} from '@/modules/form/components/ErrorMessage/InputErrorMessage';
import { InputError } from '@/modules/form/components/InputError';

import { CheckboxLabel, LabelVariant } from '../SelectionLabel';

import { CheckboxWrapperStyled, InputStyled } from './Checkbox.styled';

type CheckboxProps = InputErrorMessageProps &
  React.ComponentProps<'input'> & {
    id: string;
    hideError?: boolean;
    labelVariant?: LabelVariant;
    appliedErrorStyle?: boolean;
    className?: string;
  };

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      name,
      onChange,
      id,
      hideError = false,
      appliedErrorStyle = false,
      labelVariant,
      children,
      error,
      errorMessages,
      ErrorMessage,
      className,
      ...props
    },
    ref
  ) => {
    const [appliedError, setAppliedError] = useState('');
    const handleErrorChange = (error: string) => {
      setAppliedError(error);
    };

    return (
      <CheckboxWrapperStyled className={className} errorHidden={hideError}>
        <InputStyled
          {...props}
          type="checkbox"
          id={id}
          name={name}
          ref={ref}
          onChange={onChange}
        />
        <CheckboxLabel
          variant={labelVariant}
          appliedError={appliedErrorStyle || Boolean(appliedError)}
          htmlFor={id}
        >
          {children}
        </CheckboxLabel>
        <InputError errorHidden={hideError}>
          <InputErrorMessage
            name={name}
            error={error}
            errorMessages={errorMessages}
            ErrorMessage={ErrorMessage}
            onErrorChange={handleErrorChange}
          />
        </InputError>
      </CheckboxWrapperStyled>
    );
  }
);
