import styled, { css } from 'styled-components';

import {
  commonSpacings,
  SpacingProps,
} from '@/modules/theme/utils/commonSpacings';

import { CommonTypographyProps } from './helpers/types';

export type HeadingProps = CommonTypographyProps &
  SpacingProps & {
    variant?: 'h1' | 'h2' | 'h3';
  };

export const Heading: React.FC<HeadingProps> = ({
  children,
  variant,
  ...props
}) => {
  switch (variant) {
    case 'h1':
      return <H1Styled {...props}>{children}</H1Styled>;
    case 'h2':
      return <H2Styled {...props}>{children}</H2Styled>;
    case 'h3':
      return <H3Styled {...props}>{children}</H3Styled>;
    default:
      return <H1Styled {...props}>{children}</H1Styled>;
  }
};

type BaseStylesProps = {
  color?: string;
};

const baseStyles = css<BaseStylesProps & SpacingProps>(
  () => commonSpacings,
  ({ theme: { typography, palette }, color }) => ({
    fontFamily: typography.fonts.heading,
    color: color ? color : palette.primary.text,
    lineHeight: 1.5,
    letterSpacing: typography.letterSpacing.default,
    fontWeight: typography.fontWeight.bold,
  })
);

const H1Styled = styled('h1')(
  () => baseStyles,
  ({ theme: { typography } }) => {
    return {
      fontSize: typography.pxToRem(48),
    };
  }
);

const H2Styled = styled('h2')(
  () => baseStyles,
  ({ theme: { typography } }) => {
    return {
      fontSize: typography.fontSize.extraLarge,
    };
  }
);

const H3Styled = styled('h3')(
  () => baseStyles,
  ({ theme: { typography } }) => {
    return {
      fontSize: typography.fontSize.large,
    };
  }
);
