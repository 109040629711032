import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useSessionContext } from '@/modules/auth/hooks/useSessionContext';
import { SupplierNegotiationState } from '@/modules/negotiation/api/resources/negotiationSelectedSuppliers/negotiationSelectedSuppliersResourceConsts';
import { NegotiationSupplier } from '@/modules/negotiation/helpers/parseGetNegotiationSuppliersResponse';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { Table } from '@/modules/theme/components/Table/Table';
import { TableCell } from '@/modules/theme/components/Table/TableCell';
import { TableRow } from '@/modules/theme/components/Table/TableRow';
import { TableSkeletonLoader } from '@/modules/theme/components/TableSkeletonLoader/TableSkeletonLoader';
import { Heading, Text } from '@/modules/theme/components/Typography';
import { formatAmount } from '@/utils/stringUtils';

import { ForwardToNewContactDialog } from './components/ForwardToNewContactDialog/ForwardToNewContactDialog';
import { RemoveSupplierDialog } from './components/RemoveSupplierDialog/RemoveSupplierDialog';
import { ResetSupplierDialog } from './components/ResetSupplierDialog/ResetSupplierDialog';
import { SupplierProgress } from './components/SupplierProgress/SupplierProgress';
import { SuppliersActionsDialog } from './components/SuppliersActionsDialog/SuppliersActionsDialog';
import { SuppliersTableActionCell } from './components/SuppliersTableActionCell/SuppliersTableActionCell';

type SuppliersTableProps = {
  suppliers: NegotiationSupplier[];
  isLoading: boolean;
  isSuccess: boolean;
  pageSize: number;
  negotiationId: string;
  isNegotiationActive: boolean;
};

type SupplierDialogState = {
  supplier: NegotiationSupplier | null;
  isOpen: boolean;
};

const defaultSupplierDialogState = {
  supplier: null,
  isOpen: false,
};

export const SuppliersTable = ({
  suppliers,
  pageSize,
  isLoading,
  isSuccess,
  negotiationId,
  isNegotiationActive,
}: SuppliersTableProps) => {
  const { t } = useTranslation('modules/negotiations/NegotiationSuppliersList');
  const { customer } = useSessionContext();

  const [supplierActionsDialogState, setSupplierActionsDialogState] =
    useState<SupplierDialogState>(defaultSupplierDialogState);

  const [resetSupplierDialogState, setResetSupplierDialogState] =
    useState<SupplierDialogState>(defaultSupplierDialogState);

  const [removeSupplierDialogState, setRemoveSupplierDialogState] =
    useState<SupplierDialogState>(defaultSupplierDialogState);

  const [forwardToNewContactDialogState, setForwardToNewContactDialogState] =
    useState<SupplierDialogState>(defaultSupplierDialogState);

  const headers = {
    company: t('Company & spend'),
    email: t('Email'),
    category: t('Category'),
    progress: t('Progress'),
    emptyColumn: '',
  } as const;

  if (isLoading) {
    return (
      <Table headers={Object.values(headers)}>
        <TableSkeletonLoader rows={pageSize} height={84} />
      </Table>
    );
  }

  if (suppliers.length === 0 && isSuccess) {
    return (
      <NoSuppliersInfoStyled as="p">{t('No results')}</NoSuppliersInfoStyled>
    );
  }

  return (
    <>
      <TableStyled headers={Object.values(headers)}>
        <>
          {suppliers.map((supplier) => {
            const hasSupplierAnswered =
              supplier.progress?.state === SupplierNegotiationState.ANSWERED;
            const isSupplierExcluded =
              supplier.progress?.state === SupplierNegotiationState.EXCLUDED;

            return (
              <TableRow
                key={supplier.id}
                variant={isSupplierExcluded ? 'transparent' : 'default'}
              >
                <TableCell mobileHeader={headers.company}>
                  <FlexContainer direction="column" gap={0.5}>
                    <Text bold>{supplier.name}</Text>
                    {supplier.progress && (
                      <Text>
                        {formatAmount(supplier.progress.spend)}
                        {customer.currency}
                      </Text>
                    )}
                  </FlexContainer>
                </TableCell>
                <TableCell mobileHeader={headers.email}>
                  {supplier.progress && (
                    <Text>{supplier.progress.contactInfo.email}</Text>
                  )}
                </TableCell>
                <TableCell mobileHeader={headers.category}>
                  <Text>{supplier.category?.name}</Text>
                </TableCell>
                <TableCell mobileHeader={headers.progress}>
                  {supplier.progress && (
                    <SupplierProgress progress={supplier.progress.state} />
                  )}
                </TableCell>
                {hasSupplierAnswered || isNegotiationActive ? (
                  <SuppliersTableActionCell
                    isNegotiationActive={isNegotiationActive}
                    supplierProgress={supplier.progress}
                    onForwardToNewContact={() =>
                      setForwardToNewContactDialogState({
                        isOpen: true,
                        supplier,
                      })
                    }
                    onRemoveSupplier={() =>
                      setRemoveSupplierDialogState({
                        isOpen: true,
                        supplier,
                      })
                    }
                    onShowSupplierActions={() =>
                      setSupplierActionsDialogState({
                        isOpen: true,
                        supplier,
                      })
                    }
                    onResetSupplier={() =>
                      setResetSupplierDialogState({
                        isOpen: true,
                        supplier,
                      })
                    }
                  />
                ) : (
                  <TableCell />
                )}
              </TableRow>
            );
          })}
        </>
      </TableStyled>

      <SuppliersActionsDialog
        isOpen={supplierActionsDialogState.isOpen}
        supplierName={supplierActionsDialogState.supplier?.name || ''}
        answeredTerms={supplierActionsDialogState.supplier?.answeredTerms}
        isNegotiationActive={isNegotiationActive}
        onClose={() =>
          setSupplierActionsDialogState(defaultSupplierDialogState)
        }
      />
      <ResetSupplierDialog
        isOpen={resetSupplierDialogState.isOpen}
        negotiationId={negotiationId}
        progressId={resetSupplierDialogState.supplier?.progress?.id}
        onClose={() => setResetSupplierDialogState(defaultSupplierDialogState)}
      />
      <RemoveSupplierDialog
        negotiationId={negotiationId}
        progressId={removeSupplierDialogState.supplier?.progress?.id}
        isOpen={removeSupplierDialogState.isOpen}
        onClose={() => setRemoveSupplierDialogState(defaultSupplierDialogState)}
      />
      <ForwardToNewContactDialog
        negotiationId={negotiationId}
        supplierId={forwardToNewContactDialogState.supplier?.id}
        progressId={forwardToNewContactDialogState.supplier?.progress?.id}
        isOpen={forwardToNewContactDialogState.isOpen}
        onClose={() =>
          setForwardToNewContactDialogState(defaultSupplierDialogState)
        }
      />
    </>
  );
};

const TableStyled = styled(Table)({
  gridTemplateColumns: `repeat(4, 1fr) 0.1fr`,
});

const NoSuppliersInfoStyled = styled(Heading)(
  ({ theme: { palette, typography, spacing } }) => ({
    margin: `${spacing(3)} auto`,
    textAlign: 'center',
    color: palette.disabled.main,
    fontWeight: typography.fontWeight.bold,
    fontSize: typography.fontSize.large,
  })
);
