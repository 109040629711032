import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ReactComponent as EditIcon } from '@/assets/icons/editIcon.svg';
import { ReactComponent as MinusIcon } from '@/assets/icons/minusIcon.svg';
import { RadioField } from '@/modules/form/components/RadioField';
import { ActionButton } from '@/modules/theme/components/Table/ActionButton.styled';

import { getContactPersonDisplayLabel } from '../../utils/getContactPersonDisplayedLabel';
import { ContactPerson } from '../ContactPersonFormDialog/contactPersonFormStruct';

import {
  ContactPersonInputWrapper,
  RadioWrapperStyled,
} from './ContactPersonInput.styled';

type ContactPersonInputProps = {
  fieldName: string;
  person: ContactPerson;
  isDisabled?: boolean;
  onEditPerson: (person: ContactPerson) => void;
  onRemovePerson: (personId: ContactPerson) => void;
};

export const ContactPersonInput = ({
  fieldName,
  person,
  onEditPerson,
  onRemovePerson,
  isDisabled = false,
}: ContactPersonInputProps) => {
  const { t } = useTranslation('modules/suppliers/SupplierForm');
  const { register, setValue, getValues } = useFormContext();

  const handleUncheckSelectedContactPerson = (id: string) => {
    const currentlySelectedContactPersonId = getValues(fieldName);
    if (currentlySelectedContactPersonId === id) {
      setValue(fieldName, null);
    }
  };

  const label =
    getContactPersonDisplayLabel(person) || t('Unknown contact person');

  return (
    <ContactPersonInputWrapper>
      <RadioWrapperStyled>
        <RadioField
          {...register(fieldName)}
          labelVariant="bold"
          id={person.id}
          value={person.id}
          disabled={isDisabled}
          onClick={() => handleUncheckSelectedContactPerson(person.id)}
        >
          {label}
        </RadioField>
      </RadioWrapperStyled>
      <ActionButton
        type="button"
        onClick={() => onEditPerson(person)}
        title={t('Edit')}
      >
        <EditIcon />
      </ActionButton>
      <ActionButton
        type="button"
        onClick={() => onRemovePerson(person)}
        title={t('Delete')}
      >
        <MinusIcon />
      </ActionButton>
    </ContactPersonInputWrapper>
  );
};
