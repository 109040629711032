import { JsonApiClient } from '@/modules/api/utils/createJsonApiClient';

import { sessionResourcePath } from '../resources/session/sessionResourceConsts';

export function signOutMutation(jsonApi: JsonApiClient) {
  return () =>
    jsonApi.delete({
      path: sessionResourcePath,
    });
}
