import styled from 'styled-components';

import { CommonTypographyProps } from './helpers/types';

export type LabelProps = CommonTypographyProps & {
  appliedError?: string;
};

export const LabelStyled = styled('label')<LabelProps>(({
  theme: { typography, palette },
  color,
  appliedError,
}) => {
  return {
    color: appliedError
      ? palette.error.main
      : color
      ? color
      : palette.primary.main,
    fontFamily: typography.fonts.body,
    fontSize: typography.fontSize.default,
    lineHeight: typography.fontSize.large,
    fontWeight: typography.fontWeight.bold,
    letterSpacing: typography.letterSpacing.default,
  };
});
