import styled from 'styled-components';

import { ReactComponent as EditIcon } from '@/assets/icons/editIcon.svg';
import { Link } from '@/modules/theme/components/Link/Link';
import { Table } from '@/modules/theme/components/Table/Table';
import { TableRow } from '@/modules/theme/components/Table/TableRow';
import { blue } from '@/modules/theme/utils/colors';

export const TableStyled = styled(Table)(({ theme: { spacing } }) => ({
  gridTemplateColumns:
    'minmax(0, 1.75fr) minmax(0, 1fr) minmax(0, 2fr) minmax(0, 1fr) 100px',
  marginTop: spacing(1),
}));

export const LinkStyled = styled(Link.Internal)({
  textDecoration: 'none',
  width: 24,
  height: 24,
  display: 'block',
  color: blue[800],
});

export const EditIconStyled = styled(EditIcon)({
  height: '100%',
});

export const TableRowStyled = styled(TableRow)({
  wordBreak: 'break-word',
});

export const DeleteButtonStyled = styled('button')({
  display: 'flex',
  background: 'transparent',
  border: 0,
  cursor: 'pointer',
  padding: 0,
  margin: 0,
});

export const RowLinkStyled = styled(Link.Internal)(
  ({ theme: { breakpoints } }) => ({
    display: 'contents',
    backgroundColor: 'inherit',

    '& > *:last-child': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },

    [breakpoints.down.md]: {
      '& > *:last-child': {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        marginBottom: 0,
      },
    },
  })
);

export const NoSuppliersFoundContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: 450,
});
