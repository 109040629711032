import styled from 'styled-components';

import { Text } from '@/modules/theme/components/Typography';

export const ForgotPasswordMailSentTextStyled = styled(Text)(
  ({ theme: { spacing } }) => ({
    maxWidth: spacing(51),
    textAlign: 'center',
  })
);
