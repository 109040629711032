import { GetNegotiationResponse } from '../api/queries/getNegotiationQuery';
import { negotiationCommunicationResourceType } from '../api/resources/negotiationCommunication/negotiationCommunicationResourceConsts';
import { NegotiationCommunicationType } from '../api/resources/negotiationCommunication/negotiationCommunicationResourceStruct';
import { NegotiationMetricsType } from '../api/resources/negotiationMetrics/negotiationMetricsResourceStruct';

import { parseNegotiationTerms } from './parseNegotiationTerms';

export function parseGetNegotiationResponse(response: GetNegotiationResponse) {
  const {
    data: {
      id,
      attributes: { title, state, termRoles, period, introEmail },
      relationships,
      links,
    },
    included,
  } = response;

  const metricsItem = included.find(
    (item): item is NegotiationMetricsType =>
      item.id === relationships?.metrics?.data.id
  );

  if (!metricsItem) {
    throw new Error('missing metrics');
  }

  const communicationItems = included
    .filter(
      (item): item is NegotiationCommunicationType =>
        item.type === negotiationCommunicationResourceType
    )
    .map(({ id, attributes }) => ({
      id,
      ...attributes,
    }));

  const negotiationTermItems = parseNegotiationTerms(included);
  return {
    id,
    title,
    state,
    termRoles,
    period,
    introEmail,
    supplierPreviewUrl: links.portal,
    metrics: {
      ...metricsItem.attributes,
      totalSpend: (
        Number(metricsItem.attributes.currentSpend) +
        Number(metricsItem.attributes.supplierStates.excluded.spend)
      ).toString(),
      supplierStates: {
        ...metricsItem.attributes.supplierStates,
        active: {
          count:
            metricsItem.attributes.suppliersCount -
            metricsItem.attributes.supplierStates.excluded.count -
            metricsItem.attributes.supplierStates.invited.count,
          spend: (
            Number(metricsItem.attributes.currentSpend) -
            Number(metricsItem.attributes.supplierStates.invited.spend)
          ).toString(),
        },
      },
    },
    communication: communicationItems,
    negotiationTerms: negotiationTermItems,
  };
}

export type ParsedNegotiation = ReturnType<typeof parseGetNegotiationResponse>;
