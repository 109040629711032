import { useTranslation } from 'react-i18next';

import { useSupplierFormContext } from '@/modules/suppliers/hooks/useSupplierFormContext';

import { serializeContactData } from '../../utils/serializeContactData';

import { ContactPersonForm } from './ContactPersonForm';
import { DialogStyled } from './ContactPersonFormDialog.styled';
import {
  ContactPerson,
  ContactPersonFormType,
} from './contactPersonFormStruct';
import { useEditSupplierContactMutation } from './useEditSupplierContactMutation';

export type FormMode = 'edit' | 'add';

type ContactPersonFormDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  contactPerson: ContactPerson | null;
  supplierFormMode?: FormMode;
};

export const EditContactPersonFormDialog = ({
  isOpen,
  onClose,
  contactPerson,
  supplierFormMode,
}: ContactPersonFormDialogProps) => {
  const { t } = useTranslation('modules/suppliers/SupplierForm');
  const { setContacts } = useSupplierFormContext();
  const editSupplierContact = useEditSupplierContactMutation();

  const defaultPersonFormValues = {
    firstName: contactPerson?.firstName || '',
    lastName: contactPerson?.lastName || '',
    email: contactPerson?.email || '',
    phoneNumber: contactPerson?.phoneNumber || '',
  };

  const setContactInContext = ({
    id,
    attributes,
  }: {
    id: string;
    attributes: Nullable<ContactPersonFormType>;
  }) => {
    setContacts((prev) => {
      if (prev) {
        return prev.map((contact) => {
          if (contact.id === id) return { ...contact, ...attributes };
          return contact;
        });
      }
      return null;
    });
  };

  const handleSubmit = (attributes: ContactPersonFormType) => {
    if (contactPerson?.id) {
      const serializedAttributes = serializeContactData(attributes);
      if (supplierFormMode === 'add') {
        setContactInContext({
          id: contactPerson.id,
          attributes: serializedAttributes,
        });
        onClose();
        return;
      }

      editSupplierContact.mutate(
        { attributes: serializedAttributes, id: contactPerson.id },
        {
          onSuccess: (_, { id, attributes }) => {
            setContactInContext({ id, attributes });
            onClose();
          },
        }
      );
    }
  };

  return (
    <DialogStyled
      isOpen={isOpen}
      hasCloseIcon
      onClose={onClose}
      title={t('Edit contact')}
      variant="large"
    >
      <ContactPersonForm
        onSubmit={handleSubmit}
        defaultPersonFormValues={defaultPersonFormValues}
        isLoading={editSupplierContact.isPending}
        buttonText={t('Save changes')}
      />
    </DialogStyled>
  );
};
