import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { queryKeys } from '@/modules/api/queryKeys';
import { constructMeta } from '@/modules/reactQuery/constructMeta';
import { getSupplierCategoriesQuery } from '@/modules/suppliers/api/queries/getSupplierCategoriesQuery';

import { parseSupplierCategories } from '../../helpers/supplierCategoriesHelpers';

export const useGetSupplierCategoriesQuery = () => {
  const jsonApi = useJsonApi();
  const { t } = useTranslation('apiMessages/useGetSupplierCategoriesQuery');

  return useQuery({
    queryKey: [queryKeys.suppliers.getSupplierCategories],
    queryFn: getSupplierCategoriesQuery(jsonApi),
    select: (response) => parseSupplierCategories(response),
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t("You don't have access to see supplier's categories"),
        },
      },
    }),
  });
};
