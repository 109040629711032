import { notify } from '../notify/notify';

import { BasicApiError } from './basicApiErrorValidator';

type ErrorsResolvers = {
  [x: number]: Record<string, string | (() => void)>;
};

export const displayErrorToast = <T extends BasicApiError>(
  error: T,
  errorsResolvers: ErrorsResolvers
) => {
  const {
    response: { data, status },
  } = error;

  const errorCode = data.errors[0].code;

  const currentErrorResolver = errorsResolvers[status]?.[errorCode];

  if (typeof currentErrorResolver === 'function') {
    currentErrorResolver();
    return undefined;
  }

  if (currentErrorResolver) {
    notify.alert.error(currentErrorResolver);
  } else {
    throw new Error(`Error not handled: ${status} ${errorCode}`);
  }
};
