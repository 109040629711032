import { createContext } from 'react';

import { JsonApiClient } from '../utils/createJsonApiClient';
import { JsonApiV2Client } from '../utils/createJsonApiV2Client';

export const ApiContext = createContext<{
  jsonApi: JsonApiClient;
  jsonApiV2: JsonApiV2Client;
}>({
  get jsonApi(): never {
    throw new Error('Missing jsonApi');
  },
  get jsonApiV2(): never {
    throw new Error('Missing jsonApiV2');
  },
});
