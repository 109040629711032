import styled from 'styled-components';

interface MaxWidthProps {
  maxWidth?: number;
  padding?: number | number[];
}
export const MaxWidth = styled('div')<MaxWidthProps>(
  ({ maxWidth, padding, theme: { spacing } }) => ({
    maxWidth: maxWidth ? `${maxWidth}px` : spacing(189),
    ...(padding ? { padding: spacing(padding) } : {}),
  })
);
