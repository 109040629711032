import * as sup from 'superstruct';

import { createResourceStruct } from '@/modules/api/utils/jsonApiStructs';
import { NegotiationCommunicationState } from '@/modules/negotiation/types/negotiationTypes';
import { SupplierState } from '@/modules/suppliers/types/supplierTypes';
import { dateTime } from '@/utils/structs/structs';

import { negotiationCommunicationIdentifierStruct } from './negotiationCommunicationIdentifierStruct';

const negotiationCommunicationStateStruct = sup.enums(
  Object.values(NegotiationCommunicationState)
);

const supplierStateStruct = sup.enums(Object.values(SupplierState));

export const negotiationCommunicationResourceStruct = createResourceStruct({
  identifier: negotiationCommunicationIdentifierStruct,
  attributes: sup.object({
    state: negotiationCommunicationStateStruct,
    name: sup.string(),
    sendDay: sup.integer(),
    sendTime: sup.string(),
    sendAt: sup.nullable(dateTime),
    sentAt: sup.nullable(dateTime),
    sendTo: sup.array(supplierStateStruct),
    email: sup.object({
      titleTemplate: sup.string(),
      bodyTemplate: sup.string(),
    }),
  }),
});

export type NegotiationCommunicationType = sup.Infer<
  typeof negotiationCommunicationResourceStruct
>;
