import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@/modules/theme/components/Button/Button';
import { Dialog } from '@/modules/theme/components/Dialog/Dialog';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { Text } from '@/modules/theme/components/Typography';

import { useRemoveCodeOfConductMutation } from './useRemoveCodeOfConductMutation';

type RemoveCodeOfConductDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  negotiationId: string;
  termId: string;
};

export function RemoveCodeOfConductDialog({
  isOpen,
  onClose,
  negotiationId,
  termId,
}: RemoveCodeOfConductDialogProps) {
  const { t } = useTranslation('pages/Terms');
  const { mutate, isPending } = useRemoveCodeOfConductMutation();

  return (
    <Dialog
      isOpen={isOpen}
      hasCloseIcon
      onClose={onClose}
      title={t('Remove code of conduct')}
      description={t('Are you sure you want to remove code of conduct file?')}
    >
      <FlexContainer justify="center">
        <ButtonStyled
          variant="outline"
          loading={isPending}
          onClick={() =>
            mutate({ negotiationId, termId }, { onSuccess: onClose })
          }
        >
          <Text variant="normal">{t('Remove')}</Text>
        </ButtonStyled>
      </FlexContainer>
    </Dialog>
  );
}

const ButtonStyled = styled(Button)(({ theme: { spacing } }) => ({
  width: spacing(22),
  minWidth: 'unset',
  maxHeight: spacing(5),
}));
