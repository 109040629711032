import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { black } from '../utils/colors';
import { fade } from '../utils/fade';

import { Loader } from './Loader';

export const PageLoader = () => {
  const [delay, setDelay] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setDelay(true), 800);
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  return delay ? (
    <PageLoaderContainerStyled>
      <Loader size={100} variant="outline" />
    </PageLoaderContainerStyled>
  ) : null;
};

const PageLoaderContainerStyled = styled('div')({
  width: '100vw',
  height: '100vh',
  backgroundColor: fade(black, 0.3),
  display: 'grid',
  placeItems: 'center',
});
