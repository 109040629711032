import { JsonApiClient } from '@/modules/api/utils/createJsonApiClient';

import { credentialsResourceType } from '../resources/credentials/credentialsResourceConsts';
import { sessionResourcePath } from '../resources/session/sessionResourceConsts';

export function signInMutation(jsonApi: JsonApiClient) {
  return (attributes: { email: string; password: string; remember: boolean }) =>
    jsonApi.createOne({
      type: credentialsResourceType,
      path: sessionResourcePath,
      attributes,
    });
}
