import { GetSupplierResponse } from '../api/queries/getSupplierQuery';
import { supplierCategoryResourceType } from '../api/resources/supplierCategory/supplierCategoryResourceConsts';
import { SupplierCategory } from '../api/resources/supplierCategory/supplierCategoryResourceStruct';

export const getCategoriesHierarchy = (
  category: SupplierCategory,
  included: GetSupplierResponse['included'],
  categories: SupplierCategory[] = []
): SupplierCategory[] => {
  const deepestNestedParentCategoryId = category.relationships?.parent.data?.id;

  const parentCategory = included.find(
    (item): item is SupplierCategory =>
      item.id === deepestNestedParentCategoryId &&
      item.type === supplierCategoryResourceType
  );

  if (!parentCategory) {
    return [...categories, category];
  }

  return getCategoriesHierarchy(parentCategory, included, [
    ...categories,
    category,
  ]);
};
