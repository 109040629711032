import styled from 'styled-components';

import { ButtonLink } from '@/modules/theme/components/ButtonLink';

export const ButtonLinkStyled = styled(ButtonLink)(
  ({ theme: { spacing } }) => ({
    width: spacing(22),
    minWidth: 'unset',
  })
);
