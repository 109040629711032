import styled from 'styled-components';

import {
  commonSpacings,
  SpacingProps,
} from '@/modules/theme/utils/commonSpacings';

type BoxProps = SpacingProps;

export const Box = styled('div')<BoxProps>(() => commonSpacings);
