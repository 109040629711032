import styled from 'styled-components';

import { Heading } from '@/modules/theme/components/Typography';

import { ReactComponent as SignetLogo } from './signetColor.svg';

export const IntroSectionWrapperStyled = styled('section')(
  ({ theme: { spacing } }) => ({
    position: 'relative',
    padding: spacing([7, 0, 10]),
  })
);

export const SignetLogoStyled = styled(SignetLogo)(
  ({ theme: { zIndexes, breakpoints } }) => ({
    width: '45%',
    position: 'absolute',
    zIndex: zIndexes.background,
    right: 0,
    top: -240,
    maxWidth: 640,

    [breakpoints.down.md]: {
      display: 'none',
    },
  })
);

export const TextWrapperStyled = styled('div')(
  ({ theme: { breakpoints } }) => ({
    width: '53%',

    [breakpoints.down.md]: {
      width: '100%',
    },
  })
);

export const HeadingStyled = styled(Heading)({
  maxWidth: 363,
});
