import { useParams, useSearchParams } from 'react-router-dom';
import * as sup from 'superstruct';
import { ObjectSchema } from 'superstruct/dist/utils';

import { composeUrl } from './composeUrl';
import {
  BuildUrl,
  CombineQueryStructs,
  RouteType,
  RoutingConfig,
  UseRouteParams,
  UseRouteQuery,
} from './types';

const configStruct = sup.array(
  sup.optional(
    sup.object({
      params: sup.optional(sup.record(sup.string(), sup.string())),
      query: sup.optional(sup.record(sup.string(), sup.unknown())),
    })
  )
);

export function defineRoute<Path extends string>(routeConfig: {
  path: Path;
}): RouteType<Path, Record<never, never>>;
export function defineRoute<Path extends string, Query>(routeConfig: {
  path: Path;
  queryStruct: sup.Describe<Query>;
}): RouteType<Path, Query>;
export function defineRoute<
  Path extends string,
  Query = Record<never, never>,
>(routeConfig: {
  path: Path;
  queryStruct?: sup.Describe<Query>;
}): RouteType<Path, Query> {
  return routeConfig;
}

export function createRouting<T extends RoutingConfig>(config: T) {
  const buildUrl: BuildUrl<T> = (routeName, ...urlConfig) => {
    const [buildConfigValidationError, buildUrlConfig] =
      configStruct.validate(urlConfig);

    if (buildConfigValidationError) {
      throw buildConfigValidationError;
    }

    const { path } = config[routeName];
    const params = buildUrlConfig[0]?.params;
    const query = buildUrlConfig[0]?.query;
    return composeUrl(path, params, query);
  };

  const useGetRouteParams = <RouteName extends keyof T>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    routeName: RouteName
  ) => {
    return useParams() as UseRouteParams<T[RouteName]>;
  };

  const useGetQueryParams = <RouteName extends keyof T>(
    routeName: RouteName
  ) => {
    const struct = config[routeName].queryStruct;
    const [searchParams] = useSearchParams();

    if (!struct) {
      throw new Error(
        `queryStruct not provided for route: ${String(routeName)}`
      );
    }

    const [validationError, queryParams] = struct.validate(
      Object.fromEntries(searchParams.entries()),
      { coerce: true }
    );
    if (validationError) {
      // eslint-disable-next-line no-console
      console.error(validationError, 'query params validation error');
      return { queryParamsError: validationError, queryParams: null };
    }
    return { queryParamsError: null, queryParams } as {
      queryParamsError: null;
      queryParams: UseRouteQuery<T[RouteName]>;
    };
  };

  const useSetQueryParams = <RouteName extends keyof T>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    routeName: RouteName
  ) => {
    const [searchParams, setSearchParams] = useSearchParams();

    return (params: Partial<UseRouteQuery<T[RouteName]>>) => {
      const updatedSearchParams = new URLSearchParams(searchParams);
      for (const key in params) {
        if (params[key] === undefined) {
          updatedSearchParams.delete(key);
        } else {
          updatedSearchParams.set(key, String(params[key]));
        }
      }
      return setSearchParams(updatedSearchParams);
    };
  };

  return {
    buildUrl,
    useGetRouteParams,
    useGetQueryParams,
    useSetQueryParams,
    config,
  };
}

export function nestRoute<
  ParentPath extends string,
  ParentQuery,
  Path extends string,
  Query,
>(
  parentRouteConfig: RouteType<ParentPath, ParentQuery>,
  nestedRoute: RouteType<Path, Query>
) {
  const queryStruct =
    parentRouteConfig.queryStruct && nestedRoute.queryStruct
      ? sup.assign(
          /* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument */
          parentRouteConfig.queryStruct as sup.Struct<any, ObjectSchema>,
          nestedRoute.queryStruct as sup.Struct<any, ObjectSchema>
          /* eslint-enable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument */
        )
      : parentRouteConfig.queryStruct || nestedRoute.queryStruct;

  return {
    path: `${parentRouteConfig.path}/${nestedRoute.path}`,
    nestedPath: nestedRoute.path,
    queryStruct: queryStruct as
      | sup.Describe<CombineQueryStructs<Query, ParentQuery>>
      | undefined,
  } as const;
}
