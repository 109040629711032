import styled from 'styled-components';

import { Heading } from '@/modules/theme/components/Typography';

export const TileWrapperStyled = styled('div')(
  ({ theme: { borderRadius, boxShadows, palette } }) => ({
    width: '100%',
    height: '100%',
    borderRadius: borderRadius.default,
    boxShadow: boxShadows.tile,
    backgroundColor: palette.default.background,
  })
);

type TileHeaderStyledProps = {
  isAligned: boolean;
};

export const TileHeaderStyled = styled('div')<TileHeaderStyledProps>(
  ({ theme: { borderRadius, breakpoints, palette, spacing }, isAligned }) => ({
    borderTopLeftRadius: borderRadius.default,
    borderTopRightRadius: borderRadius.default,
    backgroundColor: palette.primary.main,
    height: 42,
    display: 'flex',
    alignItems: 'center',
    ...(isAligned ? { textAlign: 'center' } : { paddingLeft: spacing(7) }),
    [breakpoints.down.md]: {
      justifyContent: 'center',
      padding: 0,
      textAlign: 'center',
    },
  })
);

export const HeadingStyled = styled(Heading)(
  ({ theme: { breakpoints, typography } }) => ({
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    flex: 1,

    [breakpoints.down.md]: {
      fontSize: typography.pxToRem(18),
    },
  })
);
