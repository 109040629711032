import * as sup from 'superstruct';

import { JsonApiClient } from '@/modules/api/utils/createJsonApiClient';
import { createResponseStruct } from '@/modules/api/utils/jsonApiStructs';
import { businessUnitResourceStruct } from '@/modules/customer/api/businessUnits/businessUnitsHelpers';
import { negotiationResourceStruct } from '@/modules/negotiation/api/resources/negotiation/negotiationResourceStruct';
import { includedStruct } from '@/utils/structs/includedStruct';

import { getSupplierResourcePath } from '../resources/supplier/supplierResourceConsts';
import {
  supplierRelationshipKeys,
  supplierResourceStruct,
} from '../resources/supplier/supplierResourceStruct';
import { supplierCategoryResourceStruct } from '../resources/supplierCategory/supplierCategoryResourceStruct';
import { supplierContactResourceStruct } from '../resources/supplierContact/supplierContactResourceStruct';

export const getSupplierQuery = (
  jsonApi: JsonApiClient,
  { supplierId }: { supplierId: string | undefined }
) => {
  return () => {
    if (!supplierId) return Promise.reject(new Error('Invalid id'));
    return jsonApi.getOne({
      path: getSupplierResourcePath(supplierId, [
        supplierRelationshipKeys.businessUnit,
        supplierRelationshipKeys.category,
        supplierRelationshipKeys.categoryPath,
        supplierRelationshipKeys.contacts,
        supplierRelationshipKeys.defaultContact,
        supplierRelationshipKeys.negotiations,
      ]),
      responseStruct: getSupplierResponseStruct,
    });
  };
};

const getSupplierResponseStruct = createResponseStruct({
  data: supplierResourceStruct,
  included: includedStruct([
    businessUnitResourceStruct,
    supplierCategoryResourceStruct,
    supplierContactResourceStruct,
    negotiationResourceStruct,
  ]),
});

export type GetSupplierResponse = sup.Infer<typeof getSupplierResponseStruct>;
