import { useDroppable } from '@dnd-kit/core';
import { ReactNode } from 'react';
import styled from 'styled-components';

type DroppableProps = {
  id: string;
  children?: ReactNode;
};

export const Droppable = ({ children, id }: DroppableProps) => {
  const { setNodeRef } = useDroppable({ id });

  return <DroppableStyled ref={setNodeRef}>{children}</DroppableStyled>;
};

const DroppableStyled = styled('div')(() => ({
  width: '100%',
}));
