import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import { NegotiationLayout } from '@/layouts/NegotiationLayout/NegotiationLayout';
import { useGetNegotiationQuery } from '@/modules/negotiation/hooks/useGetNegotiationQuery';
import { NegotiationState } from '@/modules/negotiation/types/negotiationTypes';
import { basicErrorResponseValidator } from '@/modules/reactQuery/basicApiErrorValidator';
import { getApiErrorStatusCode } from '@/modules/reactQuery/validateApiError';
import { onboardedRoutes } from '@/modules/router/onboardedRoutes';
import { LinkType } from '@/modules/theme/components/ButtonLink';
import { HeaderButtonLink } from '@/modules/theme/components/HeaderButton';
import { Tabs } from '@/modules/theme/components/Tabs/Tabs';
import { useDetectEnglishBrowser } from '@/utils/useDetectEnglishBrowser';

import { AuthenticatedNotFoundPage } from '../AuthenticatedNotFoundPage';

import { ActivateNegotiationSection } from './components/ActivateNegotiationSection/ActivateNegotiationSection';
import { NegotiationSetupDataPanel } from './components/NegotiationSetupDataPanel/NegotiationSetupDataPanel';
import { useSetNegotiationStateToSetupMutation } from './hooks/useSetNegotiationStateToSetupMutation';
import { useTabItems } from './hooks/useTabItems';

export const NegotiationSetupPage = () => {
  const { t } = useTranslation('pages/NegotiationSetupPage');
  const { negotiationId } =
    onboardedRoutes.useGetRouteParams('negotiationSetup');
  const tabs = useTabItems();
  const isEnBrowser = useDetectEnglishBrowser();

  const {
    data: negotiation,
    isPending: isNegotiationPending,
    error: negotiationError,
  } = useGetNegotiationQuery({
    negotiationId,
  });
  const {
    mutate: setNegotiationStateToSetupMutate,
    isPending: setNegotiationStateToSetupIsPending,
  } = useSetNegotiationStateToSetupMutation({ negotiationId });

  const parsedPreviewUrl = isEnBrowser
    ? negotiation?.supplierPreviewUrl
    : negotiation?.supplierPreviewUrl?.replace('/en/', '/');

  useEffect(() => {
    if (negotiation?.state === NegotiationState.SUGGESTED)
      setNegotiationStateToSetupMutate({
        id: negotiation.id,
      });
  }, [negotiation?.state, negotiation?.id, setNegotiationStateToSetupMutate]);

  const errorCode = getApiErrorStatusCode(
    negotiationError,
    basicErrorResponseValidator
  );

  if (errorCode === 404) {
    return <AuthenticatedNotFoundPage />;
  }

  return (
    <NegotiationLayout
      negotiationName={negotiation?.title}
      negotiationState={negotiation?.state}
      isLoading={isNegotiationPending}
      buttonLink={
        <HeaderButtonLink
          to={onboardedRoutes.buildUrl('dashboard')}
          linkType={LinkType.Internal}
        >
          {t('Back')}
        </HeaderButtonLink>
      }
    >
      <>
        <NegotiationSetupDataPanel
          negotiation={negotiation}
          shouldDisplayLoader={isNegotiationPending}
        />
        <ActivateNegotiationSection
          negotiationId={negotiationId}
          isLoading={
            isNegotiationPending || setNegotiationStateToSetupIsPending
          }
          supplierPreviewUrl={parsedPreviewUrl}
        />
        <Tabs tabs={tabs} variant="xSmall" />
        <ContentWrapper>
          <Outlet />
        </ContentWrapper>
      </>
    </NegotiationLayout>
  );
};

const ContentWrapper = styled('div')(({ theme: { spacing, palette } }) => ({
  padding: spacing([2, 10, 7]),
  minHeight: 600,
  backgroundColor: palette.default.background,
}));
