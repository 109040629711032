import { JsonApiClient } from '@/modules/api/utils/createJsonApiClient';

import { getDeleteSupplierContactResourcePath } from '../resources/supplierContact/supplierContactResourceConsts';

type DeleteSupplierContactMutationPayload = {
  id: string;
};

export function deleteSupplierContactMutation(jsonApi: JsonApiClient) {
  return ({ id }: DeleteSupplierContactMutationPayload) => {
    return jsonApi.delete({
      path: getDeleteSupplierContactResourcePath({ id }),
    });
  };
}
