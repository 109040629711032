import { Navigate } from 'react-router-dom';

import { anonymousRoutes } from '@/modules/router/anonymousRoutes';

import { SignUpPage } from './SignUpPage';

export const SignUpScene = () => {
  const { queryParams, queryParamsError } =
    anonymousRoutes.useGetQueryParams('signUp');

  if (queryParamsError) {
    return <Navigate to={anonymousRoutes.buildUrl('signIn')} />;
  }
  return <SignUpPage token={queryParams.token} />;
};
