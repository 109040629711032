import { JsonApiClient } from '@/modules/api/utils/createJsonApiClient';
import { supplierResourceType } from '@/modules/suppliers/api/resources/supplier/supplierResourceConsts';

import { getSelectNegotiationSuppliersPath } from '../resources/negotiationSelectedSuppliers/negotiationSelectedSuppliersResourceConsts';

type RemoveSupplierFromNegotiationPayload = {
  selectedSuppliers: string[];
  negotiationId: string;
};

export function removeSupplierFromNegotiation(jsonApi: JsonApiClient) {
  return ({
    selectedSuppliers,
    negotiationId,
  }: RemoveSupplierFromNegotiationPayload) => {
    const suppliersIdentifiers = selectedSuppliers.map((supplierId) => ({
      type: supplierResourceType,
      id: supplierId,
    }));
    return jsonApi.toMany.remove({
      path: getSelectNegotiationSuppliersPath(negotiationId),
      payload: suppliersIdentifiers,
    });
  };
}
