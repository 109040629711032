import * as sup from 'superstruct';

import { JsonApiClient } from '@/modules/api/utils/createJsonApiClient';
import { createResponseStruct } from '@/modules/api/utils/jsonApiStructs';
import { includedStruct } from '@/utils/structs/includedStruct';

import { NegotiationState } from '../../types/negotiationTypes';
import { parseGetNegotiationsResponse } from '../../utils/parseGetNegotiationsResponse';
import {
  GetNegotiationsQueryParams,
  getNegotiationsResourcePath,
} from '../resources/negotiation/negotiationResourceConsts';
import { negotiationResourceStruct } from '../resources/negotiation/negotiationResourceStruct';
import { negotiationMetricsResourceStruct } from '../resources/negotiationMetrics/negotiationMetricsResourceStruct';

export const getNegotiationsQuery = (
  jsonApi: JsonApiClient,
  params: GetNegotiationsQueryParams
) => {
  return async () => {
    const data = await jsonApi.getMany({
      path: getNegotiationsResourcePath(params),
      responseStruct: getNegotiationsResponseStruct,
    });

    return parseGetNegotiationsResponse(data);
  };
};

export const getNegotiationsResponseStruct = createResponseStruct({
  data: sup.array(negotiationResourceStruct),
  included: includedStruct([negotiationMetricsResourceStruct]),
  meta: sup.object({
    page: sup.object({
      total: sup.integer(),
    }),
    stateCounts: sup.object({
      [NegotiationState.DRAFT]: sup.integer(),
      [NegotiationState.ESTIMATED]: sup.integer(),
      [NegotiationState.SUGGESTED]: sup.integer(),
      [NegotiationState.SETUP]: sup.integer(),
      [NegotiationState.PLANNED]: sup.integer(),
      [NegotiationState.ACTIVE]: sup.integer(),
      [NegotiationState.COMPLETED]: sup.integer(),
    }),
  }),
});

export type GetNegotiationResponseType = sup.Infer<
  typeof getNegotiationsResponseStruct
>;
