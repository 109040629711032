import { useTranslation } from 'react-i18next';

import { Box } from '@/modules/theme/components/Box';
import { DashedButton } from '@/modules/theme/components/DashedButton/DashedButton';

import { ContactPerson } from '../ContactPersonFormDialog/contactPersonFormStruct';

import { ContactPersonInput } from './ContactPersonInput';

type ContactPersonsFormProps = {
  contactPersons: ContactPerson[] | null;
  fieldName: string;
  onEditPerson: (person: ContactPerson) => void;
  onRemovePerson: (person: ContactPerson) => void;
  onAddPersonClick: () => void;
};

export const ContactPersonsForm = ({
  fieldName,
  contactPersons,
  onEditPerson,
  onRemovePerson,
  onAddPersonClick,
}: ContactPersonsFormProps) => {
  const { t } = useTranslation('modules/suppliers/SupplierForm');
  return (
    <Box mt={1}>
      {contactPersons?.map((person) => {
        return (
          <ContactPersonInput
            key={person.id}
            person={person}
            fieldName={fieldName}
            onEditPerson={onEditPerson}
            onRemovePerson={onRemovePerson}
          />
        );
      })}
      <DashedButton size="small" onClick={onAddPersonClick}>
        {t('New contact +')}
      </DashedButton>
    </Box>
  );
};
