import { ReactNode } from 'react';

import { TableCellStyled, TableMobileHeader } from './TableCell.styled';
import { TableVariant } from './types';

type TableCellProps = {
  children?: ReactNode;
  mobileHeader?: string;
  variant?: TableVariant;
  className?: string;
  warning?: boolean;
};

export const TableCell = ({
  children,
  variant = 'default',
  mobileHeader,
  className,
  warning,
}: TableCellProps) => {
  return (
    <TableCellStyled variant={variant} className={className} warning={warning}>
      {mobileHeader && <TableMobileHeader>{mobileHeader}</TableMobileHeader>}
      {children}
    </TableCellStyled>
  );
};
