import SimpleBar from 'simplebar-react';
import styled from 'styled-components';

import { blue, dark } from '@/modules/theme/utils/colors';
import 'simplebar-react/dist/simplebar.min.css';

interface ScrollbarProps {
  children?: React.ReactNode;
}

export const Scrollbar = ({ children }: ScrollbarProps) => {
  return <SimpleBarStyled autoHide={false}>{children}</SimpleBarStyled>;
};

const SimpleBarStyled = styled(SimpleBar)(
  ({ theme: { spacing, borderRadius } }) => ({
    height: '100%',
    overflowX: 'auto',
    '.simplebar-content': {
      height: '100%',
    },
    '.simplebar-vertical': {
      width: spacing(1.3),
    },
    '.simplebar-scrollbar::before': {
      opacity: 1,
      backgroundColor: blue[800],
    },
    '.simplebar-track': {
      backgroundColor: dark[50],
      borderRadius: borderRadius.default,
    },
  })
);
