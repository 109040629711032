import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { queryKeys, sortArrayQueryKey } from '@/modules/api/queryKeys';
import { getNegotiationsQuery } from '@/modules/negotiation/api/queries/getNegotiationsQuery';
import { GetNegotiationsQueryParams } from '@/modules/negotiation/api/resources/negotiation/negotiationResourceConsts';
import { constructMeta } from '@/modules/reactQuery/constructMeta';

export const useGetNegotiationsQuery = (params: GetNegotiationsQueryParams) => {
  const jsonApi = useJsonApi();
  const { t } = useTranslation('apiMessages/useGetNegotiationsQuery');

  return useQuery({
    queryKey: [
      queryKeys.negotiations.getNegotiations,
      params.pageNumber,
      params.pageSize,
      sortArrayQueryKey(params.states),
    ],
    refetchOnWindowFocus: true,
    staleTime: 5000,
    refetchInterval: 60_000,
    placeholderData: keepPreviousData,
    queryFn: getNegotiationsQuery(jsonApi, params),
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t("You don't have access to view negotiations"),
        },
      },
    }),
  });
};
