import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useSessionContext } from '@/modules/auth/hooks/useSessionContext';
import {
  BottomDataWrapperStyled,
  DataContainerStyled,
  NegotiationDataPanelWrapperStyled,
  NumberOfSuppliersStyled,
  PanelContainerStyled,
  SignetStyled,
  SpendStyled,
} from '@/modules/negotiation/components/NegotiationDataPanel/NegotiationDataPanel.styled';
import { NegotiationInvitedExcludedSuppliers } from '@/modules/negotiation/components/NegotiationDataPanel/NegotiationInvitedExcludedSuppliers';
import { ParsedNegotiation } from '@/modules/negotiation/helpers/parseGetNegotiationResponse';
import { negotiationMetrics } from '@/modules/negotiation/metrics/consts';
import { getNegotiationDetailsMetrics } from '@/modules/negotiation/metrics/getNegotiationDetailsMetrics';
import { formatPercentage } from '@/modules/negotiation/utils/formatPercentage';
import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';
import { Paragraph } from '@/modules/theme/components/Typography';
import { dark } from '@/modules/theme/utils/colors';
import { formatAmount } from '@/utils/stringUtils';

import { CompletedNegotiationMetrics } from './CompletedNegotiationMetrics';
import { CompletedNegotiationMetricsLoader } from './CompletedNegotiationMetricsLoader';

type CompletedNegotiationDataPanelProps = {
  negotiation: ParsedNegotiation | undefined;
  shouldDisplayLoader: boolean | undefined;
};

export const CompletedNegotiationDataPanel = ({
  negotiation,
  shouldDisplayLoader = false,
}: CompletedNegotiationDataPanelProps) => {
  const { t } = useTranslation('pages/CompletedNegotiationPage');

  const { customer } = useSessionContext();

  const metricsToDisplay = negotiation
    ? getNegotiationDetailsMetrics(negotiation)
    : new Set<string>();

  return (
    <CompletedNegotiationDataPanelWrapperStyled>
      <PanelContainerStyled>
        <DataContainerStyled>
          {shouldDisplayLoader ? (
            <CompletedNegotiationMetricsLoader />
          ) : (
            negotiation && (
              <CompletedNegotiationMetrics
                metrics={negotiation.metrics}
                metricsToDisplay={metricsToDisplay}
              />
            )
          )}
          <SignetStyled />
        </DataContainerStyled>
        {metricsToDisplay.has(negotiationMetrics.averageDiscount) && (
          <Paragraph variant="contentBold" color={dark[400]} alignment="right">
            {t('Average discount {{percentage}}%', {
              percentage: formatPercentage(
                negotiation ? negotiation.metrics.averageDiscount * 100 : 0
              ),
            })}
          </Paragraph>
        )}
        {shouldDisplayLoader ? (
          <SkeletonLoader height={33} />
        ) : (
          negotiation && (
            <BottomDataWrapperStyled>
              <div>
                <NumberOfSuppliersStyled variant="h3" as="span">
                  {t('{{count}} suppliers', {
                    count: negotiation.metrics.suppliersCount,
                  })}
                </NumberOfSuppliersStyled>
                <NegotiationInvitedExcludedSuppliers
                  metrics={negotiation.metrics}
                  negotiationId={negotiation.id}
                />
              </div>
              <SpendStyled>
                {t('Spend {{spend}} {{currency}}', {
                  spend: formatAmount(negotiation.metrics.currentSpend),
                  currency: customer.currency,
                })}
              </SpendStyled>
            </BottomDataWrapperStyled>
          )
        )}
      </PanelContainerStyled>
    </CompletedNegotiationDataPanelWrapperStyled>
  );
};

export const CompletedNegotiationDataPanelWrapperStyled = styled(
  NegotiationDataPanelWrapperStyled
)(({ theme: { spacing } }) => ({
  marginTop: spacing(3),
}));
