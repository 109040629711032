import {
  black,
  blue,
  dark,
  graphite,
  mint,
  orange,
  red,
  white,
} from '@/modules/theme/utils/colors';
import { fade } from '@/modules/theme/utils/fade';

export const spacingUnitPxValue = 8;

export const palette = {
  default: {
    background: white,
    text: blue[800],
  },
  primary: {
    lighter: blue[400],
    light: blue[600],
    main: blue[800],
    dark: blue[1000],
    text: blue[800],
  },
  secondary: {
    light: mint[400],
    main: mint[800],
    background: graphite,
  },
  tertiary: {
    lighter: orange[400],
    light: orange[600],
    main: orange[800],
    dark: orange[1000],
    text: white,
  },
  disabled: {
    lighter: dark[100],
    light: dark[200],
    main: dark[400],
    text: dark[100],
  },
  error: {
    light: red[500],
    main: red[800],
  },
} as const;

export function createTheme() {
  return {
    spacing: createSpacing(spacingUnitPxValue),
    breakpoints: {
      up: {
        sm: `@media (min-width: ${breakpoints.sm}px)`,
        md: `@media (min-width: ${breakpoints.md}px)`,
        lg: `@media (min-width: ${breakpoints.lg}px)`,
        xl: `@media (min-width: ${breakpoints.xl}px)`,
      },
      down: {
        sm: `@media (max-width: ${breakpoints.sm - 1}.97px)`,
        md: `@media (max-width: ${breakpoints.md - 1}.97px)`,
        lg: `@media (max-width: ${breakpoints.lg - 1}.97px)`,
        xl: `@media (max-width: ${breakpoints.xl - 1}.97px)`,
      },
    },
    palette,
    typography: {
      pxToRem,
      fonts: {
        body: "'Inter', sans-serif",
        heading: "'Sora', sans-serif",
      },
      fontWeight: {
        normal: 400,
        semiBold: 500,
        bold: 600,
        extraBold: 800,
      },
      letterSpacing: {
        default: '-0.022em',
      },
      fontSize: {
        html: htmlFontSize,
        extraSmall: pxToRem(12),
        small: pxToRem(14),
        default: pxToRem(16),
        large: pxToRem(24),
        extraLarge: pxToRem(36),
      },
    },
    zIndexes: {
      background: -100,
      basic: 100,
      tooltip: 200,
      tableHeader: 300,
      tabs: 300,
      dropdownContent: 400,
      sidebarBackdrop: 500,
      sidebar: 600,
      footer: 700,
      authCard: 800,
      dialogOverlay: 900,
      dialogContent: 1000,
    },
    boxShadows: {
      default: `0 50px 100px -20px ${fade(
        '#32325D',
        0.25
      )}, 0 30px 60px -30px ${fade(black, 0.3)}`,
      radio: `0px 4px 13px ${fade(black, 0.12)}`,
      section: `1px -5px 32px 0 ${fade(black, 0.05)}`,
      filters: `-3px 4px 18px ${fade(black, 0.19)}`,
      tile: `0px 30px 50px -30px ${fade(
        black,
        0.2
      )}, 0px 50px 100px -20px rgba(50, 50, 93, 0.25)`,
      tooltip: '0 30px 60px rgba(0, 0, 0, 0.08)',
      input: {
        hover: `0 0 0 1px ${dark[800]}`,
        focus: `0 0 0 1px ${mint[400]}`,
        error: `0 0 0 1px ${orange[800]}`,
      },
      tab: `0px -24px 32px ${fade(black, 0.05)}`,
      dropdown: `0px 30px 50px -30px ${fade(
        black,
        0.2
      )}, 0px 50px 100px -20px rgba(50, 50, 93, 0.25);`,
      card: `0px 50px 100px -20px rgba(50, 50, 93, 0.25), 0px 30px 50px -30px rgba(0, 0, 0, 0.20)`,
    },
    borders: {
      input: {
        main: `1px solid ${blue[800]}`,
        error: `1px solid ${red[800]}`,
      },
    },
    borderRadius: {
      checkbox: 4,
      small: 5,
      default: 6,
      large: 12,
      switch: 16,
      extraLarge: 30,
      round: '50%',
    },
    dialog: {
      small: 484,
      default: 584,
      large: 704,
    },
    gradients: {
      background: `linear-gradient(to right, ${mint[400]} , ${blue[700]})`,
      fadeRight: `linear-gradient(to right, transparent, ${white})`,
      introBackground:
        'linear-gradient(180deg, #AAA 0%, rgba(255, 255, 255) 100%)',
      cards: {
        financial: `linear-gradient(90deg, ${orange[800]} 0%, ${orange[400]} 100%)`,
        counter: `linear-gradient(90deg, ${blue[800]} 0%, ${mint[800]} 100%)`,
        pending: `linear-gradient(90deg, ${black} 0%, ${dark[400]} 100%)`,
      },
      header: {
        grey: `linear-gradient(90deg, ${black} 0%, ${dark[400]} 55.09%)`,
        color: `linear-gradient(90deg, ${blue[700]} 0%, ${mint[800]} 55.09%)`,
      },
    },
  };
}

const breakpoints = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

const htmlFontSize = 16;

const pxToRem = (px: number) => `${px / htmlFontSize}rem`;

function createSpacing(base: number) {
  return (indices: number | number[] = 1): string => {
    if (!Array.isArray(indices)) {
      indices = [indices];
    }

    return indices
      .map((index) => index * base)
      .map((px) => `${px}px`)
      .join(' ');
  };
}

export type Theme = ReturnType<typeof createTheme>;
