import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useSessionContext } from '@/modules/auth/hooks/useSessionContext';
import {
  BottomDataWrapperStyled,
  DataContainerStyled,
  NegotiationDataPanelWrapperStyled,
  NumberOfSuppliersStyled,
  PanelContainerStyled,
  SignetStyled,
  SpendStyled,
} from '@/modules/negotiation/components/NegotiationDataPanel/NegotiationDataPanel.styled';
import { ParsedNegotiation } from '@/modules/negotiation/helpers/parseGetNegotiationResponse';
import { getNegotiationDetailsMetrics } from '@/modules/negotiation/metrics/getNegotiationDetailsMetrics';
import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';
import { formatAmount } from '@/utils/stringUtils';

import { PlannedNegotiationMetrics } from './PlannedNegotiationMetrics';
import { PlannedNegotiationMetricsLoader } from './PlannedNegotiationMetricsLoader';

type PlannedNegotiationDataPanelProps = {
  negotiation: ParsedNegotiation | undefined;
  shouldDisplayLoader: boolean | undefined;
};

export const PlannedNegotiationDataPanel = ({
  negotiation,
  shouldDisplayLoader = false,
}: PlannedNegotiationDataPanelProps) => {
  const { t } = useTranslation('pages/PlannedNegotiationPage');
  const { customer } = useSessionContext();

  return (
    <PlannedNegotiationDataPanelWrapperStyled>
      <PanelContainerStyled>
        <DataContainerStyled>
          {shouldDisplayLoader ? (
            <PlannedNegotiationMetricsLoader />
          ) : (
            negotiation && (
              <PlannedNegotiationMetrics
                metrics={negotiation.metrics}
                metricsToDisplay={getNegotiationDetailsMetrics(negotiation)}
                termRoles={negotiation.termRoles}
              />
            )
          )}
          <SignetStyled />
        </DataContainerStyled>
        {shouldDisplayLoader ? (
          <SkeletonLoader height={33} />
        ) : (
          <BottomDataWrapperStyled>
            <NumberOfSuppliersStyled variant="h3" as="span">
              {t('{{count}} suppliers', {
                count: negotiation?.metrics.suppliersCount,
              })}
            </NumberOfSuppliersStyled>
            <SpendStyled>
              {t('Spend {{spend}} {{currency}}', {
                spend:
                  negotiation &&
                  formatAmount(negotiation?.metrics.currentSpend),
                currency: customer.currency,
              })}
            </SpendStyled>
          </BottomDataWrapperStyled>
        )}
      </PanelContainerStyled>
    </PlannedNegotiationDataPanelWrapperStyled>
  );
};

const PlannedNegotiationDataPanelWrapperStyled = styled(
  NegotiationDataPanelWrapperStyled
)(({ theme: { spacing } }) => ({
  marginTop: spacing(3),
}));
