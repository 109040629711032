import { ReactNode } from 'react';
import styled from 'styled-components';

import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';
import { Text } from '@/modules/theme/components/Typography';

export const TextWithLoader = ({
  children,
  loading,
}: {
  loading: boolean;
  children: ReactNode;
}) => {
  return <>{loading ? <SkeletonLoaderStyled /> : <Text>{children}</Text>}</>;
};

const SkeletonLoaderStyled = styled(SkeletonLoader)({
  marginBottom: 0,
  height: 24,
  width: 150,
});
