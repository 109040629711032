import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { queryKeys } from '@/modules/api/queryKeys';
import { SupplierLanguageType } from '@/modules/language/supplierLanguage/supplierLanguageConsts';
import { getNegotiationKeywordsQuery } from '@/modules/negotiation/api/queries/getNegotiationKeywordsQuery';
import { constructMeta } from '@/modules/reactQuery/constructMeta';

type UseGetNegotiationPayload = {
  negotiationId: string;
  language: SupplierLanguageType;
};

export const useGetNegotiationKeywordsQuery = ({
  negotiationId,
  language,
}: UseGetNegotiationPayload) => {
  const jsonApi = useJsonApi();
  const { t } = useTranslation('apiMessages/useGetNegotiationKeywordsQuery');

  return useQuery({
    queryKey: [queryKeys.negotiations.getKeywords, negotiationId, language],
    queryFn: getNegotiationKeywordsQuery(jsonApi, { negotiationId, language }),
    placeholderData: keepPreviousData,
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t('You do not have access to see keywords'),
        },
        404: {
          NEGOTIATION_NOT_FOUND: t('Negotiation has not been found'),
        },
      },
    }),
  });
};
