import { SuppliersOrder } from '@/modules/suppliers/utils/suppliersOrders';

type DefaultSuppliersQueryParams = {
  page: number;
  size: number;
  sort: SuppliersOrder;
};

export const defaultSuppliersQueryParams: DefaultSuppliersQueryParams = {
  page: 1,
  size: 20,
  sort: 'name',
};
