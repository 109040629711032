import styled from 'styled-components';

import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';

export const DigiProcAreaByCategoriesSkeleton = () => {
  return (
    <>
      <SkeletonLoader width="40%" height={24} />
      <WrapperStyled>
        <SkeletonLoader height={350} />
        <SkeletonLoader height={150} />
      </WrapperStyled>
    </>
  );
};

const WrapperStyled = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(3),
  width: '100%',
  maxWidth: 1200,
  margin: '0 auto',
  marginTop: spacing(3),
}));
