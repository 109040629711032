import styled from 'styled-components';

import { TableCell } from '@/modules/theme/components/Table/TableCell';
import { TableRow } from '@/modules/theme/components/Table/TableRow';

export const TableRowStyled = styled(TableRow)({
  margin: 0,
  background: 'transparent',
});

export const TableCellStyled = styled(TableCell)({
  display: 'block',
  padding: 0,
  gridColumn: 'span 5',
  marginBottom: 0,
});
