import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { queryKeys } from '@/modules/api/queryKeys';
import { constructMeta } from '@/modules/reactQuery/constructMeta';
import { addSupplierMutation } from '@/modules/suppliers/api/mutations/addSupplierMutation';
import { editSupplierMutation } from '@/modules/suppliers/api/mutations/editSupplierMutation';

export const useAddSupplierMutation = () => {
  const jsonApi = useJsonApi();
  const { t } = useTranslation('apiMessages/useAddSupplierMutation');
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [editSupplierMutation.name],
    mutationFn: addSupplierMutation(jsonApi),
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: [queryKeys.suppliers.getSuppliers],
      });
      queryClient.removeQueries({
        queryKey: [queryKeys.suppliers.getSupplier],
      });
    },
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Missing or invalid authentication credentials'),
        },
        403: {
          FORBIDDEN: t("You don't have access to add suppliers"),
        },
        422: {
          SUPPLIER_CATEGORY_NOT_FOUND: t('Supplier category not found.'),
          BUSINESS_UNIT_NOT_FOUND: t('Business unit not found'),
        },
      },
    }),
  });
};
