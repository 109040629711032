import { useTranslation } from 'react-i18next';

import { AuthenticatedLayout } from '@/layouts/AuthenticatedLayout/AuthenticatedLayout';
import { analysisAndOfferNestedRoutes } from '@/modules/router/analysisRoutes';
import { Box } from '@/modules/theme/components/Box';
import { HeaderButtonLink } from '@/modules/theme/components/HeaderButton';
import { MaxWidth } from '@/modules/theme/components/MaxWidth';
import { PageHeader } from '@/modules/theme/components/PageHeader/PageHeader';

import { QuestionnaireForm } from './components/QuestionnaireForm/QuestionnaireForm';
import { UploadSpendAndQuestionnaireIntroSection } from './components/UploadSpendAndQuestionnaireIntroSection/UploadSpendAndQuestionnaireIntroSection';

export const UploadSpendAndQuestionnairePage = () => {
  const { t } = useTranslation('pages/UploadSpendAndQuestionnairePage');

  return (
    <AuthenticatedLayout>
      <PageHeader
        text={t('Upload spend file')}
        buttonLink={
          <HeaderButtonLink to={analysisAndOfferNestedRoutes.buildUrl('intro')}>
            {t('Back')}
          </HeaderButtonLink>
        }
      />
      <main>
        <MaxWidth>
          <Box margins={[0, 7, 8]}>
            <UploadSpendAndQuestionnaireIntroSection />
            <QuestionnaireForm />
          </Box>
        </MaxWidth>
      </main>
    </AuthenticatedLayout>
  );
};
