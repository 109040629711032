import { sanitizeHtml } from '@/utils/sanitizer';

import { SelectOptionValue } from '../types';

export const Highlighter = ({
  sentence,
  keyword,
}: {
  sentence: SelectOptionValue | undefined;
  keyword: string;
}) => {
  const keywordRegexp = new RegExp(keyword, 'gi');
  if (!sentence) return null;

  const boldText = sentence
    .toString()
    .replace(keywordRegexp, (match) => match && `<strong>${match}</strong>`);
  return <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(boldText) }} />;
};
