import { useAuthContext } from '@/modules/auth/hooks/useAuthContext';
import { AnonymousRoutes } from '@/modules/router/routings/anonymousRouting';
import { AuthenticatedRoutes } from '@/modules/router/routings/authenticatedRouting';
import { PageLoader } from '@/modules/theme/components/PageLoader';

export function Routes() {
  const { isAuthenticating, user } = useAuthContext();

  if (isAuthenticating) {
    return <PageLoader />;
  }

  if (user?.userId) {
    return <AuthenticatedRoutes />;
  }
  return <AnonymousRoutes />;
}
