import { MaxWidth } from '@/modules/theme/components/MaxWidth';

import { IntroductionSection } from './components/IntroductionSection/IntroductionSection';
import { NegotiationSection } from './components/NegotiationSection/NegotiationSection';
import usePresentationSlide from './components/PresentationSlider/components/usePresentationSlide';
import { PresentationSlider } from './components/PresentationSlider/PresentationSlider';

export const IntroPage = () => {
  const presentationSlide = usePresentationSlide();

  return (
    <MaxWidth>
      <IntroductionSection />
      <NegotiationSection />
      {presentationSlide && (
        <PresentationSlider presentationUrl={presentationSlide.pdfLink} />
      )}
    </MaxWidth>
  );
};
