import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AuthBackground } from '@/layouts/common/AuthBackground';
import { anonymousRoutes } from '@/modules/router/anonymousRoutes';
import { NonBreakingSpace } from '@/modules/theme/components/NonBreakingSpace';

import {
  ContentWrapperStyled,
  CountingStyled,
  ParagraphStyled,
} from './SignUpSuccessPage.styled';

export const SignUpSuccessPage = () => {
  const { t } = useTranslation('pages/SignUpSuccessPage');
  const [secondsCount, setSecondsCount] = useState(5);
  const navigate = useNavigate();

  useEffect(() => {
    const int = setInterval(() => {
      setSecondsCount((prevState) => prevState - 1);
    }, 1000);
    return () => clearInterval(int);
  }, []);

  useEffect(() => {
    if (secondsCount < 1) {
      navigate(anonymousRoutes.buildUrl('signIn'));
    }
  }, [secondsCount, navigate]);

  return (
    <AuthBackground>
      <ContentWrapperStyled>
        <ParagraphStyled variant="contentBold">
          {t('Your account has been created.')}
        </ParagraphStyled>
        <ParagraphStyled variant="contentBold">
          {t('You will be redirected to the login page in')}
          <NonBreakingSpace />
          <CountingStyled>
            {secondsCount}
            <NonBreakingSpace />
            {t('seconds.')}
          </CountingStyled>
        </ParagraphStyled>
      </ContentWrapperStyled>
    </AuthBackground>
  );
};
