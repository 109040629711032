import * as sup from 'superstruct';

import { JsonApiClient } from '@/modules/api/utils/createJsonApiClient';
import { createResponseStruct } from '@/modules/api/utils/jsonApiStructs';
import { businessUnitResourceType } from '@/modules/customer/api/businessUnits/businessUnitsHelpers';
import { SupplierLanguageType } from '@/modules/language/supplierLanguage/supplierLanguageConsts';

import {
  addSupplierResourcePath,
  supplierResourceType,
} from '../resources/supplier/supplierResourceConsts';
import { supplierResourceStruct } from '../resources/supplier/supplierResourceStruct';
import { supplierCategoryResourceType } from '../resources/supplierCategory/supplierCategoryResourceConsts';

type AddSupplierMutationPayload = {
  attributes: {
    name: string;
    organizationNumber: string | null;
    language: SupplierLanguageType | undefined;
    currentSpend: string;
  };
  categoryId: string | null;
  businessUnitId: string | null;
};

export function addSupplierMutation(jsonApi: JsonApiClient) {
  return ({
    attributes,
    categoryId,
    businessUnitId,
  }: AddSupplierMutationPayload) => {
    return jsonApi.createOne({
      type: supplierResourceType,
      path: addSupplierResourcePath,
      responseStruct: addSupplierResponseStruct,
      attributes,
      relationships: {
        category: {
          data: categoryId
            ? {
                id: categoryId,
                type: supplierCategoryResourceType,
              }
            : null,
        },
        businessUnit: {
          data: businessUnitId
            ? {
                id: businessUnitId,
                type: businessUnitResourceType,
              }
            : null,
        },
      },
    });
  };
}

const addSupplierResponseStruct = createResponseStruct({
  data: supplierResourceStruct,
});

export type AddSupplierResponse = sup.Infer<typeof addSupplierResponseStruct>;
