import styled from 'styled-components';

export const PageWrapperStyled = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

export const PageContentStyled = styled('div')({
  position: 'relative',
  flex: 1,
});
