import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';

type SupplierFormHeadingProps = {
  isEditing: boolean;
  isLoading: boolean;
  supplierName?: string;
};

export const SupplierFormHeading = ({
  isEditing,
  isLoading,
  supplierName,
}: SupplierFormHeadingProps) => {
  const { t } = useTranslation('modules/suppliers/SupplierForm');

  if (!isEditing) return <>{t('Supplier details')}</>;

  return isLoading ? <SupplierNameLoaderStyled /> : <>{supplierName}</>;
};

export const SupplierNameLoaderStyled = styled(SkeletonLoader)({
  marginBottom: 0,
});
