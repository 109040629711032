import { compile as compilePathPattern } from 'path-to-regexp';
import qs from 'qs';

export function composeUrl(
  url: string,
  pathParams: Record<string, unknown> | undefined,
  queryParams: Record<string, unknown> | undefined
) {
  const urlWithPathParams = pathParams
    ? compilePathPattern(url, { encode: encodeURIComponent })(pathParams)
    : url;

  return queryParams
    ? `${urlWithPathParams}?${qs.stringify(queryParams, {
        arrayFormat: 'comma',
      })}`
    : urlWithPathParams;
}
