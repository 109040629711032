import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import * as sup from 'superstruct';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { queryKeys } from '@/modules/api/queryKeys';
import { JsonApiClient } from '@/modules/api/utils/createJsonApiClient';
import { createResponseStruct } from '@/modules/api/utils/jsonApiStructs';
import { constructMeta } from '@/modules/reactQuery/constructMeta';

import { activeSubscriptionPlanResourceStruct } from './subscriptionPlanHelpers';

type UseGetCustomerActiveSubscriptionPlanQueryPayload = {
  enabled?: boolean;
};

export const useGetCustomerActiveSubscriptionPlanQuery = (
  payload: UseGetCustomerActiveSubscriptionPlanQueryPayload = { enabled: true }
) => {
  const jsonApi = useJsonApi();
  const { t } = useTranslation(
    'apiMessages/useGetCustomerActiveSubscriptionPlanQuery'
  );

  return useQuery({
    queryKey: [queryKeys.customer.getActiveSubscriptionPlan],
    queryFn: getActiveSubscriptionPlanQuery(jsonApi),
    enabled: payload.enabled,
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t("You don't have access to a subscription plan"),
        },
        404: {
          SUBSCRIPTION_PLAN_NOT_FOUND: () => undefined,
        },
      },
    }),
  });
};

const getActiveSubscriptionPlanResponseStruct = createResponseStruct({
  data: activeSubscriptionPlanResourceStruct,
});

export type GetActiveSubscriptionPlanResponse = sup.Infer<
  typeof getActiveSubscriptionPlanResponseStruct
>;

const getActiveSubscriptionPlanQuery = (jsonApi: JsonApiClient) => {
  return async () => {
    const response = await jsonApi.getOne({
      path: '/customer/active_subscription_plan',
      responseStruct: getActiveSubscriptionPlanResponseStruct,
    });

    return parseGetActiveSubscriptionPlanResponse(response);
  };
};

const parseGetActiveSubscriptionPlanResponse = (
  response: GetActiveSubscriptionPlanResponse
) => {
  const {
    data: { id, attributes },
  } = response;

  return { id, ...attributes };
};
