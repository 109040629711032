import { parseNegotiationTerms } from '../helpers/parseNegotiationTerms';
import { negotiationWithProgressSuppliersOrders } from '../utils/consts';
import { parseGetNegotiationsResponse } from '../utils/parseGetNegotiationsResponse';

export enum NegotiationType {
  IMPROVED_PRICES = 'IMPROVED_PRICES',
  LOCKED_PRICES = 'LOCKED_PRICES',
  EXTRA_PAYMENT_DAYS = 'EXTRA_PAYMENT_DAYS',
  STANDARD_AGREEMENT = 'STANDARD_AGREEMENT',
  CODE_OF_CONDUCT = 'CODE_OF_CONDUCT',
  SUPPLIER_QUESTIONNAIRE = 'SUPPLIER_QUESTIONNAIRE',
  ISO = 'ISO',
}

export enum NegotiationTermRole {
  ACCEPT_CONTRACT_PERIOD = 'acceptContractPeriod',
  EXTEND_CONTRACT_PERIOD = 'extendContractPeriod',
  OFFER_DISCOUNT = 'offerDiscount',
  SELECT_DISCOUNT_IMPLEMENTATION = 'selectDiscountImplementation',
  LOCK_PRICE = 'lockPrice',
  EXTEND_INVOICE_DUE_DATE = 'extendInvoiceDueDate',
  SIGN_CODE_OF_CONDUCT = 'signCodeOfConduct',
  ANSWER_SUSTAINABILITY_QUESTION = 'answerSustainabilityQuestion',
  ANSWER_QUESTION = 'answerQuestion',
  TEXT = 'text',
  SIGN_STANDARD_AGREEMENT = 'signStandardAgreement',
  BE_ISO_CERTIFIED = 'beIsoCertified',
}

export enum NegotiationSetupPendingStep {
  START_SETUP = 'startSetup',
  SELECT_SUPPLIERS = 'selectSuppliers',
  SET_TERMS = 'setTerms',
  SET_CONTRACT_PERIOD = 'setContractPeriod',
  WRITE_INTRODUCTION = 'writeIntroduction',
  START = 'start',
}

export enum NegotiationState {
  DRAFT = 'draft',
  ESTIMATED = 'estimated',
  SUGGESTED = 'suggested',
  SETUP = 'setup',
  ACTIVE = 'active',
  PLANNED = 'planned',
  COMPLETED = 'completed',
}

export type ParsedNegotiationItem = ReturnType<
  typeof parseGetNegotiationsResponse
>['negotiationsList'][number];

export enum NegotiationCommunicationState {
  PENDING = 'pending',
  SENDING = 'sending',
  SENT = 'sent',
  CANCELLED = 'cancelled',
}

export enum Implementation {
  INVOICE_DISCOUNT = 'invoiceDiscount',
  CREDIT_INVOICE = 'creditInvoice',
  REDUCED_PRICES = 'reducedPrices',
}

export const NegotiationTermTypes = {
  [NegotiationTermRole.ANSWER_QUESTION]: 'term/answer_question',
  [NegotiationTermRole.ANSWER_SUSTAINABILITY_QUESTION]:
    'term/answer_sustainability_question',
  [NegotiationTermRole.EXTEND_CONTRACT_PERIOD]: 'term/extend_contract_period',
  [NegotiationTermRole.EXTEND_INVOICE_DUE_DATE]: 'term/extend_invoice_due_date',
  [NegotiationTermRole.LOCK_PRICE]: 'term/lock_price',
  [NegotiationTermRole.SIGN_CODE_OF_CONDUCT]: 'term/sign_code_of_conduct',

  [NegotiationTermRole.TEXT]: 'term/text',
  [NegotiationTermRole.ACCEPT_CONTRACT_PERIOD]: 'term/accept_contract_period',
  [NegotiationTermRole.OFFER_DISCOUNT]: 'term/offer_discount',
  [NegotiationTermRole.SELECT_DISCOUNT_IMPLEMENTATION]:
    'term/select_discount_implementation',
  [NegotiationTermRole.SIGN_STANDARD_AGREEMENT]: 'term/sign_standard_agreement',
  [NegotiationTermRole.BE_ISO_CERTIFIED]: 'term/be_iso_certified',
} as const;

export type TermTypes = typeof NegotiationTermTypes;

export type TermType =
  (typeof NegotiationTermTypes)[keyof typeof NegotiationTermTypes];

type ParsedTerms = ReturnType<typeof parseNegotiationTerms>[number];

export type DiscountImplementationTerm = Extract<
  ParsedTerms,
  { type: TermTypes['selectDiscountImplementation'] }
>;
export type CodeOfConductTerm = Extract<
  ParsedTerms,
  { type: TermTypes['signCodeOfConduct'] }
>;
export type NegotiationContractPeriodTerm = Extract<
  ParsedTerms,
  { type: TermTypes['acceptContractPeriod'] }
>;

export type NegotiationWithProgressSuppliersOrder =
  (typeof negotiationWithProgressSuppliersOrders)[number];

export enum AcceptableTermAnswer {
  YES = 'yes',
  NO = 'no',
  DONT_KNOW = 'dontKnow',
}

type Answer = Implementation | number | AcceptableTermAnswer;

export type AnsweredTerm = {
  type: TermType;
  text: string | null | undefined;
  answer: Answer | undefined;
};
