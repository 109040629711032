import { forwardRef, useState } from 'react';

import {
  InputErrorMessage,
  InputErrorMessageProps,
} from '@/modules/form/components/ErrorMessage/InputErrorMessage';
import { InputError } from '@/modules/form/components/InputError';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';

import { RadioInput } from './RadioInput';
import { LabelVariant, RadioLabel } from './SelectionLabel';

type RadioFieldProps = InputErrorMessageProps &
  React.ComponentProps<'input'> & {
    id: string;
    value: string | number;
    hideError?: boolean;
    labelVariant?: LabelVariant;
  };

export const RadioField = forwardRef<HTMLInputElement, RadioFieldProps>(
  (
    {
      name,
      onChange,
      value,
      id,
      hideError = false,
      labelVariant,
      children,
      error,
      errorMessages,
      ErrorMessage,
      ...props
    },
    ref
  ) => {
    const [appliedError, setAppliedError] = useState('');

    const handleErrorChange = (error: string) => {
      setAppliedError(error);
    };

    return (
      <div>
        <FlexContainer align="center" gap={0}>
          <RadioInput
            {...props}
            type="radio"
            id={id}
            name={name}
            ref={ref}
            value={value}
            onChange={onChange}
          />
          <RadioLabel
            variant={labelVariant}
            appliedError={Boolean(appliedError)}
            htmlFor={id}
          >
            {children}
          </RadioLabel>
        </FlexContainer>
        <InputError errorHidden={!hideError}>
          <InputErrorMessage
            name={name}
            error={error}
            errorMessages={errorMessages}
            ErrorMessage={ErrorMessage}
            onErrorChange={handleErrorChange}
          />
        </InputError>
      </div>
    );
  }
);
