import { ReactNode } from 'react';
import {
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
} from 'react-hook-form';

import { UseFormProps, UseFormReturn } from './hooks/useForm/helpers/types';
import { useForm } from './hooks/useForm/useForm';

/**
 * It's a wrapper for the react-hook-form `<FormProvider />` & `<form />`
 * components and `useForm` hook.
 *
 * The component takes the following props:
 * - `defaultValues` - default values for the form inputs
 * - `mode` - Validation strategy before a user submits the form
 * - `struct` - superstruct's object schema defining the form validation
 * - `onValidSubmit` - it's a function called when a valid form is submitted
 * - `onInvalidSubmit?` - it's a function called when an INVALID form is submitted
 * - `children` - render props function that takes props returned from `useForm`
 */
export function Form<T extends Record<string, unknown>>({
  struct,
  mode,
  defaultValues,
  values,
  onValidSubmit,
  onInvalidSubmit,
  children,
  id,
}: FormProps<T>) {
  const providerProps = useForm({
    struct,
    mode,
    defaultValues,
    values,
  });

  const handleSubmit =
    onValidSubmit && providerProps.handleSubmit(onValidSubmit, onInvalidSubmit);

  return (
    <FormProvider {...providerProps}>
      <form onSubmit={handleSubmit} noValidate id={id}>
        {children(providerProps)}
      </form>
    </FormProvider>
  );
}

type FormProps<T extends Record<string, unknown>> = {
  children: (props: UseFormReturn<T>) => ReactNode;
  onValidSubmit?: SubmitHandler<T>;
  onInvalidSubmit?: SubmitErrorHandler<T>;
  id?: string;
} & UseFormProps<T>;
