import styled from 'styled-components';

import { TableVariant } from './types';

export const TableMobileHeader = styled('div')(
  ({ theme: { breakpoints, spacing, palette } }) => ({
    display: 'none',
    [breakpoints.down.md]: {
      display: 'block',
      marginRight: spacing(1),
      color: palette.secondary.main,
    },
  })
);

type TableCellStyledProps = {
  variant: TableVariant;
  warning?: boolean;
};

export const TableCellStyled = styled('div')<TableCellStyledProps>(({
  theme: { spacing, borderRadius, breakpoints, palette },
  variant,
  warning = false,
}) => {
  const warningBorder = `1px solid ${palette.tertiary.main}`;

  return {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'inherit',
    minHeight: spacing(7),
    marginBottom: variant === 'striped' ? 0 : spacing(1),
    padding: spacing([2, 0, 2, 2]),
    ...(warning && {
      border: warningBorder,
      borderLeft: 'none',
      borderRight: 'none',
    }),

    '&:first-child': {
      borderRadius: variant === 'striped' ? 0 : borderRadius.default,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      ...(warning && { borderLeft: warningBorder }),
    },
    '&:last-child': {
      borderRadius: variant === 'striped' ? 0 : borderRadius.default,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      paddingRight: spacing(2),
      ...(warning && { borderRight: warningBorder }),
    },

    [breakpoints.down.md]: {
      marginBottom: 0,
      ...(warning && {
        border: warningBorder,
        borderTop: 'none',
        borderBottom: 'none',
      }),

      '&:first-child': {
        borderRadius: variant === 'striped' ? 0 : borderRadius.default,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        ...(warning && { borderTop: warningBorder }),
      },

      '&:last-child': {
        borderRadius: variant === 'striped' ? 0 : borderRadius.default,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        marginBottom: variant === 'striped' ? 0 : spacing(1),
        ...(warning && { borderBottom: warningBorder }),
      },
    },
  };
});
