import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import * as sup from 'superstruct';

import { useForm } from '@/modules/form/components/Form/hooks/useForm/useForm';
import { notify } from '@/modules/notify/notify';
import { analysisAndOfferNestedRoutes } from '@/modules/router/analysisRoutes';
import { Button } from '@/modules/theme/components/Button/Button';
import { ButtonLink } from '@/modules/theme/components/ButtonLink';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { Heading } from '@/modules/theme/components/Typography';
import { blue } from '@/modules/theme/utils/colors';

import { CategoriesSection } from './components/CategoriesSection/CategoriesSection';
import { NotesSection } from './components/NotesSection';
import { TermImportanceSection } from './components/TermImportanceSection/TermImportanceSection';
import { UploadSpendFileSection } from './components/UploadSpendFileSection';
import { questionnaireFormStruct } from './helpers/questionnaireFormStruct';
import { serializeSpendAndQuestionnaireValues } from './helpers/serializeQuestionnaireValues';
import { useUploadSpendAndQuestionnaire } from './hooks/useUploadSpendAndQuestionnaire';

export const QuestionnaireForm = () => {
  const { t } = useTranslation('pages/UploadSpendAndQuestionnairePage');
  const navigate = useNavigate();

  const { handleUploadSpendAndQuestionnaire, isPending } =
    useUploadSpendAndQuestionnaire();

  const formProps = useForm({
    defaultValues: {
      spendFile: undefined,
      offerDiscount: null,
      lockPrice: null,
      signCodeOfConduct: null,
      extendInvoiceDueDate: null,
      answerSustainabilityQuestion: null,
      importantCategories: [],
      categoryImportance: [],
      notes: '',
    },
    struct: questionnaireFormStruct,
  });

  const { handleSubmit, formState } = formProps;

  const submitQuestionnaire = async (
    values: sup.Infer<typeof questionnaireFormStruct>
  ) => {
    const serializedValues = serializeSpendAndQuestionnaireValues(values);

    await handleUploadSpendAndQuestionnaire({
      values: serializedValues,
      onSuccess: () => {
        notify.alert.success(
          t('Spend file & questionnaire were successfully uploaded')
        );
        navigate(analysisAndOfferNestedRoutes.buildUrl('analysis'));
      },
    });
  };

  return (
    <div>
      <HeadingStyled variant="h2">{t('DigiProc questionnaire')}</HeadingStyled>
      <FormProvider {...formProps}>
        <form onSubmit={handleSubmit(submitQuestionnaire)} noValidate>
          <UploadSpendFileSection />
          <TermImportanceSection />
          <CategoriesSection />
          <NotesSection />
          <FlexContainer direction="column" align="center" gap={2}>
            <Button
              type="submit"
              disabled={!formState.isValid}
              loading={isPending}
            >
              {t('Submit')}
            </Button>
            <ButtonLink
              variant="outline"
              to={analysisAndOfferNestedRoutes.buildUrl('intro')}
            >
              {t('Back')}
            </ButtonLink>
          </FlexContainer>
        </form>
      </FormProvider>
    </div>
  );
};

const HeadingStyled = styled(Heading)(({ theme: { spacing } }) => ({
  position: 'relative',

  ':before': {
    content: "''",
    position: 'absolute',
    bottom: spacing(-1),
    left: 0,
    right: 0,
    height: 1,
    backgroundColor: blue[800],
  },
}));
