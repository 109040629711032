import { useFormContext, useFieldArray, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { findSelectValue } from '@/modules/form/components/Select/findSelectValue';
import { SelectInput } from '@/modules/form/components/Select/SelectInput/SelectInput';
import { Heading } from '@/modules/theme/components/Typography';

import { categories, selectCategoriesImportance } from '../../consts';
import { QuestionnaireForm } from '../../helpers/questionnaireFormStruct';
import { QuestionnaireFormSection } from '../QuestionnaireFormSection';

import {
  CategoriesImportanceSectionWrapper,
  CategoriesSectionStyled,
  CheckboxStyled,
  DropdownWrapperStyled,
  LabelStyled,
  LabelTextStyled,
  SelectsWrapperStyled,
} from './CategoriesSection.styled';

type SelectCategoriesImportance = keyof typeof selectCategoriesImportance;

type CategoryImportanceQuestionOption = {
  label: string;
  value: SelectCategoriesImportance;
};

export const CategoriesSection = () => {
  const { t } = useTranslation('pages/UploadSpendAndQuestionnairePage');

  const { control, register, getValues } = useFormContext<QuestionnaireForm>();

  const categoryImportanceQuestionOptions: CategoryImportanceQuestionOption[] =
    [
      {
        label: t('Interesting'),
        value: selectCategoriesImportance.interesting,
      },
      { label: t('Important'), value: selectCategoriesImportance.important },
      {
        label: t('Very important'),
        value: selectCategoriesImportance.veryImportant,
      },
    ];

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'categoryImportance',
    keyName: 'categoryImportanceId',
  });

  const importantCategories = useWatch({
    control,
    name: 'importantCategories',
  });

  const handleCategoryChange = (
    value: string,
    category: (typeof categories)[number]
  ) => {
    const chosenCategoryItem = getValues('categoryImportance').find(
      (pickedCategory) => pickedCategory.label === value
    );

    if (chosenCategoryItem) {
      remove(
        getValues('categoryImportance').findIndex(
          (element) => element.label === value
        )
      );
    } else {
      append(
        {
          label: category,
          value: selectCategoriesImportance.interesting,
        },
        {
          shouldFocus: false,
        }
      );
    }
  };

  const importantCategoriesRegisterProps = register('importantCategories');

  const updateCategoryImportance = ({
    value,
    index,
    label,
  }: {
    value: SelectCategoriesImportance;
    index: number;
    label: string;
  }) => {
    update(index, {
      label,
      value,
    });
  };

  return (
    <QuestionnaireFormSection
      number={3}
      title={t('Which are your most important supplier categories?')}
    >
      <CategoriesSectionStyled>
        {categories.map((category) => {
          return (
            <CheckboxStyled
              {...importantCategoriesRegisterProps}
              id={category}
              value={category}
              labelVariant="bold"
              key={category}
              hideError
              onChange={async (event) => {
                await importantCategoriesRegisterProps.onChange(event);
                handleCategoryChange(event.target.value, category);
              }}
            >
              <LabelTextStyled>{t(category)}</LabelTextStyled>
            </CheckboxStyled>
          );
        })}
      </CategoriesSectionStyled>

      {importantCategories.length > 0 && (
        <CategoriesImportanceSectionWrapper>
          <Heading variant="h3">{t('Please rate them by importance')}</Heading>
          <SelectsWrapperStyled>
            {fields.map((field, index) => {
              const fieldId = `categoriesImportance-${index}`;

              return (
                <DropdownWrapperStyled key={index}>
                  <LabelStyled htmlFor={fieldId}>{field.label}</LabelStyled>
                  <SelectInput
                    name={register('categoryImportance').name}
                    id={fieldId}
                    options={categoryImportanceQuestionOptions}
                    defaultValue={findSelectValue({
                      currentValue: field.value,
                      options: categoryImportanceQuestionOptions,
                    })}
                    showErrorMessage={false}
                    onChange={(value) =>
                      value &&
                      updateCategoryImportance({
                        value: value.value,
                        label: field.label,
                        index,
                      })
                    }
                  />
                </DropdownWrapperStyled>
              );
            })}
          </SelectsWrapperStyled>
        </CategoriesImportanceSectionWrapper>
      )}
    </QuestionnaireFormSection>
  );
};
