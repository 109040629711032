import { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { Text } from '@/modules/theme/components/Typography/Text';

interface BulletListProps {
  list?: string[];
  className?: string;
  children?: ReactNode;
}

export const BulletList: FC<BulletListProps> = ({
  list,
  children,
  className,
}) => {
  return (
    <BulletListStyled className={className}>
      {list?.map((item) => (
        <li key={item}>
          <TextStyled>{item}</TextStyled>
        </li>
      ))}
      {children}
    </BulletListStyled>
  );
};

const BulletListStyled = styled('ul')(({ theme: { palette } }) => ({
  color: palette.default.text,
  listStyleType: 'disc',
}));

const TextStyled = styled(Text)(({ theme: { typography } }) => ({
  fontWeight: typography.fontWeight.bold,
}));
