import {
  Order,
  SortRule,
  SupplierTableColumn,
} from '@/modules/suppliers/types/supplierTypes';
import { TableHeaderCell } from '@/modules/theme/components/Table/Table.styled';

import {
  ChevronStyled,
  TableHeaderButton,
} from './SuppliersTableHeaderCell.styled';

type SuppliersTableHeaderCellProps = {
  columnId: SupplierTableColumn;
  sortRule: SortRule;
  children: string;
  onClick: (SupplierTableColumn: SupplierTableColumn) => void;
};

export const SuppliersTableHeaderCell = ({
  columnId,
  sortRule,
  children,
  onClick,
}: SuppliersTableHeaderCellProps) => {
  const arrowDirection =
    sortRule?.column === columnId
      ? sortRule.order === Order.desc
        ? 'down'
        : 'up'
      : undefined;

  return (
    <TableHeaderCell>
      <TableHeaderButton onClick={() => onClick(columnId)}>
        {children}
        <ChevronStyled
          arrowVisible={sortRule?.column === columnId}
          direction={arrowDirection}
        />
      </TableHeaderButton>
    </TableHeaderCell>
  );
};
