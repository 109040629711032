import styled from 'styled-components';

import { Link } from '@/modules/theme/components/Link/Link';
import { white } from '@/modules/theme/utils/colors';

import { ButtonProps } from './Button/Button';
import { ButtonStyled } from './Button/Button.styled';

export enum LinkType {
  Internal,
  External,
}

type ButtonLinkProps = Omit<ButtonProps, 'onClick' | 'type'> & {
  to: string;
  linkType?: LinkType;
  targetBlank?: boolean;
  download?: string;
  onClick?: () => void;
};

export const ButtonLink: React.FC<ButtonLinkProps> = ({
  children,
  to,
  linkType = LinkType.Internal,
  variant = 'default',
  download,
  className,
  ...props
}) => {
  return (
    <ButtonLinkStyled
      as={linkType === LinkType.Internal ? Link.Internal : Link.External}
      to={to}
      variant={variant}
      download={download}
      className={className}
      {...props}
    >
      {children}
    </ButtonLinkStyled>
  );
};

const ButtonLinkStyled = styled(ButtonStyled)<ButtonLinkProps>(
  ({ variant = 'default' }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
    textAlign: 'center',
    ...(variant !== 'outline' && {
      color: white,
      '&:hover, &:active, &:focus': {
        color: white,
      },
    }),
  })
);
