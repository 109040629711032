import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { AuthenticatedLayout } from '@/layouts/AuthenticatedLayout/AuthenticatedLayout';
import { Box } from '@/modules/theme/components/Box';
import { PageHeader } from '@/modules/theme/components/PageHeader/PageHeader';
import { PageMeta } from '@/modules/theme/components/PageMeta';
import { Tabs } from '@/modules/theme/components/Tabs/Tabs';

import { useAnalysisAndOfferTabItems } from './useAnalysisAndOfferTabItems';

export const ANALYSIS_PAGE_ACTIVE_TAB = 'analysisPageActiveTab';

export const AnalysisAndOfferPage = () => {
  const { t } = useTranslation('pages/AnalysisAndOfferPage');
  const tabs = useAnalysisAndOfferTabItems();

  const onClick = (tab: string) => {
    window.sessionStorage.setItem(ANALYSIS_PAGE_ACTIVE_TAB, tab);
  };

  return (
    <AuthenticatedLayout>
      <PageMeta title={t('Analysis & Offer')} />
      <PageHeader text={t('Analysis & Offer')} />
      <Box mt={7} mb={7}>
        <Tabs tabs={tabs} variant="small" onClick={onClick} />
      </Box>
      <Outlet />
    </AuthenticatedLayout>
  );
};
