import * as sup from 'superstruct';

import {
  atLeastOne,
  emailPattern,
  notEmpty,
} from '@/modules/form/utils/structs';
import { recompose } from '@/utils/structs/recompose';

export const contactPersonStruct = sup.object({
  id: sup.string(),
  firstName: sup.nullable(sup.string()),
  lastName: sup.nullable(sup.string()),
  email: sup.nullable(sup.string()),
  phoneNumber: sup.nullable(sup.string()),
});

export type ContactPerson = sup.Infer<typeof contactPersonStruct>;

export const contactPersonFormStruct = sup.object({
  firstName: atLeastOne(recompose(sup.string(), notEmpty), sup.string()),
  lastName: atLeastOne(recompose(sup.string(), notEmpty), sup.string()),
  email: atLeastOne(
    recompose(sup.string(), notEmpty, emailPattern),
    recompose(sup.string(), emailPattern)
  ),
  phoneNumber: atLeastOne(recompose(sup.string(), notEmpty), sup.string()),
});

export type ContactPersonFormType = sup.Infer<typeof contactPersonFormStruct>;
