import styled from 'styled-components';

import tickIconUrl from '@/assets/icons/tickIcon.svg';

type OptionContentWrapperStyledProps = {
  isOptionSelected: boolean;
};

export const OptionContentWrapperStyled = styled(
  'div'
)<OptionContentWrapperStyledProps>(
  ({ isOptionSelected, theme: { spacing } }) => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: spacing(3),
    lineHeight: 'initial',
    '&:after': {
      content: `url("${tickIconUrl}")`,
      position: 'absolute',
      right: spacing(2),
      top: `calc(50% - ${spacing(1)})`,
      display: isOptionSelected ? 'block' : 'none',
      height: 13,
      width: 18,
    },
  })
);
