import styled from 'styled-components';

import { Button } from '@/modules/theme/components/Button/Button';
import { Dialog } from '@/modules/theme/components/Dialog/Dialog';
import { Heading, Paragraph } from '@/modules/theme/components/Typography';

export const DialogStyled = styled(Dialog)(({ theme: { spacing } }) => ({
  padding: spacing([5, 7]),
  alignItems: 'start',
  gap: spacing(2),
}));

export const HeadingStyled = styled(Heading)(
  ({ theme: { typography, spacing } }) => ({
    fontFamily: typography.fonts.body,
    marginTop: spacing(3),
  })
);

export const ParagraphStyled = styled(Paragraph)(
  ({ theme: { typography } }) => ({
    fontWeight: typography.fontWeight.semiBold,
  })
);

export const ButtonsWrapperStyled = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  gap: spacing(1),
  marginTop: spacing(5),
}));

export const ButtonStyled = styled(Button)({
  minHeight: 'auto',
  height: 44,
  padding: 0,
});
