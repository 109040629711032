import { Navigate, Route, Routes } from 'react-router-dom';

import { SupplierFormPageProvider } from '@/modules/suppliers/components/SupplierFormPageProvider';
import { ActiveNegotiationScene } from '@/pages/ActiveNegotiationPage/ActiveNegotiationScene';
import {
  ANALYSIS_PAGE_ACTIVE_TAB,
  AnalysisAndOfferPage,
} from '@/pages/AnalysisAndOfferPage/AnalysisAndOfferPage';
import { AnalysisPage } from '@/pages/AnalysisAndOfferPage/tabs/AnalysisPage/AnalysisPage';
import DynamicTabs from '@/pages/AnalysisAndOfferPage/tabs/DynamicTabs/DynamicTabs';
import { IntroPage } from '@/pages/AnalysisAndOfferPage/tabs/IntroPage/IntroPage';
import { SuggestedNegotiationsPage } from '@/pages/AnalysisAndOfferPage/tabs/SuggestedNegotiationsPage/SuggestedNegotiationsPage';
import { AuthenticatedNotFoundPage } from '@/pages/AuthenticatedNotFoundPage';
import { CompletedNegotiationScene } from '@/pages/CompletedNegotiationPage/CompletedNegotiationScene';
import { CPOLetterPage } from '@/pages/CPOLetterPage/CPOLetterPage';
import { DashboardScene } from '@/pages/DashboardPage/DashboardScene';
import { InvitationLetter } from '@/pages/NegotiationSetupPage/components/tabs/InvitationLetter/InvitationLetter';
import { Keywords } from '@/pages/NegotiationSetupPage/components/tabs/Keywords/Keywords';
import { StartDate } from '@/pages/NegotiationSetupPage/components/tabs/StartDate/StartDate';
import { SuppliersTabScene } from '@/pages/NegotiationSetupPage/components/tabs/Suppliers/SuppliersTabScene';
import { Terms } from '@/pages/NegotiationSetupPage/components/tabs/Terms/Terms';
import { NegotiationSetupPage } from '@/pages/NegotiationSetupPage/NegotiationSetupPage';
import { PlannedNegotiationSuppliersScene } from '@/pages/PlannedNegotiationPage/components/PlannedNegotiationSuppliers/PlannedNegotiationSuppliersScene';
import { PlannedNegotiationTerms } from '@/pages/PlannedNegotiationPage/components/PlannedNegotiationTerms';
import { PlannedNegotiationPage } from '@/pages/PlannedNegotiationPage/PlannedNegotiationPage';
import { ProfilePage } from '@/pages/ProfilePage/ProfilePage';
import { SupplierAddPage } from '@/pages/SupplierAddPage/SupplierAddPage';
import { SupplierEditPage } from '@/pages/SupplierEditPage/SupplierEditPage';
import { SupplierPage } from '@/pages/SupplierPage/SupplierPage';
import { SuppliersScene } from '@/pages/SuppliersPage/SuppliersScene';
import { SupportPage } from '@/pages/SupportPage/SupportPage';
import { UploadSpendAndQuestionnairePage } from '@/pages/UploadSpendAndQuestionnairePage/UploadSpendAndQuestionnairePage';

import {
  analysisAndOfferNestedRoutes,
  analysisRoutes,
} from '../analysisRoutes';
import {
  onboardedRoutes,
  plannedNegotiationRoutes,
  setupNegotiationRoutes,
} from '../onboardedRoutes';

import { authenticatedFallthroughRoutes } from './utils/fallthroughRoutes';

export const AuthenticatedRoutes = () => {
  const activeRoute = Object.values(analysisAndOfferNestedRoutes.config).find(
    (val) =>
      val.nestedPath === window.sessionStorage.getItem(ANALYSIS_PAGE_ACTIVE_TAB)
  );

  const defaultPath = onboardedRoutes.config.dashboard.path;

  return (
    <Routes>
      <Route
        path={analysisRoutes.config.analysisAndOffer.path}
        element={<AnalysisAndOfferPage />}
      >
        <Route
          index
          element={
            <Navigate
              to={
                activeRoute?.path ||
                analysisAndOfferNestedRoutes.config.intro.nestedPath
              }
              replace
            />
          }
        />
        <Route
          path={analysisAndOfferNestedRoutes.config.intro.path}
          element={<IntroPage />}
        />
        <Route
          path={analysisAndOfferNestedRoutes.config.analysis.path}
          element={<AnalysisPage />}
        />
        <Route
          path={analysisAndOfferNestedRoutes.config.suggestedNegotiations.path}
          element={<SuggestedNegotiationsPage />}
        />
        <Route
          path={analysisAndOfferNestedRoutes.config.dynamic.path}
          element={<DynamicTabs />}
        />
      </Route>
      <Route
        path={analysisRoutes.config.uploadSpendAndQuestionnaire.path}
        element={<UploadSpendAndQuestionnairePage />}
      />
      <Route
        path={onboardedRoutes.config.dashboard.path}
        element={<DashboardScene />}
      />
      <Route
        path={onboardedRoutes.config.profile.path}
        element={<ProfilePage />}
      />
      <Route
        path={onboardedRoutes.config.support.path}
        element={<SupportPage />}
      />
      <Route
        path={onboardedRoutes.config.suppliers.path}
        element={<SuppliersScene />}
      />
      <Route
        path={onboardedRoutes.config.supplier.path}
        element={<SupplierPage />}
      />
      <Route
        path={onboardedRoutes.config.addSupplier.path}
        element={
          <SupplierFormPageProvider>
            <SupplierAddPage />
          </SupplierFormPageProvider>
        }
      />
      <Route
        path={onboardedRoutes.config.editSupplier.path}
        element={
          <SupplierFormPageProvider>
            <SupplierEditPage />
          </SupplierFormPageProvider>
        }
      />
      <Route
        path={onboardedRoutes.config.cpoLetter.path}
        element={<CPOLetterPage />}
      />
      <Route
        path={onboardedRoutes.config.negotiationSetup.path}
        element={<NegotiationSetupPage />}
      >
        <Route
          index
          element={
            <Navigate
              to={setupNegotiationRoutes.config.startDate.nestedPath}
              replace
            />
          }
        />
        <Route
          path={setupNegotiationRoutes.config.startDate.path}
          element={<StartDate />}
        />
        <Route
          path={setupNegotiationRoutes.config.invitationLetter.path}
          element={<InvitationLetter />}
        />
        <Route
          path={setupNegotiationRoutes.config.terms.path}
          element={<Terms />}
        />

        <Route
          path={setupNegotiationRoutes.config.keywords.path}
          element={<Keywords />}
        />
        <Route
          path={setupNegotiationRoutes.config.suppliers.path}
          element={<SuppliersTabScene />}
        />
      </Route>
      <Route
        path={onboardedRoutes.config.plannedNegotiation.path}
        element={<PlannedNegotiationPage />}
      >
        <Route
          path={plannedNegotiationRoutes.config.suppliers.path}
          element={<PlannedNegotiationSuppliersScene />}
        />
        <Route
          path={plannedNegotiationRoutes.config.terms.path}
          element={<PlannedNegotiationTerms />}
        />
      </Route>
      <Route
        path={onboardedRoutes.config.activeNegotiation.path}
        element={<ActiveNegotiationScene />}
      />
      <Route
        path={onboardedRoutes.config.completedNegotiation.path}
        element={<CompletedNegotiationScene />}
      />
      <Route path="*" element={<AuthenticatedNotFoundPage />} />
      {authenticatedFallthroughRoutes(defaultPath)}
    </Routes>
  );
};
