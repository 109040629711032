import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { dark } from '@/modules/theme/utils/colors';

type SaveStateIndicatorProps = {
  isUpdating: boolean;
  isSaved: boolean;
};

export const SaveStateIndicator = ({
  isUpdating,
  isSaved,
}: SaveStateIndicatorProps) => {
  const { t } = useTranslation('pages/InvitationLetter');

  if (isUpdating)
    return <SaveStatusStyled>{t('Saving draft...')}</SaveStatusStyled>;

  if (isSaved) return <SaveStatusStyled>{t('Draft saved')}</SaveStatusStyled>;

  return <SaveStatusStyled>&nbsp;</SaveStatusStyled>;
};

export const SaveStatusStyled = styled('p')(
  ({ theme: { spacing, typography } }) => ({
    color: dark[600],
    fontSize: typography.fontSize.small,
    textAlign: 'right',
    padding: spacing([3, 6]),
  })
);
