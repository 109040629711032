import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { queryKeys } from '@/modules/api/queryKeys';
import { notify } from '@/modules/notify/notify';
import { constructMeta } from '@/modules/reactQuery/constructMeta';
import { deleteSupplierMutation } from '@/modules/suppliers/api/mutations/deleteSupplierMutation';

type UseDeleteSupplierMutationParams = {
  onSuccess: () => void;
};

export const useDeleteSupplierMutation = ({
  onSuccess,
}: UseDeleteSupplierMutationParams) => {
  const jsonApi = useJsonApi();
  const { t } = useTranslation('apiMessages/useDeleteSupplierMutation');
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [deleteSupplierMutation.name],
    mutationFn: deleteSupplierMutation(jsonApi),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.suppliers.getSuppliers],
      });
      onSuccess();
      notify.alert.success(t('Supplier deleted successfully'));
    },
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHORIZED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t("You don't have access to delete suppliers"),
        },
        404: {
          SUPPLIER_NOT_FOUND: t(
            'Supplier not found. Please refresh the page or contact the administrator'
          ),
        },
      },
    }),
  });
};
