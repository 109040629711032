import { ReactNode } from 'react';

import { TableHeader, TableHeaderCell, TableStyled } from './Table.styled';

type TableProps =
  | {
      children: ReactNode;
      headers: Array<string>;
      className?: string;
    }
  | {
      children: ReactNode;
      columnsCount: number;
      className?: string;
    };

export const Table = ({ children, className, ...props }: TableProps) => {
  return (
    <TableStyled
      className={className}
      columnsCount={
        'columnsCount' in props ? props.columnsCount : props.headers.length
      }
    >
      {'headers' in props && (
        <TableHeader>
          {props.headers.map((header, index) => (
            <TableHeaderCell key={index}>{header}</TableHeaderCell>
          ))}
        </TableHeader>
      )}
      {children}
    </TableStyled>
  );
};
