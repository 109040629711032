import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { notify } from '@/modules/notify/notify';
import { plannedNegotiationRoutes } from '@/modules/router/onboardedRoutes';
import { defaultSuppliersQueryParams } from '@/modules/suppliers/helpers/defaultSuppliersQueryParams';

import { PlannedNegotiationSuppliers } from './PlannedNegotiationSuppliers';

export const PlannedNegotiationSuppliersScene = () => {
  const { t } = useTranslation('pages/PlannedNegotiationPage');

  const { queryParams, queryParamsError } =
    plannedNegotiationRoutes.useGetQueryParams('suppliers');

  const { negotiationId } =
    plannedNegotiationRoutes.useGetRouteParams('suppliers');

  useEffect(() => {
    if (queryParamsError) {
      notify.alert.error(t('Invalid pagination state'));
    }
  }, [queryParamsError, t]);

  if (queryParamsError) {
    return (
      <Navigate
        to={plannedNegotiationRoutes.buildUrl('suppliers', {
          params: { negotiationId },
          query: defaultSuppliersQueryParams,
        })}
      />
    );
  }

  return (
    <PlannedNegotiationSuppliers
      queryParams={queryParams}
      negotiationId={negotiationId}
    />
  );
};
