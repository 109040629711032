import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AuthenticatedLayout } from '@/layouts/AuthenticatedLayout/AuthenticatedLayout';
import { useGetCustomerBusinessUnitsQuery } from '@/modules/customer/api/businessUnits/useGetCustomerBusinessUnitsQuery';
import { notify } from '@/modules/notify/notify';
import { onboardedRoutes } from '@/modules/router/onboardedRoutes';
import { useGetSupplierCategoriesQuery } from '@/modules/suppliers/api/hooks/useGetSupplierCategoriesQuery';
import { ContactPerson } from '@/modules/suppliers/components/ContactPersonFormDialog/contactPersonFormStruct';
import { useAddSupplierContactMutation } from '@/modules/suppliers/components/ContactPersonFormDialog/useAddSupplierContactMutation';
import { SupplierForm } from '@/modules/suppliers/components/SupplierForm/SupplierForm';
import { SupplierFormType } from '@/modules/suppliers/components/SupplierForm/supplierFormStruct';
import { mapCustomerBusinessUnitsToSelectOptions } from '@/modules/suppliers/helpers/mapCustomerBusinessUnitsToSelectOptions';
import { mapSupplierCategoriesToSelectOptions } from '@/modules/suppliers/helpers/supplierCategoriesHelpers';
import { useEditSupplierMutation } from '@/modules/suppliers/hooks/useEditSupplierMutation';
import { useSupplierFormContext } from '@/modules/suppliers/hooks/useSupplierFormContext';
import { serializeContactData } from '@/modules/suppliers/utils/serializeContactData';
import { PageHeader } from '@/modules/theme/components/PageHeader/PageHeader';
import { PageMeta } from '@/modules/theme/components/PageMeta';
import { noop } from '@/utils/noop';

import { useAddSupplierMutation } from './hooks/useAddSupplierMutation';
import { PageContentStyled, PageWrapperStyled } from './SupplierAddPage.styled';

export const SupplierAddPage = () => {
  const { t } = useTranslation('pages/SupplierAddPage');
  const navigate = useNavigate();

  const handleAddSupplierSuccess = ({ supplierId }: { supplierId: string }) => {
    notify.alert.success(t('Supplier added successfully!'));
    navigate(onboardedRoutes.buildUrl('supplier', { params: { supplierId } }));
  };

  const addSupplierMutation = useAddSupplierMutation();
  const editSupplierMutation = useEditSupplierMutation({
    supplierId: undefined,
  });
  const addSupplierContactMutation = useAddSupplierContactMutation();
  const { contacts } = useSupplierFormContext();
  const { data: categories, isPending: isCategoriesPending } =
    useGetSupplierCategoriesQuery();
  const { data: businessUnits, isPending: isBusinessUnitsPending } =
    useGetCustomerBusinessUnitsQuery();

  const mappedSupplierCategories = categories
    ? mapSupplierCategoriesToSelectOptions(categories)
    : null;
  const mappedBusinessUnits = businessUnits
    ? mapCustomerBusinessUnitsToSelectOptions(businessUnits)
    : null;

  const addNewContactPersons = async ({
    contacts,
    supplierId,
  }: {
    contacts: ContactPerson[];
    supplierId: string;
  }) => {
    const contactPromises = contacts.map((contact) => {
      const { id, ...contactData } = contact;
      return addSupplierContactMutation.mutateAsync({
        attributes: serializeContactData(contactData),
        supplierId,
      });
    });
    await Promise.all(contactPromises);
  };

  const handleOnSubmit = async ({
    name,
    organizationNumber,
    language,
    categoryId,
    businessUnitId,
    defaultContactId,
  }: SupplierFormType) => {
    try {
      const addedSupplier = await addSupplierMutation.mutateAsync({
        attributes: {
          name,
          organizationNumber: organizationNumber || null,
          language,
          currentSpend: '0.00',
        },
        categoryId,
        businessUnitId,
      });

      if (addedSupplier?.data.id && !contacts?.length) {
        handleAddSupplierSuccess({ supplierId: addedSupplier.data.id });
        return;
      }
      if (addedSupplier?.data.id && contacts?.length) {
        const selectedContactPersonData = contacts.find(
          (contact) => contact.id === defaultContactId
        );

        if (selectedContactPersonData) {
          const filteredContacts = contacts.filter(
            (contact) => contact.id !== selectedContactPersonData.id
          );

          await addNewContactPersons({
            contacts: filteredContacts,
            supplierId: addedSupplier.data.id,
          });

          const { id, ...contactData } = selectedContactPersonData;

          const selectedContactPerson =
            await addSupplierContactMutation.mutateAsync({
              attributes: serializeContactData(contactData),
              supplierId: addedSupplier.data.id,
            });

          if (selectedContactPerson) {
            const editedSupplier = await editSupplierMutation.mutateAsync({
              ...addedSupplier.data,
              categoryId:
                addedSupplier.data.relationships.category.data?.id || null,
              businessUnitId,
              defaultContactId: selectedContactPerson.data.id,
            });
            if (editedSupplier) {
              handleAddSupplierSuccess({ supplierId: editedSupplier.data.id });
            }
          }
        } else {
          await addNewContactPersons({
            contacts,
            supplierId: addedSupplier.data.id,
          });
          handleAddSupplierSuccess({ supplierId: addedSupplier.data.id });
        }
      }
    } catch (err) {
      noop();
    }
  };

  return (
    <AuthenticatedLayout>
      <PageMeta title={t('Add supplier')} description={t('Add supplier')} />
      <PageWrapperStyled>
        <PageHeader text={t('Add supplier')} />
        <PageContentStyled>
          <SupplierForm
            mode="add"
            onSubmit={handleOnSubmit}
            contactPersonsData={contacts}
            categoriesData={mappedSupplierCategories}
            businessUnitsData={mappedBusinessUnits}
            isSubmitting={
              addSupplierMutation.isPending ||
              editSupplierMutation.isPending ||
              addSupplierContactMutation.isPending
            }
            isLoading={{
              isSupplierLoading: false,
              isCategoriesLoading: isCategoriesPending,
              isBusinessUnitsLoading: isBusinessUnitsPending,
            }}
          />
        </PageContentStyled>
      </PageWrapperStyled>
    </AuthenticatedLayout>
  );
};
