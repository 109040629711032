import styled from 'styled-components';

import { Box } from './Box';

type FlexContainerProps = {
  direction?: 'row' | 'column';
  gap?: number;
  justify?:
    | 'flex-start'
    | 'center'
    | 'flex-end'
    | 'space-between'
    | 'space-around';
  align?: 'start' | 'center' | 'end';
  wrap?: 'nowrap' | 'wrap-reverse' | 'wrap';
  columnGap?: number;
};

export const FlexContainer = styled(Box)<FlexContainerProps>(
  ({
    theme: { spacing },
    direction = 'row',
    gap = 1,
    columnGap,
    justify,
    align,
    wrap,
  }) => ({
    display: 'flex',
    flexDirection: direction,
    position: 'relative',
    gap: spacing(gap),
    ...(columnGap && { columnGap: spacing(columnGap) }),
    justifyContent: justify,
    alignItems: align,
    flexWrap: wrap,
  })
);
