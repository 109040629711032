import { ReactNode } from 'react';
import styled from 'styled-components';

import { Heading, Paragraph } from '@/modules/theme/components/Typography';
import { blue, graphite, orange } from '@/modules/theme/utils/colors';
import { formatAmount } from '@/utils/stringUtils';

type AnalysisMetricProps = {
  heading: string;
  data: string | number;
  variant?: 'suggested' | 'total';
  children: ReactNode;
};
export const AnalysisMetric = ({
  heading,
  data,
  variant = 'suggested',
  children,
}: AnalysisMetricProps) => {
  const isSuggested = variant === 'suggested';
  return (
    <MetricContainerStyled>
      <Paragraph
        variant="contentBold"
        as="h4"
        color={isSuggested ? blue[800] : graphite}
      >
        {heading}
      </Paragraph>
      <Heading
        margins={[1, 0]}
        variant="h2"
        as="span"
        color={isSuggested ? orange[800] : graphite}
      >
        {formatAmount(data)}
      </Heading>
      {children}
    </MetricContainerStyled>
  );
};

const MetricContainerStyled = styled('div')(({ theme: { spacing } }) => ({
  paddingBottom: spacing(2),
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
}));
