export const termImportance = {
  notImportant: 'notImportant',
  interesting: 'interesting',
  important: 'important',
} as const;

export const selectCategoriesImportance = {
  interesting: 'interesting',
  important: 'important',
  veryImportant: 'veryImportant',
} as const;

export const categoriesImportance = {
  ...selectCategoriesImportance,
  notImportant: 'notImportant',
} as const;

export const categories = [
  'Sales and marketing',
  'Logistics',
  'Professional services',
  'IT and telecom',
  'Facility management services',
  'Maintenance, repair and operations (MRO)',
  'Travels and conferences',
  'Vehicles',
  'Equipment and machinery',
  'Raw material',
  'Manufacturing and components',
  'Groceries, food and drinks',
  'Other',
] as const;
