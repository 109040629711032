import { useState } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

import { dark } from '@/modules/theme/utils/colors';

import { ParsedNegotiation } from '../../helpers/parseGetNegotiationResponse';
import { NegotiationExcludedSuppliersDialog } from '../NegotiationSuppliersDialogs/NegotiationExcludedSuppliersDialog';
import { NegotiationInvitedSuppliersDialog } from '../NegotiationSuppliersDialogs/NegotiationInvitedSuppliersDialog';

type NegotiationDataPanelMetrics = Pick<
  ParsedNegotiation['metrics'],
  'suppliersCount' | 'excludedCount' | 'averageDiscount' | 'currentSpend'
>;

type NegotiationInvitedExcludedSuppliersProps = {
  metrics: NegotiationDataPanelMetrics;
  negotiationId: string;
};

const translationNamespace = 'modules/negotiations/NegotiationDataPanel';

export const NegotiationInvitedExcludedSuppliers = ({
  metrics,
  negotiationId,
}: NegotiationInvitedExcludedSuppliersProps) => {
  const [isInvitedDialogOpen, setIsInvitedDialogOpen] = useState(false);
  const [isExcludedDialogOpen, setIsExcludedDialogOpen] = useState(false);

  const invitedSuppliersCount = metrics.suppliersCount - metrics.excludedCount;

  return (
    <>
      <SuppliersButtonWrapperStyled>
        <Trans
          ns={translationNamespace}
          values={{
            invitedSuppliersCount,
            excludedSuppliersCount: metrics.excludedCount,
          }}
          i18nKey={
            '<InvitedSuppliersButton>{{invitedSuppliersCount}} invited</InvitedSuppliersButton> / <ExcludedSuppliersButton>{{excludedSuppliersCount}} excluded</ExcludedSuppliersButton>'
          }
          components={{
            InvitedSuppliersButton: (
              <SuppliersButtonStyled
                onClick={() => setIsInvitedDialogOpen(true)}
              />
            ),
            ExcludedSuppliersButton: (
              <SuppliersButtonStyled
                onClick={() => setIsExcludedDialogOpen(true)}
              />
            ),
          }}
        />
      </SuppliersButtonWrapperStyled>
      <NegotiationInvitedSuppliersDialog
        negotiationId={negotiationId}
        isOpen={isInvitedDialogOpen}
        onClose={() => setIsInvitedDialogOpen(false)}
      />
      <NegotiationExcludedSuppliersDialog
        negotiationId={negotiationId}
        isOpen={isExcludedDialogOpen}
        onClose={() => setIsExcludedDialogOpen(false)}
      />
    </>
  );
};

const SuppliersButtonWrapperStyled = styled('span')(
  ({ theme: { spacing } }) => ({
    display: 'inline-block',
    margin: spacing([0.25, 0, 0, 3]),
    color: dark[400],
  })
);

const SuppliersButtonStyled = styled('button')(({ theme: { typography } }) => ({
  outline: 'none',
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  fontFamily: typography.fonts.body,
  fontSize: typography.fontSize.small,
  padding: 0,
  color: 'inherit',
}));
