export const POTENTIAL_COST_AVOIDANCE_PERCENTAGE = 2.5;
export const POTENTIAL_SAVINGS_PERCENTAGE = 2;

export const FRACTIONS_PER_CURRENCY = 100;

export const digiProcInfoEmail = 'info@digiproc.com';

export enum LANG_CODE {
  EN_US = 'en-US',
  SV_SE = 'sv-SE',
}
