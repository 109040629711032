import { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { NegotiationLayout } from '@/layouts/NegotiationLayout/NegotiationLayout';
import { ProgressOfNegotiationSkeleton } from '@/modules/negotiation/components/ProgressOfNegotiation/components/ProgressOfNegotiationSkeleton';
import {
  NegotiationPageQueryParams,
  StartedNegotiationSuppliersList,
} from '@/modules/negotiation/components/StartedNegotiationSuppliersList/StartedNegotiationSuppliersList';
import { useGetNegotiationQuery } from '@/modules/negotiation/hooks/useGetNegotiationQuery';
import { basicErrorResponseValidator } from '@/modules/reactQuery/basicApiErrorValidator';
import { getApiErrorStatusCode } from '@/modules/reactQuery/validateApiError';
import { onboardedRoutes } from '@/modules/router/onboardedRoutes';
import { Box } from '@/modules/theme/components/Box';
import { LinkType } from '@/modules/theme/components/ButtonLink';
import {
  HeaderButton,
  HeaderButtonLink,
} from '@/modules/theme/components/HeaderButton';
import { PageMeta } from '@/modules/theme/components/PageMeta';
import { useDetectEnglishBrowser } from '@/utils/useDetectEnglishBrowser';

import { AuthenticatedNotFoundPage } from '../AuthenticatedNotFoundPage';

import { ButtonLinksWrapperStyled } from './ActiveNegotiationPage.styled';
import { ActiveNegotiationDataPanel } from './components/ActiveNegotiationDataPanel';

const ProgressOfNegotiation = lazy(
  () =>
    import(
      '@/modules/negotiation/components/ProgressOfNegotiation/ProgressOfNegotiation'
    )
);

type ActiveNegotiationPageProps = {
  queryParams: NegotiationPageQueryParams;
  negotiationId: string;
};

export const ActiveNegotiationPage = ({
  queryParams,
  negotiationId,
}: ActiveNegotiationPageProps) => {
  const { t } = useTranslation('pages/ActiveNegotiationPage');
  const navigate = useNavigate();
  const isEnBrowser = useDetectEnglishBrowser();

  const {
    data: negotiation,
    isLoading: isLoadingNegotiation,
    error,
  } = useGetNegotiationQuery({
    negotiationId,
  });

  const parsedPreviewUrl = isEnBrowser
    ? negotiation?.supplierPreviewUrl
    : negotiation?.supplierPreviewUrl?.replace('/en/', '/');

  const errorCode = getApiErrorStatusCode(error, basicErrorResponseValidator);
  if (errorCode === 404) {
    return <AuthenticatedNotFoundPage />;
  }

  return (
    <NegotiationLayout
      negotiationName={negotiation?.title}
      negotiationState={negotiation?.state}
      isLoading={isLoadingNegotiation}
      buttonLink={
        <ButtonLinksWrapperStyled>
          {/* Removed for now (we don't want people to download the contactinfo without being a paying customer) */}
          {/* <SupplierExportButton negotiationId={negotiationId} /> */}
          {parsedPreviewUrl && (
            <HeaderButtonLink
              to={parsedPreviewUrl}
              linkType={LinkType.External}
            >
              {t('Suppliers view')}
            </HeaderButtonLink>
          )}

          <HeaderButtonLink
            to={onboardedRoutes.buildUrl('cpoLetter', {
              params: { negotiationId },
            })}
          >
            {t('Invitation letter')}
          </HeaderButtonLink>

          <HeaderButton onClick={() => navigate(-1)}>{t('Back')}</HeaderButton>
        </ButtonLinksWrapperStyled>
      }
    >
      <PageMeta
        title={t('Active negotiation')}
        description={t('Active negotiation')}
      />
      <ActiveNegotiationDataPanel
        negotiation={negotiation}
        shouldDisplayLoader={isLoadingNegotiation}
      />
      <Box paddings={[0, 8, 7]}>
        <Suspense
          fallback={
            <ProgressOfNegotiationSkeleton
              title={t('Progress of the negotiation so far')}
            />
          }
        >
          <ProgressOfNegotiation negotiationId={negotiationId} />
        </Suspense>
        <StartedNegotiationSuppliersList
          negotiationId={negotiationId}
          queryParams={queryParams}
          isNegotiationActive
        />
      </Box>
    </NegotiationLayout>
  );
};
