import styled from 'styled-components';

import { AccessibleSvg } from '@/modules/theme/components/AccessibleSvg';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';

export const AnonymousLayoutWrapperStyled = styled('main')({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

export const FlexContainerStyled = styled(FlexContainer)({
  flex: 1,
});

export const HeaderStyled = styled('header')(
  ({ theme: { breakpoints, spacing } }) => ({
    width: '100%',
    padding: spacing(1),
    [breakpoints.up.sm]: {
      padding: spacing([2, 3]),
    },
  })
);

export const LogoStyled = styled(AccessibleSvg)(
  ({ theme: { spacing, breakpoints } }) => ({
    height: spacing(3),
    [breakpoints.up.sm]: {
      height: spacing(4.5),
    },
  })
);
