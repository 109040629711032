import { JsonApiClient } from '@/modules/api/utils/createJsonApiClient';

import {
  changePasswordResourcePath,
  changePasswordResourceType,
} from '../resources/changePassword/changePasswordConsts';

type ChangePasswordMutationPayload = {
  currentPassword: string;
  newPassword: string;
};

export function changePasswordMutation(jsonApi: JsonApiClient) {
  return (attributes: ChangePasswordMutationPayload) =>
    jsonApi.put({
      type: changePasswordResourceType,
      path: changePasswordResourcePath,
      attributes,
    });
}
