import { NegotiationTermTypes } from '@/modules/negotiation/types/negotiationTypes';

export const acceptableTermTypes = [
  NegotiationTermTypes.answerQuestion,
  NegotiationTermTypes.answerSustainabilityQuestion,
  NegotiationTermTypes.extendContractPeriod,
  NegotiationTermTypes.extendInvoiceDueDate,
  NegotiationTermTypes.lockPrice,
  NegotiationTermTypes.signStandardAgreement,
  NegotiationTermTypes.beIsoCertified,
];
