import { useTranslation } from 'react-i18next';

import { ReactComponent as CodeOfConductIcon } from '@/assets/icons/termIcons/codeOfConductIcon.svg';
import { ReactComponent as ExtraPaymentDaysIcon } from '@/assets/icons/termIcons/extraPaymentDaysIcon.svg';
import { ReactComponent as IsoIcon } from '@/assets/icons/termIcons/isoIcon.svg';
import { ReactComponent as StandardAgreementIcon } from '@/assets/icons/termIcons/standardAgreementIcon.svg';
import { ReactComponent as SustainabilityQuestionnaireIcon } from '@/assets/icons/termIcons/sustainabilityQuestionnaireIcon.svg';
import { CustomerMetrics } from '@/modules/customer/api/resources/metrics/customerMetricsResourceStruct';

import { AnalysisTermCounter } from './AnalysisTermCounter/AnalysisTermCounter';
import { AnalysisTermCountersWrapperStyled } from './AnalysisTermCounters.styled';

type AnalysisTermCountersProps = {
  terms: CustomerMetrics['terms'];
};
export const AnalysisTermCounters = ({ terms }: AnalysisTermCountersProps) => {
  const { t } = useTranslation('pages/SuggestedNegotiationsPage');

  const {
    signCodeOfConduct,
    signStandardAgreement,
    answerSustainabilityQuestion,
    extendInvoiceDueDate,
    beIsoCertified,
  } = terms;

  return (
    <AnalysisTermCountersWrapperStyled>
      <AnalysisTermCounter
        Icon={CodeOfConductIcon}
        name={t('Code of Conduct')}
        count={signCodeOfConduct.potentialCount}
      />
      <AnalysisTermCounter
        Icon={StandardAgreementIcon}
        name={t('Standard agreement')}
        count={signStandardAgreement.potentialCount}
      />
      <AnalysisTermCounter
        Icon={SustainabilityQuestionnaireIcon}
        name={t('Supplier questionnaire')}
        count={answerSustainabilityQuestion.potentialCount}
      />
      <AnalysisTermCounter
        Icon={ExtraPaymentDaysIcon}
        name={t('Extra payment days')}
        count={extendInvoiceDueDate.potentialCount}
      />
      <AnalysisTermCounter
        Icon={IsoIcon}
        name={t('ISO')}
        count={beIsoCertified.potentialCount}
      />
    </AnalysisTermCountersWrapperStyled>
  );
};
