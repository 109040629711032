import styled from 'styled-components';

interface DynamicTabsCoverProps {
  backgroundUrl: string;
}

export const DynamicTabsCover = styled.div<DynamicTabsCoverProps>`
  background-image: url(${(props) => props.backgroundUrl});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 324px;
  width: 100%;
  padding: 60px;
`;

export const DynamicTabsTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  gap: 16px;
`;
