import styled from 'styled-components';

import { Checkbox } from '@/modules/form/components/Checkbox/Checkbox';
import { Text } from '@/modules/theme/components/Typography';
import { dark } from '@/modules/theme/utils/colors';

export const LabelStyled = styled('label')(
  ({ theme: { palette, typography } }) => ({
    color: palette.default.text,
    fontWeight: typography.fontWeight.bold,
    width: '100%',
    maxWidth: '350px',
  })
);

export const CheckboxStyled = styled(Checkbox)({
  '&:last-child': {
    gridColumn: 2,
  },
});

export const LabelTextStyled = styled(Text)(
  ({ theme: { typography, spacing } }) => ({
    display: 'inline-block',
    marginLeft: spacing(1),
    fontWeight: typography.fontWeight.bold,
  })
);

export const CategoriesSectionStyled = styled('div')(
  ({ theme: { spacing } }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: spacing(8),
    rowGap: spacing(0.5),
  })
);

export const CategoriesImportanceSectionWrapper = styled('div')(
  ({ theme: { spacing } }) => ({
    margin: spacing([5, 0, 7]),
    borderTop: `1px solid ${dark[100]}`,
    paddingTop: spacing(2),
  })
);

export const DropdownWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TextInputContainer = styled.div`
  max-width: 200px;
`;
