import styled from 'styled-components';

import { Heading, Paragraph } from '@/modules/theme/components/Typography';
import { blue, dark } from '@/modules/theme/utils/colors';

import { ReactComponent as SignetLogo } from '../icons/signetLogo.svg';

export const NegotiationDataPanelWrapperStyled = styled('div')(
  ({ theme: { spacing, borderRadius, breakpoints } }) => ({
    backgroundColor: blue[200],
    borderRadius: borderRadius.default,
    padding: spacing([3, 6]),
    margin: spacing([7, 3, 3]),

    [breakpoints.down.md]: {
      padding: spacing([2, 1]),
    },
  })
);

export const PanelContainerStyled = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  minHeight: 249,
});

export const DataContainerStyled = styled('div')({
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
  flexWrap: 'wrap',
});

export const BottomDataWrapperStyled = styled('div')(
  ({ theme: { spacing } }) => ({
    marginTop: spacing(1),
    borderTop: `1px solid ${dark[400]}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  })
);

export const SpendStyled = styled(Paragraph)(
  ({ theme: { spacing, typography } }) => ({
    paddingTop: spacing(1),
    fontWeight: typography.fontWeight.bold,
    color: dark[400],
  })
);

export const SuppliersDataWrapperStyled = styled('div')(
  ({ theme: { spacing } }) => ({
    display: 'flex',
    marginTop: spacing(1),
  })
);

export const NumberOfSuppliersStyled = styled(Heading)({
  color: dark[400],
  lineHeight: 1,
});

export const SignetStyled = styled(SignetLogo)({
  opacity: 0.5,
});
