import { toNestErrors, validateFieldsNatively } from '@hookform/resolvers';
import { FieldError } from 'react-hook-form';
import { FieldValues, ResolverOptions, ResolverResult } from 'react-hook-form';
import { Struct, StructError, validate } from 'superstruct';

type Options = Parameters<typeof validate>[2];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Resolver = <T extends Struct<any, any>>(
  schema: T,
  factoryOtions?: Options
) => <TFieldValues extends FieldValues, TContext>(
  values: TFieldValues,
  context: TContext | undefined,
  options: ResolverOptions<TFieldValues>
) => ResolverResult<TFieldValues>;

function parseStructErrors(error: StructError) {
  return error.failures().reduce<Record<string, FieldError>>(
    (acc, { message, type, refinement, path }) => ({
      ...acc,
      [path.join('.')]: {
        message,
        type: refinement ? `${type}.${refinement}` : type,
      },
    }),
    {}
  );
}

export const superstructResolver: Resolver =
  (schema, resolverOptions) => (values, _, options) => {
    const result = validate(values, schema, resolverOptions);

    if (result[0]) {
      return {
        values: {},
        errors: toNestErrors(parseStructErrors(result[0]), options),
      };
    }

    options.shouldUseNativeValidation && validateFieldsNatively({}, options);

    return {
      values: result[1],
      errors: {},
    };
  };
