import * as sup from 'superstruct';

import { createResourceStruct } from '@/modules/api/utils/jsonApiStructs';

import { customerIdentifierStruct } from '../customer/customerIdentifierStruct';

import { userIdentifierStruct } from './userIdentifierStruct';

const userRoleStruct = sup.enums([
  'customer',
  'administrator',
  'supplier',
  'demo',
]);

export const userResourceStruct = createResourceStruct({
  identifier: userIdentifierStruct,
  attributes: sup.object({
    email: sup.string(),
    firstName: sup.string(),
    lastName: sup.string(),
    roles: sup.nonempty(sup.array(userRoleStruct)),
  }),
  relationships: sup.object({
    customer: sup.object({
      data: sup.nullable(customerIdentifierStruct),
    }),
  }),
});

export type UserRoles = sup.Infer<typeof userRoleStruct>;
