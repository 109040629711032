import { useTranslation } from 'react-i18next';

import { AccessibleSvg } from '../AccessibleSvg';

import { ReactComponent as NotFoundImg } from './notFound.svg';
import {
  HeadingStyled,
  NotFoundSectionContentStyled,
  NotFoundSectionStyled,
  ParagraphStyled,
} from './NotFoundSection.styled';

export const NotFoundSection = () => {
  const { t } = useTranslation('modules/theme/NotFound');

  return (
    <NotFoundSectionStyled>
      <NotFoundSectionContentStyled>
        <AccessibleSvg
          SvgComponent={NotFoundImg}
          title={t('404')}
          description={t('Page not found')}
        />
        <HeadingStyled variant="h3" as="h1">
          {t('Page not found')}
        </HeadingStyled>
        <ParagraphStyled>
          {t(
            'The page you are looking for was moved, removed, renamed or might never existed.'
          )}
        </ParagraphStyled>
      </NotFoundSectionContentStyled>
    </NotFoundSectionStyled>
  );
};
