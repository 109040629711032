import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { AnonymousLayout } from '@/layouts/AnonymousLayout/AnonymousLayout';
import { CustomerIdNotFoundPage } from '@/pages/CustomerIdNotFoundPage';
import { LeadCustomerSurveyPage } from '@/pages/LeadCustomerSurveyPage/LeadCustomerSurveyPage';
import { SignedOutPage } from '@/pages/LoggedOutPage/SignedOutPage';
import { ResetPasswordScene } from '@/pages/ResetPasswordPage/ResetPasswordScene';
import { SignInPage } from '@/pages/SignInPage/SignInPage';
import { SignUpScene } from '@/pages/SignUpPage/SignUpScene';
import { SignUpSuccessPage } from '@/pages/SignUpSuccessPage/SignUpSuccessPage';

import { anonymousRoutes } from '../anonymousRoutes';

import { anonymousFallthroughRoutes } from './utils/fallthroughRoutes';

export const AnonymousRoutes = () => {
  const location = useLocation();

  return (
    <Routes>
      <Route element={<AnonymousLayout />}>
        <Route
          path={anonymousRoutes.config.signIn.path}
          element={<SignInPage />}
        />
        <Route
          path={anonymousRoutes.config.signUp.path}
          element={<SignUpScene />}
        />
        <Route
          path={anonymousRoutes.config.signUpSuccess.path}
          element={<SignUpSuccessPage />}
        />
        <Route
          path={anonymousRoutes.config.resetPassword.path}
          element={<ResetPasswordScene />}
        />
        <Route
          path={anonymousRoutes.config.customerIdNotFound.path}
          element={<CustomerIdNotFoundPage />}
        />
        <Route
          path={anonymousRoutes.config.signedOut.path}
          element={<SignedOutPage />}
        />
        <Route
          path={anonymousRoutes.config.leadCustomerSurvey.path}
          element={<LeadCustomerSurveyPage />}
        />
        <Route
          path="*"
          element={
            <Navigate
              to={anonymousRoutes.config.signIn.path}
              replace
              state={{ redirectTo: location }}
            />
          }
        />
      </Route>
      {anonymousFallthroughRoutes(anonymousRoutes.config.signIn.path)}
    </Routes>
  );
};
