import * as sup from 'superstruct';

import { ComposableRefinement } from './createComposableRefinement';

/**
 * It allows for using superstruct utilities similarly like yup/joi validation
 * utils works.
 *
 * So now, instead of doing this:
 * ```ts
 * sup.pattern(sup.size(sup.string(), 1, 6), someRegExp)
 * ```
 *
 * You can do this:
 * ```ts
 * recompose(sup.string(), sup.size(1, 6), sup.pattern(someRegExp))
 * ```
 *
 * Be aware that superstruct built-in refinements such as pattern or size, don't
 * work with the `recompose`. You can find their composable equivalents in:
 * `utils/superstruct/structs`
 */
export function recompose<T, S>(
  base: sup.Struct<T, S>,
  ...createComposableRefinements: readonly ComposableRefinement<T, S>[]
) {
  return createComposableRefinements.reduce(
    (struct, refine) => refine(struct),
    base
  );
}
