import { ReactNode } from 'react';
import styled from 'styled-components';

import { Box } from '@/modules/theme/components/Box';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { MaxWidth } from '@/modules/theme/components/MaxWidth';
import { Heading } from '@/modules/theme/components/Typography';
import { white } from '@/modules/theme/utils/colors';

type SurveyFormSectionProps = {
  number: number;
  title: string;
  children: ReactNode;
};

export const SurveyFormSection = ({
  number,
  title,
  children,
}: SurveyFormSectionProps) => {
  return (
    <MaxWidthStyled maxWidth={815}>
      <FlexContainer mb={4} gap={2}>
        <OrderCircleStyled>
          <Heading variant="h3" color={white}>
            {number}
          </Heading>
        </OrderCircleStyled>
        <Heading variant="h3">{title}</Heading>
      </FlexContainer>
      <Box ml={6}>{children}</Box>
    </MaxWidthStyled>
  );
};

const MaxWidthStyled = styled(MaxWidth)(({ theme: { spacing } }) => ({
  marginTop: spacing(7),
  marginBottom: spacing(7),
}));

const OrderCircleStyled = styled('div')(
  ({ theme: { palette, spacing, borderRadius } }) => ({
    backgroundColor: palette.default.text,
    width: 32,
    height: 32,
    padding: spacing([0, 1]),
    borderRadius: borderRadius.round,
  })
);
