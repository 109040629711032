/* eslint-disable no-template-curly-in-string */
import styled from 'styled-components';

import { dark, white } from '@/modules/theme/utils/colors';

type TooltipProps = {
  visible: boolean;
};

export const TooltipStyled = styled('div')<TooltipProps>(
  ({
    theme: { boxShadows, spacing, zIndexes, palette, typography, borderRadius },
    visible,
  }) => ({
    visibility: visible ? 'visible' : 'hidden',
    maxWidth: 330,
    minWidth: 330,
    lineHeight: typography.pxToRem(21),
    whiteSpace: 'pre-line',
    fontSize: typography.pxToRem(14),
    backgroundColor: white,
    boxShadow: boxShadows.tooltip,
    color: palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(2),
    transition: 'opacity 0.3s',
    zIndex: zIndexes.tooltip,
    borderRadius: borderRadius.default,

    "&[data-popper-interactive='false']": {
      pointerEvents: 'none',
    },
  })
);

export const TooltipArrowStyled = styled('div')(({ theme: { spacing } }) => ({
  height: spacing(2.5),
  position: 'absolute',
  width: spacing(2.5),
  pointerEvents: 'none',

  '&::before': {
    borderStyle: 'solid',
    content: "''",
    display: 'block',
    height: 0,
    margin: 'auto',
    width: '0',
  },

  '&::after': {
    borderStyle: 'solid',
    content: "''",
    display: 'block',
    height: 0,
    margin: 'auto',
    position: 'absolute',
    width: 0,
  },

  "${tooltip}[data-popper-placement*='bottom'] &": {
    left: 0,
    marginTop: spacing(-1),
    top: 0,
  },

  "${tooltip}[data-popper-placement*='bottom'] &::before": {
    borderColor: `transparent transparent ${dark[200]} transparent`,
    borderWidth: spacing([0, 1.25, 1, 1.25]),
    position: 'absolute',
    top: 0,
  },

  "${tooltip}[data-popper-placement*='bottom'] &::after": {
    borderColor: `transparent transparent ${white} transparent`,
    borderWidth: spacing([0, 1.25, 1, 1.25]),
    top: '1px',
  },

  "${tooltip}[data-popper-placement*='top'] &": {
    bottom: 0,
    left: 0,
    marginBottom: spacing(-2.5),
  },

  "${tooltip}[data-popper-placement*='top'] &::before": {
    borderColor: `${dark[200]}  transparent transparent transparent`,
    borderWidth: spacing([1, 1.25, 0, 1.25]),
    position: 'absolute',
    top: 0,
  },

  "${tooltip}[data-popper-placement*='top'] &::after": {
    borderColor: `${white} transparent transparent transparent`,
    borderWidth: spacing([1, 1.25, 0, 1.25]),
    top: '-1px',
  },

  "${tooltip}[data-popper-placement*='right'] &": {
    left: 0,
    marginLeft: spacing(-1.75),
  },

  "${tooltip}[data-popper-placement*='right'] &::before": {
    borderColor: `transparent ${dark[200]} transparent transparent`,
    borderWidth: spacing([1.25, 1, 1.25, 0]),
  },

  "${tooltip}[data-popper-placement*='right'] &::after": {
    borderColor: `transparent ${white} transparent transparent`,
    borderWidth: spacing([1.25, 1, 1.25, 0]),
    left: spacing(1),
    top: 0,
  },

  "${tooltip}[data-popper-placement*='left'] &": {
    marginRight: spacing(-1.75),
    right: 0,
  },

  "${tooltip}[data-popper-placement*='left'] &::before": {
    borderColor: `transparent transparent transparent ${dark[200]}`,
    borderWidth: spacing([1.25, 0, 1.25, 1]),
  },

  "${tooltip}[data-popper-placement*='left'] &::after": {
    borderColor: `transparent transparent transparent ${white}`,
    borderWidth: spacing([1.25, 0, 1.25, 1]),
    left: spacing(0.5),
    top: 0,
  },
}));
