import { GroupBase, StylesConfig } from 'react-select';

import { black, dark } from '@/modules/theme/utils/colors';
import { Theme } from '@/modules/theme/utils/createTheme';

type SelectInputProps = {
  theme: Theme;
  state: {
    error: boolean;
  };
};

type ReactStylesConstructor = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: SelectInputProps
) => StylesConfig<Option, IsMulti, Group>;

export const createSelectInputStyles: ReactStylesConstructor = ({
  theme: {
    borders,
    palette,
    typography,
    borderRadius,
    spacing,
    zIndexes,
    boxShadows,
  },
  state: { error },
}) => {
  return {
    menu: (provided) => ({
      ...provided,
      marginTop: spacing(0.5),
      backgroundColor: dark[50],
      zIndex: zIndexes.dropdownContent,
      boxShadow: boxShadows.dropdown,
    }),
    valueContainer: (provided) => ({
      ...provided,
      lineHeight: 'normal',
      color: error ? palette.error.main : black,
    }),
    control: (provided, { isFocused }) => ({
      ...provided,
      minHeight: 42,
      flexWrap: 'nowrap',
      color: black,
      fontFamily: typography.fonts.body,
      fontWeight: typography.fontWeight.normal,
      fontSize: typography.fontSize.html,
      cursor: 'pointer',
      border: isFocused
        ? error
          ? borders.input.error
          : borders.input.main
        : '1px solid transparent',
      boxShadow: 'none',
      borderRadius: borderRadius.default,
      backgroundColor: error ? palette.error.light : dark[50],
      '&:hover': {
        border: error ? borders.input.error : borders.input.main,
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: error ? palette.error.main : black,
    }),
    placeholder: (provided) => ({
      ...provided,
      fontWeight: typography.fontWeight.normal,
      color: error ? palette.error.main : dark[400],
    }),
    option: (provided, { isSelected, isFocused }) => ({
      ...provided,
      position: 'relative',
      padding: spacing([1.5, 2]),
      color: palette.primary.text,
      fontWeight: typography.fontWeight.normal,
      cursor: 'pointer',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      '&:hover, &:active, &:focus': {
        backgroundColor: dark[70],
      },
      backgroundColor: isFocused ? dark[70] : isSelected ? dark[50] : dark[50],
      '& strong': {
        fontWeight: typography.fontWeight.bold,
      },
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      boxShadow: 'none',
      borderRadius: borderRadius.default,
    }),
    dropdownIndicator: (provided, { selectProps: { menuIsOpen } }) => ({
      ...provided,
      padding: spacing(0.625),
      marginRight: spacing(0.625),
      color: palette.default.text,
      ...(menuIsOpen && { transform: 'rotate(180deg)' }),
      '&:hover': {
        color: 'inherit',
      },
    }),
  };
};
