import { NegotiationState } from '@/modules/negotiation/types/negotiationTypes';
import { Box } from '@/modules/theme/components/Box';
import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';

import { SuggestedNegotiations } from './components/SuggestedNegotiations/SuggestedNegotiations';
import { useGetNegotiationsQuery } from './hooks/useGetNegotiationsQuery';
import { MetricsWrapperStyled } from './SuggestedNegotiations.styled';

export const SuggestedNegotiationsPage = () => {
  const { isError, isPending } = useGetNegotiationsQuery({
    pageNumber: 1,
    pageSize: 100,
    states: [NegotiationState.SUGGESTED, NegotiationState.ESTIMATED],
  });

  if (isPending) {
    return (
      <Box as="main" pl={4} pr={4} mb={2} mt={16}>
        <MetricsWrapperStyled>
          <SkeletonLoader height={423} />
          <SkeletonLoader height={423} />
        </MetricsWrapperStyled>
      </Box>
    );
  }

  return <SuggestedNegotiations isLoading={isPending} isError={isError} />;
};
