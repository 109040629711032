import { MutationMeta, QueryMeta } from '@tanstack/react-query';
import * as sup from 'superstruct';

// unfortunately `sup.function` is inferred as Function and this is banned by our lint config
const arrowFunctionStruct = sup.define<() => void>(
  'ArrowFunctionStruct',
  (value) => typeof value === 'function'
);

export const metaStruct = sup.object({
  errorMessages: sup.record(
    sup.pattern(sup.string(), /\d+/),
    sup.record(sup.string(), sup.union([sup.string(), arrowFunctionStruct]))
  ),
});

export type MetaType = sup.Infer<typeof metaStruct>;

export const constructMeta = (meta: MetaType) => {
  return meta;
};

export const validateMeta = (
  unknownMeta: QueryMeta | MutationMeta | undefined
) => {
  const [metaValidationError, meta] = metaStruct.validate(unknownMeta);

  if (metaValidationError) {
    throw metaValidationError;
  }

  return meta;
};
