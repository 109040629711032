import { Navigate, Route } from 'react-router-dom';

import { anonymousRoutes } from '../../anonymousRoutes';

const basePath = '/';

export const anonymousFallthroughRoutes = (mainRoute: string) => {
  return (
    <Route path={basePath} element={<Navigate to={mainRoute} replace />} />
  );
};

export const authenticatedFallthroughRoutes = (mainRoute: string) => {
  return (
    <>
      <Route
        path={anonymousRoutes.buildUrl('signIn')}
        element={<Navigate to={mainRoute} replace />}
      />
      <Route path={basePath} element={<Navigate to={mainRoute} replace />} />
      {/* The wildcard below handles the cases where someone tries to jump */}
      {/* between the routers in the unexpected (non-linear) order. */}
      <Route path="*" element={<Navigate to={mainRoute} replace />} />
    </>
  );
};
