import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';

import { MetricsCardWrapper, MetricsContainer } from '../common.styled';

type AnalysisNegotiationMetricSkeletonProps = {
  highlight?: boolean;
};

export const AnalysisNegotiationMetricSkeleton = ({
  highlight,
}: AnalysisNegotiationMetricSkeletonProps) => {
  return (
    <MetricsCardWrapper highlight={highlight}>
      <MetricsContainer>
        <FlexContainer direction="column">
          <SkeletonLoader width={100} />
          <SkeletonLoader width={250} height={48} />
          <SkeletonLoader width={150} />
        </FlexContainer>
        <FlexContainer direction="column" mt={7}>
          <SkeletonLoader width={250} />
          <SkeletonLoader width={250} />
        </FlexContainer>
      </MetricsContainer>
      <FlexContainer mt={2} gap={1} direction="column">
        <SkeletonLoader width={200} height={20} />
        <SkeletonLoader width={200} height={20} />
        <SkeletonLoader width={200} height={20} />
      </FlexContainer>
    </MetricsCardWrapper>
  );
};
