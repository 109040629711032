import ReactPaginate from 'react-paginate';
import styled from 'styled-components';

import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { dark } from '@/modules/theme/utils/colors';

import { Arrow } from './Arrow';

interface PaginationProps {
  totalCount: number;
  perPage: number;
  pageRangeDisplayed?: number;
  onPageChange: (selectedPage: number) => void;
  forcePage: number;
}

export const Pagination = ({
  totalCount,
  perPage = 20,
  pageRangeDisplayed = 3,
  onPageChange,
  forcePage,
}: PaginationProps) => {
  const totalPages = Math.ceil(totalCount / perPage);

  return (
    <FlexContainer align="center">
      <ReactPaginateStyled
        breakLabel="..."
        nextLabel={<Arrow direction={'right'} />}
        pageRangeDisplayed={pageRangeDisplayed}
        pageCount={totalPages}
        disableInitialCallback={true}
        onPageChange={({ selected }) => onPageChange(selected)}
        previousLabel={<Arrow direction={'left'} />}
        forcePage={forcePage}
      />
    </FlexContainer>
  );
};

const ReactPaginateStyled = styled(ReactPaginate)(
  ({ theme: { spacing, palette, typography } }) => ({
    display: 'flex',
    gap: spacing(1),
    alignItems: 'center',
    '& li': {
      color: dark[400],
      cursor: 'pointer',
      fontWeight: typography.fontWeight.bold,
      lineHeight: spacing(3),
      fontSize: typography.fontSize.default,
    },
    '& li.selected, & li:hover': {
      textDecoration: 'underline',
      textUnderlineOffset: spacing(0.5),
      color: palette.primary.main,
    },
  })
);
