import * as sup from 'superstruct';

export type ComposableRefinement<T, S> = (
  struct: sup.Struct<T, S>
) => sup.Struct<T, S>;

/**
 * It allows for creating custom composable refinement that can be used
 * with the `recompose` helper.
 */
export function createComposableRefinement<T, S>(
  name: string,
  refiner: sup.Refiner<T>
): ComposableRefinement<T, S> {
  return (struct) => sup.refine(struct, name, refiner);
}
