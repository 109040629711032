import { useTranslation } from 'react-i18next';

import { mapDateToDateInputString } from '@/modules/negotiation/utils/dateUtils';
import { palette } from '@/modules/theme/utils/createTheme';
import { formatDate } from '@/utils/dateUtils';

import { EndDateStyled, InfoTextStyled } from '../StartDate.styled';

type EndDateProps = {
  isDatePickerError: boolean;
  endDate: Date | null;
};

export const EndDate = ({ isDatePickerError, endDate }: EndDateProps) => {
  const { t } = useTranslation('pages/StartDate');

  if (!isDatePickerError && endDate) {
    return (
      <>
        <EndDateStyled color={palette.default.text}>
          {t('End date')}: {formatDate(mapDateToDateInputString(endDate))}
        </EndDateStyled>
        <InfoTextStyled>
          {t('End date is always Friday next week (8 working days)')}
        </InfoTextStyled>
      </>
    );
  }

  return (
    <InfoTextStyled>
      {t(
        'Start date is when your CPO letter will be sent to the suppliers to initiate the negotiation.'
      )}
    </InfoTextStyled>
  );
};
