import { CheckboxLabel, LabelVariant } from '../SelectionLabel';

import { CheckboxWrapperStyled, InputStyled } from './Checkbox.styled';

type ReactInputProps = Omit<React.ComponentProps<'input'>, 'ref'>;

type DefaultCheckboxProps = ReactInputProps & {
  id: string;
  labelVariant?: LabelVariant;
  className?: string;
};

export const DefaultCheckbox = ({
  name,
  id,
  labelVariant,
  children,
  className,
  ...props
}: DefaultCheckboxProps) => {
  return (
    <CheckboxWrapperStyled className={className} errorHidden>
      <InputStyled type="checkbox" id={id} name={name} {...props} />
      <CheckboxLabel variant={labelVariant} htmlFor={id}>
        {children}
      </CheckboxLabel>
    </CheckboxWrapperStyled>
  );
};
