import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { queryKeys } from '@/modules/api/queryKeys';
import { constructMeta } from '@/modules/reactQuery/constructMeta';
import { createUploadSpendFileMutation } from '@/modules/user/api/mutations/createUploadSpendfileMutation';

export const useCreateUploadSpendFileMutation = () => {
  const jsonApi = useJsonApi();
  const { t } = useTranslation('apiMessages/useCreateUploadSpendFileMutation');
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [createUploadSpendFileMutation.name],
    mutationFn: createUploadSpendFileMutation(jsonApi),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.user.spendFile.getSpendFiles],
      });
    },
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t("You don't have access to add a file"),
        },
      },
    }),
  });
};
