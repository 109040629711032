import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { notify } from '@/modules/notify/notify';
import { setupNegotiationRoutes } from '@/modules/router/onboardedRoutes';
import { defaultSuppliersQueryParams } from '@/modules/suppliers/helpers/defaultSuppliersQueryParams';

import { Suppliers } from './Suppliers';

export const SuppliersTabScene = () => {
  const { t } = useTranslation('pages/SuppliersTab');

  const { negotiationId } =
    setupNegotiationRoutes.useGetRouteParams('suppliers');
  const { queryParams, queryParamsError } =
    setupNegotiationRoutes.useGetQueryParams('suppliers');

  useEffect(() => {
    if (queryParamsError) {
      notify.alert.error(t('Invalid query parameters'));
    }
  }, [queryParamsError, t]);

  if (queryParamsError) {
    return (
      <Navigate
        to={setupNegotiationRoutes.buildUrl('suppliers', {
          params: { negotiationId },
          query: defaultSuppliersQueryParams,
        })}
      />
    );
  }

  return <Suppliers queryParams={queryParams} negotiationId={negotiationId} />;
};
