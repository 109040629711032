import { Menu } from '@headlessui/react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as DeleteIcon } from '@/assets/icons/minusIcon.svg';
import { ReactComponent as MoreIcon } from '@/assets/icons/moreIcon.svg';
import { SupplierNegotiationState } from '@/modules/negotiation/api/resources/negotiationSelectedSuppliers/negotiationSelectedSuppliersResourceConsts';
import { NegotiationSupplier } from '@/modules/negotiation/helpers/parseGetNegotiationSuppliersResponse';
import { ActionButton } from '@/modules/theme/components/Table/ActionButton.styled';
import { ActionTableCell } from '@/modules/theme/components/Table/ActionTableCell.styled';

import {
  MenuButtonStyled,
  MenuItemButtonStyled,
  MenuItemsContentStyled,
} from './SuppliersTableActionCell.styled';

type SuppliersTableActionCellProps = {
  supplierProgress: NegotiationSupplier['progress'];
  isNegotiationActive: boolean;
  onShowSupplierActions: () => void;
  onResetSupplier: () => void;
  onRemoveSupplier: () => void;
  onForwardToNewContact: () => void;
};

export const SuppliersTableActionCell = ({
  supplierProgress,
  isNegotiationActive,
  onShowSupplierActions,
  onResetSupplier,
  onRemoveSupplier,
  onForwardToNewContact,
}: SuppliersTableActionCellProps) => {
  const { t } = useTranslation('modules/negotiations/NegotiationSuppliersList');

  const hasSupplierAnswered =
    supplierProgress?.state === SupplierNegotiationState.ANSWERED;

  const hasSupplierProgressed =
    supplierProgress?.state !== SupplierNegotiationState.INVITED;

  return (
    <ActionTableCell>
      {isNegotiationActive && (
        <ActionButton onClick={onRemoveSupplier}>
          <DeleteIcon />
        </ActionButton>
      )}
      <Menu as="div">
        <MenuButtonStyled>
          <MoreIcon />
        </MenuButtonStyled>
        <Menu.Items>
          <MenuItemsContentStyled>
            {hasSupplierAnswered && (
              <Menu.Item>
                <MenuItemButtonStyled onClick={onShowSupplierActions}>
                  {t('Show supplier actions')}
                </MenuItemButtonStyled>
              </Menu.Item>
            )}

            {isNegotiationActive && (
              <>
                <Menu.Item>
                  <MenuItemButtonStyled onClick={onForwardToNewContact}>
                    {t('Forward to new contact')}
                  </MenuItemButtonStyled>
                </Menu.Item>

                {hasSupplierProgressed && (
                  <Menu.Item>
                    <MenuItemButtonStyled onClick={onResetSupplier}>
                      {t('Reset supplier')}
                    </MenuItemButtonStyled>
                  </Menu.Item>
                )}
              </>
            )}
          </MenuItemsContentStyled>
        </Menu.Items>
      </Menu>
    </ActionTableCell>
  );
};
