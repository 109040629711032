import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export type UseDebouncedOptions = {
  wait: number;
  maxWait?: number;
  leading?: boolean;
  trailing?: boolean;
};

export type UseDebouncedSetter<T> = (
  value: T,
  options?: { immediate?: boolean }
) => void;

export const useDebouncedState = <T>(
  initialValue: T,
  options: UseDebouncedOptions
): [T, UseDebouncedSetter<T>] => {
  const [value, setValue] = useState(initialValue);

  // prevent recreating setDebounced when options are the same
  const previousOptions = useRef(options);
  if (isEqual(previousOptions.current, options)) {
    options = previousOptions.current;
  }

  const setDebounced = useMemo(() => {
    return debounce(setValue, options.wait, options);
  }, [options]);

  const setter: UseDebouncedSetter<T> = useCallback(
    (value, { immediate = false } = {}) => {
      if (immediate) {
        setValue(value);
      } else {
        setDebounced(value);
      }
    },
    [setDebounced]
  );

  return [value, setter];
};

export const useDebounced = <T>(value: T, options: UseDebouncedOptions): T => {
  const [debounced, setDebounced] = useDebouncedState(value, options);

  useEffect(() => {
    setDebounced(value);
  }, [setDebounced, value]);

  return debounced;
};

type UseDebouncedFieldProps<T> = {
  currentContent: T;
  onFieldSave: (debouncedContent: T, savedContent: T) => void;
  options: UseDebouncedOptions;
};

export const useDebouncedField = <T>({
  currentContent,
  onFieldSave,
  options,
}: UseDebouncedFieldProps<T>) => {
  const [savedContent, setSavedContent] = useState(currentContent);
  const [debouncedContent, setDebouncedContent] = useDebouncedState(
    currentContent,
    options
  );

  useEffect(() => {
    setDebouncedContent(currentContent);
  }, [currentContent, setDebouncedContent]);

  useEffect(() => {
    if (savedContent !== debouncedContent) {
      setSavedContent(debouncedContent);
      onFieldSave(debouncedContent, savedContent);
    }
  }, [debouncedContent, onFieldSave, savedContent]);

  const resetDebouncedField = useCallback(
    (content: T) => {
      setDebouncedContent(content, { immediate: true });
      setSavedContent(content);
    },
    [setDebouncedContent]
  );

  return {
    savedContent,
    setSavedContent,
    debouncedContent,
    setDebouncedContent,
    resetDebouncedField,
  };
};
