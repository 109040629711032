import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';

import { FlexContainer } from '../FlexContainer';

type InputSkeletonLoaderProps = {
  lines?: number;
};

export const InputSkeletonLoader = ({
  lines = 1,
}: InputSkeletonLoaderProps) => {
  return (
    <>
      {Array.from({ length: lines }).map((_, i) => (
        <FlexContainer key={i} gap={0} mb={1} mt={0.75} direction="column">
          <SkeletonLoader height={18} width={180} />
          <SkeletonLoader height={42} />
        </FlexContainer>
      ))}
    </>
  );
};
