import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { queryKeys } from '@/modules/api/queryKeys';
import { useUpdateNegotiationMutation } from '@/modules/negotiation/hooks/useUpdateNegotiationMutation';
import { NegotiationState } from '@/modules/negotiation/types/negotiationTypes';
import { useDialogState } from '@/utils/useDialogState';

import { useGetNegotiationsQuery } from '../../../../hooks/useGetNegotiationsQuery';
import { periodNotSelectedState } from '../../../NegotiationsDnD/constants';
import {
  NegotiationsDnD,
  UpdatePeriodIndexParams,
} from '../../../NegotiationsDnD/NegotiationsDnD';
import { SelectedSuppliersDialog } from '../../../SelectedSuppliersDialog/SelectedSuppliersDialog';
import { DnDNegotiation } from '../../../types';

export const SuggestedNegotiationsDnD = () => {
  const queryClient = useQueryClient();
  // We need local copy of negotations,
  // because we need to mutate it later on drag events
  const [items, setItems] = useState<DnDNegotiation[]>([]);

  const updateNegotiationMutation = useUpdateNegotiationMutation();
  const selectedSuppliersDialogState = useDialogState();
  const [pickedNegotiationId, setPickedNegotiationId] = useState<string | null>(
    null
  );

  const {
    data: negotiations,
    isPending,
    isRefetching,
  } = useGetNegotiationsQuery({
    pageNumber: 1,
    pageSize: 100,
    states: [NegotiationState.SUGGESTED, NegotiationState.ESTIMATED],
  });

  const updatePeriodIndex = ({
    negotiationId,
    destinationIndex,
    originIndex,
  }: UpdatePeriodIndexParams) => {
    updateNegotiationMutation.mutate(
      {
        negotiationId,
        periodIndex: destinationIndex,
      },
      {
        onSuccess: async () => {
          // we should refetch metrics only when card was selected
          // or removed from periods. If it switches periods
          // it's not necessary to refetch them
          if (
            periodNotSelectedState.some((state) =>
              [destinationIndex, originIndex].includes(state)
            )
          ) {
            await queryClient.invalidateQueries({
              queryKey: [queryKeys.negotiations.getSuggestedNegotiationMetrics],
            });
          }
        },
      }
    );
  };

  useEffect(() => {
    if (isPending) return;
    setItems(negotiations || []);
  }, [negotiations, isPending]);

  const handleDialogClose = () => {
    selectedSuppliersDialogState.close();
    setPickedNegotiationId(null);
  };

  const handleNegotiationCardClick = ({ id }: DnDNegotiation) => {
    setPickedNegotiationId(id);
    selectedSuppliersDialogState.open();
  };

  return (
    <>
      <NegotiationsDnD
        items={items}
        updatePeriodIndex={updatePeriodIndex}
        setItems={setItems}
        isRefetching={isRefetching}
        isLoading={isPending}
        onNegotiationCardClick={handleNegotiationCardClick}
      />
      {pickedNegotiationId && (
        <SelectedSuppliersDialog
          isOpen={selectedSuppliersDialogState.isOpen}
          onClose={handleDialogClose}
          negotiationId={pickedNegotiationId}
        />
      )}
    </>
  );
};
