import * as sup from 'superstruct';

import { JsonApiClient } from '@/modules/api/utils/createJsonApiClient';
import { createResponseStruct } from '@/modules/api/utils/jsonApiStructs';

import { supplierCategoriesResourcePath } from '../resources/supplierCategory/supplierCategoryResourceConsts';
import { supplierCategoryResourceStruct } from '../resources/supplierCategory/supplierCategoryResourceStruct';

export const getSupplierCategoriesQuery = (jsonApi: JsonApiClient) => {
  return () =>
    jsonApi.getMany({
      path: supplierCategoriesResourcePath,
      responseStruct: getSupplierCategoriesResponseStruct,
    });
};

const getSupplierCategoriesResponseStruct = createResponseStruct({
  data: sup.array(supplierCategoryResourceStruct),
});

export type GetSupplierCategoriesResponse = sup.Infer<
  typeof getSupplierCategoriesResponseStruct
>;
