import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import {
  components,
  GroupBase,
  MenuListProps as ReactSelectMenuListProps,
} from 'react-select';
import styled from 'styled-components';

import { dark } from '@/modules/theme/utils/colors';

type MenuListProps<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = PropsWithChildren<ReactSelectMenuListProps<Option, IsMulti, Group>>;

export const MenuList = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: MenuListProps<Option, IsMulti, Group>
) => {
  const { t } = useTranslation('common/MenuList');

  const {
    selectProps: { inputValue },
    children,
  } = props;

  return (
    <components.MenuList {...props}>
      {inputValue && <SearchTextStyled>{t('Results')}</SearchTextStyled>}
      {children}
    </components.MenuList>
  );
};

export const SearchTextStyled = styled('p')(
  ({ theme: { spacing, typography } }) => ({
    display: 'block',
    padding: spacing([1, 0, 1, 2]),
    color: dark[400],
    backgroundColor: dark[50],
    fontWeight: typography.fontWeight.bold,
  })
);
