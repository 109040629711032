import { ReactNode } from 'react';

import {
  RouterLinkStyled as AnchorStyled,
  DisabledLinkStyled,
} from './Link.styled';

interface ExternalLinkProps {
  to: string;
  targetBlank?: boolean;
  className?: string;
  disabled?: boolean;
  children: ReactNode;
  title?: string;
  download?: string;
}

export function ExternalLink({
  to,
  targetBlank = true,
  disabled,
  children,
  title,
  download,
  className,
}: ExternalLinkProps) {
  if (disabled) {
    return (
      <DisabledLinkStyled className={className}>{children}</DisabledLinkStyled>
    );
  }
  return (
    <AnchorStyled
      to={to}
      rel="noreferrer noopener"
      target={targetBlank ? '_blank' : undefined}
      title={title}
      className={className}
      download={download}
    >
      {children}
    </AnchorStyled>
  );
}
