export const supplierContactResourceType = 'supplier_contact';

export const getSupplierContactsResourcePath = ({
  supplierId,
}: {
  supplierId: string;
}) => `/me/customer/suppliers/${supplierId}/contacts`;

export const getEditSupplierContactResourcePath = ({ id }: { id: string }) =>
  `/me/customer/supplier_contacts/${id}`;

export const getDeleteSupplierContactResourcePath = ({ id }: { id: string }) =>
  `/me/customer/supplier_contacts/${id}`;
