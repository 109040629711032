import { ContactPerson } from '../components/ContactPersonFormDialog/contactPersonFormStruct';

export const getContactPersonDisplayLabel = (
  contactPerson: ContactPerson | null
) => {
  const fullName = `${contactPerson?.firstName || ''} ${
    contactPerson?.lastName || ''
  }`.trim();
  if (fullName) return fullName;
  if (contactPerson?.email) return contactPerson?.email;
};
