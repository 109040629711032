import { ExclusionReasonFilter } from '@/modules/customer/api/charts/getExclusionReasonsChart';

import { SuppliersOrder } from '../../../utils/suppliersOrders';

import { supplierRelationshipKeys } from './supplierResourceStruct';

export const supplierResourceType = 'supplier';

export const getSupplierResourcePath = (
  id: string,
  includes: Array<keyof typeof supplierRelationshipKeys>
) => {
  return `me/customer/suppliers/${id}?include=${includes.join(',')}`;
};

export enum SupplierListIncludes {
  businessUnit = 'businessUnit',
  category = 'category',
  categoryPath = 'categoryPath',
  contacts = 'contacts',
  defaultContact = 'defaultContact',
}

export type GetSuppliersQueryParams = {
  pageNumber: number;
  pageSize: number;
  sort?: SuppliersOrder;
  categories?: string[];
  exclusionReasons?: ExclusionReasonFilter[];
  include?: SupplierListIncludes[];
};

export const suppliersListResourcePath = '/me/customer/suppliers';

export const addSupplierResourcePath = '/me/customer/suppliers';

export const getEditSupplierResourcePath = ({ id }: { id: string }) => {
  return `/me/customer/suppliers/${id}`;
};

export const getDeleteSupplierResourcePath = ({ id }: { id: string }) =>
  `/me/customer/suppliers/${id}`;
