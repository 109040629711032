import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Dialog } from '@/modules/theme/components/Dialog/Dialog';
import { Heading } from '@/modules/theme/components/Typography';

import { SupplierNegotiationState } from '../../api/resources/negotiationSelectedSuppliers/negotiationSelectedSuppliersResourceConsts';
import { useGetNegotiationSelectedSuppliersInfiniteQuery } from '../../hooks/useGetNegotiationSelectedSuppliersInfiniteQuery';

import { SuppliersGroupTable } from './SuppliersGroupTable';

type NegotiationInvitedSuppliersDialogProps = {
  negotiationId: string;
  isOpen: boolean;
  onClose: () => void;
};

export const NegotiationInvitedSuppliersDialog = ({
  negotiationId,
  isOpen,
  onClose,
}: NegotiationInvitedSuppliersDialogProps) => {
  const { t } = useTranslation('modules/negotiations/NegotiationDataPanel');

  const { data, fetchNextPage, isFetching } =
    useGetNegotiationSelectedSuppliersInfiniteQuery({
      negotiationId,
      negotiationStates: [
        SupplierNegotiationState.ANSWERED,
        // `INVITED` state means that the supplier
        // didn't open email. It's necessary to
        // fetch other statuses because in GUI
        // invited means `everything except excluded`.
        SupplierNegotiationState.INVITED,
        SupplierNegotiationState.OPENEDEMAIL,
        SupplierNegotiationState.REGISTERED,
        SupplierNegotiationState.VISITED,
      ],
      enabled: isOpen,
    });

  const suppliers = useMemo(() => {
    return data?.pages.flatMap((page) => page.suppliers);
  }, [data]);

  return (
    <DialogStyled isOpen={isOpen} onClose={onClose} hasCloseIcon>
      <Heading variant="h2">{t('Invited')}</Heading>
      <SuppliersGroupTable
        isFetching={isFetching}
        suppliers={suppliers}
        onFetchMore={fetchNextPage}
      />
    </DialogStyled>
  );
};

const DialogStyled = styled(Dialog)(({ theme: { spacing } }) => ({
  width: '95%',
  maxWidth: spacing(159),
  alignItems: 'flex-start',
  gap: spacing(2),
}));
