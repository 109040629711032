import * as sup from 'superstruct';

import { createIdentifierStruct } from '@/modules/api/utils/jsonApiStructs';
import { NegotiationTermTypes } from '@/modules/negotiation/types/negotiationTypes';

import { acceptableTermTypes } from './negotiationTermResourceConsts';

export const termResourceIdentifierStruct = sup.object({
  id: sup.string(),
  type: sup.enums(Object.values(NegotiationTermTypes)),
});

export const acceptableTermTypesIdentifierStruct = sup.object({
  id: sup.string(),
  type: sup.enums(Object.values(acceptableTermTypes)),
});

export const offerDiscountTermIdentifierStruct = createIdentifierStruct(
  NegotiationTermTypes.offerDiscount
);

export const discountImplementationTermIdentifierStruct =
  createIdentifierStruct(NegotiationTermTypes.selectDiscountImplementation);

export const signCodeOfConductTermIdentifierStruct = createIdentifierStruct(
  NegotiationTermTypes.signCodeOfConduct
);

export const acceptContractPeriodTermIdentifierStruct = createIdentifierStruct(
  NegotiationTermTypes.acceptContractPeriod
);

export const textTermIdentifierStruct = createIdentifierStruct(
  NegotiationTermTypes.text
);
