import { useQueryClient } from '@tanstack/react-query';
import { nanoid } from 'nanoid';

import { queryKeys } from '@/modules/api/queryKeys';
import { DefaultCheckbox } from '@/modules/form/components/Checkbox/DefaultCheckbox';
import { Loader } from '@/modules/theme/components/Loader';

import { useAddSelectedSuppliersToNegotiationMutation } from '../../hooks/useAddSelectedSuppliersToNegotiationMutation';
import { useRemoveSelectedSupplierFromNegotiationMutation } from '../../hooks/useRemoveSelectedSupplierFromNegotiationMutation';

type ToggleSupplierCheckboxProps = {
  selected: boolean;
  supplierId: string;
  negotiationId: string;
};

export const ToggleSupplierCheckbox = ({
  selected,
  negotiationId,
  supplierId,
}: ToggleSupplierCheckboxProps) => {
  const checkboxId = nanoid();
  const queryClient = useQueryClient();
  const mutationPayload = { supplierId, negotiationId };

  const {
    mutate: removeSelectedSupplierFromNegotiationMutate,
    isPending: isRemoveSelectedSupplierFromNegotiationLoading,
  } = useRemoveSelectedSupplierFromNegotiationMutation(mutationPayload);
  const {
    mutate: addSelectedSupplierToNegotiationMutate,
    isPending: isAddSelectedSupplierToNegotiationLoading,
  } = useAddSelectedSuppliersToNegotiationMutation(mutationPayload);

  const isMutationLoading =
    isRemoveSelectedSupplierFromNegotiationLoading ||
    isAddSelectedSupplierToNegotiationLoading;

  const handleOnChange = async () => {
    await queryClient.cancelQueries({
      queryKey: [queryKeys.negotiations.getNegotiationMetrics],
    });
    if (selected) {
      removeSelectedSupplierFromNegotiationMutate({
        negotiationId,
        selectedSuppliers: [supplierId],
      });
    } else {
      addSelectedSupplierToNegotiationMutate({
        negotiationId,
        selectedSuppliers: [supplierId],
      });
    }
  };

  if (isMutationLoading) {
    return <Loader size={20} />;
  }

  return (
    <DefaultCheckbox
      checked={selected}
      id={checkboxId}
      onChange={handleOnChange}
    />
  );
};
