import styled from 'styled-components';

import { Heading, Paragraph } from '@/modules/theme/components/Typography';

import { dark } from '../../utils/colors';

import backgroundSrc from './notFoundBig.svg';

export const NotFoundSectionStyled = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  background: `url(${backgroundSrc}) no-repeat center center/cover`,
});

export const NotFoundSectionContentStyled = styled('div')(
  ({ theme: { breakpoints } }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 400,
    position: 'relative',
    top: '16%',

    [breakpoints.down.md]: {
      maxWidth: 300,
    },

    [breakpoints.down.sm]: {
      maxWidth: 200,
    },
  })
);

export const HeadingStyled = styled(Heading)(({ theme: { spacing } }) => ({
  lineHeight: 1.25,
  padding: spacing([5, 0, 2]),
  color: dark[600],
}));

export const ParagraphStyled = styled(Paragraph)(
  ({ theme: { typography, breakpoints } }) => ({
    textAlign: 'center',
    color: dark[600],
    fontWeight: typography.fontWeight.bold,

    [breakpoints.down.sm]: {
      fontSize: typography.pxToRem(14),
      lineHeight: 1.25,
    },
  })
);
