import styled from 'styled-components';

import { Paragraph } from '@/modules/theme/components/Typography';

export const ContainerWrapperStyled = styled('main')(
  ({ theme: { spacing, breakpoints } }) => ({
    margin: spacing([16.5, 11.25]),
    [breakpoints.down.md]: {
      margin: spacing([6, 0, 6, 2]),
    },
  })
);

export const ParagraphStyled = styled(Paragraph)(({ theme: { spacing } }) => ({
  marginTop: spacing(2),
}));
