import { ReactNode } from 'react';

import { Loader } from '../Loader';

import { ButtonStyled, LoaderWrapperStyled } from './Button.styled';

export type ButtonSizes = 'fullWidth' | 'default' | 'small';
export type ButtonVariants = 'default' | 'outline' | 'light' | 'text';

export interface ButtonProps {
  onClick?: ReactButtonProps['onClick'];
  className?: string;
  disabled?: boolean;
  type?: ReactButtonProps['type'];
  children: ReactNode;
  loading?: boolean;
  size?: ButtonSizes;
  variant?: ButtonVariants;
  form?: string;
}

export function Button({
  children,
  type = 'button',
  loading,
  size = 'default',
  variant = 'default',
  form,
  disabled,
  ...props
}: ButtonProps) {
  return (
    <ButtonStyled
      disabled={loading || disabled}
      size={size}
      variant={variant}
      type={type}
      form={form}
      {...props}
    >
      {loading ? (
        <LoaderWrapperStyled variant={variant}>
          <Loader size={20} variant={variant} />
        </LoaderWrapperStyled>
      ) : (
        children
      )}
    </ButtonStyled>
  );
}

type ReactButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;
