import styled, { css } from 'styled-components';

import { Link } from '@/modules/theme/components/Link/Link';

const baseActionButtonStyles = css(({ theme: { spacing } }) => ({
  appearance: 'none',
  background: 'none',
  border: 'none',
  height: spacing(3),
  width: spacing(3),
  padding: 0,
}));

export const ActionButton = styled('button')(
  () => baseActionButtonStyles,
  ({ theme: { palette } }) => ({
    cursor: 'pointer',
    color: palette.default.text,
    '&:disabled': {
      cursor: 'not-allowed',
    },
  })
);

export const ActionButtonLink = styled(Link.Internal)(
  () => baseActionButtonStyles,
  ({ theme: { palette }, disabled }) => ({
    textDecoration: 'none',
    color: disabled ? palette.disabled.main : palette.default.text,
  })
);
