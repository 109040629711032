import styled from 'styled-components';

import { Paragraph } from '@/modules/theme/components/Typography';

export const ParagraphStyled = styled(Paragraph)(({ theme: { spacing } }) => ({
  maxWidth: 370,
  marginBottom: spacing(1),
  textAlign: 'center',
  margin: spacing([2, 0, 3]),
}));

export const LanguagesListStyled = styled('ul')(({ theme: { spacing } }) => ({
  listStyleType: 'disc',
  paddingLeft: spacing(2),
  marginTop: spacing(1),
}));

export const LanguageListItemStyled = styled('li')(
  ({ theme: { spacing } }) => ({
    marginBottom: spacing(0.5),
  })
);

export const ListStyled = styled('ul')(({ theme: { spacing } }) => ({
  marginBottom: spacing(2),
}));
