export const periods = [
  {
    periodId: 0,
  },
  {
    periodId: 1,
  },
  {
    periodId: 2,
  },
  {
    periodId: 3,
  },
];

// DEFAULT_DROPPABLE index is used for area under period columns - negotiations put there are in ESTIMATED state.
// once they are moved to one of period columns become SUGGESTED
export const DEFAULT_DROPPABLE = -1;

export const periodNotSelectedState = [DEFAULT_DROPPABLE, null] as const;
