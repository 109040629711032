import * as sup from 'superstruct';

import { JsonApiClient } from '@/modules/api/utils/createJsonApiClient';
import { createResponseStruct } from '@/modules/api/utils/jsonApiStructs';
import { includedStruct } from '@/utils/structs/includedStruct';

import { customerResourceStruct } from '../resources/customer/customerResourceStruct';
import { userResourcePath } from '../resources/user/userResourceConsts';
import { userResourceStruct } from '../resources/user/userResourceStruct';

export const getUserQuery = (jsonApi: JsonApiClient) => {
  return () =>
    jsonApi.getOne({
      path: userResourcePath,
      responseStruct: getUserResponseStruct,
    });
};

const getUserResponseStruct = createResponseStruct({
  data: userResourceStruct,
  included: includedStruct([customerResourceStruct]),
});

export type GetUserResponse = sup.Infer<typeof getUserResponseStruct>;
