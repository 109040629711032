import { JsonApiClient } from '@/modules/api/utils/createJsonApiClient';
import { createResponseStruct } from '@/modules/api/utils/jsonApiStructs';

import { spendFileResourcePath } from '../resources/spendFile/spendFileResourceConsts';
import { uploadSpendFileResourceStruct } from '../resources/spendFile/spendFileResourceStruct';
import { uploadResourceType } from '../resources/uploadResourceConsts';

type CreateUploadSpendFileMutationPayload = {
  file: File;
};

export function createUploadSpendFileMutation(jsonApi: JsonApiClient) {
  return ({ file }: CreateUploadSpendFileMutationPayload) =>
    jsonApi.createOne({
      path: spendFileResourcePath,
      type: uploadResourceType,
      attributes: {
        fileName: file.name,
        contentType: file.type,
        sizeBytes: file.size,
      },
      responseStruct: spendFileUploadResponseStruct,
    });
}

const spendFileUploadResponseStruct = createResponseStruct({
  data: uploadSpendFileResourceStruct,
});
