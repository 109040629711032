import * as sup from 'superstruct';

import { createResourceStruct } from '@/modules/api/utils/jsonApiStructs';
import { dateTime } from '@/utils/structs/structs';

import {
  NegotiationSetupPendingStep,
  NegotiationState,
  NegotiationTermRole,
  NegotiationType,
} from '../../../types/negotiationTypes';
import { negotiationCommunicationIdentifierStruct } from '../negotiationCommunication/negotiationCommunicationIdentifierStruct';
import { negotiationMetricsIdentifierStruct } from '../negotiationMetrics/negotiationMetricsIdentifierStruct';
import { termResourceIdentifierStruct } from '../negotiationTerm/negotiationTermIdentifierStruct';

import { negotiationIdentifierStruct } from './negotiationIdentifierStruct';

const negotiationStateStruct = sup.enums(Object.values(NegotiationState));

export const negotiationTypeRolesStruct = sup.enums(
  Object.values(NegotiationType)
);

export const negotiationTermRolesStruct = sup.enums(
  Object.values(NegotiationTermRole)
);
export const negotiationSetupPendingStepsStruct = sup.enums(
  Object.values(NegotiationSetupPendingStep)
);

export const negotiationResourceStruct = createResourceStruct({
  identifier: negotiationIdentifierStruct,
  attributes: sup.object({
    title: sup.string(),
    state: negotiationStateStruct,
    createdAt: dateTime,
    timeZone: sup.string(),
    introEmail: sup.object({
      bodyTemplate: sup.string(),
    }),
    period: sup.nullable(
      sup.object({
        startAt: dateTime,
        endAt: dateTime,
        startDate: dateTime,
        endDate: dateTime,
      })
    ),
    periodIndex: sup.nullable(sup.integer()),
    periodOrder: sup.nullable(sup.integer()),
    termRoles: sup.array(negotiationTermRolesStruct),
    negotiationTypes: sup.array(negotiationTypeRolesStruct),
    pendingSteps: sup.array(negotiationSetupPendingStepsStruct),
  }),
  relationships: sup.optional(
    sup.object({
      metrics: sup.optional(
        sup.object({
          data: negotiationMetricsIdentifierStruct,
        })
      ),
      communication: sup.optional(
        sup.object({
          data: sup.array(negotiationCommunicationIdentifierStruct),
        })
      ),
      terms: sup.optional(
        sup.object({
          data: sup.array(termResourceIdentifierStruct),
        })
      ),
    })
  ),
  links: sup.object({
    portal: sup.nullable(sup.string()),
  }),
});

export type NegotiationResourceType = sup.Infer<
  typeof negotiationResourceStruct
>;
