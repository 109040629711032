import styled from 'styled-components';

import { Button } from '@/modules/theme/components/Button/Button';
import { FlexLayout } from '@/modules/theme/components/FlexLayout';

export const TextInputWrapperStyled = styled('div')(
  ({ theme: { spacing } }) => ({
    marginBottom: spacing(2),
    width: '100%',
  })
);

export const ButtonStyled = styled(Button)(({ theme: { spacing } }) => ({
  marginTop: spacing(3),
}));

export const ConfirmPasswordResetStyled = styled(FlexLayout)({
  textAlign: 'center',
});
