import { useTranslation } from 'react-i18next';

import { Heading, Paragraph } from '@/modules/theme/components/Typography';
import { mint, white } from '@/modules/theme/utils/colors';

import appScreenShot1Src from './images/appScreenShot1.png';
import appScreenShot2Src from './images/appScreenShot2.png';
import {
  AbsoluteImgStyled,
  ImagesWrapperStyled,
  SectionStyled,
  StaticImgStyled,
  TextWrapperStyled,
} from './NegotiationSection.styled';

export const NegotiationSection = () => {
  const { t } = useTranslation('pages/IntroPage');

  return (
    <SectionStyled>
      <ImagesWrapperStyled>
        <StaticImgStyled
          src={appScreenShot1Src}
          alt="Analysis & Offer page screenshot"
        />
        <AbsoluteImgStyled
          src={appScreenShot2Src}
          alt="Summary of requested negotiation screenshot"
        />
      </ImagesWrapperStyled>
      <TextWrapperStyled>
        <Heading variant="h3" color={mint[800]} mt={8}>
          {t(
            'Unable to negotiate all your suppliers? More negotiation power needed?'
          )}
        </Heading>
        <Paragraph color={white} mt={3}>
          {t(
            'Our platform is your own extra negotiator taking care of your needs securing savings and increased compliance in a way you have never seen before.'
          )}
        </Paragraph>
        <Paragraph color={white} mt={3}>
          {t('Built for CPO:s by ex CPO:s and procurement experts.')}
        </Paragraph>
      </TextWrapperStyled>
    </SectionStyled>
  );
};
