import styled from 'styled-components';

import { DashedButtonSizes } from './DashedButton';

type ButtonStyledProps = {
  size?: DashedButtonSizes;
};

export const DashedButtonStyled = styled('button')<ButtonStyledProps>(({
  size,
  theme: { spacing, borderRadius, typography, palette },
}) => {
  return {
    width: '100%',
    position: 'relative',
    cursor: 'pointer',
    fontFamily: typography.fonts.heading,
    borderRadius: borderRadius.default,
    transition: 'border-color 0.3s, color 0.3s',
    fontWeight: typography.fontWeight.bold,
    backgroundColor: palette.default.background,
    border: `2px dashed ${palette.secondary.main}`,
    color: palette.secondary.main,
    '&[disabled]': {
      borderColor: palette.disabled.light,
      color: palette.disabled.light,
      cursor: 'default',
    },
    '&:not([disabled])': {
      cursor: 'pointer',

      '&:hover': {
        borderColor: palette.secondary.light,
        color: palette.secondary.light,
      },
      '&:focus, &:active': {
        borderColor: palette.secondary.main,
        color: palette.secondary.main,
      },
    },
    ...(size === 'small'
      ? {
          minHeight: 40,
          padding: spacing(1),
          fontSize: typography.fontSize.default,
        }
      : {
          minHeight: 68,
          padding: spacing(2),
          fontSize: typography.fontSize.large,
        }),
  };
});

export const LoaderWrapperStyled = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
});
