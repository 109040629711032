import { useTranslation } from 'react-i18next';

import { ParsedNegotiation } from '@/modules/negotiation/helpers/parseGetNegotiationResponse';
import {
  Implementation,
  NegotiationTermTypes,
} from '@/modules/negotiation/types/negotiationTypes';

import { BulletListStyled } from '../NegotiationTerms.styled';

import { RichContentTerm } from './RichContentTerm';

type NegotiationTermType = ParsedNegotiation['negotiationTerms'][number];

type NegotiationTermProps = {
  term: NegotiationTermType;
};

export const NegotiationTerm = ({ term }: NegotiationTermProps) => {
  const { t } = useTranslation('modules/negotiations/NegotiationTerms');
  const implementationTranslations = {
    [Implementation.CREDIT_INVOICE]: t('Credit invoice'),
    [Implementation.INVOICE_DISCOUNT]: t('Invoice discount'),
    [Implementation.REDUCED_PRICES]: t('Reduced prices'),
  };

  if (term.type === NegotiationTermTypes.offerDiscount) {
    return null;
  }

  if (term.type === NegotiationTermTypes.selectDiscountImplementation) {
    return (
      <li>
        <RichContentTerm text={term.text} />
        <BulletListStyled>
          {term.implementations.map((implementation) => (
            <li key={implementation}>
              {implementationTranslations[implementation]}
            </li>
          ))}
        </BulletListStyled>
      </li>
    );
  }

  return (
    <li>
      <RichContentTerm text={term.text} />
    </li>
  );
};
