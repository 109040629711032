import { InfiniteData } from '@tanstack/react-query';

import { SupplierNegotiationState } from '@/modules/negotiation/api/resources/negotiationSelectedSuppliers/negotiationSelectedSuppliersResourceConsts';
import { ParsedNegotiationSuppliersResponse } from '@/modules/negotiation/helpers/parseGetNegotiationSuppliersResponse';

import { SelectedSuppliersQueryCache } from '../hooks/useUpdateSelectedSuppliersQueryCache';

type UpdateSupplierQueryCacheSelectedStateParams = {
  selectedSuppliersQueryCache: SelectedSuppliersQueryCache;
  supplierId: string;
  selected: boolean;
};

export const updateSupplierQueryCacheSelectedState = ({
  selectedSuppliersQueryCache,
  supplierId,
  selected,
}: UpdateSupplierQueryCacheSelectedStateParams): InfiniteData<ParsedNegotiationSuppliersResponse> => {
  return {
    pageParams: selectedSuppliersQueryCache.pageParams,
    pages: selectedSuppliersQueryCache.pages.map((page) => {
      return {
        ...page,
        suppliers: page.suppliers.map((supplier) => {
          if (supplier.id === supplierId && supplier.progress) {
            return {
              ...supplier,
              progress: {
                ...supplier.progress,
                state: selected
                  ? SupplierNegotiationState.INVITED
                  : SupplierNegotiationState.RECOMMENDED,
              },
            };
          }
          return supplier;
        }),
      };
    }),
  };
};
