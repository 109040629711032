import styled from 'styled-components';

import { dark, mint } from '@/modules/theme/utils/colors';

import { ReactComponent as TimelineArrow } from './TimelineArrow.svg';

export const DndWrapperStyled = styled('div')({
  position: 'relative',
});

export const PeriodsStyled = styled('div')(
  ({ theme: { spacing, typography, borderRadius, breakpoints } }) => ({
    display: 'flex',
    color: dark[400],
    fontSize: typography.fontSize.html,
    fontWeight: typography.fontWeight.bold,
    justifyContent: 'space-around',
    alignItems: 'center',
    height: spacing(5),
    borderRadius: borderRadius.default,
    border: `2px solid ${dark[400]}`,
    margin: spacing([2, 0]),
    borderRightWidth: 0,
    [breakpoints.down.sm]: {
      borderRightWidth: 2,
    },
  })
);

export const PeriodStyled = styled('div')({
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 6,
    right: 0,
    display: 'block',
    width: 1,
    height: 24,
    background: dark[400],
  },
  '&:last-of-type::after': {
    display: 'none',
  },
});

export const TimelineArrowStyled = styled(TimelineArrow)(
  ({ theme: { breakpoints } }) => ({
    position: 'absolute',
    right: 0,
    [breakpoints.down.sm]: {
      display: 'none',
    },
  })
);

export const DraggableColumnStyled = styled('div')(
  ({ theme: { spacing, borderRadius } }) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    borderRadius: borderRadius.default,
    gap: spacing(1),
  })
);

export const DefaultGridStyled = styled('div')(
  ({ theme: { spacing, borderRadius } }) => ({
    display: 'grid',
    gap: spacing(1),
    margin: spacing([4, 0]),
    grid: 'auto-flow / repeat(4, 1fr)',
    padding: spacing(1),
    borderRadius: borderRadius.default,
    minHeight: spacing(23),
  })
);

export const DndContainerStyled = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: spacing(2),
}));

export const DefaultGridPlaceholderStyled = styled('div')(
  ({ theme: { spacing, borderRadius } }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: dark[200],
    padding: spacing(1),
    borderRadius: borderRadius.default,
    border: `2px dashed ${mint[200]}`,
    minHeight: spacing(23),
    margin: spacing([4, 0]),
  })
);
