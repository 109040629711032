import * as sup from 'superstruct';

import { createRouting, defineRoute } from './config/createRouting';

export const anonymousRoutes = createRouting({
  signIn: defineRoute({
    path: '/sign-in' as const,
  }),
  signUp: defineRoute({
    path: '/sign-up' as const,
    queryStruct: sup.object({
      token: sup.string(),
    }),
  }),
  signUpSuccess: defineRoute({
    path: '/sign-up-success' as const,
  }),
  resetPassword: defineRoute({
    path: '/reset-password' as const,
    queryStruct: sup.object({
      verificationId: sup.string(),
      userId: sup.string(),
    }),
  }),
  customerIdNotFound: defineRoute({
    path: '/customer-id-not-found' as const,
  }),
  signedOut: defineRoute({
    path: '/signed-out' as const,
  }),
  leadCustomerSurvey: defineRoute({
    path: '/spend-questionnaire' as const,
  }),
});
