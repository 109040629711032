import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TableSkeletonLoader } from '@/modules/theme/components/TableSkeletonLoader/TableSkeletonLoader';
import { Paragraph } from '@/modules/theme/components/Typography';

import { useGetCustomerRecommendedSuppliersInfiniteQuery } from '../../hooks/useGetCustomerRecommendedSuppliersInfiniteQuery';
import { AddSupplierForm } from '../AddSupplierForm/AddSupplierForm';

import {
  CloseButtonStyled,
  ContentWrapperStyled,
  DialogStyled,
  HeadingStyled,
} from './AddSupplierDialog.styled';

type AddSupplierDialogProps = {
  isOpen: boolean;
  negotiationId: string;
  onClose: () => void;
};

export const AddSupplierDialog = ({
  isOpen,
  negotiationId,
  onClose,
}: AddSupplierDialogProps) => {
  const { t } = useTranslation('pages/SuppliersTab');

  const {
    data,
    fetchNextPage,
    isFetching: isFetchingRecommendedSupplies,
    isPending: isRecommendedSuppliersPending,
  } = useGetCustomerRecommendedSuppliersInfiniteQuery({
    negotiationId,
    enabled: isOpen,
  });

  const suppliers = useMemo(() => {
    return data?.pages.flatMap((page) => page.suppliers) || [];
  }, [data]);

  if (isRecommendedSuppliersPending)
    return (
      <DialogStyled isOpen={isOpen} hasCloseIcon onClose={onClose}>
        <HeadingStyled variant="h2">{t('Add supplier')}</HeadingStyled>
        <ContentWrapperStyled direction="column">
          <TableSkeletonLoader rows={8} />
        </ContentWrapperStyled>
      </DialogStyled>
    );

  if (!suppliers.length)
    return (
      <DialogStyled isOpen={isOpen} hasCloseIcon onClose={onClose}>
        <HeadingStyled variant="h2">{t('Add supplier')}</HeadingStyled>
        <ContentWrapperStyled direction="column">
          <>
            <Paragraph alignment="center">
              {t('All suppliers have already been selected')}
            </Paragraph>
            <CloseButtonStyled variant={'outline'} onClick={onClose}>
              {t('Close')}
            </CloseButtonStyled>
          </>
        </ContentWrapperStyled>
      </DialogStyled>
    );

  return (
    <DialogStyled isOpen={isOpen} hasCloseIcon onClose={onClose}>
      <HeadingStyled variant="h2">{t('Add supplier')}</HeadingStyled>
      <ContentWrapperStyled direction="column">
        <AddSupplierForm
          suppliers={suppliers}
          onNextPage={fetchNextPage}
          isFetchingSuppliers={isFetchingRecommendedSupplies}
          negotiationId={negotiationId}
          onClose={onClose}
        />
      </ContentWrapperStyled>
    </DialogStyled>
  );
};
