import { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { jsonApiClient } from '@/modules/api/httpClient';
import { useGetCustomerActiveSubscriptionPlanQuery } from '@/modules/customer/api/subscriptionPlan/useGetCustomerActiveSubscriptionPlanQuery';
import { anonymousRoutes } from '@/modules/router/anonymousRoutes';

import { useAuthContext } from '../hooks/useAuthContext';
import { useRefetchUserDataQuery } from '../hooks/useRefetchUserDataQuery';
import { useUnauthorizedResponseInterceptor } from '../utils/useUnauthorizedResponseInterceptor';

import { lastSignedInLocalStorageKey } from './AuthContextProvider';

type RefetchUserDataProps = {
  children: ReactNode;
};

export const RefetchUserData = ({ children }: RefetchUserDataProps) => {
  const { signIn, signOut, setIsAuthenticating } = useAuthContext();
  const navigate = useNavigate();

  useUnauthorizedResponseInterceptor({ httpClient: jsonApiClient });

  const {
    data: userData,
    isSuccess: isUserDataSuccess,
    isError: isUserDataError,
    refetch: refetchUserData,
  } = useRefetchUserDataQuery();

  const {
    data: subscriptionPlan,
    isSuccess: isSubscriptionPlanSuccess,
    isError: isSubscriptionPlanError,
    refetch: refetchSubscriptionPlan,
  } = useGetCustomerActiveSubscriptionPlanQuery({ enabled: false });

  const isSubscriptionPlanSettled =
    isSubscriptionPlanSuccess || isSubscriptionPlanError;
  const isGetUserDetailsSettled = isUserDataSuccess || isUserDataError;

  if (isUserDataError) {
    signOut();
  }

  useEffect(() => {
    const refetchAuthData = async () => {
      try {
        await Promise.all([refetchUserData(), refetchSubscriptionPlan()]);
      } catch (e) {
        setIsAuthenticating(false);
      }
    };

    const lastSignedIn = localStorage.getItem(lastSignedInLocalStorageKey);
    if (lastSignedIn) {
      void refetchAuthData();
    } else {
      setIsAuthenticating(false);
    }
  }, [refetchSubscriptionPlan, refetchUserData, setIsAuthenticating]);

  useEffect(() => {
    if (userData && isUserDataSuccess && isSubscriptionPlanSettled) {
      if (!userData.customer) {
        signOut({ hardRedirect: false });
        navigate(anonymousRoutes.buildUrl('customerIdNotFound'));
      } else {
        signIn({
          user: userData.user,
          customer: userData.customer,
          hasSubscription: Boolean(subscriptionPlan),
        });
      }
    }
  }, [
    userData,
    isUserDataSuccess,
    signIn,
    isSubscriptionPlanSettled,
    subscriptionPlan,
    navigate,
    signOut,
  ]);

  useEffect(() => {
    if (isGetUserDetailsSettled && isSubscriptionPlanSettled) {
      setIsAuthenticating(false);
    }
  }, [isGetUserDetailsSettled, isSubscriptionPlanSettled, setIsAuthenticating]);

  return <>{children}</>;
};
