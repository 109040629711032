import * as sup from 'superstruct';

import { createResourceStruct } from '@/modules/api/utils/jsonApiStructs';

import { negotiationKeywordIdentifierStruct } from './negotiationKeywordIdentifierStruct';

export const negotiationKeywordResourceStruct = createResourceStruct({
  identifier: negotiationKeywordIdentifierStruct,
  attributes: sup.object({
    name: sup.string(),
    defaultValue: sup.string(),
    value: sup.string(),
  }),
});
