import { ReactNode } from 'react';
import { useFormState } from 'react-hook-form';

import { DefaultErrorMessage } from './DefaultErrorMessage';
import { DisplayErrorMessage } from './DisplayErrorMessage';
import { ErrorMessageComponent } from './ErrorMessage';

export interface InputErrorMessageProps {
  name: string;
  error?: string;
  errorMessages?: Record<string, ReactNode | undefined>;
  ErrorMessage?: ErrorMessageComponent;
  onErrorChange?: (error: string) => void;
}

export function InputErrorMessage(props: InputErrorMessageProps) {
  const {
    name,
    error,
    errorMessages = {},
    ErrorMessage = DefaultErrorMessage,
    onErrorChange,
  } = props;

  const { errors } = useFormState();

  const formError = errors[name]?.type?.toString();
  const appliedError = error || formError;

  return (
    <DisplayErrorMessage
      ErrorMessage={ErrorMessage}
      error={appliedError}
      errorMessages={errorMessages}
      onErrorChange={onErrorChange}
    />
  );
}
