import styled from 'styled-components';

import { blue, dark, mint, white } from '@/modules/theme/utils/colors';

const switchHeight = 31;
const switchWidth = 62;
const togglerDiameter = 27;

export const LabelStyled = styled('label')({
  position: 'relative',
  display: 'inline-block',
  width: switchWidth,
  height: switchHeight,
  cursor: 'pointer',

  '&:has(input:disabled)': {
    cursor: 'not-allowed',
  },
});

export const StyledInput = styled('input')({
  display: 'none',

  '&:checked + span': {
    backgroundColor: mint[800],
  },

  '&:checked:disabled + span': {
    backgroundColor: mint[600],
  },

  '&:checked:disabled ~ span': {
    color: dark[60],
  },

  '&:checked ~ span:before': {
    opacity: 1,
  },

  '&:checked ~ span:after': {
    opacity: 0,
  },

  '&:checked + span:before': {
    transform: `translateX(calc(${switchWidth}px - ${switchHeight}px))`,
  },

  '&:checked:disabled + span:before': {
    backgroundColor: dark[60],
  },
});

export const SliderStyled = styled('span')(({ theme: { borderRadius } }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  borderRadius: borderRadius.switch,
  backgroundColor: blue[800],
  transition: 'all 0.2s ease-in-out',

  '&:before': {
    content: "''",
    position: 'absolute',
    top: 2,
    left: 2,
    width: togglerDiameter,
    height: togglerDiameter,
    borderRadius: borderRadius.round,
    backgroundColor: white,
    transition: 'all 0.2s ease-in-out',
  },
}));

export const LabelsStyled = styled('span')(
  ({ theme: { spacing, typography } }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    fontSize: typography.pxToRem(12),
    fontWeight: typography.fontWeight.extraBold,
    textTransform: 'uppercase',
    transition: 'all 0.2s ease-in-out',
    color: white,

    '&:after': {
      content: 'attr(data-off)',
      position: 'absolute',
      top: '50%',
      transform: `translateY(-50%)`,
      right: spacing(1),
      opacity: 1,
      transition: 'all 0.2s ease-in-out',
    },

    '&:before': {
      content: 'attr(data-on)',
      position: 'absolute',
      top: '50%',
      transform: `translateY(-50%)`,
      left: spacing(1),
      opacity: 0,
      transition: 'all 0.2s ease-in-out',
    },
  })
);
