import { FC } from 'react';
import styled from 'styled-components';

import { CommonTypographyProps } from './helpers/types';

export type EmailLinkProps = CommonTypographyProps & {
  email: string;
  subject?: string;
  body?: string;
};

export const EmailLink: FC<EmailLinkProps> = ({
  email,
  subject,
  body,
  children,
  ...props
}) => {
  return (
    <EmailLinkStyled
      href={`mailto:${email}?subject=${subject || ''}&body=${body || ''}`}
      {...props}
    >
      {children}
    </EmailLinkStyled>
  );
};

const EmailLinkStyled = styled('a')<EmailLinkProps>(({
  theme: { typography, palette },
  color,
}) => {
  return {
    color: color ? color : palette.secondary.main,
    fontFamily: typography.fonts.body,
    fontSize: typography.fontSize.default,
    lineHeight: typography.fontSize.large,
    fontWeight: typography.fontWeight.normal,
    letterSpacing: typography.letterSpacing.default,
  };
});
