import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { NegotiationState } from '@/modules/negotiation/types/negotiationTypes';
import { notify } from '@/modules/notify/notify';
import { onboardedRoutes } from '@/modules/router/onboardedRoutes';

import { DashboardPage } from './DashboardPage';

export const DashboardScene = () => {
  const { t } = useTranslation('pages/DashboardPage');
  const { queryParams, queryParamsError } =
    onboardedRoutes.useGetQueryParams('dashboard');

  const setSearchParams = onboardedRoutes.useSetQueryParams('dashboard');

  const setNegotiationState = (negotiationState: NegotiationState[]) => {
    setSearchParams({
      state: negotiationState.length > 0 ? negotiationState : undefined,
    });
  };

  useEffect(() => {
    if (queryParamsError) {
      notify.alert.error(t('Invalid negotiation states'));
    }
  }, [queryParamsError, t]);

  if (queryParamsError) {
    return <Navigate to={onboardedRoutes.buildUrl('dashboard')} />;
  }

  return (
    <DashboardPage
      chosenNegotiationStates={queryParams.state ?? []}
      setNegotiationState={setNegotiationState}
    />
  );
};
