import { useTranslation } from 'react-i18next';

import { SupplierLanguageType } from './supplierLanguageConsts';

export const useGetSupplierLanguageLabels = (): Record<
  SupplierLanguageType,
  string
> => {
  const { t } = useTranslation('common/languages');

  return {
    no: t('Norwegian'),
    en: t('English'),
    sv: t('Swedish'),
  };
};
