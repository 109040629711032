import styled from 'styled-components';

import { Checkbox } from '@/modules/form/components/Checkbox/Checkbox';
import { Text } from '@/modules/theme/components/Typography/Text';

export const ErrorMessageStyled = styled(Text)(
  ({ theme: { palette, spacing, typography } }) => ({
    display: 'block',
    color: palette.error.main,
    position: 'absolute',
    bottom: spacing(1.5),
    lineHeight: typography.fontSize.default,
  })
);

export const CheckboxStyled = styled(Checkbox)({
  margin: 0,
});
