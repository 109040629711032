import { GetNegotiationKeywordsResponseType } from '../api/queries/getNegotiationKeywordsQuery';

export const parseNegotiationKeywordsQuery = (
  response: GetNegotiationKeywordsResponseType
) => {
  return {
    keywords: response.data.map(({ id, attributes }) => ({
      id,
      ...attributes,
    })),
    languages: response.meta.languages,
  };
};

export type ParsedNegotiationKeywords = ReturnType<
  typeof parseNegotiationKeywordsQuery
>['keywords'];
