import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { mutationKeys } from '@/modules/api/mutationKeys';
import { JsonApiClient } from '@/modules/api/utils/createJsonApiClient';
import { constructMeta } from '@/modules/reactQuery/constructMeta';
import { supplierResourceType } from '@/modules/suppliers/api/resources/supplier/supplierResourceConsts';

import { updateSupplierQueryCacheSelectedState } from '../helpers/updateSupplierQueryCacheSelectedState';

import { useUpdateSelectedSuppliersQueryCache } from './useUpdateSelectedSuppliersQueryCache';

type AddSelectedSupplierToNegotiationMutationPayload = {
  supplierId: string;
  negotiationId: string;
};

export const useAddSelectedSuppliersToNegotiationMutation = ({
  supplierId,
  negotiationId,
}: AddSelectedSupplierToNegotiationMutationPayload) => {
  const jsonApi = useJsonApi();
  const { t } = useTranslation(
    'apiMessages/useAddSelectedSuppliersToNegotiationMutation'
  );
  const { updateSelectedSuppliersQueryCache } =
    useUpdateSelectedSuppliersQueryCache({ negotiationId });

  return useMutation({
    mutationKey: [mutationKeys.suppliers.addSelectedSuppliersToNegotiation],
    mutationFn: addSuppliersToNegotiation(jsonApi),
    onSuccess: async () => {
      await updateSelectedSuppliersQueryCache((selectedSuppliersQueryCache) =>
        updateSupplierQueryCacheSelectedState({
          selectedSuppliersQueryCache,
          supplierId,
          selected: true,
        })
      );
    },
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t("You don't have access to add suppliers"),
        },
        404: {
          NEGOTIATION_NOT_FOUND: t(
            'Negotiation not found. Please refresh the page or contact the administrator'
          ),
        },
        422: {
          INVALID_NEGOTIATION_STATE: t('Invalid negotiation state'),
        },
      },
    }),
  });
};

type AddSuppliersToNegotiationPayload = {
  negotiationId: string;
  selectedSuppliers: string[];
};

const addSuppliersToNegotiation = (jsonApi: JsonApiClient) => {
  return ({
    selectedSuppliers,
    negotiationId,
  }: AddSuppliersToNegotiationPayload) => {
    const suppliersIdentifiers = selectedSuppliers.map((supplierId) => ({
      type: supplierResourceType,
      id: supplierId,
    }));

    return jsonApi.toMany.add({
      path: `me/customer/negotiations/${negotiationId}/relationships/selected_suppliers`,
      payload: suppliersIdentifiers,
    });
  };
};
