import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import { appConfig } from '@/modules/app/appConfig';
import { constructMeta } from '@/modules/reactQuery/constructMeta';

export const useUploadFileMutation = ({
  onSuccess,
}: {
  onSuccess?: () => void;
} = {}) => {
  const { t } = useTranslation('apiMessages/useUploadFileMutation');

  return useMutation({
    mutationFn: uploadFile,
    onSuccess,
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Your session has expired. Please sign in'),
        },
      },
    }),
  });
};

type UploadFileMutation = {
  url: string;
  file: File;
  headers: Record<string, string>;
};

const uploadFile = ({ url, file, headers }: UploadFileMutation) => {
  const urlObject = new URL(url);
  // we need to replace API base url with config base url (http://localhost:8081),
  // because request has to go via local proxy
  // it will be removed when files are sent directly to AWS
  const uploadUrl =
    appConfig.environment === 'local'
      ? `${appConfig.api.baseUrl}${urlObject.pathname}${urlObject.search}`
      : url;

  return axios.put(uploadUrl, file, {
    headers,
    withCredentials: true,
  });
};
