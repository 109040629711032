import * as sup from 'superstruct';

import { surveyFormStruct } from './surveyFormStruct';

export const serializeSpendAndSurveyValues = (
  values: sup.Infer<typeof surveyFormStruct>
) => {
  const {
    offerDiscount,
    lockPrice,
    signCodeOfConduct,
    extendInvoiceDueDate,
    answerSustainabilityQuestion,
    isoApproval,
    signStandardAgreement,
    categoryInfo,
    notes,
    company,
  } = values;

  const chosenCategoriesImportanceObject: Record<
    string,
    {
      numberOfSuppliers: number;
      totalSpend: number;
    }
  > = {};

  for (const key of categoryInfo) {
    chosenCategoriesImportanceObject[key.category] = {
      numberOfSuppliers: key.value.numberOfSuppliers,
      totalSpend: parseFloat(key.value.totalSpend.replace(/,/g, '')),
    };
  }

  return {
    companyName: company.name,
    fromEmail: company.email,
    currency: company.currency === 'MIX' ? undefined : company.currency,
    termImportance: {
      offerDiscount: offerDiscount ?? 'notImportant',
      lockPrice: lockPrice ?? 'notImportant',
      signCodeOfConduct: signCodeOfConduct ?? 'notImportant',
      extendInvoiceDueDate: extendInvoiceDueDate ?? 'notImportant',
      answerSustainabilityQuestion:
        answerSustainabilityQuestion ?? 'notImportant',
      isoApproval: isoApproval ?? 'notImportant',
      signStandardAgreement: signStandardAgreement ?? 'notImportant',
    },
    categoryInfo: {
      ...chosenCategoriesImportanceObject,
    },
    notes,
  };
};

export type SerializedSpendAndSurveyValues = ReturnType<
  typeof serializeSpendAndSurveyValues
>;
