import * as sup from 'superstruct';

import { createResourceStruct } from '@/modules/api/utils/jsonApiStructs';

import { negotiationMetricsIdentifierStruct } from './negotiationMetricsIdentifierStruct';

const countSpendStruct = sup.object({
  count: sup.integer(),
  spend: sup.string(),
});

export const negotiationMetricsResourceStruct = createResourceStruct({
  identifier: negotiationMetricsIdentifierStruct,
  attributes: sup.object({
    suppliersCount: sup.integer(),
    contactsCount: sup.integer(),
    openedEmailCount: sup.integer(),
    visitedCount: sup.integer(),
    registeredCount: sup.integer(),
    answeredCount: sup.integer(),
    currentSpend: sup.string(),
    potentialSavings: sup.string(),
    negotiatedSavings: sup.string(),
    potentialCostAvoidance: sup.string(),
    negotiatedCostAvoidance: sup.string(),
    estimatedDiscount: sup.number(),
    estimatedInflation: sup.number(),
    acceptedTermCounts: sup.object({
      signCodeOfConduct: sup.integer(),
      extendInvoiceDueDate: sup.integer(),
      extendContractPeriod: sup.integer(),
      lockPrice: sup.integer(),
      acceptContractPeriod: sup.integer(),
      answerSustainabilityQuestion: sup.integer(),
    }),
    supplierStates: sup.object({
      excluded: countSpendStruct,
      invited: countSpendStruct,
      openedEmail: countSpendStruct,
      visited: countSpendStruct,
      registered: countSpendStruct,
      answered: countSpendStruct,
    }),
    negotiatedSpend: sup.string(),
    discountCount: sup.integer(),
    averageDiscount: sup.number(),
    excludedCount: sup.integer(),
    openedEmailSpend: sup.string(),
    visitedSpend: sup.string(),
    registeredSpend: sup.string(),
    answeredSpend: sup.string(),
  }),
});

export type NegotiationMetricsType = sup.Infer<
  typeof negotiationMetricsResourceStruct
>;
