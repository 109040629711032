import { useTranslation } from 'react-i18next';
import * as sup from 'superstruct';

import { Form } from '@/modules//form/components/Form/Form';
import { TextInput } from '@/modules//form/components/TextInput/TextInput';
import { emailPattern, notEmpty } from '@/modules//form/utils/structs';
import { Dialog } from '@/modules/theme/components/Dialog/Dialog';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { FlexLayout } from '@/modules/theme/components/FlexLayout';
import { recompose } from '@/utils/structs/recompose';

import { ButtonStyled, FormStyled } from './ForgotPasswordDialog.styled';

const forgotPasswordStruct = sup.object({
  email: recompose(sup.string(), notEmpty, emailPattern),
});

export type ForgotPasswordValues = sup.Infer<typeof forgotPasswordStruct>;

type ForgotPasswordDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onValidSubmit: (values: ForgotPasswordValues) => void;
  isLoading: boolean;
};

export function ForgotPasswordDialog({
  isOpen,
  onClose,
  onValidSubmit,
  isLoading,
}: ForgotPasswordDialogProps) {
  const { t } = useTranslation('pages/SignInPage');

  const errorMessages = {
    email: {
      'string.notEmpty': t('This field is required'),
      'string.emailPattern': t('Invalid email address'),
    },
  };

  return (
    <Dialog
      isOpen={isOpen}
      hasCloseIcon
      onClose={onClose}
      title={t('Forgot password?')}
      description={t(
        'Don’t worry. Enter your email and we will send you link to reset your password.'
      )}
    >
      <FlexLayout direction="column">
        <FlexContainer justify="center">
          <FormStyled>
            <Form
              defaultValues={{
                email: '',
              }}
              struct={forgotPasswordStruct}
              onValidSubmit={onValidSubmit}
            >
              {({ register }) => {
                return (
                  <>
                    <TextInput
                      label={t('Email')}
                      type="email"
                      placeholder={t('you@company.com')}
                      errorMessages={errorMessages.email}
                      {...register('email')}
                    />

                    <ButtonStyled
                      type="submit"
                      size="fullWidth"
                      loading={isLoading}
                    >
                      {t('Send')}
                    </ButtonStyled>
                  </>
                );
              }}
            </Form>
          </FormStyled>
        </FlexContainer>
      </FlexLayout>
    </Dialog>
  );
}
