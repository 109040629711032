import { Dialog as BaseDialog } from '@headlessui/react';
import { MutableRefObject, ReactNode, useRef } from 'react';

import { Heading } from '../Typography';

import {
  CloseIconStyled,
  ContentWrapperStyled,
  DescriptionStyled,
  DialogVariant,
  OverlayStyled,
} from './Dialog.styled';

interface DialogProps {
  isOpen: boolean;
  hasCloseIcon?: boolean;
  onClose?: () => void;
  title?: string;
  description?: string;
  initialFocus?: MutableRefObject<HTMLElement | null> | undefined;
  children?: ReactNode;
  className?: string;
  variant?: DialogVariant;
}

export function Dialog({
  title,
  description,
  onClose = () => null,
  isOpen,
  hasCloseIcon,
  initialFocus,
  children,
  className,
  variant = 'default',
}: DialogProps) {
  const handleOnClose = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.preventDefault();
    onClose();
  };

  const dialogRef = useRef<null | HTMLDivElement>(null);

  return (
    <BaseDialog
      open={isOpen}
      onClose={onClose}
      initialFocus={initialFocus || dialogRef}
    >
      <OverlayStyled />
      <ContentWrapperStyled
        className={className}
        ref={dialogRef}
        variant={variant}
      >
        {hasCloseIcon && <CloseIconStyled onClick={handleOnClose} />}
        {title && <Heading variant="h3">{title}</Heading>}
        {description && (
          <DescriptionStyled as="p">{description}</DescriptionStyled>
        )}
        {children}
      </ContentWrapperStyled>
    </BaseDialog>
  );
}
