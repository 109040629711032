import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthenticatedLayout } from '@/layouts/AuthenticatedLayout/AuthenticatedLayout';
import { onboardedRoutes } from '@/modules/router/onboardedRoutes';
import { SupplierListIncludes } from '@/modules/suppliers/api/resources/supplier/supplierResourceConsts';
import {
  Order as ColumnOrder,
  SuppliersQueryParams,
  SupplierTableColumn,
} from '@/modules/suppliers/types/supplierTypes';
import { SuppliersOrder } from '@/modules/suppliers/utils/suppliersOrders';
import { LinkType } from '@/modules/theme/components/ButtonLink';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { HeaderButtonLink } from '@/modules/theme/components/HeaderButton';
import { MaxWidth } from '@/modules/theme/components/MaxWidth';
import { PageHeader } from '@/modules/theme/components/PageHeader/PageHeader';
import { PageMeta } from '@/modules/theme/components/PageMeta';
import { Pagination } from '@/modules/theme/components/Pagination/Pagination';
import { ShowPerPageDropdown } from '@/modules/theme/components/ShowPerPageDropdown/ShowPerPageDropdown';
import { Heading } from '@/modules/theme/components/Typography';

import { SuppliersTable } from './components/SuppliersTable/SuppliersTable';
import { useGetSuppliersQuery } from './hooks/useGetSuppliersQuery';
import {
  defaultPageNumber,
  useHandleSuppliersRoutingPagination,
} from './hooks/useHandleSuppliersRoutingPagination';
import {
  ContainerStyled,
  ContentHeaderStyled,
  HeaderPaginatorContainerStyled,
  PerPageContainerStyled,
  SkeletonLoaderStyled,
  TextStyled,
} from './SuppliersPage.styled';

const pageSizeOptions = [20, 50, 100].map((value) => ({
  value,
  label: value.toString(),
}));

type SuppliersPageProps = {
  queryParams: SuppliersQueryParams;
  setQueryParams: (params: Partial<SuppliersQueryParams>) => void;
};

export const SuppliersPage = ({
  queryParams,
  setQueryParams,
}: SuppliersPageProps) => {
  const { t } = useTranslation('pages/SuppliersPage');

  const {
    pageNumber,
    pageSize,
    column,
    order,
    handlePageSizeChange,
    setPageNumber,
    setSortRule,
  } = useHandleSuppliersRoutingPagination({ queryParams, setQueryParams });

  const sortRule = column && order ? { column, order } : null;

  const { data, isPending, isPlaceholderData } = useGetSuppliersQuery({
    pageNumber,
    pageSize,
    sort: sortRuleToSortOrderAdapter({ column, order }),
    include: [SupplierListIncludes.category, SupplierListIncludes.contacts],
  });

  const paginationProps = {
    perPage: pageSize,
    totalCount: data ? data.totalSuppliersCount : 0,
    onPageChange: (selectedPage: number) => setPageNumber(selectedPage + 1),
    forcePage: pageNumber - 1,
  };

  useEffect(() => {
    if (data && data.totalSuppliersCount !== 0) {
      const maxPage = Math.ceil(data.totalSuppliersCount / pageSize);
      if (pageNumber > maxPage) {
        setPageNumber(defaultPageNumber);
      }
    }
  }, [pageNumber, pageSize, data, setPageNumber]);

  return (
    <AuthenticatedLayout>
      <PageMeta
        title={t('Supplier overview')}
        description={t('Supplier overview')}
      />
      <PageHeader
        text={t('Supplier overview')}
        isLoading={isPending}
        buttonLink={
          <HeaderButtonLink
            to={onboardedRoutes.buildUrl('addSupplier')}
            linkType={LinkType.Internal}
          >
            <TextStyled>{t('Add supplier +')}</TextStyled>
          </HeaderButtonLink>
        }
      />
      <MaxWidth>
        <ContainerStyled>
          <ContentHeaderStyled>
            <Heading variant={'h3'}>{t('Suppliers')}</Heading>
            <HeaderPaginatorContainerStyled>
              {isPending ? (
                <SkeletonLoaderStyled />
              ) : (
                <Pagination {...paginationProps} />
              )}
            </HeaderPaginatorContainerStyled>
            <PerPageContainerStyled>
              <ShowPerPageDropdown
                options={pageSizeOptions}
                selectedPageSize={pageSize}
                onPageSizeChange={handlePageSizeChange}
                defaultValue={{
                  label: pageSize.toString(),
                  value: pageSize,
                }}
              />
            </PerPageContainerStyled>
          </ContentHeaderStyled>
          <SuppliersTable
            pageSize={pageSize}
            suppliers={data?.suppliers}
            sortRule={sortRule}
            setSortRule={setSortRule}
            isLoading={isPending || isPlaceholderData}
          />
          <FlexContainer justify="center" pt={7} pb={8.5}>
            {isPending ? (
              <SkeletonLoaderStyled />
            ) : (
              <Pagination {...paginationProps} />
            )}
          </FlexContainer>
        </ContainerStyled>
      </MaxWidth>
    </AuthenticatedLayout>
  );
};

type SortRuleToSortOrderAdapterParams = {
  column: SupplierTableColumn | undefined;
  order: ColumnOrder | undefined;
};

const sortRuleToSortOrderAdapter = ({
  column,
  order,
}: SortRuleToSortOrderAdapterParams): SuppliersOrder | undefined => {
  // LEGACY: SortOrder was created before typed query & route params refactor
  // so it uses clear React Router hooks to be able to save sorting state to URL.
  // Meantime, desings changed their way to filters (now in selects instead of clicking columns)
  // so this part is going to be refactored. Unfortunately, we have to
  // keep a backwards compatibility, in order to keep this view working "as it is".
  // Link to the new designs:
  // https://www.figma.com/file/MTutb1taqmLENwukksvua0/DigiProc-(internal)?type=design&node-id=10497-115117&mode=design&t=9cknWCsHXDnaq42w-0
  if (!column || !order) return undefined;

  if (order === ColumnOrder.desc) {
    return `-${column}`;
  }
  return column;
};
