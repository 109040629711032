import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { RichContentTerm } from '@/modules/negotiation/negotiationTerms/NegotiationTerms/components/RichContentTerm';
import {
  DiscountImplementationTerm,
  Implementation,
} from '@/modules/negotiation/types/negotiationTypes';
import { setupNegotiationRoutes } from '@/modules/router/onboardedRoutes';
import { BulletList } from '@/modules/theme/components/BulletList';
import { useDialogState } from '@/utils/useDialogState';

import { AddTermButton } from '../AddTermButton/AddTermButton';
import { NegotiationTermCard } from '../NegotiationTerms/NegotiationTermCard';

import { ChooseImplementationTypesDialog } from './ChooseImplementationTypesDialog/ChooseImplementationTypesDialog';
import { RemoveImplementationTypesDialog } from './RemoveImplementationTypesDialog/RemoveImplementationTypesDialog';

type ImplementationTypesProps = {
  term: DiscountImplementationTerm;
};

export const ImplementationTypes = ({ term }: ImplementationTypesProps) => {
  const { t } = useTranslation('pages/Terms');

  const { negotiationId } = setupNegotiationRoutes.useGetRouteParams('terms');

  const implementationTypesDialog = useDialogState();

  const removeImplementationTypesDialog = useDialogState();

  const implementationTranslations = {
    [Implementation.CREDIT_INVOICE]: t('Credit invoice'),
    [Implementation.INVOICE_DISCOUNT]: t('Invoice discount'),
    [Implementation.REDUCED_PRICES]: t('Reduced prices'),
  };

  return (
    <>
      {!term.implementations.length ? (
        <AddTermButton onClick={implementationTypesDialog.open}>
          {t('Add implementation +')}
        </AddTermButton>
      ) : (
        <NegotiationTermCard
          onEdit={implementationTypesDialog.open}
          onRemove={removeImplementationTypesDialog.open}
        >
          <div>
            <RichContentTerm text={term.text} />
            <BulletListStyled>
              {term.implementations.map((implementation) => (
                <li key={implementation}>
                  {implementationTranslations[implementation]}
                </li>
              ))}
            </BulletListStyled>
          </div>
        </NegotiationTermCard>
      )}
      <ChooseImplementationTypesDialog
        isOpen={implementationTypesDialog.isOpen}
        onClose={implementationTypesDialog.close}
        implementations={term.implementations}
        negotiationId={negotiationId}
        termId={term.id}
      />
      <RemoveImplementationTypesDialog
        isOpen={removeImplementationTypesDialog.isOpen}
        onClose={removeImplementationTypesDialog.close}
        negotiationId={negotiationId}
        termId={term.id}
      />
    </>
  );
};

const BulletListStyled = styled(BulletList)(
  ({ theme: { typography, spacing } }) => ({
    margin: spacing([2, 0, 0, 2]),
    fontWeight: typography.fontWeight.bold,
    lineHeight: typography.fontSize.large,
    letterSpacing: typography.letterSpacing.default,
  })
);
