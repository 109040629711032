import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { Heading } from '@/modules/theme/components/Typography';
import { mint, white } from '@/modules/theme/utils/colors';

import { PresentationSlider } from '../IntroPage/components/PresentationSlider/PresentationSlider';

import {
  DynamicTabsCover,
  DynamicTabsTextContainer,
} from './DynamicTabs.styled';
import useAnalysisDynamicTabs from './useAnalysisDynamicTabs';

const DynamicTabs = () => {
  const { dynamicTab } = useParams();
  const analysisDynamicTabs = useAnalysisDynamicTabs();

  const tab = useMemo(() => {
    return analysisDynamicTabs?.tabs.find((val) => val.path === dynamicTab);
  }, [dynamicTab, analysisDynamicTabs?.tabs]);

  if (!tab) {
    return null;
  }

  return (
    <>
      <DynamicTabsCover backgroundUrl={tab.image || ''}>
        <DynamicTabsTextContainer>
          <Heading variant="h2" color={mint[400]}>
            {tab.title}
          </Heading>
          <Heading variant="h3" color={white}>
            {tab.description}
          </Heading>
        </DynamicTabsTextContainer>
      </DynamicTabsCover>
      <PresentationSlider presentationUrl={tab.pdfLink} />
    </>
  );
};

export default DynamicTabs;
