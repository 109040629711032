import { ReactNode } from 'react';

import { ReactComponent as EditIcon } from '@/assets/icons/editIcon.svg';
import { ReactComponent as MinusIcon } from '@/assets/icons/minusIcon.svg';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { ActionButton } from '@/modules/theme/components/Table/ActionButton.styled';

import {
  CheckRoundIconStyled,
  NegotiationTermCardWrapperStyled,
} from './NegotiationTermCard.styled';

type NegotiationTermCardProps = {
  children: ReactNode;
  onEdit?: () => void;
  onRemove?: () => void;
};

export const NegotiationTermCard = ({
  children,
  onEdit,
  onRemove,
}: NegotiationTermCardProps) => {
  return (
    <NegotiationTermCardWrapperStyled>
      <CheckRoundIconStyled />
      {children}
      <FlexContainer align="center" gap={2} justify="flex-end">
        {onEdit && (
          <ActionButton onClick={onEdit}>
            <EditIcon />
          </ActionButton>
        )}
        {onRemove && (
          <ActionButton onClick={onRemove}>
            <MinusIcon />
          </ActionButton>
        )}
      </FlexContainer>
    </NegotiationTermCardWrapperStyled>
  );
};
