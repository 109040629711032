import { useEffect, useState } from 'react';

export const useDetectEnglishBrowser = () => {
  const [isEnglishBrowser, setIsEnglishBrowser] = useState(false);

  useEffect(() => {
    setIsEnglishBrowser(window.navigator.language.startsWith('en'));
  }, []);

  return isEnglishBrowser;
};
