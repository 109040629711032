import { Menu } from '@headlessui/react';
import styled from 'styled-components';

import { white } from '@/modules/theme/utils/colors';

export const MenuButtonStyled = styled(Menu.Button)(
  ({ theme: { spacing, palette, zIndexes } }) => ({
    appearance: 'none',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    color: palette.default.text,
    height: spacing(3),
    width: spacing(3),
    padding: 0,
    position: 'relative',
    zIndex: zIndexes.tooltip,
  })
);

export const MenuItemsContentStyled = styled('div')(
  ({ theme: { borderRadius, boxShadows, spacing, zIndexes } }) => ({
    position: 'absolute',
    right: spacing(3),
    width: 220,
    background: white,
    borderRadius: borderRadius.default,
    boxShadow: boxShadows.card,
    padding: spacing([1.5, 0]),
    zIndex: zIndexes.tooltip + 1,
  })
);

export const MenuItemButtonStyled = styled('button')(
  ({ theme: { typography, spacing, palette } }) => ({
    color: palette.default.text,
    fontFamily: typography.fonts.body,
    fontSize: typography.fontSize.default,
    fontWeight: typography.fontWeight.bold,
    padding: spacing([0.5, 2]),
    appearance: 'none',
    backgroundColor: white,
    border: 'none',
    cursor: 'pointer',

    '&:disabled': {
      cursor: 'not-allowed',
      color: palette.disabled.text,
    },
  })
);
