import * as sup from 'superstruct';

import { ContactPersonFormType } from '@/modules/suppliers/components/ContactPersonFormDialog/contactPersonFormStruct';
import { createComposableRefinement } from '@/utils/structs/createComposableRefinement';
import { dynamicStruct } from '@/utils/structs/dynamicStruct';

/**
 * It only checks if given string contains @ between at least two other
 * characters
 */
export const emailPattern = createComposableRefinement<string, null>(
  'emailPattern',
  (value) =>
    value.length === 0 ||
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(
      value
    )
);

export const notEmpty = createComposableRefinement<string, null>(
  'notEmpty',
  (value) => value !== ''
);

export const passwordPattern = createComposableRefinement<string, null>(
  'passwordPattern',
  (value) =>
    value.length === 0 ||
    /^(?=.*[0-9])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*()_\-=+,<.>/?\\|'":;{}[\]`~]{6,}$/.test(
      value
    )
);

export const isTrue = createComposableRefinement<boolean, null>(
  'isTrue',
  (value) => value === true
);

export const areEqual = <Struct, Type>(
  fieldKey: Struct extends sup.Struct<infer Values> ? keyof Values : never
) => {
  return createComposableRefinement<Type, null>(
    'areEqual',
    (
      value,
      ctx: {
        branch: Array<Struct extends sup.Struct<infer Values> ? Values : never>;
        path: string[];
      }
    ) => typeof fieldKey === 'string' && value === ctx.branch[0][fieldKey]
  );
};

export const atLeastOne = <T1, T2>(
  struct1: sup.Struct<T1, null>,
  struct2: sup.Struct<T2, null>
) => {
  return dynamicStruct((value, ctx) => {
    const parent: ContactPersonFormType = ctx.branch[0];
    if (!Object.values(parent).find((value) => Boolean(value))) {
      return struct1;
    }
    return struct2;
  });
};

export const dateTime = sup.coerce(
  sup.date(),
  sup.string(),
  (value) => new Date(value)
);

export const isFutureDate = createComposableRefinement<string, null>(
  'isFutureDate',
  (value) => new Date(value).getTime() >= new Date().getTime()
);

export const isValidEndDate = <Struct, Type>(
  fieldKey: Struct extends sup.Struct<infer Values> ? keyof Values : never
) => {
  return createComposableRefinement<Type, null>(
    'isValidEndDate',
    (
      value,
      ctx: {
        branch: Array<Struct extends sup.Struct<infer Values> ? Values : never>;
        path: string[];
      }
    ) => typeof fieldKey === 'string' && value >= ctx.branch[0][fieldKey]
  );
};

export const isNotOnlyWhitespace = createComposableRefinement<string, null>(
  'isNotOnlyWhitespace',
  (value) => value.trim().length > 0
);
