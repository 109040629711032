import { ReactNode } from 'react';

import { Heading, Text } from '@/modules/theme/components/Typography';
import { white } from '@/modules/theme/utils/colors';

import {
  HeaderStyled,
  MaxWidthStyled,
  PageHeaderVariant,
  SkeletonLoaderStyled,
} from './PageHeader.styled';

type PageHeaderProps = {
  text: string;
  isLoading?: boolean;
  children?: string;
  variant?: PageHeaderVariant;
  buttonLink?: ReactNode;
};

export const PageHeader = ({
  text,
  isLoading,
  children,
  variant = 'color',
  buttonLink,
}: PageHeaderProps) => {
  return (
    <HeaderStyled variant={variant}>
      <MaxWidthStyled>
        {isLoading ? (
          <SkeletonLoaderStyled />
        ) : (
          <>
            <Heading color={white} variant="h3" as="h1">
              {text}
            </Heading>
            <Text bold color={white}>
              {children}
            </Text>
            {buttonLink}
          </>
        )}
      </MaxWidthStyled>
    </HeaderStyled>
  );
};
