import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
interface PageMetaProps {
  title: string;
  description?: string;
}

export function PageMeta({ title, description }: PageMetaProps) {
  useEffect(() => {
    if (
      // eslint-disable-next-line no-restricted-globals
      process.env.NODE_ENV !== 'production' &&
      description &&
      description.length > 155
    ) {
      // https://moz.com/learn/seo/meta-description
      // eslint-disable-next-line no-console
      console.warn('Description should have max. 155 characters length');
    }
  }, [description]);

  return (
    <Helmet>
      <title>{title}</title>
      {description && <meta name="description" content={description} />}
    </Helmet>
  );
}
