import styled from 'styled-components';

import { Card } from '@/modules/theme/components/Card';
import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';

import { EDITOR_MIN_HEIGHT } from './editorConsts';

export const EditorCardStyled = styled(Card)(({ theme: { spacing } }) => ({
  minHeight: EDITOR_MIN_HEIGHT,
  maxWidth: spacing(91),
  padding: spacing(0),
}));

export const EditorWrapperStyled = styled('div')(({ theme: { spacing } }) => ({
  padding: spacing([0, 2]),
  '.tox-tinymce': {
    border: 0,
    borderRadius: 0,
    '.tox-statusbar': {
      borderTop: 0,
    },
  },
}));

export const SkeletonLoaderStyled = styled(SkeletonLoader)({
  minHeight: EDITOR_MIN_HEIGHT,
  marginBottom: 0,
});
