import { useTranslation } from 'react-i18next';

import { useSessionContext } from '@/modules/auth/hooks/useSessionContext';
import {
  BottomDataWrapperStyled,
  DataContainerStyled,
  NegotiationDataPanelWrapperStyled,
  NumberOfSuppliersStyled,
  PanelContainerStyled,
  SignetStyled,
  SpendStyled,
} from '@/modules/negotiation/components/NegotiationDataPanel/NegotiationDataPanel.styled';
import { ParsedNegotiation } from '@/modules/negotiation/helpers/parseGetNegotiationResponse';
import { getNegotiationDetailsMetrics } from '@/modules/negotiation/metrics/getNegotiationDetailsMetrics';
import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';
import { PlannedNegotiationMetricsLoader } from '@/pages/PlannedNegotiationPage/components/PlannedNegotiationMetricsLoader';
import { formatAmount } from '@/utils/stringUtils';

import { NegotiationSetupMetrics } from './NegotiationSetupMetrics';

type NegotiationSetupDataPanelProps = {
  negotiation: ParsedNegotiation | undefined;
  shouldDisplayLoader: boolean | undefined;
};

export const NegotiationSetupDataPanel = ({
  shouldDisplayLoader = false,
  negotiation,
}: NegotiationSetupDataPanelProps) => {
  const { t } = useTranslation('pages/NegotiationSetupPage');
  const { customer } = useSessionContext();

  return (
    <NegotiationDataPanelWrapperStyled>
      <PanelContainerStyled>
        <DataContainerStyled>
          {shouldDisplayLoader ? (
            <PlannedNegotiationMetricsLoader />
          ) : (
            negotiation && (
              <NegotiationSetupMetrics
                metrics={negotiation.metrics}
                metricsToDisplay={getNegotiationDetailsMetrics(negotiation)}
                termRoles={negotiation.termRoles}
              />
            )
          )}
          <SignetStyled />
        </DataContainerStyled>
        {shouldDisplayLoader ? (
          <SkeletonLoader height={33} />
        ) : (
          <BottomDataWrapperStyled>
            <NumberOfSuppliersStyled variant="h3" as="span">
              {t('{{count}} suppliers', {
                count: negotiation?.metrics.suppliersCount,
              })}
            </NumberOfSuppliersStyled>
            <SpendStyled>
              {t('Spend {{spend}} {{currency}}', {
                spend: formatAmount(
                  negotiation ? negotiation.metrics.currentSpend : 0
                ),
                currency: customer.currency,
              })}
            </SpendStyled>
          </BottomDataWrapperStyled>
        )}
      </PanelContainerStyled>
    </NegotiationDataPanelWrapperStyled>
  );
};
