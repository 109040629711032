import styled from 'styled-components';

import { Heading, Paragraph } from '@/modules/theme/components/Typography';
import { blue, mint, orange } from '@/modules/theme/utils/colors';
import { formatAmount } from '@/utils/stringUtils';

type NegotiationMetricProps = {
  heading: string;
  data: string | number;
  variant?: 'financial' | 'counter';
};
export const NegotiationMetric = ({
  heading,
  data,
  variant = 'financial',
}: NegotiationMetricProps) => {
  return (
    <NegotiationMetricStyled>
      <Paragraph variant="contentBold" as="h4" color={blue[800]}>
        {heading}
      </Paragraph>
      <Heading
        variant="h2"
        as="span"
        color={variant === 'financial' ? orange[800] : mint[800]}
      >
        {formatAmount(data)}
      </Heading>
    </NegotiationMetricStyled>
  );
};

const NegotiationMetricStyled = styled('div')(({ theme: { spacing } }) => ({
  paddingBottom: spacing(2),
  position: 'relative',
}));
