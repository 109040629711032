import styled from 'styled-components';

import { mint } from '@/modules/theme/utils/colors';

export const NegotiationTermsPanelWrapperStyled = styled('div')(
  ({ theme: { spacing, breakpoints } }) => ({
    [breakpoints.down.lg]: {
      marginRight: spacing(2),
    },

    [breakpoints.down.md]: {
      marginRight: 0,
      width: '100%',
    },
  })
);

export const AddOnsListStyled = styled('ul')(({ theme: { spacing } }) => ({
  marginTop: spacing(2),
  padding: 0,
}));

export const AddOnStyled = styled('li')(
  ({ theme: { spacing, typography } }) => ({
    color: mint[800],
    marginTop: spacing(1),
    fontWeight: typography.fontWeight.bold,
    lineHeight: 1.5,
  })
);
