import { InfiniteQueryObserverResult } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';
import * as sup from 'superstruct';

import { useSessionContext } from '@/modules/auth/hooks/useSessionContext';
import { Checkbox } from '@/modules/form/components/Checkbox/Checkbox';
import { Form } from '@/modules/form/components/Form/Form';
import { ParsedSuppliers } from '@/modules/suppliers/helpers/parseGetSuppliersResponse';
import { Button } from '@/modules/theme/components/Button/Button';
import { Scrollbar } from '@/modules/theme/components/Scrollbar';
import { Table } from '@/modules/theme/components/Table/Table';
import { TableCell } from '@/modules/theme/components/Table/TableCell';
import { TableRow } from '@/modules/theme/components/Table/TableRow';
import { TableSkeletonLoader } from '@/modules/theme/components/TableSkeletonLoader/TableSkeletonLoader';
import { Text } from '@/modules/theme/components/Typography';
import { formatAmount } from '@/utils/stringUtils';

import { useAddSuppliersToNegotiationMutation } from '../../hooks/useAddSuppliersToNegotiationMutation';

type AddSupplierFormProps = {
  suppliers: ParsedSuppliers['suppliers'];
  onNextPage: () => Promise<InfiniteQueryObserverResult>;
  isFetchingSuppliers: boolean;
  onClose: () => void;
  negotiationId: string;
};

const addSuppliersStruct = sup.object({
  selectedSuppliers: sup.array(sup.string()),
});

type AddSuppliersStructValues = sup.Infer<typeof addSuppliersStruct>;

export const AddSupplierForm = ({
  suppliers,
  onNextPage,
  onClose,
  negotiationId,
  isFetchingSuppliers,
}: AddSupplierFormProps) => {
  const { t } = useTranslation('pages/SuppliersTab');
  const {
    customer: { currency },
  } = useSessionContext();

  const nextPageSentinel = useInView();

  const addSuppliersToNegotiationMutation =
    useAddSuppliersToNegotiationMutation();

  const addSuppliers = ({ selectedSuppliers }: AddSuppliersStructValues) => {
    addSuppliersToNegotiationMutation.mutate(
      {
        negotiationId,
        selectedSuppliers,
      },
      { onSuccess: onClose }
    );
  };

  const headers = {
    company: t('Company'),
    category: t('Category'),
    spend: t('Spend'),
    selected: t('Selected'),
  } as const;

  useEffect(() => {
    const fetchNextSuppliersPage = async () => {
      if (nextPageSentinel.inView && !isFetchingSuppliers) {
        await onNextPage();
      }
    };
    void fetchNextSuppliersPage();
  }, [onNextPage, nextPageSentinel.inView, isFetchingSuppliers]);

  return (
    <Form
      defaultValues={{
        selectedSuppliers: [],
      }}
      struct={addSuppliersStruct}
      onValidSubmit={addSuppliers}
    >
      {({ register, watch }) => {
        const selectedSuppliers = watch('selectedSuppliers');

        return (
          <>
            <FormContentWrapperStyled>
              <Scrollbar>
                <TableStyled headers={Object.values(headers)}>
                  {suppliers.map((supplier) => (
                    <TableRow
                      key={supplier.id}
                      variant={
                        selectedSuppliers.includes(supplier.id)
                          ? 'default'
                          : 'transparent'
                      }
                    >
                      <TableCell mobileHeader={headers.company}>
                        <Text bold>{supplier.name}</Text>
                      </TableCell>
                      <TableCell mobileHeader={headers.category}>
                        <Text>{supplier.category?.name}</Text>
                      </TableCell>
                      <TableCell mobileHeader={headers.spend}>
                        <Text>
                          {`${formatAmount(supplier.currentSpend)} ${currency}`}
                        </Text>
                      </TableCell>
                      <TableCell mobileHeader={headers.selected}>
                        <Checkbox
                          id={supplier.id}
                          value={supplier.id}
                          {...register('selectedSuppliers')}
                          hideError
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableStyled>

                {isFetchingSuppliers && <TableSkeletonLoader rows={20} />}
                <div ref={nextPageSentinel.ref} />
              </Scrollbar>
            </FormContentWrapperStyled>

            <ConfirmButtonStyled
              type="submit"
              loading={addSuppliersToNegotiationMutation.isPending}
            >
              {t('Confirm')}
            </ConfirmButtonStyled>
          </>
        );
      }}
    </Form>
  );
};

export const FormContentWrapperStyled = styled('div')(
  ({ theme: { spacing } }) => ({
    height: spacing(50),
  })
);

export const TableStyled = styled(Table)({
  gridTemplateColumns: 'repeat(3, 1fr) 0.4fr',
});

export const ConfirmButtonStyled = styled(Button)(({ theme: { spacing } }) => ({
  display: 'block',
  margin: `${spacing(7)} auto 0`,
}));
