import { LANG_CODE } from '@/utils/consts';

export const financialCompactFormatter = {
  format: (val: number) => {
    return (
      Intl.NumberFormat(LANG_CODE.EN_US, {
        notation: 'compact',
      })
        .format(val)
        // We need custom formatter to separate number and unit:
        // For argument: 100 000 000
        // $1 -> 100
        // $2 -> M
        .replace(/([\d.]+)(\D+)?/g, '$1 $2')
    );
  },
};
