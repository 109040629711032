export const termImportance = {
  notImportant: 'notImportant',
  interesting: 'interesting',
  important: 'important',
} as const;

export const categories = [
  'Professional services',
  'IT and telecom',
  'Facility management services',
  'Sales and marketing',
  'Logistics/Transportation',
  'Maintenance, repair and operations (MRO)',
  'Travels and conferences',
  'Vehicles',
  'Equipment and machinery',
  'Raw material',
  'Manufacturing and components',
  'Groceries, food and drinks',
  'Other',
] as const;
