import styled from 'styled-components';

import { Tooltip } from '@/modules/theme/components/Tooltip/Tooltip';

import { ReactComponent as WarningIcon } from './warningIcon.svg';

type WarningTooltipProps = {
  content: string;
};

export const WarningTooltip = ({ content }: WarningTooltipProps) => {
  return (
    <TooltipStyled tooltipContent={content} placement="right-start">
      <WarningIcon />
    </TooltipStyled>
  );
};

const TooltipStyled = styled(Tooltip)(({ theme: { spacing, zIndexes } }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  right: spacing(-5),
  zIndex: zIndexes.tooltip,
}));
