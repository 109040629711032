import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DefaultCheckbox } from '@/modules/form/components/Checkbox/DefaultCheckbox';
import { Button } from '@/modules/theme/components/Button/Button';
import { Dialog } from '@/modules/theme/components/Dialog/Dialog';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';

import {
  ContentWrapperStyled,
  ParagraphStyled,
  TextStyled,
} from './ActivateNegotiationDialog.styled';
import { useActivateNegotiationMutation } from './useActivateNegotiationMutation';

type ActivateNegotiationDialogProps = {
  isOpen: boolean;
  negotiationId: string;
  onClose: () => void;
};

export const ActivateNegotiationDialog = ({
  isOpen,
  negotiationId,
  onClose,
}: ActivateNegotiationDialogProps) => {
  const { t } = useTranslation('pages/NegotiationSetupPage');

  const [isNegotiationComplete, setIsNegotiationComplete] = useState(false);

  const activateNegotiationMutation = useActivateNegotiationMutation();

  const activateNegotiation = () => {
    activateNegotiationMutation.mutate(
      { negotiationId },
      { onSuccess: onClose }
    );
  };

  return (
    <Dialog
      isOpen={isOpen}
      hasCloseIcon
      onClose={onClose}
      title={t('Activate negotiation')}
      variant="large"
    >
      <ParagraphStyled>
        {t(
          'Be sure that all the settings are correct and confirm that you are ready to start the negotiation.'
        )}
      </ParagraphStyled>
      <ContentWrapperStyled>
        <DefaultCheckbox
          id="isNegotiationComplete"
          checked={isNegotiationComplete}
          onChange={() => setIsNegotiationComplete((prevState) => !prevState)}
        >
          <TextStyled>
            {t(
              'I have checked all the settings and I am ready to start the negotiation.'
            )}
          </TextStyled>
        </DefaultCheckbox>
        <FlexContainer direction="column" gap={2} mt={5}>
          <Button variant="outline" onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button
            disabled={!isNegotiationComplete}
            onClick={activateNegotiation}
            loading={activateNegotiationMutation.isPending}
          >
            {t('Activate')}
          </Button>
        </FlexContainer>
      </ContentWrapperStyled>
    </Dialog>
  );
};
