import styled from 'styled-components';

import { Dialog } from '@/modules/theme/components/Dialog/Dialog';

export const FormContentStyled = styled('div')(
  ({ theme: { breakpoints, spacing } }) => ({
    minWidth: 430,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: spacing(3),

    [breakpoints.down.md]: {
      minWidth: 'auto',
    },
  })
);

export const DialogStyled = styled(Dialog)(({ theme: { breakpoints } }) => ({
  [breakpoints.down.md]: {
    width: 'auto',
  },
}));

export const FormErrorStyled = styled('div')(
  ({ theme: { palette, typography, spacing } }) => ({
    color: palette.error.main,
    margin: 0,
    fontSize: typography.fontSize.extraSmall,
    alignSelf: 'flex-start',
    height: spacing(1.5),
    marginTop: spacing(4),
  })
);
