import { useTranslation } from 'react-i18next';

import { useSessionContext } from '@/modules/auth/hooks/useSessionContext';
import { ParsedNegotiation } from '@/modules/negotiation/helpers/parseGetNegotiationResponse';
import { NegotiationTermRole } from '@/modules/negotiation/types/negotiationTypes';
import { formatPercentage } from '@/modules/negotiation/utils/formatPercentage';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';
import {
  Heading,
  Paragraph,
  Text,
} from '@/modules/theme/components/Typography';
import { financialCompactFormatter } from '@/utils/financialCompactFormatter';

import {
  ContentWrapperStyled,
  SelectedSupplierMetricsContainer,
} from './SelectedSupplierMetrics.styled';

type SelectedSuppliersMetricsProps = {
  metrics: ParsedNegotiation['metrics'] | undefined;
  termRoles: ParsedNegotiation['termRoles'] | undefined;
  isFetching: boolean;
  isError: boolean;
};

export const SelectedSuppliersMetrics = ({
  metrics,
  termRoles,
  isFetching,
  isError,
}: SelectedSuppliersMetricsProps) => {
  const { t } = useTranslation('pages/SuggestedNegotiationsPage');
  const { customer } = useSessionContext();

  if (isFetching) {
    return (
      <SelectedSupplierMetricsContainer>
        <FlexContainer direction="column" gap={0}>
          <SkeletonLoader width={400} />
          <SkeletonLoader width={400} />
          <SkeletonLoader width={400} />
          <SkeletonLoader width={400} />
        </FlexContainer>
      </SelectedSupplierMetricsContainer>
    );
  }

  if (!metrics || !termRoles || isError) {
    return (
      <>
        <Heading variant="h3">{t('Something went wrong')}</Heading>
        <Paragraph>
          {t('Refresh the page or contact the administrator.')}
        </Paragraph>
      </>
    );
  }
  const isSavings = termRoles.includes(NegotiationTermRole.OFFER_DISCOUNT);
  const isAntiInflation = termRoles.includes(NegotiationTermRole.LOCK_PRICE);

  const spend = financialCompactFormatter.format(
    parseInt(metrics.currentSpend)
  );
  const estimatedSavings = financialCompactFormatter.format(
    parseInt(metrics.potentialSavings)
  );
  const estimatedCostAvoidance = financialCompactFormatter.format(
    parseInt(metrics.potentialCostAvoidance)
  );

  const estimatedDiscountPercentage = formatPercentage(
    metrics.estimatedDiscount * 100
  );
  const estimatedAntiInflationPercentage = formatPercentage(
    metrics.estimatedInflation * 100
  );

  return (
    <SelectedSupplierMetricsContainer>
      <ContentWrapperStyled>
        <Text bold>{t('Spend')}</Text>
        <Text bold>
          {spend}
          {customer.currency}
        </Text>
        <Text bold>{t('Suppliers')}</Text>
        <Text bold>{metrics.suppliersCount}</Text>
        {isSavings && (
          <>
            <Text bold>{t('Estimated savings')}</Text>
            <Text bold>
              {estimatedSavings} {customer.currency} (
              {estimatedDiscountPercentage}%)
            </Text>
          </>
        )}
        {isAntiInflation && (
          <>
            <Text bold>{t('Estimated cost avoidance')}</Text>
            <Text bold>
              {estimatedCostAvoidance} {customer.currency} (
              {estimatedAntiInflationPercentage}%)
            </Text>
          </>
        )}
      </ContentWrapperStyled>
    </SelectedSupplierMetricsContainer>
  );
};
