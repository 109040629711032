import toast, { Toast as RHTToastProps } from 'react-hot-toast';

import { ToastVariant } from '../types';

import {
  CloseButtonStyled,
  ContainerStyled,
  ContentStyled,
} from './AlertToast.styled';

export const AlertToast = ({
  rhtToastProps,
  variant,
  content,
}: {
  rhtToastProps: RHTToastProps;
  variant: ToastVariant;
  content: string;
}) => {
  const handleCancelClick = () => {
    toast.dismiss(rhtToastProps.id);
  };
  return (
    <ContainerStyled
      variant={variant}
      visible={rhtToastProps.visible}
      {...rhtToastProps.ariaProps}
    >
      <ContentStyled>{content}</ContentStyled>
      <CloseButtonStyled onClick={handleCancelClick} />
    </ContainerStyled>
  );
};
