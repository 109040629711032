import { JsonApiClient } from '@/modules/api/utils/createJsonApiClient';

import { getNegotiationSuppliersResponseStruct } from '../../helpers/getNegotiationSuppliersResponseStruct';
import { parseGetNegotiationSuppliersResponse } from '../../helpers/parseGetNegotiationSuppliersResponse';
import { GetNegotiationSuppliersPayload } from '../resources/negotiationSelectedSuppliers/negotiationSelectedSuppliersResourceConsts';

export const getNegotiationSuppliersQuery = (
  jsonApi: JsonApiClient,
  {
    negotiationId,
    negotiationStates,
    searchQuery,
    businessUnit,
    sort,
    category,
    pageSize,
    page,
    includes,
  }: GetNegotiationSuppliersPayload
) => {
  return async () => {
    const queryParams = {
      'filter[negotiationState]': negotiationStates?.join(','),
      'filter[query]': searchQuery,
      'filter[businessUnit]': businessUnit?.join(','),
      sort,
      'filter[category]': category?.join(','),
      'page[size]': pageSize,
      'page[number]': page,
      include: includes?.join(','),
    };

    const response = await jsonApi.getMany({
      path: `/me/customer/negotiations/${negotiationId}/suppliers`,
      queryParams,
      responseStruct: getNegotiationSuppliersResponseStruct,
    });

    return parseGetNegotiationSuppliersResponse(response);
  };
};
