import styled from 'styled-components';

import { Paragraph } from '@/modules/theme/components/Typography';
import { dark } from '@/modules/theme/utils/colors';

export const ParagraphStyled = styled(Paragraph)(
  ({ theme: { spacing, typography } }) => ({
    color: dark[400],
    fontSize: typography.fontSize.large,
    textAlign: 'center',
    fontWeight: typography.fontWeight.bold,
    margin: spacing([3, 0]),
  })
);

export const ButtonLinksWrapperStyled = styled('div')(
  ({ theme: { spacing, breakpoints } }) => ({
    display: 'flex',
    gap: spacing(2),
    marginLeft: 'auto',

    [breakpoints.down.md]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      marginLeft: 0,
    },
  })
);
