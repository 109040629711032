import {
  BusinessUnitResource,
  businessUnitResourceType,
} from '@/modules/customer/api/businessUnits/businessUnitsHelpers';
import { negotiationResourceType } from '@/modules/negotiation/api/resources/negotiation/negotiationResourceConsts';
import { NegotiationResourceType } from '@/modules/negotiation/api/resources/negotiation/negotiationResourceStruct';

import { GetSupplierResponse } from '../api/queries/getSupplierQuery';
import { supplierCategoryResourceType } from '../api/resources/supplierCategory/supplierCategoryResourceConsts';
import { SupplierCategory } from '../api/resources/supplierCategory/supplierCategoryResourceStruct';
import { supplierContactResourceType } from '../api/resources/supplierContact/supplierContactResourceConsts';
import { SupplierContact } from '../api/resources/supplierContact/supplierContactResourceStruct';

import { getCategoriesHierarchy } from './getCategoriesHierarchy';

export function parseGetSupplierResponse(response: GetSupplierResponse) {
  const {
    data: {
      id,
      attributes: { name, currentSpend, organizationNumber, language },
      relationships,
    },
    included,
  } = response;

  const businessUnitData = included.find(
    (item): item is BusinessUnitResource =>
      item.id === relationships.businessUnit.data?.id &&
      item.type === businessUnitResourceType
  );

  const businessUnit = businessUnitData
    ? { id: businessUnitData.id, name: businessUnitData.attributes.name }
    : null;

  const selectedContactPersonData = included.find(
    (item): item is SupplierContact =>
      item.id === relationships.defaultContact.data?.id &&
      item.type === supplierContactResourceType
  );
  const selectedContactPerson = selectedContactPersonData
    ? {
        id: selectedContactPersonData.id,
        firstName: selectedContactPersonData.attributes.firstName,
        lastName: selectedContactPersonData.attributes.lastName,
        email: selectedContactPersonData.attributes.email,
        phoneNumber: selectedContactPersonData.attributes.phoneNumber,
      }
    : null;

  const categoryData = included.find(
    (item): item is SupplierCategory =>
      item.id === relationships.category.data?.id &&
      item.type === supplierCategoryResourceType
  );

  const categoriesHierarchy = categoryData
    ? getCategoriesHierarchy(categoryData, included).map((category) => ({
        name: category.attributes.name,
        id: category.id,
      }))
    : null;

  const negotiationsIds = relationships.negotiations?.data.map(({ id }) => id);

  const currentNegotiations = negotiationsIds
    ? included
        .filter(
          (item): item is NegotiationResourceType =>
            negotiationsIds.includes(item.id) &&
            item.type === negotiationResourceType &&
            item.attributes.state === 'active'
        )
        .map(({ id, attributes }) => ({
          id,
          ...attributes,
        }))
    : [];

  const completedNegotiations = negotiationsIds
    ? included
        .filter(
          (item): item is NegotiationResourceType =>
            negotiationsIds.includes(item.id) &&
            item.type === negotiationResourceType &&
            item.attributes.state === 'completed'
        )
        .map(({ id, attributes }) => ({
          id,
          ...attributes,
        }))
    : [];

  const contacts = included
    .filter((item): item is SupplierContact => {
      return item.type === supplierContactResourceType;
    })
    .map(({ id, attributes }) => ({
      id,
      ...attributes,
    }));

  return {
    id,
    name,
    businessUnit,
    spend: currentSpend,
    organizationNumber,
    language,
    selectedContactPerson,
    categoriesHierarchy,
    currentNegotiations,
    completedNegotiations,
    contacts,
  };
}

export type ParsedSupplier = ReturnType<typeof parseGetSupplierResponse>;
