import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthenticatedLayout } from '@/layouts/AuthenticatedLayout/AuthenticatedLayout';
import { useGetCustomerBusinessUnitsQuery } from '@/modules/customer/api/businessUnits/useGetCustomerBusinessUnitsQuery';
import { onboardedRoutes } from '@/modules/router/onboardedRoutes';
import { useGetSupplierCategoriesQuery } from '@/modules/suppliers/api/hooks/useGetSupplierCategoriesQuery';
import { SupplierForm } from '@/modules/suppliers/components/SupplierForm/SupplierForm';
import { SupplierFormType } from '@/modules/suppliers/components/SupplierForm/supplierFormStruct';
import { mapCustomerBusinessUnitsToSelectOptions } from '@/modules/suppliers/helpers/mapCustomerBusinessUnitsToSelectOptions';
import { mapSupplierCategoriesToSelectOptions } from '@/modules/suppliers/helpers/supplierCategoriesHelpers';
import { useEditSupplierMutation } from '@/modules/suppliers/hooks/useEditSupplierMutation';
import { useSupplierFormContext } from '@/modules/suppliers/hooks/useSupplierFormContext';
import { PageHeader } from '@/modules/theme/components/PageHeader/PageHeader';
import { PageMeta } from '@/modules/theme/components/PageMeta';

import { useGetSupplierQuery } from '../SupplierPage/hooks/useGetSupplierQuery';

import {
  PageContentStyled,
  PageWrapperStyled,
} from './SupplierEditPage.styled';

export const SupplierEditPage = () => {
  const { t } = useTranslation('pages/SupplierEditPage');
  const { supplierId } = onboardedRoutes.useGetRouteParams('editSupplier');

  const { data: supplier, isPending: isSupplierPending } = useGetSupplierQuery({
    supplierId,
  });
  const { data: categories, isPending: isCategoriesPending } =
    useGetSupplierCategoriesQuery();
  const { data: businessUnits, isPending: isBusinessUnitsPending } =
    useGetCustomerBusinessUnitsQuery();
  const editSupplierMutation = useEditSupplierMutation({ supplierId });
  const { contacts, setContacts } = useSupplierFormContext();

  const mappedSupplierCategories = categories
    ? mapSupplierCategoriesToSelectOptions(categories)
    : null;
  const mappedBusinessUnits = businessUnits
    ? mapCustomerBusinessUnitsToSelectOptions(businessUnits)
    : null;

  useEffect(() => {
    if (supplier) {
      setContacts(supplier.contacts);
    }
    return () => setContacts(null);
  }, [setContacts, supplier]);

  const handleOnSubmit = ({
    name,
    organizationNumber,
    language,
    categoryId,
    businessUnitId,
    defaultContactId,
  }: SupplierFormType) => {
    if (supplier) {
      editSupplierMutation.mutate({
        id: supplier.id,
        attributes: {
          name,
          organizationNumber,
          language,
          currentSpend: supplier.spend,
        },
        categoryId,
        defaultContactId,
        businessUnitId,
      });
    }
  };

  return (
    <AuthenticatedLayout>
      <PageMeta title={t('Edit supplier')} description={t('Edit supplier')} />
      <PageWrapperStyled>
        <PageHeader text={t('Edit supplier')} />
        <PageContentStyled>
          <SupplierForm
            mode="edit"
            onSubmit={handleOnSubmit}
            supplierData={supplier}
            contactPersonsData={contacts}
            categoriesData={mappedSupplierCategories}
            businessUnitsData={mappedBusinessUnits}
            isSubmitting={editSupplierMutation.isPending}
            isLoading={{
              isSupplierLoading: isSupplierPending,
              isCategoriesLoading: isCategoriesPending,
              isBusinessUnitsLoading: isBusinessUnitsPending,
            }}
          />
        </PageContentStyled>
      </PageWrapperStyled>
    </AuthenticatedLayout>
  );
};
