export enum SupplierTableColumn {
  COMPANY = 'name',
  SPEND = 'currentSpend',
  EMAIL = 'defaultContact.email',
  CATEGORY = 'category.name',
}

export enum Order {
  asc = 'asc',
  desc = 'desc',
}

export type SortRule = {
  column: SupplierTableColumn;
  order: Order;
} | null;

export type SuppliersQueryParams = {
  page: number;
  size: number;
  sortBy?: SupplierTableColumn;
  order?: Order;
};

export enum SupplierState {
  INVITED = 'invited',
  OPENED_EMAIL = 'openedEmail',
  VISITED = 'visited',
  REGISTERED = 'registered',
  ANSWERED = 'answered',
  OFFERED_DISCOUNT = 'offeredDiscount',
}
