import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { notify } from '@/modules/notify/notify';
import { onboardedRoutes } from '@/modules/router/onboardedRoutes';
import { defaultSuppliersQueryParams } from '@/modules/suppliers/utils/consts';

import { SuppliersPage } from './SuppliersPage';

export const SuppliersScene = () => {
  const { t } = useTranslation('pages/SuppliersPage');

  const { queryParams, queryParamsError } =
    onboardedRoutes.useGetQueryParams('suppliers');
  const setQueryParams = onboardedRoutes.useSetQueryParams('suppliers');

  useEffect(() => {
    if (queryParamsError) {
      notify.alert.error(t('Invalid pagination state'));
    }
  }, [queryParamsError, t]);

  if (queryParamsError) {
    return (
      <Navigate
        to={onboardedRoutes.buildUrl('suppliers', {
          query: defaultSuppliersQueryParams,
        })}
      />
    );
  }

  return (
    <SuppliersPage queryParams={queryParams} setQueryParams={setQueryParams} />
  );
};
