import { useTranslation } from 'react-i18next';

import { Button } from '@/modules/theme/components/Button/Button';
import { Dialog } from '@/modules/theme/components/Dialog/Dialog';

import { useSetNegotiationContractPeriodMutation } from './useSetNegotiationContractPeriodMutation';

type RemoveNegotiationContractPeriodDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  negotiationId: string;
  termId: string;
};

export const RemoveNegotiationContractPeriodDialog = ({
  isOpen,
  onClose,
  negotiationId,
  termId,
}: RemoveNegotiationContractPeriodDialogProps) => {
  const { t } = useTranslation('pages/Terms');

  const setNegotiationContractPeriodMutation =
    useSetNegotiationContractPeriodMutation();

  const removeNegotiationContractPeriod = () => {
    setNegotiationContractPeriodMutation.mutate(
      {
        negotiationId,
        termId,
        startDate: null,
        endDate: null,
      },
      { onSuccess: onClose }
    );
  };

  return (
    <Dialog
      isOpen={isOpen}
      hasCloseIcon
      onClose={onClose}
      title={t('Remove negotiation period')}
      description={t('Are you sure you want to remove negotiation period?')}
    >
      <Button
        variant="outline"
        loading={setNegotiationContractPeriodMutation.isPending}
        onClick={removeNegotiationContractPeriod}
      >
        {t('Remove')}
      </Button>
    </Dialog>
  );
};
