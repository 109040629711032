import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { defaultPrefixedSuppliersQueryParams } from '@/modules/negotiation/helpers/defaultPrefixedSuppliersQueryParams';
import { notify } from '@/modules/notify/notify';
import { onboardedRoutes } from '@/modules/router/onboardedRoutes';

import { ActiveNegotiationPage } from './ActiveNegotiationPage';

export const ActiveNegotiationScene = () => {
  const { t } = useTranslation('pages/ActiveNegotiationPage');

  const { queryParams, queryParamsError } =
    onboardedRoutes.useGetQueryParams('activeNegotiation');

  const { negotiationId } =
    onboardedRoutes.useGetRouteParams('activeNegotiation');

  useEffect(() => {
    if (queryParamsError) {
      notify.alert.error(t('Invalid pagination state'));
    }
  }, [queryParamsError, t]);

  if (queryParamsError) {
    return (
      <Navigate
        to={onboardedRoutes.buildUrl('activeNegotiation', {
          params: { negotiationId },
          query: defaultPrefixedSuppliersQueryParams,
        })}
      />
    );
  }

  return (
    <ActiveNegotiationPage
      queryParams={queryParams}
      negotiationId={negotiationId}
    />
  );
};
