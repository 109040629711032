import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AuthBackground } from '@/layouts/common/AuthBackground';
import { anonymousRoutes } from '@/modules/router/anonymousRoutes';
import { ButtonLink } from '@/modules/theme/components/ButtonLink';
import { Heading } from '@/modules/theme/components/Typography';
import { white } from '@/modules/theme/utils/colors';

export const CustomerIdNotFoundPage = () => {
  const { t } = useTranslation('pages/CustomerIdNotFoundPage');
  return (
    <ContentWrapperStyled>
      <Heading variant="h2" as="h1" color={white}>
        {t('Your account is not associated with any customer.')}
      </Heading>
      <Heading variant="h3" as="h1" color={white}>
        {t(
          'If you should find negotiation information here, contact the platform administrator.'
        )}
      </Heading>
      <ButtonLinkStyled variant="light" to={anonymousRoutes.buildUrl('signIn')}>
        {t('Back to sign in page')}
      </ButtonLinkStyled>
    </ContentWrapperStyled>
  );
};

const ContentWrapperStyled = styled(AuthBackground)(
  ({ theme: { spacing } }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    gap: spacing(2),
    padding: spacing([3, 5]),
  })
);

const ButtonLinkStyled = styled(ButtonLink)(({ theme: { spacing } }) => ({
  '&:hover, &:focus': {
    color: white,
  },
  marginTop: spacing(2),
}));
