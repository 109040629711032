import { PropsWithChildren } from 'react';
import { ValueContainerProps } from 'react-select';
import { components, GroupBase } from 'react-select';
import styled from 'styled-components';

import { ReactComponent as MagnifierIcon } from '@/assets/icons/magnifierIcon.svg';

export const ValueContainer = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: PropsWithChildren<ValueContainerProps<Option, IsMulti, Group>>
) => (
  <ValueContainerStyled>
    <MagnifierIcon />
    <components.ValueContainer {...props} />
  </ValueContainerStyled>
);

const ValueContainerStyled = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  minHeight: 40,
  marginLeft: spacing(1),
}));
