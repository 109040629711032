import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import * as sup from 'superstruct';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { queryKeys } from '@/modules/api/queryKeys';
import { JsonApiClient } from '@/modules/api/utils/createJsonApiClient';
import { createResponseStruct } from '@/modules/api/utils/jsonApiStructs';
import { constructMeta } from '@/modules/reactQuery/constructMeta';

import {
  businessUnitFromResource,
  businessUnitResourceStruct,
} from './businessUnitsHelpers';

export { useGetCustomerBusinessUnitsQuery };

const useGetCustomerBusinessUnitsQuery = () => {
  const jsonApi = useJsonApi();
  const { t } = useTranslation('apiMessages/useGetCustomerBusinessUnitsQuery');

  return useQuery({
    queryKey: [queryKeys.customer.getCustomerBusinessUnits],
    queryFn: getCustomerBusinessUnitsQuery(jsonApi),
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t("You don't have access to business units"),
        },
      },
    }),
  });
};

const getCustomerBusinessUnitsResponseStruct = createResponseStruct({
  data: sup.array(businessUnitResourceStruct),
});

function getCustomerBusinessUnitsQuery(jsonApi: JsonApiClient) {
  return async () => {
    const response = await jsonApi.getMany({
      path: '/me/customer/business_units',
      responseStruct: getCustomerBusinessUnitsResponseStruct,
    });

    return response.data.map(businessUnitFromResource);
  };
}
