import styled, { css, keyframes } from 'styled-components';

import closeButtonIconSrc from '@/assets/icons/closeIcon.svg';
import { white } from '@/modules/theme/utils/colors';

import { ToastVariant } from '../types';

import errorIconSrc from './helpers/error-icon.svg';
import successIconSrc from './helpers/success-icon.svg';

type NotificationProps = {
  variant: ToastVariant;
  visible: boolean;
};

export const ContainerStyled = styled('div')<NotificationProps>(
  ({ theme: { spacing, boxShadows, borderRadius, typography, palette } }) => ({
    padding: spacing(1),
    minWidth: 300,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    boxShadow: boxShadows.tooltip,
    borderRadius: borderRadius.default,
    fontSize: typography.pxToRem(12),
    fontWeight: typography.fontWeight.bold,
    color: palette.default.text,
    backgroundColor: white,
    '&:before': {
      content: "''",
      width: 22,
      height: 22,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      marginRight: spacing(1),
      fontSize: typography.pxToRem(12),
    },
  }),
  ({ variant }) => toastVariants[variant],
  ({ visible }) =>
    visible
      ? css`
          animation: ${enterAnimationKeyframes} 200ms ease-out;
        `
      : css`
          animation: ${leaveAnimationKeyframes} 150ms ease-in forwards;
        `
);

export const ContentStyled = styled('div')({
  flex: 1,
});

export const CloseButtonStyled = styled('button')(
  ({ theme: { borderRadius, spacing } }) => ({
    borderRadius: borderRadius.round,
    appearance: 'none',
    border: 'none',
    width: 16,
    height: 16,
    background: `url(${closeButtonIconSrc}) center/contain no-repeat`,
    cursor: 'pointer',
    marginLeft: spacing(1),

    '&:hover': {
      opacity: 0.8,
    },
  })
);

const toastVariants: Record<
  ToastVariant,
  ReturnType<typeof css<NotificationProps>>
> = {
  success: css({
    '&:before': {
      backgroundImage: `url(${successIconSrc})`,
    },
  }),
  error: css({
    '&:before': {
      backgroundImage: `url(${errorIconSrc})`,
    },
  }),
};

const enterAnimationKeyframes = keyframes({
  '0%': {
    transform: `scale(0.9) translateY(-100%)`,
    opacity: 0,
  },
  '100%': {
    transform: `scale(1) translateY(0)`,
    opacity: 1,
  },
});

const leaveAnimationKeyframes = keyframes({
  '0%': { transform: 'scale(1)', opacity: 1 },
  '100%': { transform: 'scale(0.9)', opacity: 0 },
});
