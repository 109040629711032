import styled from 'styled-components';

import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';

export const StartDateInputLoader = () => {
  return (
    <>
      <TopSkeletonLoaderStyled />
      <MiddleSkeletonLoaderStyled />
      <BottomSkeletonLoaderStyled />
    </>
  );
};

const TopSkeletonLoaderStyled = styled(SkeletonLoader)(
  ({ theme: { spacing } }) => ({
    margin: spacing([2, 0, 0]),
    height: 76,
    width: 350,
  })
);

const MiddleSkeletonLoaderStyled = styled(SkeletonLoader)(
  ({ theme: { spacing } }) => ({
    height: 32,
    width: 150,
    margin: spacing([2, 0, 0]),
  })
);

const BottomSkeletonLoaderStyled = styled(SkeletonLoader)(
  ({ theme: { spacing } }) => ({
    margin: spacing([2, 0, 0]),
    height: 15,
    width: 350,
  })
);
