import { NegotiationState } from '@/modules/negotiation/types/negotiationTypes';
import { blue, dark, mint, orange, red } from '@/modules/theme/utils/colors';

const negotiationColors = {
  [NegotiationState.SUGGESTED]: blue[800],
  [NegotiationState.SETUP]: red[800],
  [NegotiationState.PLANNED]: mint[800],
  [NegotiationState.ACTIVE]: orange[800],
  [NegotiationState.COMPLETED]: dark[400],
};

export const getNegotiationTypeColor = (state: NegotiationState) => {
  const notLaunched =
    state === NegotiationState.DRAFT || state === NegotiationState.ESTIMATED;
  if (notLaunched) throw new Error(`unsupported negotiation type: ${state}`);

  return negotiationColors[state];
};
