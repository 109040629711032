import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { updateNegotiationKeywordMutation } from '@/modules/negotiation/api/mutations/updateNegotiationKeywordMutation';
import { constructMeta } from '@/modules/reactQuery/constructMeta';

export const useUpdateNegotiationMutation = () => {
  const jsonApi = useJsonApi();
  const { t } = useTranslation(
    'apiMessages/useUpdateNegotiationKeywordMutation'
  );

  return useMutation({
    mutationFn: updateNegotiationKeywordMutation(jsonApi),
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t("You don't have access to update this keyword."),
        },
        404: {
          NEGOTIATION_NOT_FOUND: t(
            'Negotiation not found. Please refresh the page or contact the administrator.'
          ),
          KEYWORD_NOT_FOUND: t(
            'Keyword not found. Please refresh the page or contact the administrator.'
          ),
        },
        422: {
          NEGOTIATION_PLANNED: t(
            'Negotiation is already planned. Please contact the administrator.'
          ),
        },
      },
    }),
  });
};
