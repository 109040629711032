import { useTranslation } from 'react-i18next';

import { Button } from '@/modules/theme/components/Button/Button';
import { Dialog } from '@/modules/theme/components/Dialog/Dialog';

import { useSetImplementationTypesMutation } from '../ChooseImplementationTypesDialog/useSetImplementationTypesMutation';

type RemoveImplementationTypesDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  termId: string;
  negotiationId: string;
};

export function RemoveImplementationTypesDialog({
  isOpen,
  onClose,
  termId,
  negotiationId,
}: RemoveImplementationTypesDialogProps) {
  const { t } = useTranslation('pages/Terms');

  const setImplementationTypesMutation = useSetImplementationTypesMutation();

  const handleRemoveImplementations = () => {
    setImplementationTypesMutation.mutate(
      {
        negotiationId,
        termId,
        implementations: [],
      },
      { onSuccess: onClose }
    );
  };

  return (
    <Dialog
      isOpen={isOpen}
      hasCloseIcon
      onClose={onClose}
      title={t('Remove implementations')}
      description={t('Are you sure you want to remove all implementations?')}
    >
      <Button
        variant="outline"
        loading={setImplementationTypesMutation.isPending}
        onClick={handleRemoveImplementations}
      >
        {t('Remove')}
      </Button>
    </Dialog>
  );
}
