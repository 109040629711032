import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { black, white } from '@/modules/theme/utils/colors';
import { fade } from '@/modules/theme/utils/fade';

type NavbarStateProps = {
  isOpen?: boolean;
};

export const SidebarWrapperStyled = styled('nav')<NavbarStateProps>(({
  theme: { boxShadows, palette, zIndexes, borderRadius },
  isOpen,
}) => {
  return {
    position: 'fixed',
    height: '100%',
    width: isOpen ? 283 : 70,
    borderTopRightRadius: borderRadius.small,
    borderBottomRightRadius: borderRadius.small,
    boxShadow: boxShadows.default,
    zIndex: zIndexes.sidebar,
    backgroundColor: palette.default.background,
    transition: 'width 0.3s',
  };
});

export const BackdropStyled = styled('div')<NavbarStateProps>(({
  theme: { zIndexes },
  isOpen,
}) => {
  return {
    display: isOpen ? 'block' : 'none',
    position: 'fixed',
    zIndex: zIndexes.sidebarBackdrop,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: fade(black, 0.45),
  };
});

export const SidebarToggleWrapper = styled('div')(({ theme: { spacing } }) => {
  return {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: spacing(2),
  };
});

export const SidebarToggleButtonStyled = styled('button')<NavbarStateProps>(({
  isOpen,
}) => {
  return {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    ...(isOpen && {
      transform: 'rotate(-180deg)',
    }),
  };
});

export const NavigationListItemStyled = styled('li')(
  ({ theme: { spacing } }) => ({
    padding: spacing([0, 2]),
    marginBottom: spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  })
);

const navItemCommonStyles = css(
  ({ theme: { palette, typography, borderRadius } }) => ({
    minHeight: 38,
    color: palette.default.text,
    backgroundColor: palette.default.background,
    border: 'none',
    padding: 0,
    cursor: 'pointer',
    fontSize: typography.pxToRem(16),
    fontWeight: typography.fontWeight.bold,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: borderRadius.default,
    transition: 'background-color 0.2s, color 0.2s',
    svg: {
      transition: 'stroke 0.2s',
      minWidth: 38,
      stroke: palette.primary.main,
    },
    '&:hover': {
      backgroundColor: palette.secondary.light,
      color: white,
      svg: {
        stroke: palette.default.background,
      },
    },
    '&.active': {
      backgroundColor: palette.secondary.main,
      color: white,
      svg: {
        stroke: palette.default.background,
      },
    },
  })
);

export const NavLinkStyled = styled(NavLink)(() => navItemCommonStyles, {
  textDecoration: 'none',
});

export const NavButtonStyled = styled('button')(() => navItemCommonStyles, {
  appearance: 'none',
});

export const NavigationTextStyled = styled('span')(({
  theme: { typography },
}) => {
  return {
    whiteSpace: 'nowrap',
    fontSize: typography.fontSize.default,
    lineHeight: typography.fontSize.large,
    overflow: 'hidden',
  };
});
