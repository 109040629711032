import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { DefaultCheckbox } from '@/modules/form/components/Checkbox/DefaultCheckbox';
import { Button } from '@/modules/theme/components/Button/Button';
import { Dialog } from '@/modules/theme/components/Dialog/Dialog';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { Paragraph, Text } from '@/modules/theme/components/Typography';

import { useResetSupplierMutation } from './hooks/useResetSupplierMutation';

type ResetSupplierDialogProps = {
  negotiationId: string;
  progressId: string | undefined;
  isOpen: boolean;
  onClose: () => void;
};

export const ResetSupplierDialog = ({
  negotiationId,
  progressId,
  isOpen,
  onClose,
}: ResetSupplierDialogProps) => {
  const { t } = useTranslation('modules/negotiations/NegotiationSuppliersList');

  const [isResetConfirmed, setIsResetConfirmed] = useState(false);
  const resetSupplierMutation = useResetSupplierMutation({ negotiationId });

  const onDialogClose = () => {
    setIsResetConfirmed(false);
    onClose();
  };

  return (
    <Dialog
      hasCloseIcon
      onClose={onDialogClose}
      isOpen={isOpen}
      title={t('Reset supplier')}
      variant="large"
    >
      <ContentWrapperStyled>
        <Paragraph alignment="center" margins={[2, 0, 5]}>
          {t('Are you sure you want to reset the progress for this supplier?')}
        </Paragraph>
        <DefaultCheckbox
          id="isResetConfirmed"
          checked={isResetConfirmed}
          onChange={() => setIsResetConfirmed((prevState) => !prevState)}
        >
          <Text bold>{t('Yes I want to reset this supplier')}</Text>
        </DefaultCheckbox>
        <FlexContainer direction="column" align="center" mt={5} gap={2}>
          <Button variant="outline" onClick={onDialogClose}>
            {t('Cancel')}
          </Button>
          <Button
            disabled={!isResetConfirmed}
            loading={resetSupplierMutation.isPending}
            onClick={() =>
              resetSupplierMutation.mutate(
                { progressId },
                {
                  onSuccess: onDialogClose,
                }
              )
            }
          >
            {t('Reset supplier')}
          </Button>
        </FlexContainer>
      </ContentWrapperStyled>
    </Dialog>
  );
};

const ContentWrapperStyled = styled('div')({
  maxWidth: 370,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
});
