import styled from 'styled-components';

import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';

export const PlannedNegotiationMetricsLoader = () => {
  return (
    <ContentWrapperStyled>
      <div>
        <TopSkeletonLoaderStyled />
        <BottomSkeletonLoaderStyled />
      </div>
      <div>
        <AddOnTopLoaderStyled />
        <AddOnBottomLoaderStyled />
        <AddOnBottomLoaderStyled />
      </div>
    </ContentWrapperStyled>
  );
};

const ContentWrapperStyled = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  paddingBottom: spacing(1),
  gap: spacing(6),
  flexWrap: 'wrap',
}));

const TopSkeletonLoaderStyled = styled(SkeletonLoader)({
  width: 180,
  height: 30,
});

const BottomSkeletonLoaderStyled = styled(SkeletonLoader)({
  width: 80,
  height: 50,
});

const AddOnTopLoaderStyled = styled(SkeletonLoader)(
  ({ theme: { spacing } }) => ({
    width: 100,
    marginBottom: spacing(2),
  })
);

const AddOnBottomLoaderStyled = styled(SkeletonLoader)({
  width: 200,
});
