import { useTranslation } from 'react-i18next';

import { SupplierNegotiationState } from '@/modules/negotiation/api/resources/negotiationSelectedSuppliers/negotiationSelectedSuppliersResourceConsts';
import { Text } from '@/modules/theme/components/Typography';

import {
  ProgressBarStyled,
  ProgressBarWrapperStyled,
} from './SupplierProgress.styled';

type SupplierProgressProps = {
  progress: SupplierNegotiationState;
};

const progressValuesMap = {
  [SupplierNegotiationState.INVITED]: 0,
  [SupplierNegotiationState.OPENEDEMAIL]: 25,
  [SupplierNegotiationState.VISITED]: 50,
  [SupplierNegotiationState.REGISTERED]: 75,
  [SupplierNegotiationState.ANSWERED]: 100,
};

export const SupplierProgress = ({ progress }: SupplierProgressProps) => {
  const { t } = useTranslation('modules/negotiations/NegotiationSuppliersList');

  if (progress === SupplierNegotiationState.RECOMMENDED) return <></>;

  if (progress === SupplierNegotiationState.EXCLUDED) {
    return <Text bold>{t('Excluded')}</Text>;
  }

  return (
    <ProgressBarWrapperStyled>
      <ProgressBarStyled
        progress={progress}
        max={100}
        value={progressValuesMap[progress]}
      />
      <Text variant="small">{progressValuesMap[progress]}%</Text>
    </ProgressBarWrapperStyled>
  );
};
