import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { notify } from '@/modules/notify/notify';
import { constructMeta } from '@/modules/reactQuery/constructMeta';

import { deleteSupplierContactMutation } from '../../api/mutations/deleteSupplierContactMutation';

type UseDeleteSupplierContactMutationParams = {
  onSuccess: () => void;
};

export const useDeleteSupplierContactMutation = ({
  onSuccess,
}: UseDeleteSupplierContactMutationParams) => {
  const jsonApi = useJsonApi();
  const { t } = useTranslation('apiMessages/useDeleteSupplierContactMutation');

  return useMutation({
    mutationKey: [deleteSupplierContactMutation.name],
    mutationFn: deleteSupplierContactMutation(jsonApi),
    onSuccess: () => {
      notify.alert.success(t('Supplier contact person deleted successfully'));
      onSuccess();
    },
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHORIZED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t("You don't have access delete supplier contact persons"),
        },
        404: {
          SUPPLIER_CONTACT_NOT_FOUND: t(
            'Supplier contact not found. Please refresh the page or contact the administrator'
          ),
        },
      },
    }),
  });
};
