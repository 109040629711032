import { NegotiationTerms } from '@/modules/negotiation/negotiationTerms/NegotiationTerms/NegotiationTerms';
import { onboardedRoutes } from '@/modules/router/onboardedRoutes';
import { Box } from '@/modules/theme/components/Box';

export const PlannedNegotiationTerms = () => {
  const { negotiationId } =
    onboardedRoutes.useGetRouteParams('plannedNegotiation');
  return (
    <Box paddings={[3.5, 7.5, 1]}>
      <NegotiationTerms negotiationId={negotiationId} />
    </Box>
  );
};
