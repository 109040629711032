import { nanoid } from 'nanoid';
import { PropsWithChildren, useState } from 'react';
import { OptionProps } from 'react-select';
import { components, GroupBase } from 'react-select';
import styled from 'styled-components';

import { dark } from '@/modules/theme/utils/colors';

import { DefaultCheckbox } from '../../Checkbox/DefaultCheckbox';
import { CheckboxLabel } from '../../SelectionLabel';
import { BasicOption } from '../types';

type NestOptions = {
  nestLevel?: number;
};

type NestedDropdownOption = BasicOption & NestOptions;

export const MultiOption = <
  Option extends NestedDropdownOption = NestedDropdownOption,
  IsMulti extends boolean = true,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: PropsWithChildren<OptionProps<Option, IsMulti, Group>>
) => {
  const [id] = useState(() => nanoid());
  const { nestLevel } = props.data;

  return (
    <CustomOptionStyled nestLevel={nestLevel}>
      <components.Option {...props} className="option">
        <CheckboxStyled name={id} id={id} checked={props.isSelected} readOnly>
          {nestLevel === 0 && '- '}
          {props.children}
        </CheckboxStyled>
      </components.Option>
    </CustomOptionStyled>
  );
};
const CustomOptionStyled = styled('div')<NestOptions>(
  ({ theme: { spacing }, nestLevel }) => ({
    // multiple properties are coliding with styled components props.
    // eslint-disable-next-line no-warning-comments
    // TODO: find a way to forward them without using `className`
    '.option': {
      paddingLeft: spacing(nestLevel ? nestLevel * 3 : 1),
      ...(nestLevel === 0 && { backgroundColor: dark[100] }),
    },
  })
);

const CheckboxStyled = styled(DefaultCheckbox)(
  ({ theme: { spacing, typography } }) => ({
    pointerEvents: 'none',
    width: '100%',
    [String(CheckboxLabel)]: {
      fontSize: typography.fontSize.default,
      padding: spacing([0, 3.5, 0, 0]),
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '&:before': {
        left: 'calc(100% - 20px)',
      },
    },
  })
);
