import * as sup from 'superstruct';

import { makeRefinementComposable } from './helpers/makeRefinementComposable';

export const empty = makeRefinementComposable(sup.empty);
export const size = makeRefinementComposable(sup.size);
export const min = makeRefinementComposable(sup.min);
export const max = makeRefinementComposable(sup.max);

export const dateTime = sup.coerce(
  sup.date(),
  sup.string(),
  (value) => new Date(value)
);

export const stringToNumber = sup.coerce(sup.number(), sup.string(), (value) =>
  parseInt(value)
);

export const intQuery = sup.coerce(
  sup.integer(),
  sup.pattern(sup.string(), /^\d+$/),
  Number
);
