import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextArea } from '@/modules/form/components/TextArea/TextArea';
import { Box } from '@/modules/theme/components/Box';

import { SurveyForm } from '../helpers/surveyFormStruct';

import { SurveyFormSection } from './SurveyFormSection';

export const NotesSection = () => {
  const { t } = useTranslation('pages/LeadCustomerSurveyPage');
  const { register } = useFormContext<SurveyForm>();
  return (
    <SurveyFormSection
      number={4}
      title={t(
        'Additional information that could be valuable for the analysis'
      )}
    >
      <Box mr={6}>
        <TextArea
          {...register('notes')}
          errorHidden
          placeholder={t('Text (optional)')}
        />
      </Box>
    </SurveyFormSection>
  );
};
