import styled from 'styled-components';

import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';

export const ActiveNegotiationMetricsLoader = () => {
  return (
    <ContentWrapperStyled>
      <div>
        <TopSkeletonLoaderStyled />
        <BottomSkeletonLoaderStyled />
      </div>
      <div>
        <TopSkeletonLoaderStyled />
        <BottomSkeletonLoaderStyled />
      </div>
    </ContentWrapperStyled>
  );
};

const ContentWrapperStyled = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  paddingBottom: spacing(1),
  gap: spacing(11),
  flexWrap: 'wrap',
}));

const TopSkeletonLoaderStyled = styled(SkeletonLoader)({
  width: 180,
  height: 30,
});

const BottomSkeletonLoaderStyled = styled(SkeletonLoader)({
  width: 80,
  height: 50,
});
