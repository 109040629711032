import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { queryKeys } from '@/modules/api/queryKeys';
import { getNegotiationSuppliersQuery } from '@/modules/negotiation/api/queries/getNegotiationSuppliersQuery';
import {
  NegotiationSuppliersIncludes,
  SupplierNegotiationState,
} from '@/modules/negotiation/api/resources/negotiationSelectedSuppliers/negotiationSelectedSuppliersResourceConsts';
import { constructMeta } from '@/modules/reactQuery/constructMeta';
import { SuppliersOrder } from '@/modules/suppliers/utils/suppliersOrders';

type GetPlannedNegotiationSuppliersQueryParams = {
  pageNumber: number;
  pageSize: number;
  searchQuery?: string;
  category?: string[];
  sort: SuppliersOrder;
  businessUnit?: string[];
};

type UseGetPlannedNegotiationSuppliersQueryPayload = {
  negotiationId: string;
  params: GetPlannedNegotiationSuppliersQueryParams;
};

export const useGetPlannedNegotiationSuppliersQuery = ({
  negotiationId,
  params: { searchQuery, pageNumber, pageSize, sort, category, businessUnit },
}: UseGetPlannedNegotiationSuppliersQueryPayload) => {
  const jsonApi = useJsonApi();
  const { t } = useTranslation(
    'apiMessages/useGetPlannedNegotiationSuppliersQuery'
  );

  const negotiationStates = [SupplierNegotiationState.INVITED];

  return useQuery({
    queryKey: [
      queryKeys.negotiations.getNegotiationSuppliers,
      negotiationId,
      searchQuery,
      pageNumber,
      pageSize,
      sort,
      category,
      businessUnit,
      negotiationStates,
    ],
    queryFn: getNegotiationSuppliersQuery(jsonApi, {
      negotiationId,
      page: pageNumber,
      pageSize: pageSize,
      negotiationStates,
      searchQuery,
      sort,
      category,
      businessUnit,
      includes: [
        NegotiationSuppliersIncludes.DEFAULT_CONTACT,
        NegotiationSuppliersIncludes.CATEGORY,
        NegotiationSuppliersIncludes.BUSINESS_UNIT,
      ],
    }),
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: true,
    staleTime: 5000,
    refetchInterval: 60_000,
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t('You do not have access to see suppliers'),
        },
        404: {
          NEGOTIATION_NOT_FOUND: t('Negotiation has not been found'),
        },
      },
    }),
  });
};
