export type CardVariant = 'financial' | 'counter' | 'pending' | null;

type GetCardVariantParams = {
  isAssignedToPeriod: boolean;
  isFinancialMetricsCard: boolean;
  isPending: boolean;
};

export const getCardVariant = ({
  isAssignedToPeriod,
  isFinancialMetricsCard,
  isPending,
}: GetCardVariantParams): CardVariant => {
  if (isPending) return 'pending';
  if (isAssignedToPeriod) {
    if (isFinancialMetricsCard) return 'financial';
    return 'counter';
  }
  return null;
};
