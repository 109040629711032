import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/modules/theme/components/Button/Button';
import { ButtonLink, LinkType } from '@/modules/theme/components/ButtonLink';
import { useDialogState } from '@/utils/useDialogState';

import {
  ButtonsSkeletonStyled,
  ButtonsWrapperStyled,
  ContentWrapperStyled,
  ParagraphStyled,
  SignetStyled,
} from './ActivateNegotiationSection.styled';
import { ActivateNegotiationDialog } from './components/ActivateNegotiationDialog/ActivateNegotiationDialog';
import { NegotiationValidationDialog } from './components/NegotiationValidationDialog/NegotiationValidationDialog';
import {
  ParseNegotiationValidationResponse,
  parseNegotiationValidationResponse,
} from './helpers/parseNegotiationValidationResponse';
import { useValidateNegotiationMutation } from './hooks/useValidateNegotiationMutation';

type ActivateNegotiationSectionProps = {
  supplierPreviewUrl: string | null | undefined;
  isLoading: boolean;
  negotiationId: string;
};

const validationDefaultState = {
  startDate: {
    isValid: false,
  },
  invitationLetter: {
    isValid: false,
  },
  suppliers: { isValid: false },
  terms: { isValid: false },
  keywords: {
    isValid: false,
    missingLanguages: [],
  },
};

export const ActivateNegotiationSection = ({
  supplierPreviewUrl,
  isLoading,
  negotiationId,
}: ActivateNegotiationSectionProps) => {
  const { t } = useTranslation('pages/NegotiationSetupPage');

  const validationDialog = useDialogState();
  const activateNegotiationDialog = useDialogState();
  const [validationState, setValidationState] =
    useState<ParseNegotiationValidationResponse>(validationDefaultState);

  const validateNegotiationMutation = useValidateNegotiationMutation();

  const validateNegotiation = () => {
    validateNegotiationMutation.mutate(
      { negotiationId },
      {
        onSuccess: () => {
          setValidationState(validationDefaultState);
          activateNegotiationDialog.open();
        },
        onError: (error) => {
          const parsedValidationState =
            parseNegotiationValidationResponse(error);

          setValidationState(parsedValidationState);

          validationDialog.open();
        },
      }
    );
  };

  return (
    <ContentWrapperStyled>
      <ParagraphStyled>
        {t(
          'Before you activate your negotiation you can preview it from your suppliers point of view by clicking the button “Preview as supplier”.'
        )}
      </ParagraphStyled>
      <ParagraphStyled>
        {t(
          '*Note that you can still make changes to your negotiation after activating it (including the CPO-introduction letter) either manually or by contacting us.'
        )}
      </ParagraphStyled>
      {isLoading ? (
        <ButtonsSkeletonStyled />
      ) : (
        <ButtonsWrapperStyled>
          {supplierPreviewUrl && (
            <ButtonLink
              variant="outline"
              linkType={LinkType.External}
              to={supplierPreviewUrl}
            >
              {t('Preview as supplier')}
            </ButtonLink>
          )}
          <Button
            onClick={validateNegotiation}
            loading={validateNegotiationMutation.isPending}
          >
            {t('Finish setup')}
          </Button>
        </ButtonsWrapperStyled>
      )}

      <SignetStyled />
      <NegotiationValidationDialog
        isOpen={validationDialog.isOpen}
        onClose={validationDialog.close}
        validationState={validationState}
      />
      <ActivateNegotiationDialog
        isOpen={activateNegotiationDialog.isOpen}
        negotiationId={negotiationId}
        onClose={activateNegotiationDialog.close}
      />
    </ContentWrapperStyled>
  );
};
