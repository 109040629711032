import styled from 'styled-components';

import { TableCell } from './TableCell';

export const ActionTableCell = styled(TableCell)(
  ({ theme: { spacing, breakpoints } }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    gap: spacing(2),
    alignItems: 'center',

    '&:last-child': {
      paddingRight: spacing(3),
    },

    [breakpoints.down.md]: {
      justifyContent: 'flex-start',
    },
  })
);
