import { ButtonProps } from '../Button/Button';
import { Loader } from '../Loader';

import { DashedButtonStyled, LoaderWrapperStyled } from './DashedButton.styled';

export type DashedButtonSizes = 'small' | 'default';
export type DashedButtonProps = Omit<ButtonProps, 'size' | 'variant'> & {
  size?: DashedButtonSizes;
};

export const DashedButton: React.FC<DashedButtonProps> = ({
  children,
  type = 'button',
  loading,
  size = 'default',
  ...props
}) => {
  return (
    <DashedButtonStyled disabled={loading} size={size} type={type} {...props}>
      {loading ? (
        <LoaderWrapperStyled>
          <Loader size={20} />
        </LoaderWrapperStyled>
      ) : (
        children
      )}
    </DashedButtonStyled>
  );
};
