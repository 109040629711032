import { useTranslation } from 'react-i18next';

import { Paragraph } from '@/modules/theme/components/Typography';

import {
  HeadingStyled,
  IntroSectionWrapperStyled,
  SignetLogoStyled,
  TextWrapperStyled,
} from './UploadSpendAndQuestionnaireIntroSection.styled';

export const UploadSpendAndQuestionnaireIntroSection = () => {
  const { t } = useTranslation('pages/UploadSpendAndQuestionnairePage');

  return (
    <IntroSectionWrapperStyled>
      <TextWrapperStyled>
        <HeadingStyled variant="h2">
          {t('Help us make your analysis even better!')}
        </HeadingStyled>
        <Paragraph mt={2} mb={4}>
          {t(
            'By just analysing your spend file without context we can still suggest negotiations for you. However, the result of the analysis will be even more accurate if you can provide us with the answers to the questions below. With that information you are more likely to get suggestions tailored for your business.'
          )}
        </Paragraph>
      </TextWrapperStyled>
      <SignetLogoStyled />
    </IntroSectionWrapperStyled>
  );
};
