import * as sup from 'superstruct';

import { createResourceStruct } from '@/modules/api/utils/jsonApiStructs';
import { businessUnitIdentifierStruct } from '@/modules/customer/api/businessUnits/businessUnitsHelpers';
import { supplierLanguageStruct } from '@/modules/language/supplierLanguage/supplierLanguageConsts';
import { negotiationIdentifierStruct } from '@/modules/negotiation/api/resources/negotiation/negotiationIdentifierStruct';
import { negotiationSupplierProgressIdentifierStruct } from '@/modules/negotiation/api/resources/negotiationSupplierProgress/negotiationSupplierProgressIdentifierStruct';

import { supplierCategoryIdentifierStruct } from '../supplierCategory/supplierCategoryIdentifierStruct';
import { supplierContactIdentifierStruct } from '../supplierContact/supplierContactIdentifierStruct';

import { supplierIdentifierStruct } from './supplierIdentifierStruct';

export const supplierRelationshipKeys = {
  businessUnit: 'businessUnit',
  category: 'category',
  categoryPath: 'categoryPath',
  contacts: 'contacts',
  defaultContact: 'defaultContact',
  negotiations: 'negotiations',
  negotiationProgress: 'negotiationProgress',
  conflictNegotiations: 'conflictNegotiations',
} as const;

export const supplierResourceStruct = createResourceStruct({
  identifier: supplierIdentifierStruct,
  attributes: sup.object({
    name: sup.string(),
    organizationNumber: sup.nullable(sup.string()),
    language: supplierLanguageStruct,
    currentSpend: sup.string(),
    active: sup.boolean(),
  }),
  relationships: sup.object({
    [supplierRelationshipKeys.businessUnit]: sup.object({
      data: sup.nullable(businessUnitIdentifierStruct),
    }),
    [supplierRelationshipKeys.category]: sup.object({
      data: sup.nullable(supplierCategoryIdentifierStruct),
    }),
    [supplierRelationshipKeys.categoryPath]: sup.object({
      data: sup.array(supplierCategoryIdentifierStruct),
    }),
    [supplierRelationshipKeys.contacts]: sup.object({
      data: sup.array(supplierContactIdentifierStruct),
    }),
    [supplierRelationshipKeys.defaultContact]: sup.object({
      data: sup.nullable(supplierContactIdentifierStruct),
    }),
    [supplierRelationshipKeys.negotiations]: sup.optional(
      sup.object({
        data: sup.array(negotiationIdentifierStruct),
      })
    ),
    [supplierRelationshipKeys.conflictNegotiations]: sup.optional(
      sup.object({
        data: sup.array(negotiationIdentifierStruct),
      })
    ),
    [supplierRelationshipKeys.negotiationProgress]: sup.optional(
      sup.object({
        data: sup.nullable(negotiationSupplierProgressIdentifierStruct),
      })
    ),
  }),
});

export type SupplierResource = sup.Infer<typeof supplierResourceStruct>;
